import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '@app/auth';
import { catchError, mergeMap } from 'rxjs/operators';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
    constructor(private readonly authService: AuthService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let retries = 0;
        return next.handle(req).pipe(
            catchError((err, restart) => {
                if (err.status === 401 && retries === 0) {
                    retries++;
                    return this.authService.getUpdateToken$().pipe(
                        mergeMap(() => restart),
                        catchError(() => {
                            return restart;
                        })
                    );
                }

                if (retries > 0) {
                    this.authService.logout();
                }

                return throwError(err);
            })
        );
    }
}

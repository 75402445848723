export function drawLegend(chartInstance: any, topMargin = 26) {
    const chartModel = chartInstance.getModel();
    const legendModels = chartModel.findComponents({
        mainType: 'legend',
    });
    if (!legendModels || !legendModels.length) {
        return;
    }

    const legendModel = legendModels[0];

    const items = legendModel.getData();
    const height = items.length > 25 ? 90 : 80;
    chartInstance.setOption({
        legend: {
            selector: ['all', 'inverse'],
            selectorPosition: 'start',
        }
    });

    const legendWrapper = document.createElement('div');
    legendWrapper.style.cssText = `
            position: absolute; bottom: 0; left: 0; right: 0; width: 100%; height: ${height - topMargin}px;
            overflow-y: auto; margin: 0 1.5%; display: flex; align-items: center; background: transparent;`;

    const legendContainer = document.createElement('div');
    legendContainer.className = 'legend-container d-flex justify-content-center';
    legendContainer.style.cssText = 'display: flex; flex-wrap: wrap; width: 100%; max-height: 100%;';
    legendWrapper.appendChild(legendContainer);

    const hostEl = chartInstance.getDom();
    hostEl.appendChild(legendWrapper);

    const dispatchAction = (type: string, seriesName: string) =>
        chartInstance.dispatchAction({
            type,
            seriesName,
        });

    function addLegendItemToContainer(name: string, color: string) {
        const legendItem = createLegendItem(name, color);
        legendContainer.appendChild(legendItem);
        legendItem.onmouseenter = () => dispatchAction('highlight', name);
        legendItem.onmouseleave = () => dispatchAction('downplay', name);
        let selected = false;
        legendItem.onclick = () => {
            chartInstance.dispatchAction({
                type: 'legendToggleSelect',
                name,
            });
            selected = !selected;
            legendItem.style.opacity = selected ? '.4' : '1';
        };

        let showTimeout: number;
        let tooltip: HTMLDivElement;

        legendItem.onmousemove = event => {
            if (!legendIsOverflow(event)) {
                return;
            }

            if (!tooltip) {
                window.clearTimeout(showTimeout);

                showTimeout = window.setTimeout(() => {
                    if (!tooltip) {
                        tooltip = createTooltip(name);
                        document.body.appendChild(tooltip);
                    }

                    updateTooltipPosition(tooltip, event);
                }, 200);
            } else {
                updateTooltipPosition(tooltip, event);
            }
        };

        legendItem.onmouseout = event => {
            if (!legendIsOverflow(event)) {
                return;
            }

            clearTimeout(showTimeout);
            if (tooltip) {
                tooltip.parentNode.removeChild(tooltip);
                tooltip = null;
            }
        };
    }

    items.forEach((itemModel: any) => {
        const name = itemModel.get('name');
        const seriesModel = chartModel.getSeriesByName(name)[0];

        // Series legend
        if (seriesModel) {
            const data = seriesModel.getDataParams(0);
            let color = data.color;

            // If color is a callback function
            if (typeof color === 'function') {
                // Use the first data
                color = color(seriesModel.getDataParams(0));
            }

            addLegendItemToContainer(name, color);
        } else {
            // Data legend of pie, funnel
            chartModel.eachRawSeries((model: any) => {
                if (model.legendDataProvider) {
                    const data = model.legendDataProvider();
                    const idx = data.indexOfName(name);
                    if (idx < 0) {
                        return;
                    }

                    const color = data.getItemVisual(idx, 'color');
                    addLegendItemToContainer(name, color);
                }
            });
        }
    });
}

export function createTooltip(text: string): HTMLDivElement {
    const tooltip = document.createElement('div');

    tooltip.style.cssText = `
        position: fixed;
        padding: 10px;
        background: #fff;
        border-radius: 5px;
        transform: translate(-50%, -135%);
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    `;

    tooltip.textContent = text;

    return tooltip;
}

export function updateTooltipPosition(tooltip: HTMLDivElement, event: MouseEvent) {
    tooltip.style.top = `${event.clientY}px`;
    tooltip.style.left = `${event.clientX}px`;
    tooltip.style.zIndex = '9999999';
}

function createLegendItem(name: string, color: string) {
    const itemRepe = document.createElement('div');
    itemRepe.className = 'legend-item-repeat';
    itemRepe.style.cssText = `display:inline-block;width: 23%;`;
    const itemEl = document.createElement('div');
    itemEl.className = 'legend-item';
    itemEl.style.cssText = `
             padding-left: 18px;line-height: 15px; font-size: 11; height: 15px; margin-bottom: 3px; margin-right: 2px;
             flex: 0 0 16%;white-space: nowrap; text-overflow: ellipsis; overflow: hidden; min-width: 100px; cursor: pointer;`;
    itemEl.textContent = name;
    const itemColor = document.createElement('span');
    itemColor.className = 'legend-item-color';
    itemColor.style.cssText = `
             background: ${color};width:12px;height:12px;border-radius:50%;display:inline-block`;
    itemColor.appendChild(itemEl);
    itemRepe.appendChild(itemColor);
    return itemRepe;
}

function legendIsOverflow(e: MouseEvent) {
    const target = e.target as HTMLDivElement;

    return target.offsetWidth < target.scrollWidth;
}

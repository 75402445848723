import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from '@app/shared/components/basic/not-found/not-found.component';
import { LocationPermissionGuard } from '@app/core/services/location-permission.guard';
import { AccessDeniedComponent } from '@app/shared/components/basic/access-denied/access-denied.component';
import { ROLE_ADMIN, ROLE_CONSUMER, ROLE_PRODUCER, ROLE_USER_GROUP_ADMIN } from '@app/core/models/user.model';
import { LoginPermissionGuard } from "@app/core/services/login-permission.guard";

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./pages/landing-page/landing-page.module').then(m => m.LandingPageModule),
    },
    {
        path: 'assets',
        canActivate: [LoginPermissionGuard],
        loadChildren: () => import('./pages/assets-page/assets-page.module').then(m => m.AssetsPageModule),
    },
    {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard-page/dashboard-page.module').then(m => m.DashboardPageModule),
        canActivate: [LocationPermissionGuard],
        data: {
            roles: [ROLE_PRODUCER, ROLE_USER_GROUP_ADMIN, ROLE_ADMIN],
        },
    },
    {
        path: 'manage-assets',
        loadChildren: () => import('./pages/manage-assets-page/manage-assets-page.module').then(m => m.ManageAssetsPageModule),
        canActivate: [LocationPermissionGuard],
        data: {
            roles: [ROLE_PRODUCER, ROLE_USER_GROUP_ADMIN, ROLE_ADMIN],
        },
    },
    {
        path: 'users',
        loadChildren: () => import('./pages/users-page/users-page.module').then(m => m.UsersPageModule),
        canActivate: [LocationPermissionGuard],
        data: {
            roles: [ROLE_ADMIN],
        },
    },
    {
        path: 'groups',
        loadChildren: () => import('./pages/groups-page/groups-page.module').then(m => m.GroupsPageModule),
        canActivate: [LocationPermissionGuard],
        data: {
            roles: [ROLE_USER_GROUP_ADMIN, ROLE_ADMIN],
        },
    },
    {
        path: 'profile',
        loadChildren: () => import('./pages/user-profile-page/user-profile-page.module').then(m => m.UserProfilePageModule),
        canActivate: [LocationPermissionGuard],
    },
    {
        path: 'manage-landing-page',
        loadChildren: () => import('./pages/manage-landing-page/manage-landing-page.module').then(m => m.ManageLandingPageModule),
        canActivate: [LocationPermissionGuard],
        data: {
            roles: [ROLE_ADMIN],
        },
    },
    {
        path: 'messages',
        loadChildren: () => import('./pages/messages-page/messages-page.module').then(m => m.MessagesPageModule),
        canActivate: [LocationPermissionGuard],
        data: {
            roles: [ROLE_CONSUMER, ROLE_PRODUCER, ROLE_USER_GROUP_ADMIN, ROLE_ADMIN],
        },
    },
    {
        path: 'about',
        loadChildren: () => import('./pages/about-page/about-page.module').then(m => m.AboutPageModule),
    },
    {
        path: 'community',
        loadChildren: () => import('./pages/community-page/community-page.module').then(m => m.CommunityPageModule),
    },
    {
        path: 'access-denied',
        component: AccessDeniedComponent,
    },
    { path: '**', component: NotFoundComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AuthService } from '@app/auth';
import { TranslationService } from '@app/core/services/translation.service';
import { ROLE_CONSUMER } from '@app/core/models/user.model';

@Injectable({
    providedIn: 'root',
})
export class LocationPermissionGuard implements CanActivate {
    constructor(
        private readonly authService: AuthService,
        private readonly router: Router,
        private readonly toastrService: ToastrService,
        private readonly translationService: TranslationService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this.authService.onAfterLogin$.pipe(
            switchMap(() => {
                const access = this.authService.hasAnyRole(route.data?.roles || [ROLE_CONSUMER]);
                if (access) {
                    return of(true);
                }
                return this.translationService.translateObject('accessDenied').pipe(
                    map(accessDeniedTransl => {
                        this.toastrService.error(accessDeniedTransl.toastrMessage, accessDeniedTransl.altText);
                        return this.router.parseUrl('/');
                    })
                );
            })
        );
    }
}

import { Subject } from 'rxjs';

import { EChartsChart } from '../echarts.chart';
import { drawLegend } from '../pie.legend';
import { Injector } from '@angular/core';
import { qualitativePalette, subTextColor, textColor } from '../charts-colors';

export class PieEChartsChart extends EChartsChart {
    options: Record<string, any> = {
        textStyle: {
            fontFamily: 'sans-serif',
            fontSize: 12,
        },
        grid: {
            top: '5%',
            bottom: '40%',
        },
        title: {
            text: '',
            subtext: '',
            textAlign: 'center',
            textVerticalAlign: 'top',
            left: '50%',
            textStyle: {
                color: textColor(),
                fontSize: 14,
                fontWeight: '500',
                fontFamily: 'sans-serif',
            },
            subtextStyle: {
                color: subTextColor(),
                fontSize: 12,
                fontFamily: 'sans-serif',
            }
        },
        xAxis: {
            show: false,
            name: '',
        },
        yAxis: {
            show: false,
            name: '',
        },
        legend: {
            bottom: '5',
            icon: 'circle',
            width: '85%',
            textStyle: {
                fontSize: 11,
                color: textColor()
            },
            selector: ['all', 'inverse'],
            selectorPosition: 'start',
        },
        series: [] as any[],
        color: qualitativePalette,
        tooltip: {
            trigger: 'item',
            textStyle: {
                fontSize: 12,
            },
        },
    };

    typeAttributes = {
        type: 'pie',
        stillShowZeroSum: false,
        center: ['50%', '50%'],
        radius: ['0', '55%']
    };

    onCustomize = false;
    editWidgetMode = false;

    assignOptions(options: Record<string, any>) {
        if (options.series && options.series[0].data && options.series[0].data.length > 6) {
            this.options.legend['type'] = 'scroll';
            this.options.legend['pageFormatter'] = 'page {current} of {total}';
            this.options.legend['pageButtonItemGap'] = 30;
        }

        // table data available for the widget. So show table on the remaining width
        if (options.tableHeader1) {
            if (options.onCustomize || options.editWidgetMode) {
                this.typeAttributes.center = ['50%', '50%'];
            } else {
                this.typeAttributes.center = ['25%', '40%'];
            }
        }
        options.series.forEach((element: any) => {
            element.label = {
                show: false
            };
            element.labelLine = {
                show: false
            };

            element.data = element.data.map((elem: any, i: number) => ({
                name: elem['name'],
                value: elem['y'] !== undefined ? elem['y'] : elem.value,
                itemStyle: {
                    color: options.colors[(i % options.colors.length)],
                },
            }));
        });

        this.onCustomize = options.onCustomize;
        this.editWidgetMode = options.editWidgetMode;
        super.assignOptions(options);
    }

    handleEvents(emitter: Subject<{ type: string; payload: any; }>, chartInstance: any, injector: Injector) {
        if (!chartInstance) {
            return;
        }

        chartInstance.on('click', 'series.pie', (event: any) => {
            emitter.next({
                type: 'barClicked',
                payload: {
                    barName: event.name
                }
            });
        });

        if (!this.onCustomize && !this.editWidgetMode && this.options.hasOwnProperty('tableHeader1') && this.options.tableHeader1 !== '') {
            drawLegend(chartInstance, injector);
        }
        super.handleEvents(emitter, chartInstance);
    }
}

import {DefaultHttpClient} from '../services/default/default-http-client';
import {Observable} from 'rxjs';
import {DefaultHashPathResolver} from "../services/default/default-hash-path-resolver";
import {DefaultEnvResolver} from "../services/default/default-env-resolver";

export interface IHttpClient {
    post(url: string, data: any, params: any, logEnabled?: boolean): Promise<any | undefined> | Observable<any | undefined>;
    setToken(token: string): void;
    setHeaderName(header: string): void;
}

export interface IUserSession {
    readonly id: string;
    readonly lastLoginTime?: number;
    readonly roles?: string[];
}

export interface IEnv {
    environment: string,
    envName?: string,
    envId: string;
    customer?: string;
    product?: string;
}

export interface IUserSessionResolver {
    getUserSession(): Promise<IUserSession> | Observable<IUserSession>;
}

export interface IEnvResolver {
    getEnv(): Promise<IEnv> | Observable<IEnv>;
}

export interface IPagePathResolver {
    getPath(): string;

    setPath(page: string): void;
}

export class KeepNIConfig {
    constructor(
        public serviceUrl?: string,
        public userSessionResolver?: IUserSessionResolver,
        public httpClient: IHttpClient = new DefaultHttpClient(),
        public interval = 10_000,
        public envResolver: IEnvResolver = new DefaultEnvResolver(),
        public pagePathResolver: IPagePathResolver = new DefaultHashPathResolver()
    ) {
    }
}

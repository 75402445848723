<lib-stream-wrapper [dataStream$]="rootCategories$" class="categories-wrapper">
    <ng-template contentTemplate let-rootCategories="data">
        <lib-card class="p-0 mb-3" [staticCard]="true" [withShadow]="true"
                  *ngFor="let rootCategory of rootCategories; let i = index"
        >
            <h2 class="mt-0 p-3">{{ rootCategory.name }}</h2>
            <ngb-accordion class="categories-accordion font-14px" #accordion>
                <ngb-panel *ngFor="let primaryCategory of rootCategory.children"
                           [id]="'category-' + primaryCategory.id"
                >
                    <ng-template ngbPanelHeader let-opened="opened">
                        <div
                            class="d-flex align-items-center cursor-pointer"
                            [class.closed]="!opened"
                            (click)="primaryCategory.children?.length && accordion.toggle('category-' + primaryCategory.id)"
                        >
                            <div class="toggle-icon">
                                <fa-icon *ngIf="primaryCategory.children?.length"
                                         [icon]="opened ? 'chevron-up' : 'chevron-down'"
                                ></fa-icon>
                            </div>

                            <span class="col pl-0 category-name"
                                  [ngbTooltip]="primaryCategory.name"
                                  triggers="manual"
                                  libTooltipWhenOverflow
                                  container="body"
                                  placement="top"
                            >
                                {{ primaryCategory.name }}
                            </span>
                        </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <div class="child-category color-brand cursor-pointer"
                             [class.child-category--selected]="selectedCategoryFilter?.id === primaryCategory.id"
                             (click)="selectCategory(primaryCategory.id, true)"
                        >
                            <div class="text-truncate"
                                 [ngbTooltip]="'All ' + primaryCategory.name"
                                 container="body"
                                 placement="top"
                                 triggers="manual"
                                 libTooltipWhenOverflow
                            >
                                All {{ primaryCategory.name }}
                            </div>
                            <fa-icon *ngIf="selectedCategoryFilter?.id === primaryCategory.id"
                                     icon="simpleCheck" class="d-block font-24px"
                            ></fa-icon>
                        </div>
                        <div *ngFor="let secondaryCategory of primaryCategory.children"
                             class="child-category color-brand cursor-pointer"
                             [class.child-category--selected]="selectedCategoryFilter?.id === secondaryCategory.id"
                             (click)="selectCategory(secondaryCategory.id, false)"
                        >
                            <div class="text-truncate"
                                 [ngbTooltip]="secondaryCategory.name"
                                 container="body"
                                 placement="top"
                                 triggers="manual"
                                 libTooltipWhenOverflow
                            >
                                {{ secondaryCategory.name }}
                            </div>
                            <fa-icon *ngIf="selectedCategoryFilter?.id === secondaryCategory.id"
                                     icon="simpleCheck" class="d-block font-24px"
                            ></fa-icon>
                        </div>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </lib-card>
    </ng-template>
</lib-stream-wrapper>

<lib-stream-wrapper [dataStream$]="filters$" class="filters-wrapper">
    <ng-template contentTemplate let-filters="data">
        <lib-card *ngIf="filters?.length" class="p-0" [staticCard]="true" [withShadow]="true">
            <h2 class="mt-0 p-3">{{ 'assets.page.filters' | transloco }}</h2>
            <ngb-accordion #accordion class="filters-accordion font-14px">
                <ngb-panel *ngFor="let filter of filters" [id]="'filter-' + filter.id + '-' + filter.type">
                    <ng-template ngbPanelHeader let-opened="opened">
                        <div
                            class="d-flex align-items-center cursor-pointer"
                            [class.closed]="!opened"
                            (click)="filter.children?.length && accordion.toggle('filter-' + filter.id + '-' + filter.type)"
                        >
                            <div class="toggle-icon">
                                <fa-icon *ngIf="filter.children?.length"
                                         [icon]="opened ? 'chevron-up' : 'chevron-down'"
                                ></fa-icon>
                            </div>

                            <span class="col pl-0 filter-name"
                                  [ngbTooltip]="filter.name"
                                  triggers="manual"
                                  libTooltipWhenOverflow
                                  container="body"
                                  placement="top"
                            >
                                {{ filter.name }}
                            </span>
                            <span *ngIf="filter.checked" class="pr-2 color-brand">
                                <fa-icon icon="checkAll" class="mr-2"></fa-icon>
                                {{ filter.checked }} {{ 'assets.page.selected' | transloco }}
                            </span>
                        </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <ng-container *ngIf="toFilters(filter.children) as children">
                            <ng-container
                                *ngFor="let child of children.slice(0, getIsFilterExpanded(filter.id) ? children.length : 12)"
                            >
                                <div class="child-filter color-brand d-flex align-items-center">
                                    <checkbox class="mb-3"
                                              [ngbTooltip]="child.name"
                                              container="body"
                                              placement="top-left"
                                              [openDelay]="500"
                                              [value]="child.value"
                                              (change)="handleCheckbox(child, $event)"
                                    >
                                        {{ child.name }}
                                    </checkbox>
                                </div>
                            </ng-container>
                            <button *ngIf="children.length > 12"
                                    class="btn btn-link size-s ml-40px mb-3"
                                    (click)="setIsFilterExpanded(filter.id, !getIsFilterExpanded(filter.id))"
                            >
                                <fa-icon icon="{{ getIsFilterExpanded(filter.id) ? 'eyeOff' : 'facEyeOpen' }}"
                                         class="font-14px mr-7px"
                                ></fa-icon>
                                {{ (getIsFilterExpanded(filter.id) ? 'assets.listWrapper.showLess' : 'assets.listWrapper.showMore') | transloco }}
                            </button>
                        </ng-container>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </lib-card>
    </ng-template>
</lib-stream-wrapper>

import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RoleDto } from '@app/core/models/user.model';
import { ENV_TOKEN } from '@app/tokens';

@Injectable({
    providedIn: 'root',
})
export class RolesService {
    private readonly baseApi = this.env.rolesApiURL;

    constructor(@Inject(ENV_TOKEN) private readonly env: Env, private readonly http: HttpClient) {}

    getAll(): Observable<RoleDto[]> {
        return this.http.get<RoleDto[]>(this.baseApi);
    }
}

import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FileSizePipe } from './pipes/file-size.pipe';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { AvatarUrlPipe } from './pipes/avatar-url.pipe';
import { SharedComponentsModule } from '@dagility-ui/shared-components';
import { CheckboxGroupComponent } from '@app/shared/components/checkbox-group/checkbox-group.component';
import { IsAuthorizedDirective } from '@app/shared/directives/is-authorized.directive';
import { UserEditFormComponent } from './components/users/user-edit-form/user-edit-form.component';
import { AssetsListWrapperComponent } from '@app/shared/components/assets/assets-list-wrapper/assets-list-wrapper.component';
import { AssetsFilterComponent } from '@app/shared/components/assets/assets-list-wrapper/assets-filter/assets-filter.component';
import { AssetsListComponent } from '@app/shared/components/assets/assets-list-wrapper/assets-list/assets-list.component';
import { AssetListItemComponent } from '@app/shared/components/assets/assets-list-wrapper/assets-list/asset-list-item/asset-list-item.component';
import { TranslocoRootModule } from '../transloco-root.module';
import { RejectReasonModalComponent } from './components/assets/modals/reject-reason-modal/reject-reason-modal.component';
import { UpgradeVersionModalComponent } from './components/assets/modals/upgrade-version-modal/upgrade-version-modal.component';
import { RedirectLinkPipe } from '@app/shared/pipes/redirect-link.pipe';
import { AssetLogoUrlPipe } from '@app/shared/pipes/asset-logo-url.pipe';
import { DateBadgeComponent } from './components/date-badge/date-badge.component';
import { TagsComponent } from './components/tags/tags.component';
import { NoDataAvailableComponent } from './components/basic/no-data-available/no-data-available.component';
import { PageIsUnderDevelopmentComponent } from './components/basic/page-is-under-development/page-is-under-development.component';
import { AssetPriceTagComponent } from './components/assets/components/asset-price-tag/asset-price-tag.component';
import { DownloadFilesModalComponent } from './components/assets/modals/download-files-modal/download-files-modal.component';
import { UsersTableComponent } from './components/users/users-table/users-table.component';
import { NotificationBannerComponent } from './components/notification-banner/notification-banner.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { AddItemTemplateDirective, ItemTemplateDirective } from '@app/shared/components/carousel/carousel.directives';
import { AngularSplitModule } from 'angular-split';
import { AssetsPageFiltersComponent } from '@app/shared/components/assets/assets-list-wrapper/assets-page-filters/assets-page-filters.component';
import { AccessDeniedComponent } from '@app/shared/components/basic/access-denied/access-denied.component';
import { NotFoundComponent } from '@app/shared/components/basic/not-found/not-found.component';
import { AssetVersionInputComponent } from './components/controls/asset-version-input/asset-version-input.component';
import { CkEditorComponent } from './components/controls/ck-editor/ck-editor.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { AssetCardComponent } from '@app/shared/components/assets/assets-list-wrapper/assets-list/asset-list-item/asset-card/asset-card.component';
import { AssetStatusLabelComponent } from '@app/shared/components/assets/components/asset-status-label/asset-status-label.component';
import { NotificationInlineBannerComponent } from './components/notification-inline-banner/notification-inline-banner.component';
import { SecondaryHeaderTemplateDirective } from '@app/shared/directives/secondary-header-template.directive';
import { AssetStatusBadgeComponent } from './components/assets/components/asset-status-badge/asset-status-badge.component';
import { AssetRatingStatisticComponent } from './components/assets/components/asset-rating-statistic/asset-rating-statistic.component';
import { RequestAccessModalComponent } from '@app/shared/components/assets/modals/request-access-modal/request-access-modal.component';
import { SuccessBannerComponent } from './components/success-banner/success-banner.component';

const modules = [
    CommonModule,
    FormsModule,
    FontAwesomeModule,
    NgbModule,
    RouterModule,
    ReactiveFormsModule,
    DragDropModule,
    SharedComponentsModule,
    AngularSplitModule,
    TranslocoRootModule,
    CKEditorModule,
];

const basicComponents: Type<any>[] = [NoDataAvailableComponent, PageIsUnderDevelopmentComponent, AccessDeniedComponent, NotFoundComponent];

const assetsComponents: Type<any>[] = [
    AssetsListWrapperComponent,
    AssetsFilterComponent,
    AssetsPageFiltersComponent,
    AssetsListComponent,
    AssetListItemComponent,
    AssetCardComponent,
    AssetPriceTagComponent,
    AssetStatusLabelComponent,
    AssetStatusBadgeComponent,
    AssetRatingStatisticComponent,
];

const usersComponents: Type<any>[] = [UserEditFormComponent, UsersTableComponent];

const components: Type<any>[] = [
    ...basicComponents,
    ...assetsComponents,
    ...usersComponents,
    CheckboxGroupComponent,
    DateBadgeComponent,
    TagsComponent,
    NotificationBannerComponent,
    NotificationInlineBannerComponent,
    SuccessBannerComponent,
    CarouselComponent,
    AssetVersionInputComponent,
    CkEditorComponent,
];

const modals: Type<any>[] = [
    RejectReasonModalComponent,
    UpgradeVersionModalComponent,
    DownloadFilesModalComponent,
    RequestAccessModalComponent,
];

const pipes: any[] = [TimeAgoPipe, FileSizePipe, AvatarUrlPipe, AssetLogoUrlPipe, RedirectLinkPipe];

const directives: any[] = [IsAuthorizedDirective, ItemTemplateDirective, AddItemTemplateDirective, SecondaryHeaderTemplateDirective];

@NgModule({
    declarations: [...components, ...pipes, ...directives, ...modals],
    imports: [...modules],
    entryComponents: [],
    exports: [...modules, ...components, ...pipes, ...directives],
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
        };
    }
}

import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { Directive, Input, NgModule, OnInit } from '@angular/core';

export interface ValidationPattern {
    pattern: string;
    errorMsg: string;
    errorKey: string;
}

function validateWithCustomPatterns(patterns: ValidationPattern[]) {
    return (c: AbstractControl) => {
        const result = c.value && (patterns && patterns.length > 0) ?
            patterns.map((pattern: ValidationPattern) => {
                return new RegExp('^' + pattern.pattern + '$').test(c.value) ? null : {
                    [`${pattern.errorKey}`]: {
                        valid: false,
                        errorMsg: pattern.errorMsg
                    }
                };
            })
                .filter(x => x) : null;

        return result ? Object.assign({}, ...result) : null;
    };
}

@Directive({
    selector: '[libCustomPatterns][ngModel],[libCustomPatterns][formControl]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: CustomPatternsValidatorDirective, multi: true }
    ]
})
export class CustomPatternsValidatorDirective implements Validator, OnInit {
    @Input('libCustomPatterns') patterns: ValidationPattern[];

    validator: Function;

    ngOnInit() {
        this.validator = validateWithCustomPatterns(this.patterns);
    }

    validate(control: AbstractControl): ValidationErrors | null {
        return this.validator(control);
    }
}

@NgModule({
    declarations: [CustomPatternsValidatorDirective],
    exports: [CustomPatternsValidatorDirective]
})
export class CustomPatternsValidatorDirectiveModule {}



import {IConvertedEvent} from '../model/track-events';

export interface IEventsStorage {
    eventsInProgress: number;

    maxEventBufferSize: number;

    addEvent(event: IConvertedEvent): void;

    getEventsForProcessing(): IConvertedEvent[];

    resetEventsForProcessing(): void;
}

export class TrackEventStorageService implements IEventsStorage {
    eventsInProgress = 0;

    private readonly eventsStorageKey = 'UT_EVENTS';
    private readonly eventsToProcessKey = 'UT_EVENTS_PROCESSING';

    private events: IConvertedEvent[] = [];
    private processingEvents: any[] = [];

    constructor(public maxEventBufferSize: number = 1000) {
        this.events = JSON.parse(localStorage.getItem(this.eventsStorageKey)) || [];
        this.processingEvents = JSON.parse(localStorage.getItem(this.eventsToProcessKey)) || [];
    }

    get _events() {
        return this.events;
    }

    addEvent(event: IConvertedEvent) {
        this.events.push(event);
        this.checkBufferOverflow();

        this.updateStorage();
    }

    getEventsForProcessing() {
        this.processingEvents = [...this.processingEvents, ...this.events];
        this.events = [];

        this.updateStorage();

        return this.processingEvents;
    }

    resetEventsForProcessing() {
        this.processingEvents.length = 0;
        this.updateStorage();
    }

    private updateStorage() {
        localStorage.setItem(this.eventsStorageKey, JSON.stringify(this.events));
        localStorage.setItem(this.eventsToProcessKey, JSON.stringify(this.processingEvents));
    }

    private checkBufferOverflow() {
        if (this.events.length > this.maxEventBufferSize) {
            this.events.shift();
        }
    }
}

<ng-container *transloco="let t; read: 'assets.card'">
    <div class="d-flex text-truncate" [ngClass]="isGridView ? 'flex-column justify-content-between': ''">
        <div class="d-flex text-truncate">
            <div class="asset-card--sidebar flex-shrink-0">
                <div class="asset-pic">
                    <img *ngIf="asset.logoExists; else defaultLogo"
                         [src]="asset | assetLogoUrl"
                         [alt]="asset.assetName"
                         width="80"
                    />
                    <ng-template #defaultLogo>
                        <fa-icon [icon]="assetIcon"
                                 class="color-brand"
                                 style="font-size: 80px"
                        ></fa-icon>
                    </ng-template>
                </div>
                <ng-template [ngTemplateOutlet]="!isGridView ? rating : null"></ng-template>
            </div>
            <div class="asset-description-block text-truncate">
                <ng-template [ngTemplateOutlet]="topInfo"></ng-template>
                <ng-template [ngTemplateOutlet]="!isGridView ? details : rating"></ng-template>
            </div>
        </div>
        <ng-template [ngTemplateOutlet]="isGridView ? details : null"></ng-template>
    </div>

    <ng-template #rating>
        <div class="asset-rating">
            <lib-rating *ngIf="asset.averageScore"
                        [value]="asset.averageScore"
                        [expanded]="true"
                        [ratingStatistics]="ratingStatistics$"
                        [translatedText]="ratingTranslatedText"
            ></lib-rating>
        </div>
    </ng-template>

    <ng-template #topInfo>
        <div class="asset-top-info text-truncate line-height-18px">
            <div class="disable font-12px line-height-16px">№ {{ asset.id }}</div>
            <div class="d-flex align-items-center text-truncate my-4px">
                <fa-icon *ngIf="asset.recommended"
                         class="font-18px mr-16px color-brand"
                         icon="check-broken"
                         [ngbTooltip]="t('recommendedTooltip')"
                         container="body"
                         [openDelay]="500"
                ></fa-icon>
                <span class="text-truncate"
                      [ngbTooltip]="asset.assetName"
                      container="body"
                      triggers="manual"
                      libTooltipWhenOverflow
                >
                {{ asset.assetName }}
            </span>
            </div>
            <app-asset-status-label class="my-4px max-width-fit-content"
                                    *ngIf="showStatus && asset.status"
                                    [status]="asset.status"
            ></app-asset-status-label>
            <app-tags [tags]="asset.tags"></app-tags>
        </div>
    </ng-template>

    <ng-template #details>
        <div class="asset-details text-truncate line-height-18px">
            <div class="d-flex align-items-center" [ngClass]="isGridView ? 'mt-16px' : 'mt-8px'">
                <div class="disable min-width-80px">{{ t('version') }}:</div>
                <div class="ml-1">{{ asset.assetVersion }}</div>
                <div class="disable">
                    <em class="dot mx-2"></em>
                    <app-date-badge class="text-secondary" [date]="asset.lastModifiedAt"></app-date-badge>
                </div>
            </div>
            <div class="d-flex text-truncate mt-2px">
                <div class="disable min-width-80px">{{ t('publisher') }}:</div>
                <div class="ml-1 flex-1 text-truncate"
                     [ngbTooltip]="asset.groupName"
                     container="body"
                     triggers="manual"
                     libTooltipWhenOverflow
                >
                    {{ asset.groupName }}
                </div>
            </div>
            <div class="d-flex text-truncate mt-2px">
                <div class="disable min-width-80px">{{ t('description') }}:</div>
                <div class="ml-1 flex-1 text-truncate" [innerHTML]="asset.description"></div>
            </div>
            <ng-content></ng-content>
        </div>
    </ng-template>
</ng-container>



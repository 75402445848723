import { CustomIcon } from './types';

export const facKey: CustomIcon = {
    prefix: 'fac',
    iconName: 'facKey',
    icon: [
        24, // width
        24, // height
        [], // ligatures
        '', // unicode (if relevant)
        'M15.5008 15.5254L19.0008 19.0254M21.0008 21.0254L19.0008 19.0254L21.0008 21.0254ZM11.3908 11.4154C11.9071 10.9059 12.3176 10.2994 12.5985 9.6306C12.8795 8.96185 13.0254 8.24413 13.0278 7.51876C13.0302 6.79339 12.8891 6.07471 12.6127 5.40409C12.3362 4.73347 11.9298 4.12416 11.4169 3.61125C10.904 3.09833 10.2947 2.69194 9.62407 2.41548C8.95345 2.13901 8.23476 1.99793 7.5094 2.00036C6.78403 2.00279 6.06631 2.14868 5.39755 2.42964C4.7288 2.71059 4.12223 3.12105 3.61276 3.63739C2.61089 4.6747 2.05652 6.06401 2.06906 7.5061C2.08159 8.94818 2.66002 10.3276 3.67976 11.3474C4.69951 12.3671 6.07897 12.9456 7.52106 12.9581C8.96314 12.9706 10.3525 12.4163 11.3898 11.4144L11.3908 11.4154ZM11.3908 11.4154L15.5008 15.5254L11.3908 11.4154ZM15.5008 15.5254L18.5008 12.5254L22.0008 16.0254L19.0008 19.0254L15.5008 15.5254Z', // svg path data
    ],
    customStyles: {
        stroke: 'currentColor',
        'stroke-linecap': 'round',
        'stroke-linejoin': 'round',
        fill: 'none'
    }
};

export const facArrowUp: CustomIcon = {
    prefix: 'fac',
    iconName: 'facArrowUp',
    icon: [
        10, // width
        10, // height
        [], // ligatures
        '', // unicode (if relevant)
        'M0 4.5L0.7 5.2L4 2L4 8H5L5 2L8.3 5.2L9 4.5L4.50051 0L0 4.5Z'
    ],
};

export const facArrowDown: CustomIcon = {
    prefix: 'fac',
    iconName: 'facArrowDown',
    icon: [
        10, // width
        10, // height
        [], // ligatures
        '', // unicode (if relevant)
        'M9 3.5L8.3 2.8L5 6L5 0H4L4 6L0.7 2.8L0 3.5L4.49949 8L9 3.5Z'
    ],
};

export const facDuplicate: CustomIcon = {
    prefix: 'far',
    iconName: 'duplicate',
    icon: [
        14,
        14,
        [],
        '',
        'M11.6667 5.08301H6.41667C5.77233 5.08301 5.25 5.60534 5.25 6.24967V11.4997C5.25 12.144 5.77233 12.6663 6.41667 12.6663H11.6667C12.311 12.6663 12.8333 12.144 12.8333 11.4997V6.24967C12.8333 5.60534 12.311 5.08301 11.6667 5.08301 M2.91602 8.58333H2.33268C2.02326 8.58333 1.72652 8.46042 1.50772 8.24162C1.28893 8.02283 1.16602 7.72609 1.16602 7.41667V2.16667C1.16602 1.85725 1.28893 1.5605 1.50772 1.34171C1.72652 1.12292 2.02326 1 2.33268 1H7.58268C7.8921 1 8.18885 1.12292 8.40764 1.34171C8.62643 1.5605 8.74935 1.85725 8.74935 2.16667V2.75',
    ],
    customStyles: {
        stroke: 'currentColor',
        fill: 'none'
    }
};

export const facCreateNew: CustomIcon = {
    prefix: 'far',
    iconName: 'createNew',
    icon: [
        16,
        15,
        [],
        '',
        'M12.0326 7.4502C12.3087 7.4502 12.5326 7.67405 12.5326 7.9502V14.1169C12.5326 14.393 12.3087 14.6169 12.0326 14.6169C11.7564 14.6169 11.5326 14.393 11.5326 14.1169V7.9502C11.5326 7.67405 11.7564 7.4502 12.0326 7.4502 M8.44922 11.0335C8.44922 10.7574 8.67308 10.5335 8.94922 10.5335H15.1159C15.392 10.5335 15.6159 10.7574 15.6159 11.0335C15.6159 11.3097 15.392 11.5335 15.1159 11.5335H8.94922C8.67308 11.5335 8.44922 11.3097 8.44922 11.0335 M1.63235 1.64329C1.7508 1.52371 1.91144 1.45652 2.07895 1.45652H4.5886L5.55324 2.91747C5.64109 3.05052 5.78899 3.13043 5.94737 3.13043H10.9211C11.0886 3.13043 11.2492 3.19762 11.3676 3.31721C11.4861 3.4368 11.5526 3.59899 11.5526 3.76812V5.5H12.5V3.76812C12.5 3.34531 12.3336 2.93982 12.0375 2.64084C11.7414 2.34187 11.3398 2.17391 10.9211 2.17391H6.20088L5.23623 0.712969C5.14838 0.579918 5.00048 0.5 4.8421 0.5H2.07895C1.66018 0.5 1.25857 0.66796 0.962463 0.966931C0.666353 1.2659 0.5 1.67139 0.5 2.0942V9.9058C0.5 10.3286 0.666353 10.7341 0.962463 11.0331C1.25857 11.332 1.66018 11.5 2.07895 11.5H6.5V10.5435H2.07895C1.91144 10.5435 1.7508 10.4763 1.63235 10.3567C1.51391 10.2371 1.44737 10.0749 1.44737 9.9058V2.0942C1.44737 1.92508 1.51391 1.76288 1.63235 1.64329',

    ],
};

export const facExport: CustomIcon = {
    prefix: 'fac',
    iconName: 'export',
    icon: [
        24,
        24,
        [],
        '',
        'M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15 M17 8L12 3L7 8 M12 3V15',

    ],
    customStyles: {
        stroke: 'currentColor',
        'stroke-linecap': 'round',
        'stroke-linejoin': 'round',
        fill: 'none'
    }
};

export const facSwap: CustomIcon = {
    prefix: 'far',
    iconName: 'swap',
    icon: [
        16,
        15,
        [],
        '',
        'M8.0501 7.19974C7.89389 7.04353 7.89389 6.79026 8.0501 6.63405L10.6839 4.00023L8.0501 1.36641C7.89389 1.2102 7.89389 0.95693 8.0501 0.80072C8.20631 0.64451 8.45958 0.64451 8.61579 0.80072L11.5325 3.71739C11.6075 3.7924 11.6496 3.89414 11.6496 4.00023C11.6496 4.10632 11.6075 4.20806 11.5325 4.28307L8.61579 7.19974C8.45958 7.35595 8.20631 7.35595 8.0501 7.19974 M1.84961 4.00023C1.84961 3.77931 2.0287 3.60023 2.24961 3.60023L11.2496 3.60023C11.4705 3.60023 11.6496 3.77932 11.6496 4.00023C11.6496 4.22114 11.4705 4.40023 11.2496 4.40023L2.24961 4.40023C2.0287 4.40023 1.84961 4.22114 1.84961 4.00023 M3.9499 6.80075C4.10611 6.95696 4.10611 7.21023 3.9499 7.36644L1.31608 10.0003L3.9499 12.6341C4.10611 12.7903 4.10611 13.0436 3.9499 13.1998C3.79369 13.356 3.54042 13.356 3.38421 13.1998L0.467548 10.2831C0.392534 10.2081 0.350391 10.1063 0.350391 10.0003C0.350391 9.89417 0.392534 9.79243 0.467548 9.71742L3.38421 6.80075C3.54042 6.64454 3.79369 6.64454 3.9499 6.80075 M10.1504 10.0003C10.1504 10.2212 9.9713 10.4003 9.75039 10.4003L0.750391 10.4003C0.529477 10.4003 0.350391 10.2212 0.350391 10.0003C0.350391 9.77935 0.529477 9.60026 0.750391 9.60026L9.75039 9.60026C9.9713 9.60026 10.1504 9.77935 10.1504 10.0003',

    ],
};

export const facTree: CustomIcon = {
    prefix: 'far',
    iconName: 'tree',
    icon: [
        16,
        16,
        [],
        '',
        'M6.75 4.25V5.7998H6.7498V7.4998C6.7498 7.63787 6.6379 7.7498 6.4998 7.7498H4V7.7508V8.2488V8.2498H6.4998C6.6379 8.2498 6.7498 8.36173 6.7498 8.4998V10.1998H6.75V11.7496C6.75 12.1638 7.08579 12.4996 7.5 12.4996H11.2V11.9996H7.5C7.36193 11.9996 7.25 11.8877 7.25 11.7496L7.24944 10.1998H7.2498V8.4998C7.2498 8.30772 7.17759 8.13249 7.05883 7.9998C7.17759 7.86712 7.2498 7.69189 7.2498 7.4998V5.7998H7.24944L7.25 4.25C7.25 4.1119 7.36193 4 7.5 4H11.2V3.5H7.5C7.08579 3.5 6.75 3.8358 6.75 4.25Z M11.7 2C11.3134 2 11 2.3134 11 2.7V4.8C11 5.1866 11.3134 5.5 11.7 5.5H13.8C14.1866 5.5 14.5 5.1866 14.5 4.8V2.7C14.5 2.3134 14.1866 2 13.8 2H11.7ZM13.8 4.8H11.7V2.7H13.8V4.8ZM1 6.95C1 6.5634 1.3134 6.25 1.7 6.25H3.8C4.1866 6.25 4.5 6.5634 4.5 6.95V9.05C4.5 9.4366 4.1866 9.75 3.8 9.75H1.7C1.3134 9.75 1 9.4366 1 9.05V6.95ZM1.7 9.05H3.8V6.95H1.7V9.05ZM11 11.2C11 10.8134 11.3134 10.5 11.7 10.5H13.8C14.1866 10.5 14.5 10.8134 14.5 11.2V13.3C14.5 13.6866 14.1866 14 13.8 14H11.7C11.3134 14 11 13.6866 11 13.3V11.2ZM11.7 13.3H13.8V11.2H11.7V13.3Z',
    ],
};

import {Component, EventEmitter, HostListener, NgZone} from '@angular/core';
import {Toast, ToastPackage, ToastrService} from 'ngx-toastr';

@Component({
    selector: '[dagility-toast-expire-component]',
    templateUrl: './dagility-toastr-expire.component.html'
})
export class DagilityToastrExpireComponent extends Toast {
    public remaining: number;
    public closeEvent = new EventEmitter();
    public closeIntervalEvent = new EventEmitter();

    constructor(
        toastrService: ToastrService,
        toastPackage: ToastPackage,
        ngZone: NgZone,
    ) {
        super(toastrService, toastPackage, ngZone);
    }

    @HostListener('mouseenter')
    stickAround = () => {
        return;
    }

    @HostListener('mouseleave')
    delayedHideToast = () => {
        return;
    }

    updateProgress() {
        super.updateProgress();
        const now = new Date().getTime();
        const remaining = this['hideTime'] - now;
        this.remaining = Math.round(remaining / 1000);
    }

    onClose = () => this.closeEvent.emit(() => this.remove());

    onCloseTimeout(): void {
        this.closeIntervalEvent.emit(() => this.remove());
    }

    activateToast() {
        this.state = {...this.state, value: 'active'};
        if (!this.options.disableTimeOut && this.options.timeOut) {
            this.outsideTimeout(() => this.onCloseTimeout(), this.options.timeOut);
            this['hideTime'] = new Date().getTime() + this.options.timeOut;
            if (this.options.progressBar) {
                this.outsideInterval(() => this.updateProgress(), 10);
            }
        }
    }
}

import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Pipe({
    name: 'fileSize',
})
export class FileSizePipe implements PipeTransform {
    readonly units = ['bytes', 'kilobytes', 'megabytes', 'gigabytes'];

    constructor(private translocoService: TranslocoService) {}

    transform(value: number, mantissaDigitsCount: number = 1): string {
        let result = '';
        let unitIndex = 0;
        let maxUnitIndex = this.units.length;

        if (value >= 0) {
            while (value / 1000 > 0.1 && unitIndex < maxUnitIndex) {
                value = value / 1000;
                unitIndex++;
            }
            mantissaDigitsCount = value % 1 ? mantissaDigitsCount : 0;
            result = `${value.toFixed(mantissaDigitsCount)} ${this.translocoService.translateObject(
                `common.fileSizes.${this.units[unitIndex]}`
            )}`;
        }
        return result;
    }
}

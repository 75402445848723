import {fromEvent, Observable} from 'rxjs';
import {TrackEventGroup} from '../model/track-event-group';
import {ActionUserEvent, EventType, IParams} from '../model/track-events';
import {TrackTTFBService} from './track-ttfb.service';
import {TrackEventSenderService} from "./track-event-sender.service";
import {EventGroups} from "./event-groups/event-groups";
import {MouseTrajectoryOptimizationService} from "./mouse-trajectory-optimization.service";

export enum HtmlElementAttrs {
    ATTR_ID = 'id',
    ATTR_TYPE = 'type',

    KEEPNI_ATTR_EVENT_TYPE = 'keepni-event-type',
    KEEPNI_ATTR_EVENT_NAME = 'keepni-event-name',
    KEEPNI_ATTR_ROUTE_TYPE = 'keepni-path-type',
    KEEPNI_ATTR_ROUTE_NAME = 'keepni-path-name',
    KEEPNI_ATTR_PARAM = 'keepni-param',
    KEEPNI_ATTR_TAGS = 'keepni-tags',
    KEEPNI_ATTR_USER_DATA = 'keepni-user-data',
}

export class UserActivityTrackingService {
    static FOCUS_EVENTS: Array<string> = ['focus', 'blur'];
    static DOCUMENT_EVENTS: Array<string> = ['scroll', 'resize'];
    static MOUSE_MOVE_EVENTS: Array<string> = ['mouseover', 'mousemove', 'drag'];
    static MOUSE_EVENTS: Array<string> = ['click', 'dblclick', 'auxclick', 'wheel', 'drop']
        .concat(UserActivityTrackingService.MOUSE_MOVE_EVENTS);
    static INPUT_EVENTS: Array<string> = ['keyup', 'change', 'click'];
    static ALL_EVENTS: Array<string> =
        UserActivityTrackingService.FOCUS_EVENTS
            .concat(UserActivityTrackingService.DOCUMENT_EVENTS)
            .concat(UserActivityTrackingService.MOUSE_EVENTS)
            .concat(UserActivityTrackingService.INPUT_EVENTS)
            .filter((value, index, self) => self.indexOf(value) === index) // unique
    ;

    private enabledEvents: Array<string>;
    private listeners$: Array<Observable<any>>;
    private eventGroupByEvent: Map<string, Array<TrackEventGroup>>;
    private static trajectoryService: MouseTrajectoryOptimizationService = new MouseTrajectoryOptimizationService();

    private static getComponentPath(el: HTMLElement, path: Array<Object> = []): Array<Object> {
        const closestPathNameEl: HTMLElement = this.findClosestEl(el, HtmlElementAttrs.KEEPNI_ATTR_ROUTE_NAME);
        if (closestPathNameEl) {
            const closestPathType = closestPathNameEl
                ? closestPathNameEl.getAttribute(HtmlElementAttrs.KEEPNI_ATTR_ROUTE_TYPE)
                : 'GROUP';
            const closestPathName = closestPathNameEl
                ? closestPathNameEl.getAttribute(HtmlElementAttrs.KEEPNI_ATTR_ROUTE_NAME)
                : null;
            this.getComponentPath(closestPathNameEl.parentElement, path);
            const pathTypes = closestPathType.split(',').map(p => p.trim());
            const pathNames = closestPathName.split(',').map(p => p.trim());
            pathTypes.forEach((t, ix) => path.push({type: t, name: ix >= pathNames.length ? null : pathNames[ix]}));
        }
        return path;
    }

    private static fillInParamsFromHtmlEvent(params: IParams, ev: Event) {
        switch (ev.type) {
            case 'mouseover':
            case 'mousemove':
                params.points = JSON.stringify(UserActivityTrackingService.trajectoryService.getResultArray());
                break;
            case 'click':
            case 'auxclick':
            case 'wheel':
                const eventMousemove: MouseEvent = (<MouseEvent>ev);
                params.points = JSON.stringify([[eventMousemove.x, eventMousemove.y]]);
                break;
            default:
        }
    }

    private static fillInParamsFromInputEl(params: IParams, eventTargetEl: HTMLElement) {
        if (params.element === 'input' || params.element === 'button') {
            params.elementType = 'input';
            if (params.element === 'input') {
                params.elementInputType = eventTargetEl.getAttribute(HtmlElementAttrs.ATTR_TYPE) || 'text';
            } else if (params.element === 'button') {
                params.elementInputType = eventTargetEl.getAttribute(HtmlElementAttrs.ATTR_TYPE) || 'button';
            }
        }
    }

    private static findClosestAttrValueOrDefault(el: HTMLElement, attr: HtmlElementAttrs, multi?: boolean, defaultVal?: string): string {
        const closestEl = this.findClosestEl(el, attr);
        if (closestEl) {
            const val = closestEl.getAttribute(attr) || defaultVal;
            return multi
                ? JSON.stringify(val.split(',').map(t => t.trim()))
                : val;
        }
        return defaultVal;
    }

    private static _collectAttrValuesFromRoot(el: HTMLElement, attr: HtmlElementAttrs, multi?: boolean): string[] {
        const closestEl = this.findClosestEl(el, attr);
        if (closestEl) {
            const val = closestEl.getAttribute(attr);
            if (val == null) {
                return [];
            }
            let parentResult = this._collectAttrValuesFromRoot(closestEl.parentElement, attr, multi);
            let localResult = multi
                ? val.split(',').map(t => t.trim())
                : [val];
            return parentResult.concat(localResult);
        }
        return [];
    }

    private static collectAttrValuesFromRoot(el: HTMLElement, attr: HtmlElementAttrs, multi?: boolean): string {
        let collected = this._collectAttrValuesFromRoot(el, attr, multi);
        return collected.length > 0 ? JSON.stringify(collected) : null;
    }

    private static findClosestEl(el: HTMLElement, attr: HtmlElementAttrs): HTMLElement {
        return el.closest(`[${attr}]`);
    }

    private static applyParamIfNotEmpty(params: IParams, name: string, val: string) {
        if (val) {
            // @ts-ignore
            params[name] = val;
        }
    }

    public static createActionUserEvent(event: Event): ActionUserEvent {
        const eventTargetEl: HTMLElement = (<HTMLElement>event.target);

        let params: IParams = new class implements IParams {
            public componentPath: string = JSON.stringify(UserActivityTrackingService.getComponentPath(eventTargetEl));
            public element: string = eventTargetEl.localName;
            public event: string = event.type;
        };

        this.applyParamIfNotEmpty(params, 'elementId',
            eventTargetEl.getAttribute(HtmlElementAttrs.ATTR_ID)
        );
        this.applyParamIfNotEmpty(params, 'tags',
            this.collectAttrValuesFromRoot(eventTargetEl, HtmlElementAttrs.KEEPNI_ATTR_TAGS, true)
        );
        this.applyParamIfNotEmpty(params, 'userData',
            this.findClosestAttrValueOrDefault(eventTargetEl, HtmlElementAttrs.KEEPNI_ATTR_USER_DATA)
        );

        const closestEventType = this.findClosestAttrValueOrDefault(eventTargetEl, HtmlElementAttrs.KEEPNI_ATTR_EVENT_TYPE);
        const closestEventName = this.findClosestAttrValueOrDefault(eventTargetEl, HtmlElementAttrs.KEEPNI_ATTR_EVENT_NAME);

        const url: string = location.href;
        const type: EventType | string = closestEventType || event.type;
        const name: string = closestEventName || type;

        this.fillInParamsFromInputEl(params, eventTargetEl);
        this.fillInParamsFromHtmlEvent(params, event);

        const closestParams = this.findClosestAttrValueOrDefault(eventTargetEl, HtmlElementAttrs.KEEPNI_ATTR_PARAM);
        if (closestParams) {
            const addParams = JSON.parse(closestParams);
            params = {
                ...params,
                ...addParams
            }
        }

        return new ActionUserEvent(
            type,
            name,
            url,
            params
        );
    }

    private subscribed: boolean = false;

    constructor(
        private eventSender: TrackEventSenderService,
        private ttfbTrackingService: TrackTTFBService
    ) {
        // Subscribing to document events
        this.enabledEvents = UserActivityTrackingService.ALL_EVENTS;
        this.listeners$ = this.enabledEvents.map(eventName => {
            return fromEvent(document, eventName, {capture: true});
        });

        this.initGroups();
    }

    public subscribeToObservables() {
        if (!this.subscribed) {
            this.subscribed = true;
            this.listeners$.forEach(l => l.subscribe((ev) => this.trackEventFunction(ev)));

            this.ttfbTrackingService.trackPageTTFB();
            this.ttfbTrackingService.subscribe();
        }
    }

    private initGroups(): void {
        this.eventGroupByEvent = EventGroups.eventGroups.reduce(
            (acc, val) => {
                val.eventNames.forEach(e => {
                    acc.has(e) ? acc.get(e).push(val) : acc.set(e, new Array<TrackEventGroup>(val));
                });
                return acc;
            },
            new Map<string, Array<TrackEventGroup>>()
        );
    }

    private trackEventFunction = (ev: Event) => {
        const time = new Date().getTime();
        if (ev.type === 'mousemove' || ev.type === 'mouseover') {
            UserActivityTrackingService.trajectoryService.addPointByEvent(<MouseEvent> ev);
        }
        const trackEventGroups = this.eventGroupByEvent.get(ev.type);
        if (trackEventGroups) {
            trackEventGroups
                .filter(g => g.isEnoughTimeElapsed(time))
                .forEach(g => this.eventSender.sendEvent(g.prepareEvent(ev, time)));
        }
    };
}

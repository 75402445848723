import {Inject, Injectable, Injector, NgZone} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {Overlay, TOAST_CONFIG, ToastrService, ToastToken} from 'ngx-toastr';

import {DagilityToastrComponent} from '../../components/dagility-toastr/dagility-toastr.component';

@Injectable()
export class PopupService extends ToastrService {
    constructor(
        @Inject(TOAST_CONFIG) token_: ToastToken,
        overlay: Overlay,
        _injector: Injector,
        sanitizer: DomSanitizer,
        ngZone: NgZone
    ) {
        super(token_, overlay, _injector, sanitizer, ngZone);

        // Configure toastr
        this.toastrConfig.timeOut = 5000;
        this.toastrConfig.newestOnTop = true;
        this.toastrConfig.preventDuplicates = true;
        this.toastrConfig.resetTimeoutOnDuplicate = true;
        this.toastrConfig.autoDismiss = true;
        this.toastrConfig.progressBar = true;
        this.toastrConfig.closeButton = true;
        this.toastrConfig.positionClass = 'toast-bottom-right';
        this.toastrConfig.progressAnimation = 'decreasing';
        this.toastrConfig.toastClass = 'toastr';
        this.toastrConfig.messageClass = 'toastr-message';
        this.toastrConfig.titleClass = 'toastr-title';
        this.toastrConfig.toastComponent = DagilityToastrComponent;
        this.toastrConfig.extendedTimeOut = 5000;
        this.toastrConfig.tapToDismiss = false;
        this.toastrConfig.iconClasses = {
            error: 'toastr-error',
            info: 'toastr-info',
            success: 'toastr-success',
            warning: 'toastr-warning'
        };
    }
}

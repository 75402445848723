import { Inject, Injectable } from '@angular/core';
import { AssetActionResponse, AssetCreationDto, AssetDto, AssetEvent, AssetStatus } from '@app/shared/components/assets/asset.model';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { AssetEventsService } from '@app/shared/services/api/asset-events.service';
import { RejectReason } from '@app/shared/components/assets/modals/reject-reason-modal/reject-reason-modal.component';
import { ENV_TOKEN } from '@app/tokens';

type Transition = {
    [key in AssetStatus]: string[];
};

const STATUSES: AssetStatus[] = [
    AssetStatus.DRAFT,
    AssetStatus.REJECTED,
    AssetStatus.AWAITING_FOR_APPROVAL,
    AssetStatus.AWAITING_LAUNCH,
    AssetStatus.LAUNCHED,
];

const EMPTY_REASON: RejectReason = {
    resolutionId: 0,
    comment: '',
};

@Injectable({
    providedIn: 'root',
})
export class StateMachineService {
    private readonly baseUrl = `${this.env.stateMachineApiURL}`;

    private readonly transitions = new Map<AssetStatus, string[]>();
    private readonly events = new Map<AssetEvent, boolean>();

    private readonly _assetEventsInit$ = new BehaviorSubject<boolean>(false);

    get assetEventsInit$() {
        return this._assetEventsInit$;
    }

    get canEditLaunched() {
        return this.events.get(AssetEvent.EDIT_LAUNCHED);
    }

    get canSaveDraft() {
        return this.events.get(AssetEvent.SAVE_DRAFT);
    }

    get canSubmit() {
        return this.events.get(AssetEvent.SUBMIT);
    }

    get canApprove() {
        return this.events.get(AssetEvent.ADMIN_APPROVE);
    }

    get canReject() {
        return this.events.get(AssetEvent.ADMIN_REJECT);
    }

    get canLaunch() {
        return this.events.get(AssetEvent.LAUNCH);
    }

    get canCancel() {
        return this.events.get(AssetEvent.CANCEL);
    }

    get canUpgradeVersion() {
        return this.events.get(AssetEvent.UPGRADE_VERSION);
    }

    constructor(
        @Inject(ENV_TOKEN) private readonly env: Env,
        private readonly http: HttpClient,
        private readonly assetEventsService: AssetEventsService
    ) {
        this.init();
    }

    init() {
        this.http
            .get<Transition>(`${this.baseUrl}/transitions`)
            .pipe(take(1))
            .subscribe(transitions => {
                this.initTransitions(transitions);
                this.initEvents();
                this._assetEventsInit$.next(true);
            });
    }

    private initTransitions(transitions: Transition) {
        STATUSES.forEach(status => this.transitions.set(status, transitions[status]));
    }

    private initEvents() {
        this.events.set(
            AssetEvent.SAVE_DRAFT,
            !!(this.transitions.get(AssetStatus.DRAFT) || []).find(event => event === AssetEvent.SAVE_DRAFT)
        );
        this.events.set(AssetEvent.SUBMIT, !!(this.transitions.get(AssetStatus.DRAFT) || []).find(event => event === AssetEvent.SUBMIT));
        this.events.set(
            AssetEvent.EDIT_LAUNCHED,
            !!(this.transitions.get(AssetStatus.LAUNCHED) || []).find(event => event === AssetEvent.EDIT_LAUNCHED)
        );

        this.events.set(
            AssetEvent.ADMIN_APPROVE,
            !!(this.transitions.get(AssetStatus.AWAITING_FOR_APPROVAL) || []).find(event => event === AssetEvent.ADMIN_APPROVE)
        );
        this.events.set(
            AssetEvent.ADMIN_REJECT,
            !!(this.transitions.get(AssetStatus.AWAITING_FOR_APPROVAL) || []).find(event => event === AssetEvent.ADMIN_REJECT)
        );

        this.events.set(
            AssetEvent.LAUNCH,
            !!(this.transitions.get(AssetStatus.AWAITING_LAUNCH) || []).find(event => event === AssetEvent.LAUNCH)
        );
        this.events.set(
            AssetEvent.CANCEL,
            !!(this.transitions.get(AssetStatus.AWAITING_LAUNCH) || []).find(event => event === AssetEvent.CANCEL)
        );

        this.events.set(
            AssetEvent.UPGRADE_VERSION,
            !!(this.transitions.get(AssetStatus.LAUNCHED) || []).find(event => event === AssetEvent.UPGRADE_VERSION)
        );
    }

    handleAssetSlimEvent(asset: AssetDto, event: AssetEvent, rejectReason = EMPTY_REASON): Observable<AssetActionResponse> {
        if ([AssetEvent.ADMIN_APPROVE, AssetEvent.ADMIN_REJECT].includes(event)) {
            return this.assetEventsService.handleAssetApprove(asset, event, rejectReason);
        }

        if ([AssetEvent.LAUNCH, AssetEvent.CANCEL].includes(event)) {
            return this.assetEventsService.handleLaunch(asset, event);
        }
    }

    handleAssetCreationEvent(asset: AssetCreationDto, event: AssetEvent) {
        if ([AssetEvent.SAVE_DRAFT, AssetEvent.SUBMIT, AssetEvent.UPGRADE_VERSION, AssetEvent.EDIT_LAUNCHED].includes(event)) {
            return this.assetEventsService.handleAssetSave(asset, event);
        }
    }
}

<ng-container *ngIf="hasFiltersBlock; else wrapper">
    <as-split [id]="splitterId" #splitter class="h-auto" [unit]="'pixel'"
              *transloco="let t; read: 'assets.page'"
    >
        <as-split-area [size]="250" [minSize]="200" [maxSize]="500">
            <app-assets-page-filters class="d-block"
                                     [class.mr-16px]="splitter.direction === 'horizontal'"
                                     [hasRouteObservation]="hasRouteObservation"
            ></app-assets-page-filters>
        </as-split-area>
        <as-split-area>
            <ng-container [ngTemplateOutlet]="wrapper"></ng-container>
        </as-split-area>
    </as-split>
</ng-container>

<ng-template #wrapper>
    <ng-container *ngIf="!asset">
        <div class="d-flex justify-content-between align-items-center"
             [ngClass]="{ 'page-header with-title': title }"
             *transloco="let t; read: 'assets.listWrapper'"
        >
            <div>
                <h1 *ngIf="title" class="m-0">{{ title }}</h1>
            </div>

            <div class="page-header-controls" [class.w-100]="hasFiltersBlock">
                <app-assets-filter class="w-100" [(layout)]="layout" [withRouteObservation]="hasRouteObservation">
                    <div *ngIf="selectedFilters.length"
                         class="d-flex flex-wrap align-items-center mt-8px"
                         [style.gap]="'10px 0'"
                    >
                        <lib-tag
                            *ngFor="let f of selectedFilters.slice(0, filtersExpanded ? selectedFilters.length : 12)"
                            class="mr-8px"
                            [tag]="f.name"
                            removable="true"
                            (remove)="removeFilter(f)"
                        ></lib-tag>
                        <button *ngIf="selectedFilters.length > 12"
                                class="btn btn-link size-s"
                                (click)="filtersExpanded = !filtersExpanded"
                        >
                            <fa-icon icon="{{ filtersExpanded ? 'eyeOff' : 'facEyeOpen' }}"
                                     class="font-14px mr-7px"
                            ></fa-icon>
                            {{ t(filtersExpanded ? 'showLess' : 'showMore') }}
                        </button>
                        <button class="btn btn-link size-s ml-8px" (click)="removeAllFilters()">
                            <fa-icon icon="facTrash" class="font-14px mr-7px"></fa-icon>
                            {{ t('clearAll') }}
                        </button>
                    </div>
                </app-assets-filter>
                <div *ngIf="canAddAsset && currentUser.canPublish" class="d-inline-flex min-width-fit-content">
                    <div class="button-divider mx-4"></div>
                    <button class="btn btn-primary" [routerLink]="'new'">
                        <fa-icon icon="plus" class="fa font-16px mr-7px"></fa-icon>
                        {{ t('addBtn') }}
                    </button>
                </div>
            </div>
        </div>
    </ng-container>

    <lib-nav *ngIf="hasGroupTabs; else assetsList"
             [activeId]="activeTab"
             [useHorizontalScroll]="true"
             (navChange)="changeNav($event.nextId)"
    >
        <ng-container *ngFor="let tab of assetsListTabs">
            <ng-template #navItemTemplate>
                {{ ('assets.listWrapper.navItems.' + tab) | transloco }}
                <span class="custom-badge custom-badge-background text-white size-s ml-4px"
                      *ngIf="assetCountByTabAndStatusMap.get(tab).get(null) >= 0"
                >
                {{ assetCountByTabAndStatusMap.get(tab).get(null) }}
            </span>
            </ng-template>

            <ng-template navItem
                         [navItemId]="tab"
                         [navItemHeaderTemplate]="navItemTemplate"
            >
                <ng-container [ngTemplateOutlet]="assetsList"></ng-container>
            </ng-template>
        </ng-container>
    </lib-nav>

    <ng-template #assetsList>
        <div *ngIf="hasFilterByStatus"
             [style.visibility]="assetCountByTabAndStatusMap.get(activeTab).get(null) > 0 ? 'visible' : 'hidden'"
             class="button-toggle-group"
        >
            <ng-container *transloco="let t; read: 'assets.statusFilter'">
                <input id="all-status-filter"
                       type="radio"
                       name="asset-status-filter"
                       [value]="null"
                       [ngModel]="assetFilter.status"
                       (ngModelChange)="changeStatusFilter($event)"
                />
                <label for="all-status-filter" class="button-toggle">
                    {{ t('all') }}
                    <span class="custom-badge custom-badge-background size-s ml-8px"
                          *ngIf="assetCountByTabAndStatusMap.get(activeTab).get(null) >= 0"
                    >
                            {{ assetCountByTabAndStatusMap.get(activeTab).get(null) }}
                        </span>
                </label>
                <ng-container *ngFor="let status of assetStatus | keyvalue">
                    <input [id]="status.key + '-status-filter'"
                           type="radio"
                           name="asset-status-filter"
                           [value]="status.value"
                           [ngModel]="assetFilter.status"
                           (ngModelChange)="changeStatusFilter($event)"
                    />
                    <label [for]="status.key + '-status-filter'" class="button-toggle">
                        {{ getStatusTextByStatus(status.key) | titlecase }}
                        <span class="custom-badge custom-badge-background size-s ml-8px"
                              *ngIf="assetCountByTabAndStatusMap.get(activeTab).get(status.value) >= 0"
                        >
                                {{ assetCountByTabAndStatusMap.get(activeTab).get(status.value) }}
                            </span>
                    </label>
                </ng-container>
            </ng-container>
        </div>

        <ng-container *ngIf="!loading; else loadingTmpl">
            <ng-container *ngIf="assets?.length > 0; else noData">
                <lib-pagination [metaData]="pagination"
                                (pagerdata)="onPageChange($event)"
                                [translatedText]="paginationTranslatedText"
                                [noDataText]="''"
                ></lib-pagination>
                <app-assets-list
                    class="d-block my-2"
                    [class.assets-layout-grid]="layout === 'grid'"
                    [assets]="assets"
                    [isManageAssets]="isManageAssets"
                    [hasManagementActions]="hasManagementActions"
                    [hasActionButtons]="hasActionButtons"
                    [showStatus]="showStatus"
                    [canSelectAssets]="canSelectAssets"
                    [readOnly]="readOnly"
                    [isLoggedIn]="isLoggedIn"
                    [isGridView]="layout === 'grid'"
                    (managementActionDone)="handleManagementAction($event)"
                ></app-assets-list>
                <lib-pagination class="d-block mb-3"
                                [metaData]="pagination"
                                (pagerdata)="onPageChange($event)"
                                [translatedText]="paginationTranslatedText"
                                [noDataText]="''"
                ></lib-pagination>
            </ng-container>
        </ng-container>
    </ng-template>

    <ng-template #noData>
        <app-no-data-available></app-no-data-available>
    </ng-template>

    <ng-template #loadingTmpl>
        <div class="d-flex align-items-center justify-content-center">
            <lib-spinner></lib-spinner>
        </div>
    </ng-template>
</ng-template>


import { Inject, NgModule, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { EnvironmentModel } from '@dagility-ui/kit';

@Pipe({
    name: 'imagePath',
})
export class ImagePathPipe implements PipeTransform {
    environment: any;
    storagePrefix: string = 'storage:';

    constructor(@Inject('environment') environment: EnvironmentModel, private domSanitizer: DomSanitizer) {
        this.environment = environment;
    }

    transform(imageUrl: string, part?: string): any {
        if (!imageUrl) {
            return '';
        }
        if (imageUrl.startsWith('http:') || imageUrl.startsWith('https:')) {
            return imageUrl;
        }
        if (imageUrl.startsWith('data:image/')) {
            return this.domSanitizer.bypassSecurityTrustUrl(imageUrl);
        }
        let path;
        if (imageUrl.startsWith(this.storagePrefix)) {
            imageUrl = imageUrl.substring(this.storagePrefix.length);
            path = this.environment.adminApiURL + '/storage';
        } else {
            path = '/assets/images';
        }
        return part ? `${path}/${part}/${imageUrl}` : `${path}/${imageUrl}`;
    }
}

@NgModule({
    declarations: [ImagePathPipe],
    exports: [ImagePathPipe]
})
export class ImagePathPipeModule {}

import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-asset-price-tag',
    templateUrl: './asset-price-tag.component.html',
    styleUrls: ['./asset-price-tag.component.scss'],
})
export class AssetPriceTagComponent {
    @Input() price: number = 100;
}

import {
    Component,
    EventEmitter,
    forwardRef,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
    ControlValueAccessor,
    FormBuilder,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from '@angular/forms';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { debounceTime, filter, map, switchMap, takeUntil } from 'rxjs/operators';

import { EnvironmentModel, observeProperty, sortObjectArrayByKey, validateFormAndDisplayErrors } from '@dagility-ui/kit';
import { ToolCategoryType } from '../../models/domain/tool.models';

const resolvedPromise = Promise.resolve(null);

@Component({
    selector: 'lib-tool-choosing',
    templateUrl: './tool-choosing.component.html',
    styleUrls: ['./tool-choosing.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ToolChoosingComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => ToolChoosingComponent),
            multi: true,
        },
    ],
})
export class ToolChoosingComponent implements OnInit, OnChanges, ControlValueAccessor, Validator, OnDestroy {
    baseUrl: string;
    buildApiURL: string;
    @ViewChild('controlContainer', { read: ViewContainerRef }) controlContainerRef: ViewContainerRef;
    @Input() category: ToolCategoryType;
    @Input() toolTypes: string[];
    @Input() projectId: number;
    @Input() componentId: number;
    @Input() pipelineId: number;
    @Input() unusedTools = false;
    @Input() toolId: number = null;
    @Input() unitGroupId: number = null;
    @Input() unitGroupName = '';
    @Input() unitId: number = null;
    @Input() unitName = '';
    @Input() label = 'Tool';
    @Input() refreshItemsTrigger = true;

    @Input() isDoTheMagic = false;
    @Input() hideTool = false;
    @Input() hideUnit = false;
    @Input() combineGroups = false;
    @Input() addGroup = true;
    @Input() addUnit = true;
    @Input() toolDisabled = false;
    @Input() requiredTool = false;
    @Input() requiredGroup = false;
    @Input() requiredUnit = false;

    @Input() set setDisabled(value: boolean) {
        this.disabled = value;
        this.applyDisabledState();
    }

    @Input() markDisabledTools = true;
    @Input() initToolByDefault = false;
    @Input() contextHelpTemplate: TemplateRef<any> = null;
    @Input() appendTo: string | null = 'body';

    @Output() refreshItemsTriggerChange: EventEmitter<boolean> = new EventEmitter();
    @Output() valueChange: EventEmitter<IToolsChoosingResult> = new EventEmitter();
    @Output() selectedToolState: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() formInitialized: EventEmitter<boolean> = new EventEmitter<boolean>();

    formGroup: FormGroup;

    value: IToolsChoosingResult;

    toolsTree: ISelectResponse[];
    selectedTool: ISelectResponse;
    selectedUnitGroup: ISelectResponseUnit;
    selectedUnit: ISelectResponseUnit;

    errorState = false;
    loading = false;

    isToolEnabled = false;

    Validators = Validators;

    filters = FILTERS;

    private defaultGroupName = ['', '<ROOT>', 'all', 'view/all', '/view/all'];

    private category$: Observable<ToolCategoryType>;
    private projectId$: Observable<number>;
    private unusedTools$: Observable<boolean>;
    private refreshItemsTrigger$: Observable<boolean>;

    private toolId$: Observable<number>;
    private unitGroupId$: Observable<number>;
    private unitGroupName$: Observable<string>;
    private unitId$: Observable<number>;
    private unitName$: Observable<string>;

    groups$: Observable<ISelectResponseUnit[]>;
    units$: Observable<ISelectResponseUnit[]>;

    private initToolsTrigger$ = new Subject<void>();
    private firsTimeTrigger = 0;

    initialized$: Subject<{ from: string }> = new Subject();

    private destroy$ = new Subject<void>();

    private disabled = false;
    private jobManagemenToolIds: number[] = [];

    get isDisabled() {
        return this.disabled;
    }

    constructor(private http: HttpClient, private fb: FormBuilder, @Inject('environment') environment: EnvironmentModel) {
        this.baseUrl = `${environment.adminApiURL}`;
        this.buildApiURL = `${environment.buildApiURL}`;
    }

    onChange = (_: any) => {};
    onTouched = () => {};

    getToolsSelectStructure(): Observable<ISelectResponse[]> {
        const url = `${this.baseUrl}/tool-choosing/select`;
        let params: HttpParams = new HttpParams().set('category', this.category);
        if (this.projectId) {
            params = params.set('projectId', `${this.projectId}`);
        }
        if (this.componentId) {
            params = params.set('componentId', `${this.componentId}`);
        }
        if (this.pipelineId) {
            params = params.set('pipelineId', `${this.pipelineId}`);
        }
        if (this.toolTypes) {
            params = params.set('toolTypes', `${this.toolTypes}`);
        }
        params = params.set('unusedTools', `${this.unusedTools}`);
        return this.http.get<ISelectResponse[]>(url, { params: params });
    }

    getActiveTool(): Subject<boolean> {
        const subject = new Subject<boolean>();
        this.http.get<number[]>(`${this.buildApiURL}/jobs-management/active-tools`).subscribe(
            toolIds => {
                this.jobManagemenToolIds = toolIds;
                subject.next(true);
            },
            () => {
                subject.next(false);
            }
        );
        return subject;
    }

    ngOnInit() {
        this.toolsTree = this.toolsTree || [];
        this.initForm();

        this.category$ = observeProperty(this, 'category').pipe(takeUntil(this.destroy$));
        this.projectId$ = observeProperty(this, 'projectId').pipe(takeUntil(this.destroy$));
        this.unusedTools$ = observeProperty(this, 'unusedTools').pipe(takeUntil(this.destroy$));
        this.refreshItemsTrigger$ = observeProperty(this, 'refreshItemsTrigger').pipe(
            filter(value => !!value || this.firsTimeTrigger++ === 0),
            takeUntil(this.destroy$)
        );

        this.toolId$ = observeProperty(this, 'toolId').pipe(takeUntil(this.destroy$));
        this.unitGroupId$ = observeProperty(this, 'unitGroupId').pipe(takeUntil(this.destroy$));
        this.unitGroupName$ = observeProperty(this, 'unitGroupName').pipe(takeUntil(this.destroy$));
        this.unitId$ = observeProperty(this, 'unitId').pipe(takeUntil(this.destroy$));
        this.unitName$ = observeProperty(this, 'unitName').pipe(takeUntil(this.destroy$));

        this.initToolsTrigger$
            .pipe(
                switchMap(() => {
                    this.errorState = false;
                    this.loading = true;
                    return this.getToolsSelectStructure();
                }),
                takeUntil(this.destroy$)
            )
            .subscribe(
                tools => {
                    if (this.isDoTheMagic) {
                        this.getActiveTool().subscribe(loaded => {
                            if (loaded) {
                                tools
                                    .map(x =>
                                        Object.assign(x, {
                                            disabled: false,
                                        })
                                    )
                                    .forEach(t => {
                                        if (this.category === 'CISRV') {
                                            t.toolEnabled = this.jobManagemenToolIds.includes(t.toolId);
                                        }
                                        t.disabled = !t.toolEnabled;
                                    });
                            }
                            this.initTools(tools);
                        });
                    } else {
                        this.initTools(tools);
                    }
                },
                () => {
                    this.errorState = true;
                    this.loading = false;
                    this.initialized$.next({ from: 'getToolsError' });
                }
            );

        combineLatest([this.category$, this.projectId$, this.unusedTools$, this.refreshItemsTrigger$])
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.initToolsTrigger$.next();
            });

        this.refreshItemsTrigger$.pipe(takeUntil(this.destroy$)).subscribe(value => {
            if (value) {
                resolvedPromise.then(() => {
                    this.refreshItemsTrigger = false;
                    this.refreshItemsTriggerChange.emit(this.refreshItemsTrigger);
                });
            }
        });

        combineLatest([this.toolId$, this.unitGroupId$, this.unitGroupName$, this.unitId$, this.unitName$, this.initialized$])
            .pipe(debounceTime(100), takeUntil(this.destroy$))
            .subscribe(([toolId, unitGroupId, unitGroupName, unitId, unitName, initialized]) => {
                const currentInputs: IToolsChoosingResult = {
                    toolId: toolId || null,
                    unitGroupId: unitGroupId || null,
                    unitGroupName: unitGroupName || '',
                    unitId: unitId || null,
                    unitName: unitName || '',
                };
                let initByDefault = false;
                if (initialized && initialized.from === 'writeValue') {
                    this.renewAddedUnitsByInputs(this.getValueFromSelectedUnits(), currentInputs);
                } else if (initialized && initialized.from === 'getTools' && !toolId) {
                    initByDefault = this.initToolByDefault;
                }
                this.initSelectedUnits(this.toolsTree, initByDefault);
            });
    }

    private initTools(tools: any) {
        this.loading = false;
        this.toolsTree = tools || [];
        this.toolsTree = this.preProcessToolsTree(this.toolsTree);
        this.sortToolsTree(this.toolsTree);
        this.errorState = false;
        this.handleUpdateNames();
        this.initialized$.next({ from: 'getTools' });
    }

    private handleUpdateNames() {
        if (this.toolsTree[0] && this.toolsTree[0].units && this.toolsTree[0].units.length > 0) {
            const unit = this.unitGroupId ? this.toolsTree[0].units.find(x => x.id === this.unitGroupId) : null;
            const unitChild =
                this.unitId && unit && unit.units && unit.units.length > 0 ? unit.units.find(x => x.id === this.unitId) : null;
            const needChangeGroupName = this.unitGroupName && unit && this.unitGroupName !== unit.name;
            const needChangeName = this.unitName && unitChild && this.unitName !== unitChild.name;
            const obj = {
                toolId: this.toolId,
                unitGroupId: this.unitGroupId,
                unitGroupName: needChangeGroupName ? unit.name : this.unitGroupName,
                unitId: this.unitId,
                unitName: needChangeName ? unitChild.name : this.unitName,
            };
            if (needChangeName || needChangeGroupName) {
                if (needChangeGroupName) {
                    this.formGroup.get('unitGroupId').setValue(obj.unitGroupName);
                }
                if (needChangeName) {
                    this.formGroup.get('unitId').setValue(obj.unitName);
                }
                this.updateInputs(obj as IToolsChoosingResult);
            }
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        const toolDisabledChange = changes['toolDisabled'];
        if (toolDisabledChange) {
            this.handleToolDisabled();
        }
    }

    setDisabledState(isDisabled: boolean): void {
        this.setDisabled = isDisabled;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(obj: IToolsChoosingResult) {
        this.updateInputs(obj);
        this.initialized$.next({ from: 'writeValue' });
    }

    validate(): ValidationErrors | null {
        return (
            !this.formGroup.valid && {
                toolChoosingError: true,
            }
        );
    }

    private preprocessUnits(units: ISelectResponseUnit[]): ISelectResponseUnit[] {
        units = units || [];
        units.forEach(unit => {
            unit.id = unit.id ? unit.id : 'add_' + (unit.name || '');
            unit.name = unit.name ? unit.name : '';
            unit.deleted = unit.deleted ? unit.deleted : false;
            unit.units = this.preprocessUnits(unit.units);
        });
        return units;
    }

    private preProcessToolsTree(tools: ISelectResponse[]): ISelectResponse[] {
        (tools || []).forEach(tool => {
            tool.units = this.preprocessUnits(tool.units);
        });
        return tools;
    }

    renewAddedUnitsByInputs(previous: IToolsChoosingResult, current: IToolsChoosingResult) {
        let initToolsFlag = false;
        if (!previous.unitGroupId && current.unitGroupId) {
            if (this.selectedUnitGroup && this.selectedUnitGroup.name === current.unitGroupName) {
                this.selectedUnitGroup.id = current.unitGroupId;
            } else {
                initToolsFlag = true;
            }
        }
        if (!previous.unitId && current.unitId) {
            if (this.selectedUnit && this.selectedUnit.name === current.unitName) {
                this.selectedUnit.id = current.unitId;
            } else {
                initToolsFlag = true;
            }
        }
        if (initToolsFlag) {
            this.initToolsTrigger$.next();
        }
    }

    validateFormAndDisplayErrors() {
        validateFormAndDisplayErrors(this.formGroup);
    }

    updateInputs(obj: IToolsChoosingResult) {
        obj = obj ? obj : defaultInputsState;
        this.toolId = obj.toolId;
        this.unitGroupId = obj.unitGroupId;
        this.unitGroupName = obj.unitGroupName;
        this.unitId = obj.unitId;
        this.unitName = obj.unitName;
    }

    initForm() {
        this.formGroup = this.fb.group({
            toolId: [{ value: null, disabled: this.toolDisabled }],
            unitGroupId: [null],
            unitGroupName: [''],
            unitId: [null],
            unitName: [''],
        });
        this.setRequired();
    }

    private handleToolDisabled() {
        if (this.formGroup && this.formGroup.get('toolId')) {
            const toolIdControl = this.formGroup.get('toolId');
            if (this.toolDisabled) {
                toolIdControl.disable();
            } else {
                toolIdControl.enable();
            }
        }
    }

    private setRequiredByKey(key: string, required: boolean) {
        if (this.formGroup && this.formGroup.get(key)) {
            if (required) {
                this.formGroup.get(key).setValidators(Validators.required);
            } else {
                this.formGroup.get(key).clearValidators();
            }
            this.formGroup.get(key).updateValueAndValidity();
        }
    }

    private setRequired() {
        const requiredUnitCondition = this.requiredUnit && !this.hideUnit;
        const requiredGroupCondition = (this.requiredGroup || requiredUnitCondition) && this.selectedTool && this.selectedTool.grouped;
        const requiredToolCondition = (this.requiredTool || this.requiredGroup || requiredUnitCondition) && !this.hideTool;
        this.setRequiredByKey('toolId', requiredToolCondition);
        this.setRequiredByKey('unitGroupId', requiredGroupCondition);
        this.setRequiredByKey('unitId', requiredUnitCondition);
    }

    private sortToolsTree(tools: ISelectResponse[]) {
        if (tools && tools.length) {
            tools = sortObjectArrayByKey(tools, 'toolName');
            tools.forEach(tool => {
                this.sortUnits(tool.units);
            });
        }
        return tools;
    }

    private sortUnits(units: ISelectResponseUnit[]) {
        if (units && units.length) {
            sortObjectArrayByKey(units, 'name');
            units.forEach(unit => {
                this.sortUnits(unit.units);
            });
        }
        return units;
    }

    initSelectedUnits(tools: ISelectResponse[], initTools: boolean = false) {
        tools = tools || [];
        if (!this.formGroup) {
            this.initForm();
        }

        // initialization of selected tool
        this.selectedTool = !this.toolId ? null : tools.find(tool => tool.toolId === this.toolId);
        if (!this.selectedTool && this.toolId) {
            this.selectedTool = {
                toolId: this.toolId,
                toolName: `tool id: ${this.toolId}`,
                units: [],
                grouped: !!(this.unitGroupId || this.unitGroupName),
                toolImageUrl: null,
                baseUrl: null,
                groupLabel: 'Group',
                unitLabel: 'Unit',
                hasRoot: false,
                toolEnabled: false,
                toolType: '',
            };
            tools = tools || [];
            tools.push(this.selectedTool);
            this.sortToolsTree(tools);
        }

        if (!this.selectedTool && !this.toolId && initTools && tools.length > 0) {
            this.selectedTool = tools[0];
        }

        // initialization of selected unit group
        this.selectedUnitGroup = !(this.selectedTool && this.selectedTool.units && this.unitGroupId)
            ? null
            : this.selectedTool.units.find(unit => unit.id === this.unitGroupId);

        this.selectedUnitGroup =
            !this.selectedUnitGroup &&
            this.selectedTool &&
            this.selectedTool.units &&
            (this.unitGroupName || (this.selectedTool && this.selectedTool.hasRoot))
                ? this.selectedTool.units.find(unit => unit.name === this.unitGroupName)
                : this.selectedUnitGroup;

        if (this.selectedTool && this.selectedTool.units && !this.selectedTool.grouped) {
            this.selectedUnitGroup = !this.selectedUnitGroup
                ? this.findUnitGroupByDefaultNames(this.selectedTool.units)
                : this.selectedUnitGroup;

            this.selectedUnitGroup =
                !this.selectedUnitGroup && this.selectedTool.units.length && !(this.unitGroupId || this.unitGroupName)
                    ? this.selectedTool.units[0]
                    : this.selectedUnitGroup;
        }

        let unitGroupRef = null;
        if (this.selectedTool && !initTools) {
            unitGroupRef = this.checkAndInitUnit(this.selectedTool.units, this.selectedUnitGroup, this.unitGroupId, this.unitGroupName);
        }
        this.selectedUnitGroup = unitGroupRef ? unitGroupRef : this.selectedUnitGroup;

        // initialization of selected unit
        this.selectedUnit = null;
        if (this.selectedTool && this.selectedUnitGroup && this.selectedUnitGroup.units) {
            this.selectedUnit = this.unitId
                ? this.selectedUnitGroup.units.find(unit => unit.id === this.unitId)
                : this.unitName
                ? this.selectedUnitGroup.units.find(unit => unit.name === this.unitName)
                : this.selectedUnit;
        }

        let unitRef = null;
        if (this.selectedUnitGroup) {
            unitRef = this.checkAndInitUnit(this.selectedUnitGroup.units, this.selectedUnit, this.unitId, this.unitName);
        }
        this.selectedUnit = unitRef ? unitRef : this.selectedUnit;

        this.formGroup.patchValue({
            toolId: !this.selectedTool ? null : this.selectedTool.toolId || null,
            unitGroupId: !this.selectedUnitGroup ? null : this.selectedUnitGroup.id || null,
            unitGroupName: !this.selectedUnitGroup ? null : this.selectedUnitGroup.name || '',
            unitId: !this.selectedUnit ? null : this.selectedUnit.id || null,
            unitName: !this.selectedUnit ? null : this.selectedUnit.name || '',
        });
        this.emitResult();
        this.formInitialized.emit(true);
    }

    private checkAndInitUnit(
        units: ISelectResponseUnit[],
        unit: ISelectResponseUnit,
        unitId: number,
        unitName: string
    ): ISelectResponseUnit {
        if (!unit && (unitName || unitId)) {
            unit = {
                id: unitId || `add_${unitName || ''}` || null,
                name: unitName || null,
                units: [],
                deleted: false,
            };
            units.push(unit);
            this.sortUnits(units);
        }
        return unit;
    }

    private findUnitGroupByDefaultNames(units: ISelectResponseUnit[]): ISelectResponseUnit {
        let i = 0;
        let unitGroup = null;
        while (i < this.defaultGroupName.length && !unitGroup) {
            unitGroup = (units || []).find(unit => unit.name === this.defaultGroupName[i]);
            i++;
        }
        return unitGroup;
    }

    getValueFromSelectedUnits() {
        return {
            toolId: !this.selectedTool ? null : this.selectedTool.toolId || null,
            unitGroupId: !this.selectedUnitGroup ? null : +this.selectedUnitGroup.id || null,
            unitGroupName: !this.selectedUnitGroup ? null : this.selectedUnitGroup.name || '',
            unitId: !this.selectedUnit ? null : +this.selectedUnit.id || null,
            unitName: !this.selectedUnit ? null : this.selectedUnit.name || '',
            toolType: !this.selectedTool ? null : this.selectedTool.toolType || null,
        };
    }

    handleToolChange(value: ISelectResponse) {
        this.selectedTool = value;
        this.selectedUnitGroup = null;

        if (this.selectedTool && !this.selectedTool.grouped) {
            this.selectedUnitGroup =
                this.isDoTheMagic && !this.hideUnit && this.combineGroups
                    ? this.combineGroupsIntoRoot(this.selectedTool.units)
                    : this.findUnitGroupByDefaultNames(this.selectedTool.units);

            this.selectedUnitGroup =
                !this.selectedUnitGroup && this.selectedTool.units && this.selectedTool.units.length
                    ? this.selectedTool.units[0]
                    : this.selectedUnitGroup;

            if (!this.selectedUnitGroup) {
                let unitGroupRef = null;
                if (this.selectedTool) {
                    unitGroupRef = this.checkAndInitUnit(
                        this.selectedTool.units,
                        this.selectedUnitGroup,
                        this.unitGroupId,
                        this.unitGroupName
                    );
                }
                this.selectedUnitGroup = unitGroupRef ? unitGroupRef : this.selectedUnitGroup;
            }
        }

        this.selectedUnit = null;

        this.formGroup.patchValue({
            unitGroupId: null,
            unitGroupName: '',
            unitId: null,
            unitName: '',
        });
        this.resetFilter(true);
        this.emitResult();
    }

    combineGroupsIntoRoot(units: ISelectResponseUnit[]): ISelectResponseUnit {
        return {
            id: 0,
            name: '<ROOT>',
            deleted: false,
            units: units.reduce((flattenedUnits, group) => [...flattenedUnits, ...(group.units || [])], []),
        };
    }

    handleUnitGroupChange(value: ISelectResponseUnit) {
        this.selectedUnitGroup = value;
        this.selectedUnit = null;

        this.formGroup.patchValue({
            unitGroupName: value ? value.name : '',
            unitId: null,
            unitName: '',
        });
        this.resetFilter(false, 'units');
        this.emitResult();
    }

    handleUnitChange(value: ISelectResponseUnit) {
        this.selectedUnit = value;

        this.formGroup.patchValue({
            unitName: value ? value.name : '',
        });
        this.emitResult();
    }

    emitResult() {
        this.setRequired();
        this.isToolEnabled = this.selectedTool ? this.selectedTool.toolEnabled : false;
        this.value = this.getValueFromSelectedUnits();
        this.valueChange.emit(this.value);
        this.selectedToolState.emit(this.isToolEnabled);
        this.onChange(this.value);
        this.onTouched();
        this.initItemsForDropdowns();
    }

    addUnitToSelectedParent = (units: ISelectResponseUnit[], value: string) => {
        units = units || [];
        const newId = `add_${value || ''}`;
        const newUnit: ISelectResponseUnit = {
            id: newId,
            name: value,
            units: [],
            deleted: false,
        };

        units.push(newUnit);
        this.sortUnits(units);
        return newUnit;
    };

    addUnitGroupFn = (value: string) => {
        return this.addUnitToSelectedParent(this.selectedTool.units, value);
    };

    addUnitFn = (value: string) => {
        return this.addUnitToSelectedParent(this.selectedUnitGroup.units, value);
    };

    private applyDisabledState() {
        if (this.formGroup) {
            Object.keys(this.formGroup.controls).forEach(controlKey => {
                if (this.disabled) {
                    this.formGroup.get(controlKey).disable({ onlySelf: true });
                } else {
                    this.formGroup.get(controlKey).enable({ onlySelf: true });
                }
            });
        }
    }

    initItemsForDropdowns() {
        this.groups$ = of(this.selectedTool?.units || []).pipe(map(x => this.getFiltered('groups', x)));
        this.units$ = of(this.selectedUnitGroup?.units || []).pipe(map(x => this.getFiltered('units', x)));
    }

    resetFilter(resetAll?: boolean, type?: FilterType) {
        if (resetAll) {
            this.filters.forEach((v, k, m) => (v = ''));
        } else {
            this.filters.set(type, '');
        }
    }

    setFilterByDefault(value: string, type: FilterType) {
        this.filters.set(type, value);
    }

    handleFilter(type: FilterType, event: any) {
        this.filters.set(type, event);
        this.initItemsForDropdowns();
    }

    getFiltered(type: FilterType, items: ISelectResponseUnit[]) {
        if (items) {
            const term = this.filters.get(type);
            const filterItems = term ? items.filter(i => i.name.toLowerCase().includes(term.toLowerCase())) : items;
            return this.sliceFilteredData(filterItems, type);
        } else {
            return [];
        }
    }

    sliceFilteredData(filterItems: ISelectResponseUnit[], type: FilterType) {
        if (filterItems.length > BATCH_SIZE) {
            const selectedValue = (type === 'groups' ? this.formGroup.get('unitGroupName') : this.formGroup.get('unitName')).value;
            const unit = filterItems.find(x => x.name === selectedValue);
            const slicedResult = filterItems.slice(0, BATCH_SIZE);
            if (unit && !slicedResult.find(x => x.name === unit.name)) {
                slicedResult.push(unit);
            }
            return slicedResult;
        } else {
            return filterItems;
        }
    }

    onCloseResetFilter(type: FilterType) {
        this.resetFilter(false, type);
        this.initItemsForDropdowns();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }
}

export interface IToolsChoosingResult {
    toolId: number;
    unitGroupId?: number;
    unitGroupName?: string;
    unitId?: number;
    unitName: string;
    toolType?: string;
}

interface ISelectResponse {
    toolId: number;
    toolName: string;
    toolImageUrl: string;
    baseUrl: string;
    toolType: string;

    toolEnabled: boolean;
    grouped: boolean;
    groupLabel: string;
    unitLabel: string;
    hasRoot: boolean;

    units?: ISelectResponseUnit[];
}

interface ISelectResponseUnit {
    id: number | string;
    name: string;
    units?: ISelectResponseUnit[];
    deleted: boolean;
}

const defaultInputsState: IToolsChoosingResult = {
    toolId: null,
    unitGroupId: null,
    unitGroupName: '',
    unitId: null,
    unitName: '',
};

export const FILTERS = new Map<FilterType, string>([
    ['groups', ''],
    ['units', ''],
]);

export type FilterType = 'units' | 'groups';

export const BATCH_SIZE = 100;

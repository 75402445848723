import { ModuleWithProviders, NgModule, Optional } from '@angular/core';

import { HelpSupportService } from './help-support.service';
import { BasicHttpClient } from './basic-http-client';

const providers = [HelpSupportService, BasicHttpClient];

@NgModule({
    providers: [...providers]
})
export class HelpSupportModule {
    static forRoot(@Optional() authInterceptor: any): ModuleWithProviders<HelpSupportModule> {
        return {
            ngModule: HelpSupportModule,
            providers: [
                ...providers,
                {
                    provide: 'AuthInterceptor',
                    useValue: authInterceptor
                }
            ],
        };
    }
}

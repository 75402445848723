import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { TourDialogComponent } from './tour-dialog.component';
import { InteractiveTourAnchorDirective, InteractiveTourAnchorPopoverDirective } from './interactive-tour-anchor-directive';




@NgModule({
  declarations: [
    TourDialogComponent,
    InteractiveTourAnchorDirective,
    InteractiveTourAnchorPopoverDirective
  ],
  exports: [InteractiveTourAnchorDirective, InteractiveTourAnchorPopoverDirective],
  imports: [
    CommonModule,
    NgbModalModule
  ]
})
export class InteractiveTourModule { }

import { Inject, Injectable } from '@angular/core';
import { kni, KNI } from '@keep-ni';
import { IKniEvent } from '@app/shared/models/event.model';
import { AuthService } from '@app/auth';
import { ENV_TOKEN } from '@app/tokens';

@Injectable({ providedIn: 'root' })
export class KeepNiService {
    private readonly kni: KNI;

    constructor(@Inject(ENV_TOKEN) private readonly env: Env, private readonly authService: AuthService) {
        this.kni = kni(this.env.webConsoleApiUrl)
            .userSessionResolver(this.authService)
            .withProductAndCustomer(this.env.env.product, this.env.env.customer, this.env.env.name)
            .start(!!this.env.webConsoleApiUrl);
    }

    sendEvent(event: IKniEvent): void {
        this.kni.api.event(event.type, event.params);
    }

    setPage(url: string): void {
        const paramIdx: number = url.indexOf('?');
        const urlWithoutParams: string = paramIdx > -1 ? url.slice(0, paramIdx + 1) : url;
        this.kni.api.setPage(urlWithoutParams);
    }
}

import {IEvent} from './track-events';

export class TrackEventGroup {

    public lastEventSentTs: number;

    constructor(
        public groupName: string,
        public trackEventType: string,
        public eventNames: Array<string>,
        public restrictFrequencyMs: number,
        private createEvent: (ev: Event) => IEvent
    ) {
    }

    isEnoughTimeElapsed(time: number): boolean {
        return !this.lastEventSentTs || this.lastEventSentTs + this.restrictFrequencyMs < time;
    }

    updateTs(time: number) {
        this.lastEventSentTs = time;
    }

    prepareEvent(ev: Event, time: number) {
        this.updateTs(time);
        return this.createEvent(ev);
    }
}

import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {EnvironmentModel} from '@dagility-ui/kit';

import {CiCdProperty, HierarchyLevel} from '../../../model/fields.model';

@Injectable()
export class PropertyService {
    private readonly BASE_PATH = `${this.environment.adminApiURL}/v2/projects`;

    constructor(private http: HttpClient, @Inject('environment') private environment: EnvironmentModel) {}

    getPredefinedProperties(hierarchyLevel: HierarchyLevel) {
        let params = new HttpParams();
        params = params.append('hierarchyLevel', hierarchyLevel);
        return this.http.get<CiCdProperty[]>(`${this.BASE_PATH}/standard_properties`, { params });
    }
}

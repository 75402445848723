import { NgModule, Type } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { HelpSupportModule, PopupService } from '@dagility-ui/shared-components';
import { AuthInterceptor } from '@app/core/http-interceptors/auth-interceptor';
import { APPLICATION_NAME } from '@app/tokens';
import {LoginModalComponent} from "@app/shared/components/modals/login-modal/login-modal.component";

const modals: Type<any>[] = [LoginModalComponent];

@NgModule({
    declarations: [AppComponent, ...modals],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CoreModule,
        ToastrModule.forRoot(),
        AppRoutingModule,
        SharedModule.forRoot(),
        HelpSupportModule.forRoot(AuthInterceptor),
    ],
    providers: [
        {
            provide: ToastrService,
            useClass: PopupService,
        },
        {
            provide: APPLICATION_NAME,
            useValue: 'UST PACE SolutionHub',
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}

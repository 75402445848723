import {IEnv, IEnvResolver} from "../../api/keep-ni-config";
import {Observable} from "rxjs";

export class DefaultEnvResolver implements IEnvResolver {

    private environment: string;
    private envId: string;
    private product: string;
    private customer: string;

    public static withProductAndCustomer(product: string, customer: string, environment?: string, envId?: string): DefaultEnvResolver {
        let e = new DefaultEnvResolver();
        e.environment = environment;
        e.product = product;
        e.customer = customer;
        e.envId = envId;
        return e;
    }

    getEnv(): Promise<IEnv> | Observable<IEnv> {
        return Promise.resolve(this.fillEnv());
    }

    protected fillEnv(): IEnv {
        return {
            envName: this.environment || window.location.origin,
            environment: window.location.origin,
            envId: this.envId || window.location.origin,
            customer: this.customer,
            product: this.product
        };
    }
}

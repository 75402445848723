export type EventType =
    | 'LOGOUT'
    | 'ACTIVE'
    | 'GLOBAL'
    | 'PAGE_TTFB'
    | 'RESOURCE_TTFB'
;

export interface IEvent {
    type: EventType | string;
    getParameters?(): any | null;
}

export interface IConvertedEvent {
    type?: EventType | string;
    timestamp: number;
    page: string;
    parameters?: any;
}

export class TrackingTTFBEvent implements IEvent {
    readonly type: EventType;

    constructor(public readonly value: number, public readonly resource: string, public readonly navigation: boolean) {
        this.type = navigation ? 'PAGE_TTFB' : 'RESOURCE_TTFB';
    }

    getParameters() {
        return {
            numericVal: this.value,
            resource: this.resource
        };
    }
}

export class UserActivity implements IEvent {
    readonly type: EventType = 'ACTIVE';
}

export interface IParams {
    event: string;
    tags?: string; // Array<string>;
    key?: string;
    points?: string; // Array<Array<number>>;
    elementId?: string;
    element: string;
    elementType?: string;
    elementInputType?: string;
    componentPath: string; // Array<{type: string, name: string}>;
}

export class ActionUserEvent implements IEvent {

    constructor(
        public readonly type: EventType | string,
        public readonly name: string,
        public readonly page: string,
        public parameters: IParams
    ) {}

    getParameters() {
        return this.parameters;
    }
}

export class Logout implements IEvent {
    readonly type: EventType = 'LOGOUT';
}

export class LoginEvent implements IEvent {
    readonly type: EventType | string = 'LOGIN';
}

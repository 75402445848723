<div *transloco="let transloco; read: 'loginModal'">
    <div class="modal-body d-flex flex-column">
        <div class="d-flex flex-row">
            <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="activeModal.dismiss('Cancel')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="d-flex justify-content-center flex-row">
            <div class="d-flex icon">
                <fa-icon icon="facInfoCircle" [style.font-size.px]="34" class="color-brand mr-10px"></fa-icon>
            </div>
        </div>

        <div class="d-flex justify-content-center mb-1">
            <h2>{{ transloco('unauthorizedAccess') }}</h2>
        </div>
        <div class="d-flex justify-content-center mb-1">
            <h2>{{ transloco('pleaseSignIn') }}</h2>
        </div>
    </div>
    <div class="modal-footer justify-content-center">
        <button class="btn btn-secondary" type="button" (click)="register()">
            {{ transloco('register') }}
        </button>
        <button class="btn btn-primary ml-25px" type="submit" (click)="login()">
            <fa-icon class="mr-8px font-16px" [icon]="icons.logIn"></fa-icon>
            {{ transloco('signIn') }}
        </button>
    </div>
</div>

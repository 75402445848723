import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { concatMap, filter, pluck, shareReplay } from 'rxjs/operators';
import { TranslationKeysOfDropdown, TranslationKeysOfPagination, TranslationKeysOfTextarea } from '@dagility-ui/kit';
import { TranslationKeysOfRating } from '@dagility-ui/kit/modules/forms/controls/rating/rating.component';

@Injectable({
    providedIn: 'root',
})
export class TranslationService {
    private readonly payload$ = this.translocoService.events$.pipe(
        filter(e => {
            return e.type === 'translationLoadSuccess' || e.type === 'langChanged';
        }),
        pluck('payload'),
        shareReplay()
    );

    private _dropdownTranslatedText: Partial<Record<TranslationKeysOfDropdown, string>>;
    get dropdownTranslatedText(): Partial<Record<TranslationKeysOfDropdown, string>> {
        return this._dropdownTranslatedText;
    }

    private _textareaTranslatedText: Partial<Record<TranslationKeysOfTextarea, string>>;
    get textareaTranslatedText(): Partial<Record<TranslationKeysOfTextarea, string>> {
        return this._textareaTranslatedText;
    }

    private _paginationTranslatedText: Partial<Record<TranslationKeysOfPagination, string>>;
    get paginationTranslatedText(): Partial<Record<TranslationKeysOfPagination, string>> {
        return this._paginationTranslatedText;
    }

    private _ratingTranslatedText: Partial<Record<TranslationKeysOfRating, string>>;
    get ratingTranslatedText(): Partial<Record<TranslationKeysOfRating, string>> {
        return this._ratingTranslatedText;
    }

    private _toastrTranslatedText: any;
    get toastrTranslatedText(): any {
        return this._toastrTranslatedText;
    }

    constructor(private readonly translocoService: TranslocoService) {
        this.translateObject('common.dropdown').subscribe(
            dropdown =>
                (this._dropdownTranslatedText = {
                    UNNAMED_GROUP: dropdown.unnamedGroup,
                    SELECT: dropdown.select,
                    ALL: dropdown.all,
                    NONE: dropdown.none,
                    INVERT: dropdown.invert,
                    SELECTED: dropdown.selected,
                    ADD_ITEM: dropdown.addItem,
                })
        );
        this.translateObject('common.textarea').subscribe(
            textarea =>
                (this._textareaTranslatedText = {
                    CHARACTERS_LEFT: textarea.charactersLeft,
                })
        );
        this.translateObject('common.pagination').subscribe(
            pagination =>
                (this._paginationTranslatedText = {
                    SHOW: pagination.show,
                    ENTRIES: pagination.entries,
                    SHOWING: pagination.showing,
                    TO: pagination.to,
                    OF: pagination.of,
                    JUMP_TO: pagination.jumpTo,
                })
        );
        this.translateObject('assets.card.rating').subscribe(
            ratingText =>
                (this._ratingTranslatedText = {
                    USER_RATINGS: ratingText.userRatings,
                    DOWNLOADS: ratingText.downloads,
                    STAR: ratingText.star,
                    STARS: ratingText.stars,
                })
        );
        this.translateObject('common.toastr').subscribe(toastr => (this._toastrTranslatedText = toastr));
    }

    translateObject(key: string): Observable<any> {
        return this.payload$.pipe(
            concatMap(() => {
                return this.translocoService.selectTranslateObject(key);
            })
        );
    }
}

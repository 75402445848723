import { Directive, ElementRef, EventEmitter, HostListener, Input, NgModule, NgZone, Output } from '@angular/core';

@Directive({
    selector: '[libDrag]',
})
export class DragDirective {
    @Input('libDrag') dragMeta: any;

    @Input('libDragDisabled') disabled: boolean;

    @Output() dragStarted = new EventEmitter();

    @Output() dropped = new EventEmitter();

    dragInfo: any = null;

    lastDroppable: any;

    hoverClass = 'drop--hovered';

    constructor(private ngZone: NgZone, private elRef: ElementRef) {}

    @HostListener('mousedown', ['$event'])
    onMouseDown(e: any) {
        if (this.disabled) {
            return;
        }

        e.stopPropagation();
        this.dragInfo = {
            downX: e.pageX,
            downY: e.pageY,
        };
        this.dragStarted.emit();

        this.ngZone.runOutsideAngular(() => {
            document.addEventListener('mousemove', this.move);

            document.addEventListener(
                'mouseup',
                () => {
                    this.ngZone.run(() => {
                        this.dropped.emit({ to: this.lastDroppable, meta: this.dragMeta });
                    });

                    this.finishDrag();
                },
                {
                    once: true,
                }
            );
        });
    }

    move = (e: any) => {
        const dragInfo = this.dragInfo;
        if (!dragInfo) {
            return;
        }

        document.body.classList.add('dragging');
        if (!dragInfo.clone) {
            const el = this.elRef.nativeElement;
            dragInfo.clone = el.cloneNode(true);
            dragInfo.clone.classList.add('drag-clone');

            let width = el.offsetWidth;
            let height = el.offsetHeight;
            if (el.parentNode && el.parentNode.tagName === 'foreignObject') {
                const zoom = el.parentNode.getCTM().a;
                width *= zoom;
                height *= zoom;
            }
            dragInfo.clone.style.width = width + 'px';
            dragInfo.clone.style.height = height + 'px';
            // const coords = getCoords(this.elRef.nativeElement);
            dragInfo.shiftX = 0; // dragInfo.downX - coords.left;
            dragInfo.shiftY = 0; // dragInfo.downY - coords.top;

            this.startDrag();
        }

        dragInfo.clone.style.left = e.pageX - dragInfo.shiftX + 'px';
        dragInfo.clone.style.top = e.pageY - dragInfo.shiftY + 'px';

        this.dragInfo.clone.hidden = true;

        const elem = document.elementFromPoint(e.clientX, e.clientY);
        this.handleDroppable(elem);

        this.dragInfo.clone.hidden = false;
    };

    handleDroppable(elem: any) {
        if (this.lastDroppable) {
            this.lastDroppable.classList.remove(this.hoverClass);
        }

        this.dragInfo.clone.removeAttribute('data-hint');
        this.dragInfo.clone.classList.add('drag-clone--empty');
        if (elem && elem.dataset.zone === 'drop') {
            this.lastDroppable = elem;
            if (elem.dataset.hint) {
                this.dragInfo.clone.setAttribute('data-hint', elem.dataset.hint);
                this.dragInfo.clone.classList.remove('drag-clone--empty');
            }
            this.lastDroppable.classList.add(this.hoverClass);
        } else if (this.lastDroppable) {
            this.lastDroppable = null;
        }
    }

    startDrag() {
        const clone = this.dragInfo.clone;

        document.body.appendChild(clone);
        clone.style.zIndex = 9999;
        clone.style.position = 'fixed';
    }

    private finishDrag() {
        if (this.dragInfo.clone) {
            this.dragInfo.clone.parentNode.removeChild(this.dragInfo.clone);
        }
        if (this.lastDroppable) {
            this.lastDroppable.classList.remove(this.hoverClass);
        }
        this.dragInfo = null;
        this.lastDroppable = null;
        document.removeEventListener('mousemove', this.move);
        document.body.classList.remove('dragging');
    }
}

@NgModule({
    declarations: [DragDirective],
    exports: [DragDirective]
})
export class DragDirectiveModule {}

import { Inject, Pipe, PipeTransform } from '@angular/core';
import { AssetDto } from '@app/shared/components/assets/asset.model';
import { ENV_TOKEN } from '@app/tokens';

enum AssetVersionImageType {
    NORMAL = 'NORMAL',
    RESERVED = 'RESERVED',
}

@Pipe({
    name: 'assetLogoUrl',
})
export class AssetLogoUrlPipe implements PipeTransform {
    constructor(@Inject(ENV_TOKEN) private readonly env: Env) {}

    transform(asset: AssetDto, type: AssetVersionImageType = AssetVersionImageType.NORMAL) {
        if (!asset.logoExists) {
            return '';
        }
        return `${this.env.assetsApiURL}/${asset.id}/versions/${asset.assetVersionId}/image?type=${type}${
            asset.lastModifiedAt ? '&t=' + Date.parse(asset.lastModifiedAt) : ''
        }`;
    }
}

import { Validators } from '@angular/forms';

export const fieldAttributes = {
    name: {
        minLength: 3,
        maxLength: 50,
        pattern: '^[A-Za-z0-9_]+[A-Za-z0-9_ ]*$',
        placeHolder: 'Must be between 3 - 50 characters',
        customErrors: {
            pattern: 'Allowed letters, numbers, space, \'_\'. Space is not allowed as first character.'
        }
    },
    url: {
        minLength: 8,
        maxLength: 100,
        pattern: new RegExp('(http:\\/\\/www\\.|https:\\/\\/www\\.|http:\\/\\/|https:\\/\\/)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?|^((http:\\/\\/www\\.|https:\\/\\/www\\.|http:\\/\\/|https:\\/\\/)?([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])', 'i'),
        placeHolder: 'Must be between 8 - 100 characters',
    },
    sla: {
        minLength: 1,
        maxLength: 6,
        pattern: '(?:100(?:\\.0{1,2})?|[0-9]{1,2}(?:\\.[0-9]{1,2})?)',
        placeHolder: 'Must be between 0.0 - 100.00',
        customErrors: {
            pattern: 'Invalid SLA. Valid Formats x.x, xx.x',
            min: 'SLA must be greater or equal then 0',
            max: 'SLA must be less or equal then 100'
        }
    },
    expectedResponse: {
        minLength: 3,
        maxLength: 3,
        pattern: '^[1-9]{1}[0-9]{2}$',
        placeHolder: 'Must be 3 digits HTTP status',
        customErrors: {
            pattern: 'Invalid Expected Response Status. Only digits allowed'
        }
    },
    schedule: {
        minLength: 1,
        maxLength: 6,
        pattern: '^[1-9]{1}[0-9]{0,5}$',
        placeHolder: 'Must be between 1 - 6 digits',
        customErrors: {
            pattern: 'Invalid Schedule. Only digits allowed'
        }
    },
    responseTime: {
        minLength: 1,
        maxLength: 6,
        pattern: '^[1-9]{1}[0-9]{0,5}$',
        placeHolder: 'Must be between 1 - 6 digits',
        customErrors: {
            pattern: 'Invalid Expected Response. Only digits allowed'
        }
    },
};

export function defaultValidations(config: any) {
    return [
        Validators.required,
        Validators.minLength(config.minLength),
        Validators.maxLength(config.maxLength),
        Validators.pattern(config.pattern),
    ];
}

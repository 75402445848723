
export interface Point {
    x: number,
    y: number,
    timeStamp: number,
}

export class MouseTrajectoryOptimizationService {
    private static line: number = 10; //px
    private static controlTime: number = 500; //ms

    private outputArray: Array<Array<number>> = [];
    private currentPoint: Point = null;

    public addPointByEvent(event: MouseEvent): void {
        let point: Point = new class implements Point {
            timeStamp: number = event.timeStamp;
            x: number = event.x;
            y: number = event.y;
        };
        this.logic(point);
    }

    public getResultArray() {
        let arr = this.outputArray;
        this.outputArray = [];
        return arr;
    }

    private logic(point: Point): void {
        if (this.currentPoint == null) {
            this.outputArray.push([point.x, point.y]);
            this.currentPoint = point;
            return;
        }
        if (point.timeStamp - this.currentPoint.timeStamp < MouseTrajectoryOptimizationService.controlTime)
            return;
        const x: number = point.x - this.currentPoint.x;
        const y: number = point.y - this.currentPoint.y;
        const deltaLength: number = Math.pow((Math.pow(x, 2) + Math.pow(y, 2)), 0.5);
        if (deltaLength > MouseTrajectoryOptimizationService.line) {
            this.outputArray.push([point.x, point.y]);
            this.currentPoint = point;
        }
    }
}

import { EChartsChart } from '../echarts.chart';
import { Subject } from 'rxjs';

import { getAllLegendItems } from '../../../chart-legend/chart-legend.component';
import { qualitativePalette, subTextColor, textColor } from '../charts-colors';
import { BasicLineEchartsChart } from './basic-line-echarts';

export class LineEChartsChart extends EChartsChart {
    options: Record<string, any> = {
        textStyle: {
            fontFamily: 'sans-serif',
            fontSize: 12,
        },
        grid: {
            top: '15%',
            bottom: '30%',
        },
        title: {
            text: '',
            subtext: '',
            textAlign: 'center',
            textVerticalAlign: 'top',
            left: '50%',
            textStyle: {
                color: textColor(),
                fontSize: 14,
                fontWeight: '500',
                fontFamily: 'sans-serif',
            },
            subtextStyle: {
                color: subTextColor(),
                fontSize: 12,
                fontFamily: 'sans-serif',
            },
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: [] as any[],
            axisLabel: {
                fontSize: 10,
                rotate: 45,
                margin: 11,
                textStyle: {
                    color: textColor(),
                },
            },
            axisLine: {
                show: false,
            },
            axisTick: {
                show: false,
                alignWithLabel: true,
            },
            nameLocation: 'center',
            nameGap: 55,
            nameTextStyle: {
                color: textColor(),
            },
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                textStyle: {
                    color: textColor(),
                },
            },
            axisLine: {
                show: false,
            },
            axisTick: {
                show: false,
            },
            nameLocation: 'center',
            nameGap: 48,
            nameTextStyle: {
                color: textColor(),
            },
        },
        legend: {
            bottom: '10',
            icon: 'circle',
            width: '85%',
            textStyle: {
                fontSize: 11,
                color: textColor(),
            },
            selector: ['all', 'inverse'],
            selectorPosition: 'start',
        },
        series: [] as any[],
        color: qualitativePalette,
        tooltip: {
            trigger: 'item',
            renderMode: 'html',
            textStyle: {
                fontSize: 12,
            },
            formatter: BasicLineEchartsChart.toolTipFormatter,
        },
    };

    typeAttributes = {
        type: 'line',
        smooth: true,
        symbol: 'rect',
        symbolSize: 8,
        showAllSymbol: 'auto',
        animation: true,
    };

    assignOptions(options: Record<string, any>) {
        if (options.xAxis) {
            if (options.xAxis.type) {
                this.options.xAxis.type = options.xAxis.type;
            }
            if (options.xAxis.show !== undefined) {
                this.options.xAxis.show = options.xAxis.show;
            }
        }
        if (options.yAxis && options.yAxis.show !== undefined) {
            this.options.yAxis.show = options.yAxis.show;
        }
        if (options.dataZoom) {
            this.options.dataZoom = [
                {
                    height: '8%',
                    id: 'dataZoomX',
                    type: 'slider',
                    xAxisIndex: [0],
                    filterMode: 'filter',
                    showDetail: false,
                    start: 30,
                    bottom: 10,
                },
            ];
        }
        if (options.hasOwnProperty('editWidgetMode') && options.editWidgetMode) {
            this.options.yAxis.nameGap = 25;
        }
        if (options.grid && options.grid.bottom) {
            this.options.grid.bottom = options.grid.bottom;
        }
        if (options.series.length > 6) {
            this.options.legend['type'] = 'scroll';
            this.options.legend['pageFormatter'] = 'page {current} of {total}';
            this.options.legend['pageButtonItemGap'] = 30;
            this.options.grid.bottom = '45%';
            this.options.legend.bottom = '30';
        }
        if (options.series.length && options.series[0].stack && options.series[0].stack === 'Total') {
            this.typeAttributes.smooth = false;
        }

        const chartColors = this.getDynamicChartColors(options.series);
        if (chartColors.length && (!options.colors || !options.colors.length)) {
            options.colors = chartColors;
            options.series = this.removeDynamicCOlors([...options.series]);
        } else if (chartColors.length && options.colors && options.colors.length) {
            options.series = this.removeDynamicCOlors([...options.series]);
        }

        super.assignOptions(options);
    }

    removeDynamicCOlors(series: any) {
        return series.map((obj: any) => {
            const itemStyle = obj.data.filter((x: any) => x != null && x.itemStyle);
            if (obj.data.length && itemStyle.length && itemStyle[0].itemStyle && itemStyle[0].itemStyle.color) {
                obj.data = obj.data.map((x: any) => {
                    return x !== null ? x.value : null;
                });
            }
            return obj;
        });
    }

    getDynamicChartColors(series: any) {
        return series
            .map((obj: any) => {
                let reslt = '';
                const itemStyle = obj.data.filter((x: any) => x != null && x.itemStyle);
                if (obj.data.length && itemStyle.length && itemStyle[0].itemStyle && itemStyle[0].itemStyle.color) {
                    reslt = itemStyle[0].itemStyle.color;
                }
                return reslt;
            })
            .filter((color: string) => color);
    }

    handleEvents(emitter: Subject<{ type: string; payload: any }>, chartInstance: any) {
        if (!chartInstance) {
            return;
        }

        chartInstance.on('click', 'series', (event: any) => {
            emitter.next({
                type: 'barClicked',
                payload: {
                    barId: event.seriesId,
                    barName: event.seriesName,
                    name: event.name,
                    value: event.value,
                    legend: getAllLegendItems(chartInstance),
                },
            });
        });

        super.handleEvents(emitter, chartInstance);
    }
}

import { Component, Input } from '@angular/core';
import { checkAllIcon } from '@app/shared/icons/solutionhub-icons';

@Component({
    selector: 'app-success-banner',
    templateUrl: './success-banner.component.html',
    styleUrls: ['./success-banner.component.scss'],
})
export class SuccessBannerComponent {
    @Input() title: string = '';
    @Input() description: string = '';

    readonly checkAllIcon = checkAllIcon;
}

import { AssetDto } from '@app/shared/components/assets/asset.model';

export class KniParam {
    assetId: number;
    versionId: number;
    createdByUserId: number;
    userGroupId: number;

    constructor(assetId: number, versionId: number, ownerId: number, groupId: number) {
        this.assetId = assetId;
        this.versionId = versionId;
        this.createdByUserId = ownerId;
        this.userGroupId = groupId;
    }

    public static fromListDto(asset: AssetDto): KniParam {
        return new KniParam(asset.id, asset.assetVersionId, asset.createdBy, asset.groupId);
    }
}

export interface IKniEvent {
    type: string;
    params: KniParam;
}

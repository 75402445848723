<div class="assets-list-container">
    <app-asset-list-item class="d-block"
                         *ngFor="let item of assets"
                         [item]="item"
                         [class.mb-3]="!isGridView"
                         [isManageAssets]="isManageAssets"
                         [hasManagementActions]="hasManagementActions"
                         [hasActionButtons]="hasActionButtons"
                         [canSelectAsset]="canSelectAssets"
                         [showStatus]="showStatus"
                         [readOnly]="readOnly"
                         [isLoggedIn]="isLoggedIn"
                         [isGridView]="isGridView"
                         (managementActionDone)="managementActionDone.emit($event)"
    ></app-asset-list-item>
</div>

import { inject, InjectionToken } from '@angular/core';

export const ENV_TOKEN = new InjectionToken<Env>('app-env-token');

export const APPLICATION_NAME = new InjectionToken<string>('Application name');

export const APPLICATION_TITLE_NAME = new InjectionToken<string>('Application name in browser tab', {
    factory: () => {
        const nameFromEnv = inject(ENV_TOKEN)?.titleName;
        const nameFromToken = inject(APPLICATION_NAME);

        return nameFromEnv ?? nameFromToken;
    },
});

import {TrackEventSenderService} from '../services/track-event-sender.service';
import {KeepNIConfig} from './keep-ni-config';
import {UserActivityTrackingService} from '../services/user-activity-tracking.service';
import {TrackTTFBService} from '../services/track-ttfb.service';
import {EventType, IConvertedEvent} from '../model/track-events';

export class KeepNiApi {
    private readonly eventSender: TrackEventSenderService;
    private readonly userActivityTrackingService: UserActivityTrackingService;
    private readonly userTtfbTrackingService: TrackTTFBService;

    constructor(private config: KeepNIConfig) {
        this.eventSender = new TrackEventSenderService(config);
        this.userTtfbTrackingService = new TrackTTFBService(this.eventSender);
        this.userActivityTrackingService = new UserActivityTrackingService(this.eventSender, this.userTtfbTrackingService);
    }

    event(type: EventType | string, parameters?: any): KeepNiApi{
        this.eventSender.sendEvent({
            type: type,
            getParameters(): any {
                return parameters;
            }
        })
        return this;
    }

    start(on: boolean = true): KeepNiApi {
        this.eventSender.setEnabled(on);
        this.userActivityTrackingService.subscribeToObservables();
        return this;
    }

    setPage(page: string): KeepNiApi {
        this.config.pagePathResolver.setPath(page);
        return this;
    }

    subscribe(): IConvertedEvent {
        return this.eventSender._latestEvent;
    }


    trackLogout(successCallback = () => {/*empty implementation by default*/}, errorCallback = (_err: any) => {/*empty implementation by default*/}): KeepNiApi {
        this.eventSender.sendBeforeLogout(successCallback, errorCallback);
        return this;
    }

    isEnabled(): boolean {
        return this.eventSender.isEnabled();
    }

    enableLog(enabled: boolean): KeepNiApi {
        this.eventSender.logEnabled = enabled;
        return this;
    }

    maxEventBufferSize(maxEventBufferSize: number) {
        this.eventSender.maxEventBufferSize(maxEventBufferSize);
    }
}

import { NgModule } from '@angular/core';
import { UiKitModule } from '@dagility-ui/kit';

import { SvgBuilderComponent } from './components/svg-builder/svg-builder.component';
import { SvgBlockDirective } from './directives/svg-block.directive';
import { SvgEdgeDirective } from './directives/svg-edge.directive';
import { DragDirectiveModule } from '../../directives/drag.directive';
import { SvgZoomDirectiveModule } from '../../directives/svg-zoom.directive';

const directives = [SvgBlockDirective, SvgEdgeDirective];
const components = [SvgBuilderComponent];

@NgModule({
    declarations: [directives, components],
    imports: [UiKitModule, DragDirectiveModule, SvgZoomDirectiveModule],
    exports: [directives, components],
})
export class SvgBuilderModule {}

export { SvgBlockDirective, SvgEdgeDirective, SvgBuilderComponent };


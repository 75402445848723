import { Component, OnInit } from '@angular/core';
import { AssetFileDto, AssetDto } from '@app/shared/components/assets/asset.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DOWNLOAD_SELECTED_PREFIX, FileService } from '@app/shared/services/api/file.service';
import { facDownload } from '@dagility-ui/kit';

export interface AssetDownloadable extends AssetFileDto {
    selected: boolean;
    hover?: boolean;
}

@Component({
    selector: 'app-download-files-modal',
    templateUrl: './download-files-modal.component.html',
    styleUrls: ['./download-files-modal.component.scss'],
})
export class DownloadFilesModalComponent implements OnInit {
    readonly asset: AssetDto;
    files: AssetDownloadable[] = [];
    readonly icons = {
        download: facDownload,
    };
    selected: number;

    get allSelected(): boolean {
        return this.selected === this.files?.length;
    }

    get downloadingSelected(): boolean {
        return this.fileService.isDownloading(`${DOWNLOAD_SELECTED_PREFIX}${this.asset.assetVersionId}`);
    }

    getDownload(file: AssetDownloadable) {
        return this.fileService.getDownloading(file.uuid);
    }

    ngOnInit(): void {
        this.files = this.asset?.downloadable.map(d => ({ ...d, selected: true })) || [];
        this.selected = this.files.length || 0;
    }

    constructor(public activeModal: NgbActiveModal, private readonly fileService: FileService) {}

    isDownloading(uuid: string): boolean {
        return this.fileService.isDownloading(uuid);
    }

    download(file: AssetDownloadable) {
        if (this.isDownloading(file.uuid)) {
            return;
        }
        this.fileService.downloadFile(file.uuid, this.asset.assetVersionId, file.originalFileName).subscribe(
            () => (file.hover = true),
            () => (file.hover = false),
            () => (file.hover = false)
        );
        this.fileService.sendAssetDownloadEvent(this.asset);
    }

    downloadSelected() {
        const toDownload = this.files.filter(f => f.selected).map(f => f.uuid);
        this.fileService
            .downloadSelected(toDownload, this.asset.assetVersionId, this.asset.assetName + '.zip')
            .subscribe(() => this.activeModal.close());
        this.fileService.sendAssetDownloadEvent(this.asset);
    }

    selectFile(file: AssetDownloadable) {
        const selectedFile = this.files.find(f => f.uuid === file.uuid);
        if (selectedFile) {
            selectedFile.selected = !selectedFile.selected;
        }
        this.selected = this.files.filter(f => f.selected)?.length || 0;
    }

    selectAll() {
        this.files.forEach(f => (f.selected = !this.allSelected));
        this.selected = this.files.filter(f => f.selected)?.length || 0;
    }
}

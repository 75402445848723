import { EmbeddedViewRef, Injector } from '@angular/core';

import { AgGridService } from '../../../ag-grid/ag-grid.service';

const TRIM_LEN = 12;

export function drawLegend(chartInstance: any, injector: Injector) {
    const chartModel = chartInstance.getModel();
    const legendModels = chartModel.findComponents({
        mainType: 'legend',
    });
    if (!legendModels || !legendModels.length) {
        return;
    }

    const legendModel = legendModels[0];

    const items = legendModel.getData();
    const chartOptions = chartInstance.getOption();

    const header1 = chartOptions.tableHeader1 !== undefined ? chartOptions.tableHeader1 : '';
    const header2 = chartOptions.tableHeader2 !== undefined ? chartOptions.tableHeader2 : '';

    function cellRenderer(params: any) {
        return params.value ? params.value : '';
    }

    let columnDefs: any = [
        {
            headerName: header1,
            field: header1,
            valueFormatter: function(params: any) {
                return params.value;
            },
            filter: 'agTextColumnFilter',
            filterParams: {
                suppressAndOrCondition: true,
                filterOptions: ['contains'],
            },
            headerTooltip: header1,
            cellRenderer: cellRenderer,
            tooltip: function(params: any) {
                const tooltipValue = params.value.substring(params.value.match(/<\/label>/).index + 8, params.value.length);
                return tooltipValue;
            },
        },
        {
            headerName: header2,
            field: header2,
            headerTooltip: header2,
            filter: 'agTextColumnFilter',
            filterParams: {
                suppressAndOrCondition: true,
                filterOptions: ['contains'],
            },
        },
    ];

    let rowData: any = [];

    const legendWrapper = document.createElement('div');
    legendWrapper.style.cssText = `
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    margin: 0;
    padding-top: 10px;
    background: transparent;`;
    const legendContainer = document.createElement('div');
    legendContainer.className = 'legend-container';
    legendContainer.style.cssText = 'width: 100%; max-height: 100%;';

    const gridService = injector.get(AgGridService);
    const inputValues: any = { columnDefs, rowData };
    const componentRef = gridService.createGridComp(inputValues);

    legendContainer.appendChild((componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0]);
    legendWrapper.appendChild(legendContainer);

    const hostEl = chartInstance.getDom();
    hostEl.appendChild(legendWrapper);

    function addLegendItemToContainer(name: string, color: string, value: number = 0) {
        const legendItem = createLegendItem(name, color, value);
        rowData.push({
            [header1]: `<label style="width: 12px;height: 12px;margin-top: 3px;border-radius:50%;vertical-align: middle;margin-right: 10px;cursor: pointer;background: ${color}"></label>${name}`,
            [header2]: value,
        });

        let showTimeout: number;
        let tooltip: HTMLDivElement;

        legendItem.onmousemove = event => {
            if (!tooltip) {
                window.clearTimeout(showTimeout);

                showTimeout = window.setTimeout(() => {
                    if (!tooltip) {
                        tooltip = createTooltip(name);
                        document.body.appendChild(tooltip);
                    }

                    updateTooltipPosition(tooltip, event);
                }, 200);
            } else {
                updateTooltipPosition(tooltip, event);
            }
        };

        legendItem.onmouseout = event => {
            clearTimeout(showTimeout);
            if (tooltip) {
                tooltip.parentNode.removeChild(tooltip);
                tooltip = null;
            }
        };
    }

    items.forEach((itemModel: any, i: number) => {
        const name = itemModel.get('name');
        const seriesModel = chartModel.getSeriesByName(name)[0];

        // Series legend
        if (seriesModel) {
            const data = seriesModel.getData();
            let color = data.getVisual('color');

            // If color is a callback function
            if (typeof color === 'function') {
                // Use the first data
                color = color(seriesModel.getDataParams(0));
            }

            addLegendItemToContainer(name, color);
        } else {
            // Data legend of pie, funnel
            chartModel.eachRawSeries((model: any) => {
                if (model.legendVisualProvider) {
                    const provider = model.legendVisualProvider;

                    if (!provider.containName(name)) {
                        return;
                    }
                    const idx = provider.indexOfName(name);
                    const style = provider.getItemVisual(idx, 'style');
                    const color = style.fill;

                    addLegendItemToContainer(name, color, model.option.data[idx].value);
                }
            });
        }
    });
    componentRef.changeDetectorRef.detectChanges();
}

export function createTooltip(text: string): HTMLDivElement {
    const tooltip = document.createElement('div');

    tooltip.style.cssText = `
        position: fixed;
        padding: 10px;
        background: #fff;
        border-radius: 5px;
        transform: translate(-50%, -135%);
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    `;

    tooltip.textContent = text;

    return tooltip;
}

export function updateTooltipPosition(tooltip: HTMLDivElement, event: MouseEvent) {
    tooltip.style.top = `${event.clientY}px`;
    tooltip.style.left = `${event.clientX}px`;
    tooltip.style.zIndex = '9999999';
}

function createLegendItem(name: string, color: string, value: number = 0) {
    const itemEl = document.createElement('tr');
    itemEl.style.cssText = `
    padding: 20px; line-height: 20px; font-size: 11; height: 15px; flex: 0 0 16%;
             white-space: nowrap; text-overflow: ellipsis; overflow: hidden; min-width: 100px; cursor: pointer;`;
    const tableTD1 = document.createElement('td');
    tableTD1.className = 'legend-item';
    const legendColor = document.createElement('label');
    legendColor.className = 'legend-color pull-left';
    legendColor.style.cssText = `width: 12px;height: 12px;margin-top: 3px;border-radius:50%;vertical-align: middle;margin-right: 10px;cursor: pointer;background: ${color}`;

    tableTD1.textContent = name.length > TRIM_LEN ? name.substr(0, TRIM_LEN - 1) + '...' : name;
    tableTD1.appendChild(legendColor);
    tableTD1.style.cssText = `width: 58%;`;
    itemEl.appendChild(tableTD1);

    const tableTD2 = document.createElement('td');
    tableTD2.style.cssText = `width: 42%;`;
    tableTD2.textContent = `${value}`;
    itemEl.appendChild(tableTD2);

    return itemEl;
}

function legendIsOverflow(e: MouseEvent) {
    const target = e.target as HTMLDivElement;

    return target.offsetWidth < target.scrollWidth;
}

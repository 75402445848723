import { EChartsChart } from '../echarts.chart';
import { Subject } from 'rxjs';

import { qualitativePalette, textColor } from '../charts-colors';

export class BoxplotEChartsChart extends EChartsChart {
    options: Record<string, any> = {
        textStyle: {
            fontFamily: 'sans-serif',
            fontSize: 12,
            color: textColor(),
        },
        tooltip: {
            trigger: 'item',
        },
        color: qualitativePalette,
        boxWidth: [4, 30],
        grid: {
            left: '10%',
        },
        yAxis: {
            splitArea: {
                show: false,
            },
            axisTick: {
                show: false,
            },
            axisLine: {
                show: false,
            },
            axisLabel: {
                textStyle: {
                    color: textColor(),
                },
            },
            nameLocation: 'center',
            nameGap: 42,
            minInterval: 1,
            nameTextStyle: {
                color: textColor(),
            },
        },
        xAxis: {
            type: 'category',
            data: null,
            boundaryGap: true,
            nameGap: 50,
            splitArea: {
                show: false,
            },
            splitLine: {
                show: false,
            },
            renderMode: 'html',
            triggerEvent: true,
            axisLabel: {
                rotate: '45',
                fontSize: 9,
                margin: 9,
                formatter: this.labelFormatter,
                textStyle: {
                    color: textColor(),
                },
            },
            axisTick: {
                show: false,
                alignWithLabel: true,
            },
            axisLine: {
                show: false,
            },
            nameLocation: 'center',
            nameTextStyle: {
                color: textColor(),
            },
        },
        series: [
            {
                name: 'boxplot',
                type: 'boxplot',
                data: null,
                tooltip: {},
            },
            {
                name: 'outlier',
                type: 'scatter',
                data: null,
                tooltip: {},
            },
        ],
    };
    tooltipConfig: any = null;
    gounder: any; // counter for storing the count values, for tooltip

    async assignOptions(options: Record<string, any>) {
        this.tooltipConfig = options.toolTipConfig;
        this.gounder = this.calculateCount(options.series);
        const { prepareBoxplotData } = await import('../echarts.imports');
        const data = prepareBoxplotData(options.series, {});
        options.series = this.options.series;
        options.series[0].data = data.boxData;
        options.series[1].data = data.outliers;
        Object.assign(options.xAxis, this.options.xAxis);
        options.xAxis.data = options.categories;
        Object.assign(options, { colors: ['#317ce2', '#317ce2'] });
        if (this.tooltipConfig) {
            this.options.series[0].tooltip['formatter'] = this.tooltipConfiguration.bind(this);
            this.options.series[1].tooltip['formatter'] = this.outlierTooltipConfiguration.bind(this);
        }

        if (options.hasOwnProperty('editWidgetMode') && options.editWidgetMode) {
            this.options.yAxis.nameGap = 30;
        }
        super.assignOptions(options);
    }

    tooltipConfiguration(param: any) {
        const paramData = param.data.value ? param.data.value : param.data;
        return [
            '<div style="width:180px;">' +
                '<div style="white-space:nowrap;overflow:hidden;text-overflow:ellipsis;"> <label style="border-radius: 50%;width: 10px;height: 10px;vertical-align: middle;margin: 0;background:' +
                param.color +
                '"></label>&nbsp;&nbsp;' +
                param.name +
                '</div>',
            this.tooltipConfig.upper + ' : <b>' + Math.round((paramData[5] + Number.EPSILON) * 100) / 100 + '</b>',
            this.tooltipConfig.q3 + ' : <b>' + Math.round((paramData[4] + Number.EPSILON) * 100) / 100 + '</b>',
            this.tooltipConfig.median + ' : <b>' + Math.round((paramData[3] + Number.EPSILON) * 100) / 100 + '</b>',
            this.tooltipConfig.q1 + ' : <b>' + Math.round((paramData[2] + Number.EPSILON) * 100) / 100 + '</b>',
            this.tooltipConfig.lower + ' : <b>' + Math.round((paramData[1] + Number.EPSILON) * 100) / 100 + '</b>' + '<br/>',
            'Inter Quartile Range : <b>' + Math.round((paramData[4] - paramData[2] + Number.EPSILON) * 100) / 100 + '</b>',
            'Count : <b>' + this.gounder[param.dataIndex] + '</b>' + '</div>',
        ].join('<br/>');
    }

    outlierTooltipConfiguration(param: any) {
        const paramData = param.data.value ? param.data.value : param.data;
        return [
            '<div style="width:180px;">' +
                '<div style="white-space:nowrap;overflow:hidden;text-overflow:ellipsis;"> <label style="border-radius: 50%;width: 10px;height: 10px;vertical-align: middle;margin: 0;background:' +
                param.color +
                '"></label>&nbsp;&nbsp;' +
                param.name +
                ' : <b>' +
                Math.round((paramData[1] + Number.EPSILON) * 100) / 100 +
                '</b>' +
                '</div> </div>',
        ].join('<br/>');
    }

    private calculateCount(countData: any) {
        const lengthHolder: any = [];
        countData.forEach((arr: any, i: any) => {
            if (arr.length === 1 && arr[0] === 0) {
                lengthHolder[i] = 0;
            } else {
                lengthHolder[i] = arr.length;
            }
        });
        return lengthHolder;
    }

    handleEvents(emitter: Subject<{ type: string; payload: any }>, chartInstance: any) {
        if (!chartInstance) {
            return;
        }

        chartInstance.on('click', 'series', (event: any) => {
            emitter.next({
                type: 'barClicked',
                payload: {
                    barName: event.name,
                    barId: event.id,
                },
            });
        });
        super.handleEvents(emitter, chartInstance);
    }
}

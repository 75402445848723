import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment, runtimeEnv } from '@app/env';
import { AppModule } from './app/app.module';
import { ENV_TOKEN } from '@app/tokens';
import { isNil, omitBy } from 'lodash';
import { buildApiUrls } from './environments/env.common';

if (environment.production) {
    enableProdMode();
}

function loadAuthConfig(env: Env): Promise<void> {
    return fetch(`${env.baseApiURL}/oauth2-config`)
        .then(res => res.json())
        .then(config => (env.keycloakParams = config));
}

function loadEnvironment(): Promise<Env> {
    return fetch(`/assets/config/env.json?${+Date.now()}`)
        .then(res => res.json())
        .then(config => {
            const mergedRuntimeEnv: RuntimeEnv = Object.assign(runtimeEnv, omitBy(config, isNil) as any);
            Object.assign(mergedRuntimeEnv, buildApiUrls(mergedRuntimeEnv.serviceUrl, 'v1'));
            return { ...environment, ...mergedRuntimeEnv };
        });
}

(async () => {
    const env = await loadEnvironment();
    await loadAuthConfig(env);

    await platformBrowserDynamic([
        { provide: 'environment', useValue: env },
        { provide: ENV_TOKEN, useValue: env },
    ])
        .bootstrapModule(AppModule)
        .then(module => {
            // @ts-ignore
            window['ngModuleInjector'] = module.injector;
        })
        .catch(showError);
})();

function showError() {
    // hide loader
    const loader = document.getElementById('loader');
    loader.parentNode.removeChild(loader);

    document.body.insertAdjacentHTML(
        'beforeend',
        `<div class="bootstrap-error"><p>Something went wrong</p><div class="btn btn-primary size-s">Try again</div></div>`
    );
    const errorCard = document.body.lastChild as HTMLDivElement;
    errorCard.querySelector<HTMLDivElement>('.btn').onclick = () => location.reload();
}

<form [formGroup]="form">
    <ng-container *transloco="let t; read: 'requestAccess'">
        <div class="modal-header">
            <h1 class="mt-0">
                {{ mode === 'REJECT' ? t('rejectTitle') : t('requestTitle', {assetName: assetAccessRequest.requestedAssetName}) }}
            </h1>
            <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
                    (click)="activeModal.dismiss('Cancel')"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-body font-14px d-flex flex-column">
            <div class="d-flex flex-column mb-16px">
                <ng-container *ngIf="mode !== 'REQUESTED'; else successTmpl">
                    <div class="d-flex flex-row line-height-18px mb-8px" *ngIf="mode === 'REJECT'">
                        <div class="disable min-width-120px mr-8px">{{ t('fields.asset') }}:</div>
                        <div class="d-flex flex-row color-brand cursor-pointer"
                             (click)="navigateToAsset(assetAccessRequest.requestedAssetId, assetAccessRequest.requestedAssetVersionId)"
                        >
                            {{ assetAccessRequest.requestedAssetName }}
                            <fa-icon icon="facExternalLink" class="font-16px ml-10px color-brand"></fa-icon>
                        </div>
                    </div>
                    <div class="d-flex flex-row line-height-18px mb-8px"
                         *ngIf="mode === 'REQUEST' || mode === 'REJECT'">
                        <div class="disable min-width-120px mr-8px">{{ t('fields.requestDate') }}:</div>
                        <div class="d-flex flex-row">
                            <app-date-badge [date]="assetAccessRequest.createdAt"
                                            [pattern]="'d MMMM y'"
                            ></app-date-badge>
                        </div>
                    </div>
                    <div class="d-flex flex-row line-height-18px mb-8px" *ngIf="mode === 'REJECT'">
                        <div class="disable min-width-120px mr-8px">{{ t('fields.requestNumber') }}:</div>
                        <div class="d-flex flex-row">
                            {{ assetAccessRequest.id }}
                        </div>
                    </div>
                </ng-container>
                <ng-template #successTmpl>
                    <app-success-banner class="mb-24px"
                                        [title]="t('successTitle')"
                                        [description]="t('successDescription')"
                    ></app-success-banner>
                </ng-template>

                <div class="d-flex flex-row line-height-18px mb-2px">
                    <div class="disable min-width-120px mr-8px">{{ t('fields.requester') }}:</div>
                    <div class="d-flex flex-row h3 m-0">
                        {{ assetAccessRequest.requesterName }}
                    </div>
                </div>
                <div class="d-flex flex-row line-height-18px mb-8px">
                    <div class="disable min-width-120px mr-8px">{{ t('fields.requesterEmail') }}:</div>
                    <div class="d-flex flex-row">
                        {{ assetAccessRequest.requesterEmail }}
                    </div>
                </div>
                <div class="d-flex flex-row line-height-18px mb-2px">
                    <div class="disable min-width-120px mr-8px">{{ t('fields.acceptor') }}:</div>
                    <div class="d-flex flex-row h3 m-0">
                        {{ assetAccessRequest.acceptorName }}
                    </div>
                </div>
                <div class="d-flex flex-row line-height-18px">
                    <div class="disable min-width-120px mr-8px">{{ t('fields.acceptorEmail') }}:</div>
                    <div class="d-flex flex-row">
                        {{ assetAccessRequest.acceptorEmail }}
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column" *ngIf="mode === 'REJECT' || mode === 'REQUEST'">
                <lib-dropdown
                    class="w-100 mb-16px"
                    *ngIf="mode === 'REJECT' && (rejectReasons$ | async) as rejectReasons"
                    labelPosition="top"
                    formControlName="rejectReason"
                    [label]="t('fields.rejectReason')"
                    [placeholder]="t('fields.rejectReasonPlaceholder')"
                    [items]="rejectReasons"
                    valueKey="id"
                    labelKey="name"
                    [clearable]="false"
                ></lib-dropdown>
                <lib-textarea
                    class="w-100"
                    labelPosition="top"
                    formControlName="{{mode === 'REJECT' ? 'rejectComment' : 'comment'}}"
                    [label]="t('fields.comment')"
                    maxlength="256"
                    [translatedText]="textareaTranslatedText"
                    [resize]="false"
                    [customHeight]="true"
                ></lib-textarea>
            </div>
        </div>

        <div class="modal-footer" [ngSwitch]="mode">
            <ng-container *ngSwitchCase="'REQUEST'">
                <button type="button" class="btn btn-secondary mr-24px ml-auto"
                        (click)="activeModal.dismiss('Cancel')"
                >
                    {{ t('cancelBtn') }}
                </button>
                <button type="button" class="btn btn-primary"
                        [disabled]="processing || form.invalid || requestExist == null"
                        [btnWithLoader]="processing"
                        (click)="execute()"
                >
                    {{ t('requestBtn') }}
                </button>
            </ng-container>
            <ng-container *ngSwitchCase="'REJECT'">
                <button type="button" class="btn btn-secondary mr-24px ml-auto"
                        (click)="activeModal.dismiss('Cancel')"
                >
                    {{ t('cancelBtn') }}
                </button>
                <button type="button" class="btn btn-primary"
                        [disabled]="processing || form.invalid"
                        [btnWithLoader]="processing"
                        (click)="execute()"
                >
                    {{ t('rejectBtn') }}
                </button>
            </ng-container>
            <ng-container *ngSwitchCase="'REQUESTED'">
                <button type="button" class="btn btn-primary" (click)="execute()">
                    {{ t('okBtn') }}
                </button>
            </ng-container>
        </div>
    </ng-container>
</form>

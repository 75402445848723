import { EChartsChart } from '../echarts.chart';
import { Subject } from 'rxjs';

import { qualitativePalette, textColor } from '../charts-colors';

export class GanttEchartsChart extends EChartsChart {
    options: any = {
        tooltip: {
            formatter: function(params: any) {
                return (
                    params.marker +
                    params.name +
                    '<br>Start date: ' +
                    new Date(params.value[1]).toLocaleDateString() +
                    '<br>End date: ' +
                    new Date(params.value[2]).toLocaleDateString()
                );
            },
        },
        dataZoom: [
            {
                id: 'dataZoomY',
                type: 'slider',
                yAxisIndex: [0],
                filterMode: 'filter',
                showDetail: false,
            },
        ],
        xAxis: {
            splitNumber: 16,
            axisLabel: {
                rotate: '45',
                fontSize: 10,
                margin: 11,
                hideOverlap: true,
                formatter: function(val: any) {
                    return new Date(val).toLocaleDateString();
                },
                textStyle: {
                    color: textColor(),
                },
            },
            nameTextStyle: {
                color: textColor(),
            },
        },
        color: qualitativePalette,
        yAxis: {
            data: {},
            axisLabel: {
                textStyle: {
                    color: textColor(),
                },
            },
            nameTextStyle: {
                color: textColor(),
            },
        },
        polar: { show: false },
        radiusAxis: { show: false },
        angleAxis: { show: false },
    };

    async assignOptions(options: Record<string, any>) {
        const { graphic } = await import('../echarts.imports');
        if (options.data) {
            this.options.series = [
                {
                    type: 'custom',
                    renderItem: function(params: any, api: any) {
                        const categoryIndex = api.value(0);
                        const start = api.coord([api.value(1), categoryIndex]);
                        const end = api.coord([api.value(2), categoryIndex]);
                        const height = api.size([0, 1])[1] * 0.2;

                        const rectShape = graphic.clipRectByRect(
                            {
                                x: start[0],
                                y: start[1] - height / 2,
                                width: end[0] - start[0],
                                height: height,
                            },
                            {
                                x: params.coordSys.x,
                                y: params.coordSys.y,
                                width: params.coordSys.width,
                                height: params.coordSys.height,
                            }
                        );

                        return (
                            rectShape && {
                                type: 'rect',
                                shape: rectShape,
                                style: api.style(),
                            }
                        );
                    },
                    itemStyle: {
                        opacity: 0.8,
                    },
                    encode: {
                        x: [1, 2],
                        y: 0,
                    },
                    data: options.data,
                },
            ];
        }

        if (options.hasMarkLine) {
            this.options.series[0] = {
                ...this.options.series[0],
                markLine: {
                    symbol: ['none', 'circle'],
                    silent: true,
                    label: { show: false },
                    data: [{ xAxis: Date.now() }],
                },
            };

            this.options.xAxis.splitNumber = 10;
        }

        if (options.categories) {
            this.options.categories = options.categories;
        }
        if (options.startDate) {
            this.options.xAxis.min = options.startDate;
        }

        super.assignOptions(options);
    }

    handleEvents(emitter: Subject<{ type: string; payload: any }>, chartInstance: any) {
        if (!chartInstance) {
            return;
        }

        chartInstance.on('click', 'series', (event: any) => {
            emitter.next({
                type: 'serieClicked',
                payload: {
                    name: event.name,
                    event,
                },
            });
        });

        super.handleEvents(emitter, chartInstance);
    }
}

import { CustomIcon } from './types';

import * as customIcons from './custom-icons';
import * as processorIcons from './processor-monitoring-icons';
import * as pipelineIcons from './project-pipelines-icons';
import * as headerIcons from './header-icons';

export const CUSTOM_ICONS = Object.values(customIcons).filter((icon: any) => !!icon.prefix) as CustomIcon[];
export const PROCESSOR_ICONS = Object.values(processorIcons).filter((icon: any) => !!icon.prefix);
export const PROJECT_PIPELINE_ICONS = Object.values(pipelineIcons).filter((icon: any) => !!icon.prefix);
export const HEADER_ICONS = Object.values(headerIcons).filter((icon: any) => !!icon.prefix);

CUSTOM_ICONS.concat(PROCESSOR_ICONS)
    .concat(PROJECT_PIPELINE_ICONS)
    .concat(HEADER_ICONS)
    .forEach((icon: CustomIcon) => (icon.prefix = 'far'));

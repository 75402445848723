import { Component } from '@angular/core';
import { INoRowsOverlayAngularComp } from '@ag-grid-community/angular';
import { INoRowsOverlayParams } from '@ag-grid-community/core';
@Component({
    selector: 'lib-ag-grid-no-rows-overlay',
    templateUrl: './ag-grid-no-rows-overlay.component.html',
    styleUrls: ['./ag-grid-no-rows-overlay.component.scss'],
})
export class AgGridNoRowsOverlayComponent implements INoRowsOverlayAngularComp {
    agInit(params: INoRowsOverlayParams) {}
}

import { CustomIcon } from './types';

export const facClose: CustomIcon = {
    prefix: 'fac',
    iconName: 'facClose',
    icon: [
        14,
        14,
        [],
        '',
        'M8.46 7L14 12.54V14H12.54L7 8.46L1.46 14H0V12.54L5.54 7L0 1.46V0H1.46L7 5.54L12.54 0H14V1.46L8.46 7Z',
    ],
};

export const facPipelineEditor: CustomIcon = {
    prefix: 'fac',
    iconName: 'facPipelineEditor',
    icon: [
        14,
        14,
        [],
        '',
        'M9.05,2 C9.43659932,2 9.75,2.31340068 9.75,2.7 L9.75,4.8 C9.75,5.18659932 9.43659932,5.5 9.05,5.5 L6.95,5.5 C6.56340068,5.5 6.25,5.18659932 6.25,4.8 L6.25,2.7 C6.25,2.31340068 6.56340068,2 6.95,2 L9.05,2 Z M6.95,2.7 L6.95,4.8 L9.05,4.8 L9.05,2.7 L6.95,2.7 Z M9.05,10 C9.43659932,10 9.75,10.3134007 9.75,10.7 L9.75,12.8 C9.75,13.1865993 9.43659932,13.5 9.05,13.5 L6.95,13.5 C6.56340068,13.5 6.25,13.1865993 6.25,12.8 L6.25,10.7 C6.25,10.3134007 6.56340068,10 6.95,10 L9.05,10 Z M6.95,10.7 L6.95,12.8 L9.05,12.8 L9.05,10.7 L6.95,10.7 Z M3.8,10 C4.18659932,10 4.5,10.3134007 4.5,10.7 L4.5,12.8 C4.5,13.1865993 4.18659932,13.5 3.8,13.5 L1.7,13.5 C1.31340068,13.5 1,13.1865993 1,12.8 L1,10.7 C1,10.3134007 1.31340068,10 1.7,10 L3.8,10 Z M1.7,10.7 L1.7,12.8 L3.8,12.8 L3.8,10.7 L1.7,10.7 Z M14.3,10 C14.6865993,10 15,10.3134007 15,10.7 L15,12.8 C15,13.1865993 14.6865993,13.5 14.3,13.5 L12.2,13.5 C11.8134007,13.5 11.5,13.1865993 11.5,12.8 L11.5,10.7 C11.5,10.3134007 11.8134007,10 12.2,10 L14.3,10 Z M12.2,10.7 L12.2,12.8 L14.3,12.8 L14.3,10.7 L12.2,10.7 Z M8.25,5.25 L8.2498,7.749 L12.7,7.75 C13.1142,7.75 13.45,8.08579 13.45,8.5 L13.45,10.2 L12.95,10.2 L12.95,8.5 C12.95,8.36193 12.8381,8.25 12.7,8.25 L8.2498,8.249 L8.25,10.25 L7.75,10.25 L7.7498,8.249 L3.2998,8.25 C3.16173,8.25 3.0498,8.36193 3.0498,8.5 L3.0498,10.2 L2.5498,10.2 L2.5498,8.5 C2.5498,8.08579 2.88559,7.75 3.2998,7.75 L7.7498,7.749 L7.75,5.25 L8.25,5.25 Z'
    ],
};

import { AfterViewInit, Component, Inject, NgZone, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import {
    GuardsCheckEnd,
    GuardsCheckStart,
    NavigationCancel,
    NavigationEnd,
    RouteConfigLoadEnd,
    RouteConfigLoadStart,
    Router,
} from '@angular/router';
import { AuthService } from '@app/auth';
import { SidenavService } from '@app/core/components/sidenav/sidenav.service';
import { StateMachineService } from '@app/shared/services/api/state-machine.service';
import { ThemeService } from '@app/core/services/theme.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        {
            provide: 'secondaryHeaderTemplate',
            useValue: {} as { tmpl: TemplateRef<any> },
        },
    ],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
    private readonly formattedContextHelpData: any[] = [];

    readonly user$ = this.authService.user$;

    readonly sidenavItems$ = this.sidenavService.sidenavItems$;

    readonly assetEventsInit$ = this.stateMachineService.assetEventsInit$;

    footerIsSticky: boolean = false;
    private footerResizeObserver: ResizeObserver;
    private appContent: HTMLElement;

    private secondaryHeaderResizeObserver: ResizeObserver;
    private mainContainer: HTMLElement;
    private secondaryHeader: HTMLElement;

    constructor(
        @Inject('secondaryHeaderTemplate') public secondaryHeaderTemplate: { tmpl: TemplateRef<any> },
        private readonly router: Router,
        private readonly themeService: ThemeService,
        private readonly authService: AuthService,
        private readonly sidenavService: SidenavService,
        private readonly stateMachineService: StateMachineService,
        private readonly zone: NgZone
    ) {}

    ngOnInit() {
        this.themeService.setLightTheme();

        this.handleLoadingForLazyLoadedModules();

        this.footerResizeObserver = new ResizeObserver(entries => {
            entries.forEach(entry => {
                const footerIsSticky =
                    (entry.target.scrollHeight != entry.target.clientHeight &&
                        entry.target.scrollHeight + (this.footerIsSticky ? 140 + 69 : 0) < entry.target.clientHeight) ||
                    entry.target.scrollHeight == entry.target.clientHeight;

                if (footerIsSticky !== this.footerIsSticky) {
                    this.zone.run(() => {
                        this.footerIsSticky = footerIsSticky;
                    });
                }
            });
        });

        this.secondaryHeaderResizeObserver = new ResizeObserver(entries => {
            entries.forEach(entry => {
                this.zone.run(() => {
                    this.appContent.setAttribute(
                        'style',
                        `height: ${this.mainContainer.clientHeight - entry.target.clientHeight}px; margin-top: ${
                            entry.target.clientHeight
                        }px`
                    );
                });
            });
        });
    }

    ngAfterViewInit() {
        document.body.classList.add('app-initialization-completed');

        this.appContent = document.getElementById('app-content');
        this.footerResizeObserver.observe(this.appContent);

        this.mainContainer = document.getElementById('main-container-id');
        this.secondaryHeader = document.getElementById('app-secondary-header');
        this.secondaryHeaderResizeObserver.observe(this.secondaryHeader);
    }

    ngOnDestroy(): void {
        this.footerResizeObserver.unobserve(this.appContent);
        this.secondaryHeaderResizeObserver.unobserve(this.secondaryHeader);
    }

    private handleLoadingForLazyLoadedModules() {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.secondaryHeaderTemplate.tmpl = null;
            } else if (event instanceof RouteConfigLoadStart || event instanceof GuardsCheckStart) {
                document.body.classList.add('lazy-loading');
            } else if (event instanceof RouteConfigLoadEnd || event instanceof GuardsCheckEnd || event instanceof NavigationCancel) {
                document.body.classList.remove('lazy-loading');
            }
        });
    }
}

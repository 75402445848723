import { Injector } from '@angular/core';
import { Subject } from 'rxjs';
import { debounce } from 'lodash';
import { Chart } from '../chart-library';

import { getAllLegendItems } from '../../chart-legend/chart-legend.component';
import { createTooltip, updateTooltipPosition } from './custom.legend';

declare let ResizeObserver: any;

interface ChartEvent {
    dataIndex: number; // series index
    seriesIndex: number; // bar index
    seriesName: string; // bar name
    name: string; // series name
    value: any;
    id?: unknown;
}

const TRIM_LEN = 14;
const ALLOWED_X_AXIS_LENGTH = 15;
const FEATURE_PROGRESS_TRIM_LEN = 15;

export const formatLabel = (len: number = TRIM_LEN) => (value: any) =>
    typeof value === 'string'
        ? value
            .split('\n')
            .map(row => (row.length > len ? `${row.substr(0, len - 1)}...` : row))
            .join('\n')
        : value;

export function isInDom(elem: any) {
    return (
        typeof elem === 'object' &&
        typeof elem.nodeType === 'number' &&
        typeof elem.ownerDocument === 'object' &&
        elem.clientWidth &&
        elem.clientHeight
    );
}

function toPoint(event: MouseEvent) {
    return [event.offsetX, event.offsetY];
}

function getCategoryAxis(chartInstance: any): { axis: any; axisIndex: number } {
    // eslint-disable-next-line no-underscore-dangle
    const axes = chartInstance._coordSysMgr._coordinateSystems[0].getAxes();
    let axis = null;
    let axisIndex = null;

    for (let i = 0; i < axes.length; i++) {
        if (axes[i].type === 'category') {
            axisIndex = i;
            axis = axes[i];
            break;
        }
    }

    return { axis, axisIndex };
}

function onAxisPointerClick(chartInstance: any, handler: (event: ChartEvent) => void) {
    const zr = chartInstance.getZr();
    zr.on('click', (params: MouseEvent) => {
        const pointInPixel = toPoint(params);

        if (!chartInstance.containPixel('grid', pointInPixel)) {
            return;
        }

        const pointInGrid: [number, number] = chartInstance.convertFromPixel('grid', pointInPixel);
        const { axis, axisIndex } = getCategoryAxis(chartInstance);

        if (!axis) {
            return;
        }

        const seriesIndex = pointInGrid[axisIndex];
        const category = axis.model.option.data[seriesIndex];

        handler({
            dataIndex: seriesIndex,
            seriesIndex: null,
            seriesName: null,
            name: category,
            value: null,
        });
    }).on('mousemove', (params: MouseEvent) => {
        zr.setCursorStyle(chartInstance.containPixel('grid', toPoint(params)) ? 'pointer' : 'default');
    });
}

export abstract class EChartsChart implements Chart {
    abstract options: Record<string, any>;

    typeAttributes = {};
    typeAttributesWithoutStack: Record<string, any> = null;
    markPointData: any = {};
    labelFormatter = formatLabel(TRIM_LEN);

    private resizeObserverDisposeFn: () => void;

    protected axisPointerClick: boolean | null = null;

    assignOptions(options: Record<string, any>, isCustom?: boolean) {
        if (isCustom && options.xAxis && options.yAxis) {
            this.options.xAxis = options.xAxis;
            this.options.yAxis = options.yAxis;
        }

        if (options.legend !== undefined && options.anyWidget === true && this.options.legend) {
            Object.assign(this.options.legend, options.legend);
        }

        if (options.type !== 'scatterchart' && options.categories) {
            if (this.options.xAxis && this.options.xAxis.type && this.options.xAxis.type === 'category') {
                if (options.sortedCategories && options.sortedCategories.length) {
                    this.options.xAxis.data = options.sortedCategories;
                    this.options.xAxis.axisLabel = this.options.xAxis.axisLabel || {};
                    this.options.xAxis.axisLabel.formatter = (value: any, index: number) => options.categories[index];
                } else {
                    this.options.xAxis.data = options.categories;
                }
            } else {
                this.options.yAxis.data = options.categories;
            }
        }
        if (options.minValueX) {
            this.options.xAxis.min = options.minValueX;
        }

        if (options.angleAxisLabelMargin) {
            this.options.angleAxis.axisLabel.margin = options.angleAxisLabelMargin;
        }

        if (options.maxRadialValue) {
            this.options.angleAxis.max = options.maxRadialValue;
            this.options.angleAxis.interval = options.maxRadialValue / 4.8;
        }

        if (options.angleAxisLabelColor) {
            this.options.angleAxis.axisLabel.color = options.angleAxisLabelColor;
        }

        if (options.subgraphEnabled) {
            this.options.subgraphEnabled = options.subgraphEnabled;
        }

        if (options.xAxis && options.xAxis.name && this.options.xAxis) {
            this.options.xAxis.name = options.xAxis.name;
        }

        if (options.yAxis && options.yAxis.name && this.options.yAxis) {
            this.options.yAxis.name = options.yAxis.name;
        }

        if (options.xAxis && options.xAxis.minInterval && this.options.xAxis) {
            this.options.xAxis.minInterval = options.xAxis.minInterval;
        }
        if (options.yAxis && options.yAxis.minInterval && this.options.yAxis) {
            this.options.yAxis.minInterval = options.yAxis.minInterval;
        }

        if (this.options.xAxis && options.xaxisLabel) {
            this.options.xAxis['name'] = options.xaxisLabel;
        }
        if (this.options.yAxis && options.yaxisLabel) {
            this.options.yAxis['name'] = options.yaxisLabel;
        }

        if (options.tableHeader1) {
            this.options['tableHeader1'] = options.tableHeader1;
        }
        if (options.tableHeader2) {
            this.options['tableHeader2'] = options.tableHeader2;
        }

        if ((options.anyWidget || options.inserted) && this.options.tooltip) {
            if (options.tooltipFormatter) {
                this.options.tooltip.formatter = options.tooltipFormatter;
            }
            if (options.tooltipTrigger) {
                this.options.tooltip.trigger = options.tooltipTrigger;
            }

            this.options.tooltip.enterable = options.enterableTooltip;
            this.options.tooltip.appendToBody = true;
            if (options.enterableTooltip && options.tooltipClosePosition) {
                this.options.tooltip.position = function(point: any, params: any, dom: any, rect: any, size: any) {
                    return [
                        point[0] + size.contentSize[0] > size.viewSize[0] ? point[0] - size.contentSize[0] : point[0],
                        point[1] + size.contentSize[1] > size.viewSize[1] ? point[1] - size.contentSize[1] : point[1],
                    ];
                };
            }
            if (!options.inserted) {
                this.options.tooltip.confine = true;
            }
        }

        if (options.title && options.type === 'radialChart') {
            this.options.title = options.title;
        }

        if (options.radius && options.type === 'radialChart') {
            this.options.polar.radius = options.radius;
        }

        if (options.minYAxisValue) {
            this.options.yAxis.min = options.minYAxisValue;
        }

        if (options.maxYAxisValue) {
            this.options.yAxis.max = options.maxYAxisValue;
        }

        if (options.dataZoom) {
            this.options.dataZoom = [
                {
                    id: 'dataZoomX',
                    type: 'slider',
                    xAxisIndex: [0],
                    filterMode: 'filter',
                    showDetail: false,
                    height: '8%',
                    bottom: 10,
                },
            ];
        }

        if (options.series) {
            if (
                options.series.length &&
                options.series[0].hasOwnProperty('markArea') &&
                options.series[0].markArea.hasOwnProperty('data') &&
                options.series[0].markArea.data.length > 0
            ) {
                options.series[0].markArea['animation'] = false;
                options.series[0].markArea.data.forEach((element: any, index: number) => {
                    if (options.markAreaColors) {
                        element[0]['itemStyle'] = {
                            color: options.markAreaColors[index],
                        };
                    }
                    element[0]['label'] = {
                        show: true,
                        color: '#666666',
                    };
                });
            }

            if (options.stacked !== undefined) {
                this.typeAttributes =
                    options.stacked || !this.typeAttributesWithoutStack ? this.typeAttributes : this.typeAttributesWithoutStack;
            }

            if (Array.isArray(options.series) || (!!options.anyWidget && options.series.type !== 'treemap')) {
                this.options.series = options.series.map((s: any, i: any) => ({
                    ...s,
                    markArea: options.series[0].hasOwnProperty('markArea') ? options.series[0].markArea : {},
                    markLine:
                        options.series[0].type === 'scatterchart'
                            ? this.getMarkLineForScatter(options.series, options.colors)
                            : options.series[i].markLine
                                ? options.series[i].markLine
                                : {},
                    markPoint: Object.keys(this.markPointData).length ? this.markPointData : {},
                    itemStyle: s.hasOwnProperty('colorBy') && s.colorBy === 'data' ? {} :
                        s.hasOwnProperty('color')
                        ? { color: s.color }
                        : options.colors
                            ? {
                                color: s.type === 'scatterchart' ? 'transparent' : options.colors[i],
                                borderRadius: options.borderRadius || 0,
                                borderColor: s.type === 'scatterchart' ? options.colors[i] : '',
                                borderWidth: ['scatterchart', 'boxplot'].includes(s.type) ? 2 : 0,
                            }
                            : {},
                    label:
                        options.anyWidget && options.showLabel && ['bar'].includes(s.type)
                            ? {
                                show: true,
                                position: 'inside',
                            }
                            : undefined,
                    ...this.typeAttributes,
                    ...s.seriesCustomOptions,
                }));
            }
        }

        if (options.grid) {
            this.options.grid = options.grid;
        }

        if (this.axisPointerClick !== null) {
            if (options.isBarSeriesClickEventSource) {
                this.axisPointerClick = false;
            }
        }

        if (this.axisPointerClick) {
            this.options.tooltip.axisPointer = {
                type: 'cross',
                show: false,
                label: {
                    show: false,
                },
            };

            const [categoryAxis, valueAxis] =
                this.options.yAxis.type === 'category'
                    ? [this.options.yAxis, this.options.xAxis]
                    : [this.options.xAxis, this.options.yAxis];

            categoryAxis.axisPointer = {
                type: 'shadow',
                shadowStyle: {
                    color: 'rgba(0, 151, 171, 0.12)',
                },
            };
            valueAxis.axisPointer = {
                show: false,
            };
        }
    }

    getMarkLineForScatter(seriesData: any, colorData: any) {
        const meanValue = this.calculateMean(seriesData[0].data);
        const standardDeviation = this.calculateStandardDeviation(seriesData[0].data, meanValue);
        const uclValue = this.calculateUCL(meanValue, standardDeviation);
        const lclValue = this.calculateLCL(meanValue, standardDeviation);

        const mData = [];
        for (let i = 0; i < seriesData[0].data.length; i++) {
            if (seriesData[0].data[i][1] < lclValue || seriesData[0].data[i][1] > uclValue) {
                mData.push({
                    coord: [seriesData[0].data[i][0], seriesData[0].data[i][1]],
                    name: seriesData[0].data[i][2],
                    itemStyle: { borderWidth: 3, borderColor: colorData[1] ? colorData[1] : '#0389C6' },
                });
            }
        }

        if (mData.length) {
            this.markPointData = {
                symbol: 'circle',
                symbolSize: 10,
                lineStyle: {
                    type: 'solid',
                },
                data: mData,
            };
        }

        this.options.yAxis['max'] = function(value: any) {
            return uclValue > value.max ? Math.ceil(uclValue) : value.max;
        };

        return {
            symbol: 'circle',
            lineStyle: {
                type: 'solid',
            },
            data: [
                { type: 'average', name: 'Mean', lineStyle: { color: '#0389C6' } },
                { yAxis: uclValue, name: 'UCL', lineStyle: { color: '#CE7B00' } },
                { yAxis: lclValue, name: 'LCL', lineStyle: { color: '#3D8D41' } },
            ],
        };
    }

    calculateMean(data: any) {
        let sumValue = 0;
        for (let index = 0; index < data.length; index++) {
            sumValue += data[index][1];
        }
        return sumValue / data.length;
    }

    calculateSquareDifference(data: any, meanValue: number) {
        const squareDiff = [];
        for (let index = 0; index < data.length; index++) {
            squareDiff[index] = (data[index][1] - meanValue) * (data[index][1] - meanValue);
        }
        return squareDiff;
    }

    calculateStandardDeviation(data: any, meanValue: number) {
        const squareDifference = this.calculateSquareDifference(data, meanValue);
        let sumSquareDiffValue = 0;
        for (let index = 0; index < squareDifference.length; index++) {
            sumSquareDiffValue += squareDifference[index];
        }
        const averageValue = sumSquareDiffValue / (data.length - 1);
        return Math.sqrt(averageValue);
    }

    calculateUCL(meanValue: any, standardDeviation: any) {
        return meanValue + standardDeviation * 3;
    }

    calculateLCL(meanValue: any, standardDeviation: any) {
        const lcl = meanValue - standardDeviation * 3;
        if (lcl < 0) {
            return 0;
        }
        return lcl;
    }

    handleEvents(emitter: Subject<{ type: string; payload: any }>, chartInstance: any, injector?: Injector) {
        if (!chartInstance) {
            return;
        }

        const emitBarClickedEvent = (event: any) => {
            if (event.dataIndex !== undefined) {
                const option = chartInstance.getOption();
                // don't emit event when no data
                if (option.series?.length && option.series.every((series: any) => (series.data || [])[event.dataIndex] == null)) {
                    return;
                }
            }
            emitter.next({
                type: 'barClicked',
                payload: {
                    barId: event.id,
                    barName: event.name,
                    event,
                    legend: getAllLegendItems(chartInstance),
                },
            });
        };

        if (this.axisPointerClick) {
            onAxisPointerClick(chartInstance, event => emitBarClickedEvent(event));
        } else if (this.axisPointerClick === false) {
            chartInstance.on('click', 'series', (event: any) => emitBarClickedEvent(event));
        }

        if (this.options?.series?.length && this.options.series[0]?.hasOwnProperty('silent') && this.options.series[0].silent === true) {
            // need handle this
        } else {
            this.rotateAxisLabel(chartInstance);
            this.truncateAxisLabel(chartInstance);
            if (this.options?.series && this.options?.subgraphEnabled) {
                this.subgraphAnimation(chartInstance);
            }
        }
        this.adjustMarkAreaLabels(chartInstance);
    }

    rotateAxisLabel(chartInstance: any) {
        let resizeTimeout: number;
        let resizeObserver = new ResizeObserver(() => {
            window.clearTimeout(resizeTimeout);
            resizeTimeout = window.setTimeout(() => {
                const isMobile = chartInstance.getWidth() <= 620;
                const hasPredefinedLeft = this.options.grid?.left;
                let seriesLength = 0;
                if (this.options.series && this.options.series.length) {
                    this.options.series.forEach((element: any) => {
                        if (element.data && element.data.length > seriesLength) {
                            seriesLength = element.data.length;
                        }
                    });
                }
                if (chartInstance._model.option.yAxis[0].type === 'category') {
                    seriesLength = ALLOWED_X_AXIS_LENGTH - 1; // setting it, so labels shown without rotation

                    chartInstance.setOption({
                        xAxis: {
                            axisLabel: {
                                rotate: isMobile && seriesLength >= ALLOWED_X_AXIS_LENGTH ? 45 : seriesLength > 20 ? 45 : 0,
                            },
                        },
                    });
                } else {
                    const xAxis = chartInstance._model.option.xAxis[0];
                    const nameGapMap = xAxis.labelRotationToNameGapMap;
                    const rotate = isMobile && seriesLength >= ALLOWED_X_AXIS_LENGTH ? 45 : seriesLength > 20 ? 45 : 0;
                    chartInstance.setOption({
                        xAxis: {
                            axisLabel: {
                                rotate,
                            },
                            ...({ nameGap: nameGapMap && nameGapMap[rotate] !== undefined ? nameGapMap[rotate] : (xAxis.nameGap || 0) })
                        },
                    });
                }
            }, 250);
        });

        let hostEl = chartInstance.getDom();
        resizeObserver.observe(hostEl);
        this.resizeObserverDisposeFn = () => {
            resizeObserver.unobserve(hostEl);
            resizeObserver = null;
            hostEl = null;
            clearTimeout(resizeTimeout);
        };
    }

    dateFormatter(value: any, index: number) {
        const monthNames = ['Jan', 'Feb', 'Ma', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const date = new Date(value);
        const texts = [monthNames[date.getMonth()], date.getDate()];
        return texts.join('-');
    }

    private adjustMarkAreaLabels(chartInstance: any) {
        if (
            this.options.series.length &&
            this.options.series[0].hasOwnProperty('markArea') &&
            this.options.series[0].markArea.hasOwnProperty('data') &&
            this.options.series[0].markArea.data.length > 0
        ) {
            // eslint-disable-next-line no-underscore-dangle
            const markerAreaView = chartInstance._componentsViews.find((view: any) => view.type === 'markArea');
            if (markerAreaView && markerAreaView.group) {
                const resetLabels = () => {
                    this.options.series.forEach((series: any) => {
                        if (series.markArea) {
                            series.markArea.data.forEach((markArea: any) => {
                                markArea[0].label.show = true;
                            });
                        }
                    });
                    setTimeout(() => {
                        chartInstance.setOption({
                            series: this.options.series,
                        });
                    });
                };

                resetLabels();

                const adjustMarkAreaLabels = () => {
                    let dirty = false;
                    resetLabels();
                    chartInstance.one('finished', () => {
                        (markerAreaView.group.children() || []).forEach((group: any, i: number) => {
                            let leftOffset = 0;
                            const polygons = group.children() || [];
                            polygons.sort((a: any, b: any) => a.getBoundingRect().x - b.getBoundingRect().x);

                            polygons.forEach((polygon: any) => {
                                const zrText = polygon.getTextContent();

                                const rectangle = zrText.getBoundingRect().clone();
                                if (zrText.transform) {
                                    rectangle.applyTransform(zrText.transform);
                                }
                                const { x, width } = rectangle;
                                const data = this.options.series[i].markArea.data.find((x: any) => x[0].name === zrText.style.text);
                                if (x < leftOffset) {
                                    data[0].label.show = false;
                                    dirty = true;
                                } else {
                                    leftOffset = x + width;
                                }
                                data[0].emphasis = data[0].emphasis || { itemStyle: { color: 'rgb(236,245,234)' } };
                                if (data[0].emphasis) {
                                    data[0].emphasis.label = data[0].emphasis.label || {
                                        color: '#000',
                                        padding: [0, 15, 0, 15],
                                    };
                                    data[0].emphasis.label.show = true;
                                    data[0].emphasis.label.backgroundColor = '#ffffff';
                                }
                            });
                        });
                        if (dirty) {
                            setTimeout(() => {
                                chartInstance.setOption({
                                    series: this.options.series,
                                });
                            });
                        }
                    });
                };
                chartInstance.one('finished', adjustMarkAreaLabels);
                chartInstance.on('datazoom', debounce(adjustMarkAreaLabels, 300));
            }
        }
    }

    private truncateAxisLabel(chartInstance: any) {
        const isOverflow = (event: any, chartInstance: any) => {
            if (event.targetType !== 'axisLabel' || !event.event.target) {
                return false;
            }
            const axisComponent = chartInstance.getModel().getComponent(event.componentType);
            const formatter = axisComponent.option.axisLabel.formatter || this.labelFormatter;
            const formattedText = (event.event.target.formattedText = event.event.target.formattedText || formatter(event.value));

            return formattedText !== event.value;
        };

        let tooltip: any;
        let showTimeout: number;

        chartInstance.on('mousemove', { type: 'category' }, (event: any) => {
            if (!isOverflow(event, chartInstance)) {
                return;
            }

            if (!tooltip) {
                window.clearTimeout(showTimeout);
                showTimeout = window.setTimeout(() => {
                    if (!tooltip) {
                        tooltip = createTooltip(this.formatHTMLData(event.value, 0, false));
                        document.body.appendChild(tooltip);
                    }

                    updateTooltipPosition(tooltip, event.event.event);
                }, 200);
            } else {
                updateTooltipPosition(tooltip, event.event.event);
            }
        });
        chartInstance.on('mouseout', { type: 'category' }, (event: any) => {
            if (!isOverflow(event, chartInstance)) {
                return;
            }

            window.clearTimeout(showTimeout);
            if (tooltip) {
                tooltip.parentNode.removeChild(tooltip);
                tooltip = null;
            }
        });
    }

    formatHTMLData(value: any, index: number, trimData: boolean = true) {
        let label;
        if (value.length > 0 && value.match(/>/)) {
            const labelPart = value.substring(value.match(/>/).index + 1, value.length);
            label = labelPart.substring(0, labelPart.match(/</).index);
        } else {
            label = value;
        }
        if (trimData) {
            return label.length > FEATURE_PROGRESS_TRIM_LEN ? label.substr(0, FEATURE_PROGRESS_TRIM_LEN - 2) + '...' : label;
        } else {
            return label;
        }
    }

    destroy() {
        if (this.resizeObserverDisposeFn) {
            this.resizeObserverDisposeFn();
        }
    }

    subgraphAnimation(chartInstance: any) {
        chartInstance.on('mouseover', 'series', (params: any) => {
            const options = Object.assign({}, this.options);
            this.options.series = options.series.map((s: any) => {
                s.itemStyle.opacity = 0.5;
                const data: Array<any> = [...s.data];
                if (s.type === 'bar') {
                    s.data = data.map((item: any, index: number) => {
                        if (!item.value) {
                            return {
                                value: item,
                                itemStyle: {
                                    opacity: index === params.dataIndex ? 1 : 0.5,
                                },
                            };
                        } else {
                            item.itemStyle = {
                                opacity: index === params.dataIndex ? 1 : 0.5,
                            };
                            return item;
                        }
                    });
                } else {
                    s.data = data.map((item: any, index: number) => {
                        if (!item.value) {
                            return {
                                value: item,
                                itemStyle: {
                                    opacity: index === params.dataIndex && item === params.value ? 1 : 0.5,
                                },
                            };
                        } else {
                            item.itemStyle = {
                                opacity: index === params.dataIndex && item.value === params.value ? 1 : 0.5,
                            };
                            return item;
                        }
                    });
                }
                return {
                    ...s,
                };
            });
            if (this.options.legend && this.options.legend.selector) {
                delete this.options.legend.selector;
            }
            chartInstance.setOption({ series: this.options.series, legend: this.options.legend });
        });

        chartInstance.on('mouseout', 'series', () => {
            const options = Object.assign({}, this.options);
            this.options.series = options.series.map((s: any) => {
                s.itemStyle.opacity = 1;
                const data: Array<any> = [...s.data];
                s.data = data.map((item: any) => {
                    if (!item.value) {
                        return {
                            value: item,
                            itemStyle: {
                                opacity: 1,
                            },
                        };
                    } else {
                        item.itemStyle = {
                            opacity: 1,
                        };
                        return item;
                    }
                });

                return {
                    ...s,
                };
            });
            if (this.options.legend && this.options.legend.selector) {
                delete this.options.legend.selector;
            }
            chartInstance.setOption({ series: this.options.series, legend: this.options.legend });
        });
    }
}

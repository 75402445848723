import { EChartsChart } from '../echarts.chart';
import { textColor } from '../charts-colors';

const BASIC_LINE_ECHARTS_CHART_OPTIONS: Record<string, any> = {
    textStyle: {
        fontFamily: 'sans-serif',
        fontSize: 9
    },
    legend: {
        show: false,
        textStyle: {
            fontSize: 11,
            color: textColor(),
        },
    },
    xAxis: {
        type: 'category',
        boundaryGap: false,
        data: [] as any[],
        axisLabel: {
            textStyle: {
                color: textColor(),
            },
        },
        axisLine: {
            show: false,
        },
        axisTick: {
            show: false,
            alignWithLabel: true,
        },
        nameTextStyle: {
            color: textColor(),
        },
    },
    yAxis: {
        type: 'value',
        axisTick: {
            show: false,
        },
        nameLocation: 'center',
        nameGap: 55,
        nameTextStyle: {
            color: textColor(),
        },
        axisLine: {
           lineStyle: {
               color: textColor(),
           }
        },
        axisLabel: {
            fontSize: 8,
            align: 'right',
            textStyle: {
                color: textColor(),
            },
        },
    },
    color: [textColor]
};

export class BasicLineEchartsChart extends EChartsChart {
    options: Record<string, any> = Object.assign({}, BASIC_LINE_ECHARTS_CHART_OPTIONS, {
        tooltip: {
            trigger: 'item',
            renderMode: 'html',
            textStyle: {
                fontSize: 12,
            },
            formatter: BasicLineEchartsChart.toolTipFormatter
        }
    });

    typeAttributes = {
        type: 'line'
    };

     static toolTipFormatter(params: any) {
        let output = '';
        if (params.data.prop) {
            params.data.prop.forEach((elem: any) => {
                output = `${output}${elem}<br/>`;
            });
        } else if (params.seriesName) {
            output = params.name ? params.name + '<br/>' : '';
            output +=
                '<label style="border-radius: 50%;width: 10px;height: 10px;vertical-align: middle;margin: 0;background:' +
                params.color +
                '"></label>&nbsp;&nbsp;' +
                params.seriesName +
                ': </span>';
            output += '<b>' + params.data + '</b><br/>';
        } else {
            return undefined;
        }
        return output;
    }
}

import { EmbeddedViewRef, Injector, ComponentFactoryResolver } from '@angular/core';

export function customTrendDataTooltip(params: any, trendData: any) {
    const scoreFactorTypes: Record<string, any> = {
        applicationHealth: {
            name: 'Health',
            path: 'assets/images/icons/icon_app_health.svg',
        },
        productivity: {
            name: 'Productivity',
            path: 'assets/images/icons/icon_app_productivity.svg',
        },
        quality: {
            name: 'Quality',
            path: 'assets/images/icons/icon_app_quality.svg',
        },
        feature_advancement: {
            name: 'Feat. Adv.',
            path: 'assets/images/icons/icon_app_feat_adv.svg',
        },
    };

    let output = '';
    output = `${output}<div style="position:relative;margin-bottom: 1rem;">`;
    output = `${output}<div style="min-width: ${trendData.length > 7 ? '600px' : '620px'};max-width: ${trendData.length > 7 ? '700px' : '720px'}">`;
    output = `${output}<table style="width: 100%;table-layout: fixed;">`;
    output = `${output}<thead>`;
    output = `${output}<tr style="font-family: Open Sans;font-size:12px;font-style:normal;line-height:45px;">`;
    output = `${output}<th style="font-size: 14px;color: #000000;font-weight:normal !important;width:200px;padding-right:10px;">Projects</th>`;
    let tempOutput = '';
    trendData[0].ratingData.forEach((header: any) => {
        if (header.key === trendData[0].selectedScoreFactor) {
            tempOutput = `${tempOutput}<th style="font-size: 14px;color: #000000;font-weight:normal !important;width:100px;"><img style="margin-right:5px;width: 12% !important;" src="${
                scoreFactorTypes[header.key].path
            }"/>${scoreFactorTypes[header.key].name}</th>`;
            tempOutput = `${tempOutput}<th style="font-size: 14px;color: #000000;font-weight:normal !important;width:100px;">Statistics</th>`;
        } else {
            tempOutput = `${tempOutput}<th style="font-size: 14px;color: #000000;font-weight:normal !important;width:100px;"><img style="margin-right:5px;width: 12% !important;" src="${
                scoreFactorTypes[header.key].path
            }"/>${scoreFactorTypes[header.key].name}</th>`;
        }
    });
    output = `${output}${tempOutput}</tr>`;
    output = `${output}</tr>`;
    output = `${output}</thead>`;
    output = `${output}</table>`;
    output = `${output}</div>`;
    output = `${output}<div style="min-width:620px;max-width:720px;max-height:245px;overflow-y: auto;">`;
    output = `${output}<table style="width: 100%;table-layout: fixed;">`;
    output = `${output}<tbody class="tbody-wrap">`;
    const firstRow = trendData.filter((trendObj: any) => trendObj.name === params.name)[0];
    if (firstRow !== undefined) {
        output = `${output}<tr style="font-family: Open Sans; border-bottom: 1px solid #EFEFEF; font-size: 15px; font-style: normal; line-height: 40px;">`;
        output = `${output}<td style="width:200px;padding-right:10px;overflow: hidden; font-weight: 600;text-overflow: ellipsis;color:${firstRow.color};">${firstRow.name}</td>`;
        tempOutput = '';
        firstRow.ratingData.forEach((firstRating: any) => {
            if (firstRating.key === firstRow.selectedScoreFactor) {
                tempOutput = `${tempOutput}<td style="width:100px;color:${firstRating.color};"><strong>${
                    firstRating.hasOwnProperty('data') && firstRating.data.length ? firstRating.data[firstRating.data.length - 1] : ''
                }</strong>(${firstRating.hasOwnProperty('data') && firstRating.data.length ? getDifference(firstRating) : 0}%)</td>`;
                tempOutput = `${tempOutput}<td style="width:100px;" class="${firstRow.id}"></td>`;
            } else {
                tempOutput = `${tempOutput}<td style="width:100px;color:${firstRating.color};"><strong>${
                    firstRating.hasOwnProperty('data') && firstRating.data.length ? firstRating.data[firstRating.data.length - 1] : ''
                }</strong>(${firstRating.hasOwnProperty('data') && firstRating.data.length ? getDifference(firstRating) : 0}%)</td>`;
            }
        });
        output = `${output}${tempOutput}</tr>`;
    }

    trendData.forEach((elem: any) => {
        if (firstRow === undefined || elem.name !== firstRow.name) {
            output = `${output}<tr style="font-family: Open Sans; font-size: 14px; font-style: normal; line-height: 30px;">`;
            output = `${output}<td style="width:200px;;overflow: hidden; font-weight: 300; text-overflow: ellipsis;color:${elem.color};">${elem.name}</td>`;
            tempOutput = '';
            elem.ratingData.forEach((rating: any) => {
                if (rating.key === elem.selectedScoreFactor) {
                    tempOutput = `${tempOutput}<td style="width:100px;color:${rating.color};"><strong>${
                        rating.hasOwnProperty('data') && rating.data.length ? rating.data[rating.data.length - 1] : ''
                    }</strong>(${rating.hasOwnProperty('data') && rating.data.length ? getDifference(rating) : 0}%)</td>`;
                    tempOutput = `${tempOutput}<td style="width:100px;" class="${elem.id}"></td>`;
                } else {
                    tempOutput = `${tempOutput}<td style="width:100px;color:${rating.color};"><strong>${
                        rating.hasOwnProperty('data') && rating.data.length ? rating.data[rating.data.length - 1] : ''
                    }</strong>(${rating.hasOwnProperty('data') && rating.data.length ? getDifference(rating) : 0}%)</td>`;
                }
            });
            output = `${output}${tempOutput}</tr>`;
        }
    });
    output = `${output}</tbody>`;
    output = `${output}</table>`;
    output = `${output}</div>`;
    output = `${output}</div>`;
    return output;
}

export function drawTooltip(trendData: any, chartInstance: any, injector: Injector) {
    const hostEl = chartInstance.getDom();
    const chartComponentType = injector.get('chartComponent');
    const resolver = injector.get(ComponentFactoryResolver);
    const factory = resolver.resolveComponentFactory<any>(chartComponentType);

    trendData.forEach((item: any) => {
        const componentRef = factory.create(injector);
        const targetElement = hostEl.querySelector(`.${item.id}`);
        componentRef.instance.type = 'simplelinechart';
        componentRef.instance.height = 'min';
        componentRef.instance.options = {
            series: [
                {
                    name: item.name,
                    data: item.ratingData.filter((scoreData: any) => scoreData.key === item.selectedScoreFactor)[0].data,
                    color: item.ratingData.filter((scoreData: any) => scoreData.key === item.selectedScoreFactor)[0].color,
                },
            ],
        };
        if (targetElement) {
            targetElement.appendChild((componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0]);
        }
        componentRef.changeDetectorRef.detectChanges();
    });
}

function getDifference(rating: any) {
    return rating.data[0] == 0 && rating.data[rating.data.length - 1] == 0
        ? 0
        : rating.data[0] == 0
        ? `+100`
        : (rating.data[rating.data.length - 1] / rating.data[0]) * 100 < 100
        ? Math.round(100 - (rating.data[rating.data.length - 1] / rating.data[0]) * 100) == 0 ? 0 : `-${Math.round(100 - (rating.data[rating.data.length - 1] / rating.data[0]) * 100)}`
        : Math.round((rating.data[rating.data.length - 1] / rating.data[0]) * 100 - 100) == 0 ? 0 : `+${Math.round((rating.data[rating.data.length - 1] / rating.data[0]) * 100 - 100)}`;
}

import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BasicHttpClient } from './basic-http-client';
import { Observable } from 'rxjs';
const headers = new HttpHeaders({
    'skip-global-error': 'true',
});
const options = { headers };

@Injectable()
export class HelpSupportService extends BasicHttpClient {
    getmenu(): Observable<any[]> {
        return this.http.get<any[]>(
            `${this.API_URL}/jsonapi/menu_link_content/menu_link_content?filter[menu_name][value]=${this.Version}`,
            options
        );
    }

    getBlogData(id: string): Observable<any[]> {
        return this.http.get<any[]>(`${this.API_URL}/jsonapi/node/documentation_page?filter[drupal_internal__nid][value]=${id}`, options);
    }

    getFilteredData(title: string) {
        return (JSON.parse(sessionStorage.getItem('helpSupportData'))['data'] || []).filter((it: any) => it.attributes.title.trim() === title);
    }

    getHelpSupportPaginatedData(url: any) {
        return this.http.get<any[]>(url, options);
    }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { mostValuableUserRole, userRoleMap } from '@app/core/models/user.model';
import { checkAllIcon } from '@app/shared/icons/solutionhub-icons';

@Component({
    selector: 'app-user-role-badge',
    templateUrl: './user-role-badge.component.html',
    styleUrls: ['./user-role-badge.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserRoleBadgeComponent {
    @Input() user: any;

    readonly checkAll = checkAllIcon;

    get userRole(): string {
        return userRoleMap.get(mostValuableUserRole(this.user?.roles));
    }

    get badgeBackground(): string {
        return `${this.userRole.toLowerCase()}-role-background`;
    }
}

<lib-toastr
    [toastrType]="toastPackage.toastType"
    [title]="title"
    [message]="message"
    [htmlBodyContent]="options.enableHtml"
    [closeButton]="options.closeButton"
    [onCloseFunction]="onClose"
>
    <ng-template bodyTemplate>
        <div>The session will be over in {{remaining}} seconds</div>
    </ng-template>
</lib-toastr>

import { qualitativePalette, subTextColor, textColor } from '../charts-colors';
import { EChartsChart } from '../echarts.chart';

export class CustomEchartsChart extends EChartsChart {
    options = {
        xAxis: {
            type: 'category',
            axisTick: { show: false },
            axisLine: { show: false },
            axisLabel: {
                color: textColor(),
                margin: 10,
                fontSize: 10,
                textStyle: {
                    color: textColor(),
                },
            },
        },
        yAxis: {
            type: 'value',
            axisTick: { show: false },
            axisLine: { lineStyle: { color: textColor() } },
            axisLabel: {
                color: subTextColor(),
                fontSize: 10,
                textStyle: {
                    color: textColor(),
                },
            },
            splitLine: { lineStyle: { color: textColor() } },
            nameLocation: 'center',
            nameGap: 30,
            nameTextStyle: {
                color: textColor(),
                fontSize: '9px',
            },
        },
        legend: {
            icon: 'rect',
            selectedMode: false,
            bottom: 0,
            itemWidth: 6,
            itemHeight: 6,
            itemGap: 30,
            textStyle: {
                color: textColor(),
                fontSize: 10,
            },
        },
        color: qualitativePalette,
    };
}

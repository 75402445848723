<div
    style="width: 100%; height: 85vh; display: flex; align-items: center; justify-content: center; flex-direction: column;"
    *transloco="let t; read: 'accessDenied'"
>
    <img src="../assets/images/warning_laptop.png" [alt]="t('altText')"/>
    <h2 class="cursor-default">{{ t('title') }}</h2>
    <br/>
    <button class="btn btn-primary" routerLink="/assets">
        <fa-icon class="font-16px mr-7px" icon="facArrowLeftRegular"></fa-icon>
        {{ t('buttonText') }}
    </button>
</div>

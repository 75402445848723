import { Component, OnInit } from '@angular/core';
import { FileService } from '@app/shared/services/api/file.service';
import { combineLatest } from 'rxjs';

@Component({
    selector: 'app-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit {
    progress: number = 0;
    isSpinnerVisible: boolean = false;

    constructor(private readonly fileService: FileService) {}

    ngOnInit(): void {
        combineLatest([this.fileService.downloadingFiles$, this.fileService.isFileUploading$]).subscribe(
            ([downloadingFiles, isFileUploading]) => {
                this.isSpinnerVisible = isFileUploading || downloadingFiles.size > 0;
            }
        );
    }
}

import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {of} from 'rxjs';

import {EnvironmentModel} from '@dagility-ui/kit';

@Injectable()
export class ToolService {
    private readonly basePath = `${this.environment.dpApiURL}/tools`;
    private options = {
        headers: { 'skip-global-error': 'true' },
    };

    constructor(private http: HttpClient, @Inject('environment') private environment: EnvironmentModel) {}

    getAllTools(search = '') {
        const params = search !== '' ? { searchString: search } : null;
        return this.http.get(this.basePath, { params });
    }

    getToolDetails(toolName: any) {
        return this.http.get(`${this.basePath}/${toolName}`);
    }

    createTool(toolDetails: any) {
        return this.http.post<any>(this.basePath, toolDetails, this.options);
    }

    verifyAccess(tool: any) {
        return this.http.put(`${this.basePath}/verify`, tool);
    }

    editTool(toolDetails: any, toolId: string) {
        return this.http.put<any>(`${this.basePath}/${toolId}`, toolDetails, this.options);
    }

    deleteTool(toolId: string, toolDeleteType: string) {
        return this.http.delete(`${this.basePath}/${toolId}?deleteType=${toolDeleteType}`);
    }

    enabledisableTool(id: string, status: any) {
        return this.http.put(`${this.basePath}/enabled/${id}?enabled=${status}`, []);
    }

    getPluginByName(name: string) {
        return this.http.get(`${this.basePath}/${name}`);
    }

    getToolById(toolId: string) {
        return this.http.get(`${this.basePath}/${toolId}`);
    }

    getAllProjectsForPlugin(toolName: string) {
        return this.http.get(`${this.environment.adminApiURL}/plugins/projects/${toolName}`);
    }

    getJdbcTools() {
        return this.http.get<string[]>(`${this.environment.dpApiURL}/data-processor/jdbc-tools`).pipe(catchError(() => of([] as string[])));
    }

    getEnabledTools() {
        return this.http.get<string[]>(`${this.environment.dpApiURL}/data-processor/enabled-tools`);
    }

    generateToolSettings(pluginType: string, pluginName: string): any {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('toolId', pluginType);
        httpParams = httpParams.set('name', pluginName);
        return this.http.get(`${this.basePath}/tool-fields`, {
            params: httpParams,
        });
    }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService } from '@app/auth';
import { ROLE_CONSUMER } from '@app/core/models/user.model';
import { ModalService } from "@dagility-ui/kit";
import { LoginModalComponent } from "@app/shared/components/modals/login-modal/login-modal.component";

@Injectable({
    providedIn: 'root',
})
export class LoginPermissionGuard implements CanActivate {
    constructor(
        private readonly authService: AuthService,
        private readonly router: Router,
        private readonly modalService: ModalService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this.authService.onAfterLogin$.pipe(
            switchMap(() => {
                const access = this.authService.hasAnyRole(route.data?.roles || [ROLE_CONSUMER]);
                if (access) {
                    return of(true);
                }

                const modalRef = this.modalService.open(
                    LoginModalComponent,
                    {
                        backdrop: 'static',
                        centered: true,
                        size: 'w400'
                    }
                );
                modalRef.result.then( () => undefined, () => undefined);
                return of(this.router.parseUrl('/'));
            })
        );
    }
}

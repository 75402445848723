import { EChartsChart } from '../echarts.chart';
import { qualitativePalette, subTextColor, textColor } from '../charts-colors';

export class AreaEChartsChart extends EChartsChart {
    options: Record<string, any> = {
        textStyle: {
            fontFamily: 'sans-serif',
            fontSize: 12,
        },
        grid: {
            top: '15%',
            bottom: '35%',
        },
        title: {
            text: '',
            subtext: '',
            textAlign: 'center',
            textVerticalAlign: 'top',
            left: '50%',
            textStyle: {
                color: textColor(),
                fontSize: 14,
                fontWeight: '500',
                fontFamily: 'sans-serif',
            },
            subtextStyle: {
                color: subTextColor(),
                fontSize: 12,
                fontFamily: 'sans-serif',
            },
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: [] as any[],
            axisLabel: {
                rotate: '45',
                fontSize: 10,
                margin: 11,
                textStyle: {
                    color: textColor(),
                },
            },
            position: 'category',
            axisLine: {
                show: false,
            },
            axisTick: {
                show: false,
                alignWithLabel: true,
            },
            nameLocation: 'center',
            nameGap: 55,
            nameTextStyle: {
                color: textColor(),
            },
        },
        yAxis: {
            type: 'value',
            axisLine: {
                show: false,
            },
            axisLabel: {
                textStyle: {
                    color: textColor(),
                },
            },
            axisTick: {
                show: false,
            },
            nameLocation: 'center',
            nameGap: 48,
            nameTextStyle: {
                color: textColor(),
            },
        },
        legend: {
            bottom: '0',
            icon: 'circle',
            textStyle: {
                fontSize: 11,
                color: textColor(),
            },
            selector: ['all', 'inverse'],
            selectorPosition: 'start',
        },
        series: [] as any[],
        color: qualitativePalette,
        tooltip: {
            trigger: 'axis',
            renderMode: 'html',
            textStyle: {
                fontSize: 12,
            },
            axisPointer: {
                type: 'line',
                lineStyle: {
                    width: 0,
                },
                label: {
                    show: false,
                },
            },
        },
    };

    typeAttributes = {
        type: 'line',
        areaStyle: {},
        symbol: 'rect',
        symbolSize: 6,
        showAllSymbol: 'auto',
        emphasis: {
            scale: true,
        },
        animation: true,
    };

    assignOptions(options: Record<string, any>) {
        if (options.hasOwnProperty('editWidgetMode') && options.editWidgetMode) {
            this.options.yAxis.nameGap = 25;
            if (options.series.length > 3) {
                this.options.legend['type'] = 'scroll';
                this.options.legend['pageFormatter'] = 'page {current} of {total}';
                this.options.legend['pageButtonItemGap'] = 30;
            }
        }
        super.assignOptions(options);
    }
}

import { Observable } from 'rxjs';
import { HttpEvent, HttpEventType, HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { distinctUntilChanged, scan } from 'rxjs/operators';

function isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
    return event.type === HttpEventType.Response;
}

function isHttpProgressEvent(event: HttpEvent<unknown>): event is HttpProgressEvent {
    return event.type === HttpEventType.DownloadProgress || event.type === HttpEventType.UploadProgress;
}

export interface Download {
    content: Blob | null;
    progress: number;
    state: DownloadState;
}

export enum DownloadState {
    PENDING = 'PENDING',
    IN_PROGRESS = 'IN_PROGRESS',
    DONE = 'DONE',
}

export function download(saver?: (b: Blob) => void): (source: Observable<HttpEvent<Blob>>) => Observable<Download> {
    return (source: Observable<HttpEvent<Blob>>) =>
        source.pipe(
            scan(
                (d: Download, e): Download => {
                    if (isHttpProgressEvent(e)) {
                        return {
                            progress: e.total ? Math.round((100 * e.loaded) / e.total) : d.progress,
                            state: DownloadState.IN_PROGRESS,
                            content: null,
                        };
                    }
                    if (isHttpResponse(e)) {
                        if (saver) {
                            saver(e.body);
                        }
                        return {
                            progress: 100,
                            state: DownloadState.DONE,
                            content: e.body,
                        };
                    }
                    return d;
                },
                { state: DownloadState.PENDING, progress: 0, content: null }
            ),
            distinctUntilChanged((a, b) => a.state === b.state && a.progress === b.progress && a.content === b.content)
        );
}

import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-date-badge',
    templateUrl: './date-badge.component.html',
})
export class DateBadgeComponent {
    @Input() date: any;
    @Input() pattern: string;

    readonly now = Date.now();
    readonly month = 25 * 24 * 60 * 60 * 1000;

    parseDate(date: string): number {
        return Date.parse(date);
    }
}

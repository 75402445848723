import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { EnvironmentModel } from '@dagility-ui/kit';
import { ProjectItem } from '../models/domain/tool.models';
import { ProjectSort } from '../models/project-list-filter/project-filter.constants';

type ProjectDeletionType = 'SOFT' | 'HARD';

@Injectable()
export class ProjectService {
    private basePath = `${this.environment.adminApiURL}/project`;
    private pluginsPath = `${this.environment.adminApiURL}/plugins`;
    private readonly customVariablesUrl = `${this.environment.adminApiURL}/custom-variables`;

    constructor(private http: HttpClient, @Inject('environment') private environment: EnvironmentModel) {}

    getAllTeams(id?: string, unitId?: string) {
        let params = new HttpParams();
        if (id) {
            params = params.set('projectId', id);
        }
        if (unitId) {
            params = params.set('unitId', unitId);
        }
        return this.http.get<any[]>(`${this.pluginsPath}/teams`, { params });
    }

    getAllBoards(toolId?: string) {
        let params = new HttpParams();
        if (toolId) {
            params = params.set('toolId', toolId);
        }
        return this.http.get<any[]>(`${this.pluginsPath}/boards`, { params });
    }

    getTeamBoardsForProject(id: string) {
        return this.http.get<any[]>(`${this.pluginsPath}/board-teams?id=${id}`);
    }

    saveTeamBoardsForProject(id: string, teams: { teamId: string; boardId: string }[], toolId: string) {
        const basePath = `${this.environment.adminApiURL}/project/board-teams/${id}/tool/${toolId}`;

        return this.http.post(basePath, teams);
    }

    getComponentsByToolId(toolId: string): Observable<ProjectItem[]> {
        return this.http.get<ProjectItem[]>(`${this.pluginsPath}/components/${toolId}`);
    }

    setProjectEnabled(id: string, enable: boolean) {
        const formData = new FormData();
        formData.append('value', `${enable}`);

        return this.http.post(`${this.basePath}/changeEnableStatus/${id}`, formData);
    }

    listAllProjects(projectSort: ProjectSort[]) {
        return this.http.post(`${this.basePath}/allProjects`, projectSort);
    }

    deleteProject(projectId: string, deletionType: ProjectDeletionType) {
        const formData = new FormData();
        formData.append('type', deletionType);

        return this.http.post(`${this.basePath}/delete/${projectId}`, formData);
    }

    getNestedProjects(projectId: string) {
        return this.http.get(`${this.basePath}/nested/${projectId}`);
    }

    setProjectFavourite(id: string) {
        return this.http.get(`${this.basePath}/favourite/${id}`);
    }

    getTeamsByProject(projectId: number, userInfo = false) {
        return this.http.get<Array<ProjectTeam>>(`${this.environment.adminApiURL}/external/project-teams/${projectId}`, {
            params: {
                userInfo,
            },
        });
    }

    getProjectImportingInformation(email: string): Observable<any> {
        return this.http.get<any>(`${this.customVariablesUrl}/project-importing-wizard-${email}`);
    }

    updateProjectImportingInformation(email: string, informationClosed: boolean): Observable<any> {
        return this.http.post<any>(`${this.customVariablesUrl}/project-importing-wizard-${email}`, { informationClosed });
    }
}

export interface ProjectTeam {
    id: string;
    name: string;
    mongoId: string;
    defaultTeam: boolean;
}

import {forkJoin, from} from 'rxjs';

import {IEventsStorage, TrackEventStorageService} from './track-event-storage.service';
import {EventType, IConvertedEvent, IEvent, Logout} from '../model/track-events';
import {IEnv, IUserSession, KeepNIConfig} from '../api/keep-ni-config';
import {switchMap} from "rxjs/operators";


interface EventsBatch {
    environment: string;
    envName?: string;
    envId?: string;
    customer?: string,
    product?: string,
    user: IUserSession;
    events: IConvertedEvent[];
    pages?: {page: string, desc: string}[]
}

export enum LabelPriority {
    PATH = 1,
    TITLE = 2,
    DEFAULT = 3
}

export class PageByPath {
    page: string;
    desc: string = null;
    sendFlag: boolean = false;
    isChanged: boolean = false;
    priority: LabelPriority;
}

export class TrackEventSenderService {
    static readonly EVENTS_ENDPOINT = '/receive/events';
    static readonly DAGILITY = 'DAgility';
    static readonly DAGILITY_RE = new RegExp(`${TrackEventSenderService.DAGILITY}( - )?`);

    static readonly PATH_COMPONENTS = new Set<string>(['MODULE', 'PAGE', 'DASHBOARD']);
    static readonly IGNORED_EVENT_TYPES_FOR_PAGES = new Set<string>(['PAGE_TTFB', 'RESOURCE_TTFB']);

    private enabled = false;
    public logEnabled: boolean = false;

    private knownPagesMap: Map<string, PageByPath> = new Map<string, PageByPath>();

    private eventsStorage: IEventsStorage = new TrackEventStorageService();

    public latestEvent: IConvertedEvent

    private static convertEvent(page: string, event: IEvent): IConvertedEvent {
        const parameters = event.getParameters ? event.getParameters() : null;

        return Object.assign(
            {
                page,
                timestamp: new Date().getTime(),
                type: event.type,
            },
            !!parameters && {parameters}
        );
    }

    constructor(private config: KeepNIConfig, enabledByDefault?: boolean) {
        this.enabled ||= enabledByDefault;
    }

    get _eventStorage(): IEventsStorage {
        return this.eventsStorage;
    }

    get _latestEvent(): IConvertedEvent {
        return this.latestEvent
    }

    public sendEvent(event: IEvent, pagePath?: string, system?: boolean) {
        if (this.enabled) {
            const page = pagePath || this.config.pagePathResolver.getPath();
            const convertedEvent: IConvertedEvent = TrackEventSenderService.convertEvent(page, event);
            this.checkKnownPage(system, page, convertedEvent.parameters?.componentPath, event.type);
            this.eventsStorage.addEvent(convertedEvent);
            this.latestEvent = convertedEvent
            if (this.logEnabled) {
                console.log(JSON.stringify(convertedEvent));
            }
        }
    }

    isEnabled() {
        return this.enabled && !!this.config.serviceUrl;
    }

    setEnabled(state: boolean) {
        this.enabled = state ;

        if (this.isEnabled()) {
            this.scheduleNextBatch();
        }
    }

    private sendAllEvents() {
        if (!this.isEnabled()) {
            return false;
        }

        const processingEvents = this.eventsStorage.getEventsForProcessing();

        if (processingEvents.length) {
            this.eventsStorage.eventsInProgress++;
            this.uploadEvents(processingEvents, this.successCallback, this.errorCallback);
        }

        return !!processingEvents.length;
    }

    private uploadEvents(processingEvents: IConvertedEvent[],
                         successCallback = () => {/*empty implementation by default*/},
                         errorCallback = (_err: any) => {/*empty implementation by default*/}
                         ) {
        from(
            forkJoin([
                this.config.envResolver.getEnv(),
                this.config.userSessionResolver.getUserSession()
            ])
        )
            .pipe(
                switchMap(p => {
                    const env: IEnv = p[0];
                    const user: IUserSession = p[1];
                    const batch: EventsBatch = {
                        environment: env.environment,
                        envName: env.envName || env.environment,
                        envId: env.envId,
                        product: env.product,
                        customer: env.customer,
                        events: processingEvents,
                        user: user,
                        pages: this.getPagesInfo()
                    };
                    return from(
                        this.config.httpClient
                            .post(
                                this.receiveEventsUrl(),
                                batch,
                                {headers: {'skip-global-error': 'true'}},
                                this.logEnabled
                            )
                    );
                })
            )
            .subscribe(
                () => {
                    successCallback();
                },
                errorCallback
            );
    }

    private receiveEventsUrl(): string {
        const serviceUrl = this.config.serviceUrl;
        const url = serviceUrl.endsWith('/') ? serviceUrl.substring(0, serviceUrl.length - 1) : serviceUrl;
        return `${url}${TrackEventSenderService.EVENTS_ENDPOINT}`;
    }

    private successCallback = () => {
        this.resetSendFlags();
        this.eventsStorage.resetEventsForProcessing();
        this.scheduleNextBatch(--this.eventsStorage.eventsInProgress);
    };

    private errorCallback = (err: any) => {
        if (this.logEnabled) {
            console.error(err);
        }
        this.scheduleNextBatch(--this.eventsStorage.eventsInProgress);
    };

    private sendEvents = () => {
        if (!this.isEnabled()) {
            return;
        }

        if (this.eventsStorage.eventsInProgress > 0) {
            this.scheduleNextBatch();
        }

        if (!this.sendAllEvents()) {
            this.scheduleNextBatch();
        }
    };

    sendBeforeLogout(successCallback = () => {/*empty implementation by default*/}, errorCallback = (_err: any) => {/*empty implementation by default*/}): void {
        if (!this.isEnabled()) {
            successCallback();
            return;
        }
        try {

            this.sendEvent(new Logout());
            this.setEnabled(false);

            const processingEvents = [...this.eventsStorage.getEventsForProcessing()];
            this.eventsStorage.resetEventsForProcessing();

            this.uploadEvents(processingEvents, successCallback, errorCallback);
        } catch (e) {
            errorCallback(e);
        }
    }

    private scheduleNextBatch(count: number = 0) {
        if (!this.enabled) {
            return;
        }

        if (!count) {
            setTimeout(this.sendEvents, this.config.interval);
        }
    }

    public maxEventBufferSize(maxEventBufferSize: number) {
        this.eventsStorage.maxEventBufferSize = maxEventBufferSize;
    }

    private checkKnownPage(system: boolean, page: string, componentPath: any, type: EventType | string) {
        if (system || TrackEventSenderService.IGNORED_EVENT_TYPES_FOR_PAGES.has(type)) {
            return;
        }
        if (page && this.checkPriority(componentPath, page)) {
            this.knownPagesMap.get(page).desc = this.calculatePagePath(componentPath);
            this.knownPagesMap.get(page).sendFlag = true;
        }
    }

    private resetSendFlags(): void {
        this.knownPagesMap.forEach((page: PageByPath, path: string, map: Map<string, PageByPath>) => {
            page.sendFlag = false;
        });
    }

    private getPagesInfo(): {page: string, desc: string}[] {
        const pages: Array<any> = [];
        this.knownPagesMap.forEach((page: PageByPath, path: string, map: Map<string, PageByPath>) => {
            if (page.sendFlag) {
                pages.push({
                    page: page.page,
                    desc: page.desc
                });
            }
        });
        return pages !== [] ? pages : null;
    }

    private calculatePagePath(componentPathStr: string): string {
        let p = null;
        if (componentPathStr) {
            p = (JSON.parse(componentPathStr) as {type: string, name: string}[])
                .filter(pp => TrackEventSenderService.PATH_COMPONENTS.has(pp.type))
                .map(pp => pp.name)
                .join("/");
        }
        let docTitle = document.title && document.title.startsWith(TrackEventSenderService.DAGILITY)
            ? document.title.replace(TrackEventSenderService.DAGILITY_RE, '')
            : null;
        return p || (docTitle ? docTitle : null);
    }

    private static getPagePriority(componentPathStr: string): LabelPriority {
        if (componentPathStr && componentPathStr !== '[]') { return LabelPriority.PATH; }
        const docTitle = document.title && document.title.startsWith(TrackEventSenderService.DAGILITY)
            ? document.title.replace(TrackEventSenderService.DAGILITY_RE, '')
            : null;
        if (docTitle) { return LabelPriority.TITLE; }
        return LabelPriority.DEFAULT;
    }

    private checkPriority(componentPathStr: string, page: string): boolean {
        if (!this.knownPagesMap.get(page)) {
            this.knownPagesMap.set(page, new PageByPath());
            this.knownPagesMap.get(page).page = page;
            this.knownPagesMap.get(page).priority = LabelPriority.DEFAULT;
        }
        const lvl: LabelPriority = TrackEventSenderService.getPagePriority(componentPathStr);
        if (lvl < this.knownPagesMap.get(page).priority) {
            if (this.knownPagesMap.get(page).priority !== LabelPriority.DEFAULT) { this.knownPagesMap.get(page).isChanged = true; }
            this.knownPagesMap.get(page).priority = lvl;
            return true;
        }
        return false;
    }
}

import { NgModule, Pipe, PipeTransform } from '@angular/core';

export type Mapper<T, G> = (item: T, ...args: any[]) => G;

@Pipe({
    name: 'mapper',
})
export class MapperPipe implements PipeTransform {
    transform<T, G>(value: T, mapper: Mapper<T, G>, ...args: any[]): G {
        return mapper(value, ...args);
    }
}

@NgModule({
    declarations: [MapperPipe],
    exports: [MapperPipe],
})
export class MapperPipeModule {}

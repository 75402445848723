export const divergentPalette = ['#62CD20', '#2ECC71', '#40B2FF', '#6D62E5', '#DAD2BC', '#FADB14', '#FF9800', '#FB4E4E', '#872204'];
export const divergentPalette4 = ['#2ECC71', '#40B2FF', '#6D62E5', '#FB4E4E'];
export const divergentPalette3 = ['#2ECC71', '#FF9800', '#FB4E4E'];
export const UST_COLORS = ['#01B27C', '#FC6A59', '#7A7480', '#C2BCBE', '#DAD2BC', '#881E87'];
export const qualitativePalette = ['#40B2FF', '#62CD20', '#FADB14', '#FF3F85', '#13C2C2', '#01B27C', '#FC6A59', '#A933E0', '#FD38C6'];

export function isDarkTheme() {
    return document.documentElement.dataset.theme?.includes('dark');
}

export function textColor() {
    return isDarkTheme() ? '#DEDEDE' : '#4F4C4D';
}

export function subTextColor() {
    return isDarkTheme() ? '#B2B1B1' : '#868484';
}

import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { MetadataFieldDto, MetadataFieldWithValuesDto, MetadataSlimDto } from '@app/shared/models/metadata.model';
import { map, tap } from 'rxjs/operators';
import { Id } from '@app/shared/services/api/assets.service';
import { ENV_TOKEN } from '@app/tokens';

@Injectable({
    providedIn: 'root',
})
export class MetadataService {
    private readonly baseUrl = this.env.metaDataApiURL;

    readonly metadata$: BehaviorSubject<MetadataFieldWithValuesDto[]> = new BehaviorSubject<MetadataFieldWithValuesDto[]>([]);
    readonly metadataByCategory$: BehaviorSubject<MetadataFieldWithValuesDto[]> = new BehaviorSubject<MetadataFieldWithValuesDto[]>([]);

    constructor(@Inject(ENV_TOKEN) private readonly env: Env, private readonly http: HttpClient) {
        this.http
            .get<MetadataFieldWithValuesDto[]>(`${this.baseUrl}/system`)
            .pipe(
                map(metadata => {
                    metadata.sort((a, b) => a.order - b.order);
                    return metadata;
                })
            )
            .subscribe(metadata => this.metadata$.next(metadata));
    }

    getMetadataTypesByCategoryId(categoryId: Id): Observable<MetadataSlimDto[]> {
        return this.http.get<MetadataSlimDto[]>(`${this.baseUrl}`, {
            params: {
                categoryId,
            },
        });
    }

    getMetadataFieldsById(id: number): Observable<MetadataFieldDto[]> {
        return this.http.get<MetadataFieldDto[]>(`${this.baseUrl}/${id}/fields`);
    }

    getMetadataFieldsByCategoryId(categoryId: Id): Observable<MetadataFieldWithValuesDto[]> {
        return this.http
            .get<MetadataFieldWithValuesDto[]>(`${this.baseUrl}/category`, { params: { categoryId } })
            .pipe(tap(metadata => this.metadataByCategory$.next(metadata)));
    }

    getMetadataFieldValues(metadataId: number, id: number): Observable<string[]> {
        return this.http.get<string[]>(`${this.baseUrl}/${metadataId}/fields/${id}/values`);
    }
}

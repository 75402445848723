import { NgModule } from '@angular/core';
import { UserRoleBadgeComponent } from './components/user-role-badge/user-role-badge.component';
import { HeaderNavigationComponent } from './components/header-navigation/header-navigation.component';
import { HeaderNavigationDropdownComponent } from './components/header-navigation/header-navigation-dropdown/header-navigation-dropdown.component';
import { SharedModule } from '@app/shared/shared.module';
import { HeaderComponent } from '@app/core/components/header/header.component';

@NgModule({
    declarations: [HeaderComponent, UserRoleBadgeComponent, HeaderNavigationComponent, HeaderNavigationDropdownComponent],
    imports: [SharedModule],
    exports: [HeaderComponent],
    providers: [],
})
export class HeaderModule {}

import {Component, Inject, NgZone} from '@angular/core';
import {Toast, ToastPackage, ToastrService} from 'ngx-toastr';
import {ToasterService} from '../../services/Toaster/toaster.service';
import {EnvironmentModel} from '@dagility-ui/kit';

@Component({
    selector: '[dagility-toast-component]',
    templateUrl: './dagility-toastr.component.html',
})
export class DagilityToastrComponent extends Toast {
    hasAdminUrl = !!this.environment.adminApiURL;

    constructor(
        toastrService: ToastrService,
        toastPackage: ToastPackage,
        ngZone: NgZone,
        private service: ToasterService,
        @Inject('environment') private environment: EnvironmentModel
    ) {
        super(toastrService, toastPackage, ngZone);
    }

    onClose = () => {
        this.remove();
    }

    parseJSON(json: string) {
        try {
            return JSON.parse(json);
        } catch (e) {
            return json;
        }
    }

    report(error: any) {
        try {
            this.service.sendReport(error.extraData).subscribe(() => this.toastrService.success('Report was successfully sent', 'Success'));
            this.toastrService.toasts
                .filter(item => JSON.parse(item.message).isError)
                .forEach(toast => this.toastrService.clear(toast.toastId));
        } catch (ignored) {
        }
    }
}

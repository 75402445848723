import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CodeMirrorEditorComponent } from './codemirror-editor.component';
import { LibFormsModule } from '@dagility-ui/kit';

const components = [CodeMirrorEditorComponent];
const modules = [CommonModule, ];

@NgModule({
    declarations: [...components],
    imports: [...modules, LibFormsModule],
    exports: [...modules, ...components],
})
export class CodemirrorEditorModule {}

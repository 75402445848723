import { Component, Input, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MarketplaceTemplateRating } from '@dagility-ui/kit';
import { AssetDto } from '../../../../asset.model';
import { TranslationService } from '@app/core/services/translation.service';
import { TranslationKeysOfRating } from '@dagility-ui/kit/modules/forms/controls/rating/rating.component';
import { assetIcon } from '@app/shared/icons/solutionhub-icons';
import { getAssetRatingStatistic } from '@app/shared/components/assets/components/asset-rating-statistic/asset-rating-statistic.component';

@Component({
    selector: 'app-asset-card',
    templateUrl: './asset-card.component.html',
    styleUrls: ['./asset-card.component.scss'],
})
export class AssetCardComponent implements OnInit {
    @Input() asset: AssetDto;

    @Input() expanded = false;

    @Input() showStatus = false;

    @Input() isGridView = false;

    readonly assetIcon = assetIcon;

    ratingStatistics$: Observable<MarketplaceTemplateRating>;

    get ratingTranslatedText(): Partial<Record<TranslationKeysOfRating, string>> {
        return this.translationService.ratingTranslatedText;
    }

    constructor(private readonly translationService: TranslationService) {}

    ngOnInit(): void {
        this.ratingStatistics$ = getAssetRatingStatistic(of(this.asset));
    }
}

import { Inject, Pipe, PipeTransform } from '@angular/core';
import { UserDto } from '@app/core/models/user.model';
import { ENV_TOKEN } from '@app/tokens';

@Pipe({
    name: 'avatarUrl',
})
export class AvatarUrlPipe implements PipeTransform {
    constructor(@Inject(ENV_TOKEN) private readonly env: Env) {}

    transform(user: UserDto, type: 'NORMAL' | 'SMALL' = 'NORMAL') {
        if (!user.avatarExists) {
            return '';
        }

        let url = `${this.env.usersApiURL}/${user.id}/avatar?type=${type}`;
        if (user.lastModifiedAt) {
            url += '&t=' + new Date(user.lastModifiedAt).getTime();
        }

        return url;
    }
}

import { AfterContentInit, AfterViewInit, Component, EventEmitter, HostBinding, Input, Output, SimpleChanges } from '@angular/core';
import { BaseFormControl, LabelPosition } from '@dagility-ui/kit';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function assetVersionValidator(previousVersion?: string): ValidatorFn {
    const internalPreviousVersion: string = previousVersion || '1.0.0-0';

    const isVersionGreaterThenSourceVersion = (version: string): boolean => {
        const previousVersionItems: string[] = internalPreviousVersion.split(/[.-]/);
        const versionItems: string[] = version.split(/[.-]/);

        const isPartGreater = (part: number): boolean => +versionItems[part] > +previousVersionItems[part];
        const isPartsEqual = (part: number): boolean => +versionItems[part] == +previousVersionItems[part];
        const majorPartGreater = (): boolean => isPartGreater(0);
        const majorPartEqual = (): boolean => isPartsEqual(0);
        const minorPartGreater = (): boolean => isPartGreater(1);
        const minorPartEqual = (): boolean => isPartsEqual(1);
        const patchPartGreater = (): boolean => isPartGreater(2);
        const patchPartEqual = (): boolean => isPartsEqual(2);

        return (
            majorPartGreater() ||
            (majorPartEqual() && minorPartGreater()) ||
            (majorPartEqual() && minorPartEqual() && (patchPartGreater() || (previousVersion ? false : patchPartEqual())))
        );
    };

    return (control: AbstractControl): ValidationErrors | null => {
        if (!/^(?:\d+[.]\d+[.]\d+-\d+|\d+[.]\d+[.]\d+)$/.test(control.value)) {
            return { pattern: { requiredPattern: 'of asset version' } };
        } else {
            return isVersionGreaterThenSourceVersion(control.value) ? null : { min: { min: internalPreviousVersion } };
        }
    };
}

@Component({
    selector: 'app-asset-version-input',
    templateUrl: './asset-version-input.component.html',
    styleUrls: ['./asset-version-input.component.scss'],
    host: { class: 'asset-version-input' },
})
export class AssetVersionInputComponent extends BaseFormControl<string, HTMLInputElement> implements AfterContentInit, AfterViewInit {
    versionParts = ['', '', '', ''];
    disabledParts = [false, false, false, true];
    partFocuses = [false, false, false, false];

    hover: boolean = false;

    @Input() labelPosition: LabelPosition = 'top';

    @Input() clearable: boolean;

    @Input() set setDisable(value: boolean) {
        this.setDisabledState(value);
    }

    @Input() lockByFocus = false;

    @Input() name: string;

    @Input() id: string;

    @Output() clear = new EventEmitter();

    @Output() controlValueChange = new EventEmitter();

    @Output() blur = new EventEmitter();

    @HostBinding('class') get cssClass() {
        return `asset-version-input d-flex ${this.labelPosition === 'left' ? 'flex-row' : 'flex-column'} asset-version-input--${
            this.labelPosition
        }`;
    }

    get focus(): boolean {
        return this.partFocuses.some(focus => focus === true);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.input) {
            const props = ['id', 'name'] as const;
            props.forEach(prop => {
                if (changes[prop]) {
                    this.input.nativeElement[prop] = this[prop];
                }
            });
        }
    }

    ngAfterContentInit(): void {
        this.control.valueChanges.subscribe(value => {
            this.initValue(value);
            this.setValue();
        });

        this.initValue();
        this.updateValue();

        this.control.markAsPristine();
        this.control.markAsUntouched();
        this.control.updateValueAndValidity();
    }

    ngAfterViewInit(): void {
        this.toggleReadonly(true);
    }

    private initValue(value?: string) {
        const versionItems: string[] = (value || this.value || '').split(/[.-]/);
        this.versionParts[0] = versionItems[0] || '';
        this.versionParts[1] = versionItems[1] || '';
        this.versionParts[2] = versionItems[2] || '';
        this.versionParts[3] = this.readonly ? versionItems[3] || '0' : '0';
        this.setDisabledState(this.readonly);
    }

    handleFocus(versionPart?: 0 | 1 | 2 | 3) {
        this.partFocuses = this.partFocuses.map((_, i) => i === versionPart && !this.disabledParts[versionPart]);
    }

    handleInput(event: any, versionPart: 0 | 1 | 2 | 3) {
        if ((event.target.value as string) == '' || this.isVersionPartValid(event.target.value as string)) {
            this.versionParts[versionPart] = event.target.value;
            this.updateValue();
        } else {
            event.target.value = this.versionParts[versionPart];
        }
    }

    isVersionPartValid(value: string): boolean {
        return /^\d+$/.test(value);
    }

    toggleReadonly(lock: boolean) {
        if (this.readonly || !this.lockByFocus) {
            return;
        }
        this.input.nativeElement.readOnly = lock;
    }

    setDisabledState(isDisabled: boolean) {
        this.input.nativeElement.disabled = isDisabled;
        this.readonly = isDisabled;
        this.disabledParts = this.disabledParts.map((_, i) => (i !== 3 ? isDisabled : true));
    }

    handleClearBtn() {
        this.clear.emit();
        this.versionParts[0] = '';
        this.versionParts[1] = '';
        this.versionParts[2] = '';
        this.updateValue();
    }

    private updateValue() {
        this.setValue();
        this.onChange(this.value);
        this.controlValueChange.emit(this.value);
    }

    private setValue() {
        this.writeValue(`${this.versionParts[0]}.${this.versionParts[1]}.${this.versionParts[2]}-${this.versionParts[3]}`);
    }
}

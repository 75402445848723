import { Inject, Injectable } from '@angular/core';
import { AssetActionResponse, AssetCreationDto, AssetDto, AssetEvent } from '@app/shared/components/assets/asset.model';
import { HttpClient } from '@angular/common/http';
import { RejectReason } from '@app/shared/components/assets/modals/reject-reason-modal/reject-reason-modal.component';
import { Observable } from 'rxjs';
import { ENV_TOKEN } from '@app/tokens';

@Injectable({
    providedIn: 'root',
})
export class AssetEventsService {
    private readonly baseUrl = `${this.env.assetsApiURL}`;

    constructor(@Inject(ENV_TOKEN) private readonly env: Env, private readonly http: HttpClient) {}

    handleAssetSave(asset: AssetCreationDto, event: AssetEvent): Observable<AssetActionResponse> {
        return this.http.post<AssetActionResponse>(`${this.baseUrl}/save`, asset, { params: { action: event } });
    }

    handleAssetApprove(asset: AssetDto, event: AssetEvent, rejectReason: RejectReason): Observable<AssetActionResponse> {
        return this.http.put<AssetActionResponse>(`${this.baseUrl}/${asset.id}/versions/${asset.assetVersionId}/approve`, rejectReason, {
            params: { action: event },
        });
    }

    handleLaunch(asset: AssetDto, event: AssetEvent): Observable<AssetActionResponse> {
        return this.http.put<AssetActionResponse>(`${this.baseUrl}/${asset.id}/versions/${asset.assetVersionId}/launch`, asset, {
            params: { action: event },
        });
    }
}

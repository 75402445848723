import { Inject, Injectable } from '@angular/core';
import { ENV_TOKEN } from '@app/tokens';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PageFilter } from '@app/shared/services/api/assets.service';
import { Page } from '@dagility-ui/kit';

export enum AccessRequestStatus {
    PENDING = 'PENDING',
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED',
}

export interface AssetAccessRequest {
    id: number;
    requestedAssetId: number;
    requestedAssetName: string;
    requestedAssetVersionId: number;
    createdAt: string;
    requesterName: string;
    requesterEmail: string;
    acceptorId: number;
    acceptorName: string;
    acceptorEmail: string;
    status: AccessRequestStatus;
    comment: string;
    rejectComment: string;
    rejectReason: string;
}

export interface AssetRequestReject {
    id: number;
    rejectComment: string;
    rejectReason: number;
}

@Injectable({
    providedIn: 'root',
})
export class AssetRequestService {
    private readonly baseUrl = this.env.assetRequestApiURL;

    constructor(@Inject(ENV_TOKEN) private readonly env: Env, private readonly http: HttpClient) {}

    getActiveRequestsCount(): Observable<number> {
        return this.http.post<number>(`${this.baseUrl}/active/count`, {});
    }

    getRequests({ page, pageSize, sort }: PageFilter): Observable<Page<AssetAccessRequest>> {
        return this.http.post<Page<AssetAccessRequest>>(
            `${this.baseUrl}`,
            {},
            {
                params: {
                    size: pageSize,
                    page,
                    sort,
                },
            }
        );
    }

    getUserRequests({ page, pageSize, sort }: PageFilter): Observable<Page<AssetAccessRequest>> {
        return this.http.post<Page<AssetAccessRequest>>(
            `${this.baseUrl}/user-requests`,
            {},
            {
                params: {
                    size: pageSize,
                    page,
                    sort,
                },
            }
        );
    }

    createRequest(request: AssetAccessRequest): Observable<AssetAccessRequest> {
        return this.http.post<AssetAccessRequest>(`${this.baseUrl}/create`, request);
    }

    acceptRequest(requestId: number): Observable<AssetAccessRequest> {
        return this.http.put<AssetAccessRequest>(`${this.baseUrl}/${requestId}/accept`, {});
    }

    rejectRequest(requestId: number, reject: AssetRequestReject): Observable<AssetAccessRequest> {
        return this.http.put<AssetAccessRequest>(`${this.baseUrl}/${requestId}/reject`, reject);
    }

    revertRequest(requestId: number): Observable<AssetAccessRequest> {
        return this.http.put<AssetAccessRequest>(`${this.baseUrl}/${requestId}/revert`, {});
    }
}

import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserDto, UserRole, userRoleMap } from '@app/core/models/user.model';
import { RolesService } from '@app/core/services/roles.service';
import { DropdownItem, FormDirtyDirective } from '@dagility-ui/kit';
import { map, shareReplay } from 'rxjs/operators';
import { AuthService } from '@app/auth';

@Component({
    selector: 'app-user-edit-form',
    templateUrl: './user-edit-form.component.html',
})
export class UserEditFormComponent implements OnChanges, OnInit {
    @Input() user: UserDto;

    form = this.fb.group({
        id: [''],
        firstName: ['', Validators.compose([Validators.required, Validators.maxLength(100)])],
        lastName: ['', Validators.compose([Validators.required, Validators.maxLength(100)])],
        login: ['', Validators.compose([Validators.required, Validators.maxLength(100)])],
        email: ['', Validators.compose([Validators.email, Validators.required, Validators.maxLength(100)])],
        group: [null, Validators.compose([Validators.required])],
        roles: [{}, Validators.minLength(1)],
    });

    defaultFormValue: any;

    roles$ = this.rolesService.getAll().pipe(
        map(roles =>
            roles.map(role => ({
                label: userRoleMap.get(role.key),
                value: role.key,
                disabled: this.user?.id === this.authService.getUser().id,
            }))
        ),
        shareReplay(1)
    );

    roles: UserRole[] = [];

    groups: DropdownItem<number>[];

    @ViewChild(FormDirtyDirective, { static: true }) formDirtyDir: FormDirtyDirective;

    constructor(private fb: FormBuilder, private readonly rolesService: RolesService, private authService: AuthService) {}

    ngOnInit(): void {
        this.groups = [
            {
                label: this.user.groupName,
                value: this.user.groupId,
            },
        ];
    }

    async ngOnChanges(): Promise<void> {
        const allRoles = await this.roles$.toPromise();
        // wait for checkbox-group is initialized
        setTimeout(() => {
            const { id, firstName, lastName, login, email, groupId, roles } = this.user;
            this.form.setValue({
                id,
                firstName,
                lastName,
                login,
                email,
                group: groupId,
                roles: allRoles.reduce((acc, cur) => {
                    acc[cur.value] = roles.includes(cur.value);
                    return acc;
                }, {} as Record<string, boolean>),
            });
            this.defaultFormValue = this.form.getRawValue();
        });
    }

    save() {
        this.defaultFormValue = this.form.getRawValue();
    }

    reset() {
        this.form.markAsPristine();
        this.form.markAsUntouched();
        this.form.updateValueAndValidity();

        this.form.patchValue(this.defaultFormValue);
    }
}

import { Injectable, OnDestroy } from '@angular/core';
import { AssetFilter, Id, PageFilter } from '@app/shared/services/api/assets.service';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { AssetStatus } from '@app/shared/components/assets/asset.model';
import { ActivatedRoute, Router } from '@angular/router';
import { cloneDeep, isEqual, pickBy } from 'lodash';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { CategoryService } from '@app/shared/services/api/category.service';
import { MetadataFieldWithValuesDto } from '@app/shared/models/metadata.model';
import { DictionaryItem, DictionaryService } from '@app/shared/services/api/dictionary.service';
import { MetadataService } from '@app/shared/services/api/metadata.service';

export interface Filter {
    id: number | string;
    name: string;
    children: Filter[];
    checked: number;
    type: FilterType;
    value?: boolean;
}

export type FilterType = 'PRIMARY_CATEGORY' | 'CATEGORY' | 'PUBLISHER' | 'TAXONOMY' | 'METADATA_BY_CATEGORY';

export const INITIAL_ASSETS_PAGE_FILTER: PageFilter = {
    page: 0,
    pageSize: 50,
    sort: 'name,asc',
};

export const INITIAL_MANAGE_ASSETS_PAGE_FILTER: PageFilter = {
    page: 0,
    pageSize: 50,
    sort: 'publishDate,desc',
};

export const INITIAL_LANDING_DISCOVER_PAGE_FILTER: PageFilter = {
    page: 0,
    pageSize: 50,
    sort: 'name,asc',
};

export const INITIAL_ASSET_DEPENDENCIES_PAGE_FILTER: PageFilter = {
    page: 0,
    pageSize: 10,
    sort: '',
};

export const INITIAL_FEATURED_ASSETS_MANAGEMENT_PAGE_FILTER: PageFilter = {
    page: 0,
    pageSize: 50,
    sort: 'name,asc',
};

export const INITIAL_ASSET_FILTER: AssetFilter = {
    searchTerm: '',
};

export type AssetFiltersPagePath = 'assets' | 'manage-assets' | 'landing-discover' | 'asset-dependencies' | 'featured-assets-management';

@Injectable({
    providedIn: 'root',
})
export class AssetFiltersService implements OnDestroy {
    private readonly destroy$: Subject<any> = new Subject<any>();

    private hasFiltersBlock: boolean;
    private hasFilterByStatus: boolean;
    private hasFilterByGroup: boolean;
    private hasRouteObservation: boolean;

    readonly pageFilterChanged$: BehaviorSubject<PageFilter> = new BehaviorSubject<PageFilter>(this.initialPageFilter);

    readonly pageChanged$: BehaviorSubject<number> = new BehaviorSubject<number>(this.initialPageFilter.page);
    readonly pageSizeChanged$: BehaviorSubject<number> = new BehaviorSubject<number>(this.initialPageFilter.pageSize);
    readonly sortChanged$: BehaviorSubject<string> = new BehaviorSubject<string>(this.initialPageFilter.sort);

    readonly assetFilterChanged$: BehaviorSubject<AssetFilter> = new BehaviorSubject<AssetFilter>(this.initialAssetFilter);

    readonly searchTermChanged$: BehaviorSubject<string> = new BehaviorSubject<string>(this.initialAssetFilter.searchTerm);
    readonly statusChanged$: BehaviorSubject<AssetStatus> = new BehaviorSubject<AssetStatus>(this.initialAssetFilter.status);
    readonly primaryCategoryIdChanged$: BehaviorSubject<Id> = new BehaviorSubject<Id>(this.initialAssetFilter.primaryCategoryId);
    readonly categoryIdChanged$: BehaviorSubject<Id> = new BehaviorSubject<Id>(this.initialAssetFilter.categoryId);
    readonly groupIdsChanged$: BehaviorSubject<Id[]> = new BehaviorSubject<Id[]>(this.initialAssetFilter.groupIds);
    readonly dropdownIdsChanged$: BehaviorSubject<Id[]> = new BehaviorSubject<Id[]>(this.initialAssetFilter.dropdownIds);
    readonly fieldValuesChanged$: BehaviorSubject<Id[]> = new BehaviorSubject<Id[]>(this.initialAssetFilter.fieldValues);

    readonly selectedCategoryFilters$: BehaviorSubject<Filter[]> = new BehaviorSubject<Filter[]>([]);

    readonly filters$: BehaviorSubject<Filter[]> = new BehaviorSubject<Filter[]>([]);
    readonly selectedFilters$: BehaviorSubject<Filter[]> = new BehaviorSubject<Filter[]>([]);

    constructor(
        private readonly categoryService: CategoryService,
        private readonly metadataService: MetadataService,
        private readonly dictionaryService: DictionaryService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly pagePath: AssetFiltersPagePath
    ) {}

    init(hasRouteObservation: boolean, hasFilterByStatus: boolean, hasFilterByGroup: boolean, hasFiltersBlock: boolean) {
        this.forceResetAllFilters();

        this.hasRouteObservation = hasRouteObservation;
        this.hasFilterByStatus = hasFilterByStatus;
        this.hasFilterByGroup = hasFilterByGroup;
        this.hasFiltersBlock = hasFiltersBlock;

        if (this.hasFiltersBlock) {
            this.observeCategoriesChanges();
            this.observeMetadataChanges();
        }
        if (this.hasRouteObservation) {
            this.subscribeOnRouteChanges();
        }
    }

    destroy() {
        this.destroy$.next();
    }

    forceResetAllFilters() {
        this.forceResetPageFilters();
        this.forceResetAssetFilters();
    }

    forceResetPageFilters() {
        this.pageFilterChanged$.next(this.initialPageFilter);

        this.pageChanged$.next(this.initialPageFilter.page);
        this.pageSizeChanged$.next(this.initialPageFilter.pageSize);
        this.sortChanged$.next(this.initialPageFilter.sort);
    }

    forceResetAssetFilters() {
        this.assetFilterChanged$.next(this.initialAssetFilter);

        this.searchTermChanged$.next(this.initialAssetFilter.searchTerm);
        this.statusChanged$.next(this.initialAssetFilter.status);
        this.primaryCategoryIdChanged$.next(this.initialAssetFilter.primaryCategoryId);
        this.categoryIdChanged$.next(this.initialAssetFilter.categoryId);
        this.groupIdsChanged$.next(this.initialAssetFilter.groupIds);
        this.dropdownIdsChanged$.next(this.initialAssetFilter.dropdownIds);
        this.fieldValuesChanged$.next(this.initialAssetFilter.fieldValues);
    }

    forceUpdatePageFilter(newState: Partial<PageFilter>) {
        if (newState.page !== undefined && !isEqual(newState.page, this.pageChanged$.value)) {
            this.pageChanged$.next(newState.page);
        }
        if (newState.pageSize !== undefined && !isEqual(newState.pageSize, this.pageSizeChanged$.value)) {
            this.pageSizeChanged$.next(newState.pageSize);
        }
        if (newState.sort !== undefined && !isEqual(newState.sort, this.sortChanged$.value)) {
            this.sortChanged$.next(newState.sort);
        }

        this.pageFilterChanged$.next({
            ...this.pageFilter,
            ...newState,
        });
    }

    forceUpdateAssetFilter(newState: Partial<AssetFilter>) {
        if (newState.searchTerm !== undefined && !isEqual(newState.searchTerm, this.searchTermChanged$.value)) {
            this.searchTermChanged$.next(newState.searchTerm);
        }
        if (newState.status !== undefined && !isEqual(newState.status, this.statusChanged$.value)) {
            this.statusChanged$.next(newState.status);
        }
        if (newState.primaryCategoryId !== undefined && !isEqual(newState.primaryCategoryId, this.primaryCategoryIdChanged$.value)) {
            this.primaryCategoryIdChanged$.next(newState.primaryCategoryId);
        }
        if (newState.categoryId !== undefined && !isEqual(newState.categoryId, this.categoryIdChanged$.value)) {
            this.categoryIdChanged$.next(newState.categoryId);
        }
        if (newState.groupIds !== undefined && !isEqual(newState.groupIds, this.groupIdsChanged$.value)) {
            this.groupIdsChanged$.next(newState.groupIds);
        }
        if (newState.dropdownIds !== undefined && !isEqual(newState.dropdownIds, this.dropdownIdsChanged$.value)) {
            this.dropdownIdsChanged$.next(newState.dropdownIds);
        }
        if (newState.fieldValues !== undefined && !isEqual(newState.fieldValues, this.fieldValuesChanged$.value)) {
            this.fieldValuesChanged$.next(newState.fieldValues);
        }

        this.assetFilterChanged$.next({
            ...this.assetFilter,
            ...newState,
        });
    }

    updateRouteByAllFilters(newPageFilter: Partial<PageFilter>, newAssetFilter: Partial<AssetFilter>) {
        this.updateRoute({ ...newPageFilter, ...newAssetFilter });
    }

    updateRouteByPageFilter(newState: Partial<PageFilter>) {
        this.updateRoute(newState);
    }

    updateRouteByAssetFilter(newState: Partial<AssetFilter>) {
        this.updateRoute(newState);
    }

    private updateRoute(newState: object) {
        this.router
            .navigate([this.pagePath], {
                queryParams: pickBy(
                    {
                        ...this.pageFilter,
                        ...this.assetFilter,
                        ...newState,
                    },
                    val => val !== undefined
                ),
                queryParamsHandling: 'merge',
            })
            .then();
    }

    private subscribeOnRouteChanges() {
        this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
            const assetFilterFromParams: AssetFilter = {
                ...pickBy(cloneDeep(params), val => val != null),
            } as AssetFilter;

            if (typeof assetFilterFromParams.groupIds === 'string') {
                assetFilterFromParams.groupIds = [assetFilterFromParams.groupIds];
            }

            if (typeof assetFilterFromParams.dropdownIds === 'string') {
                assetFilterFromParams.dropdownIds = [assetFilterFromParams.dropdownIds];
            }

            if (typeof assetFilterFromParams.fieldValues === 'string') {
                assetFilterFromParams.fieldValues = [assetFilterFromParams.fieldValues];
            }

            if (assetFilterFromParams.status && !AssetStatus[assetFilterFromParams.status]) {
                this.updateRouteByAssetFilter({ status: null });
                return;
            }

            if (assetFilterFromParams.primaryCategoryId && assetFilterFromParams.categoryId) {
                this.updateRouteByAssetFilter({
                    primaryCategoryId: assetFilterFromParams.primaryCategoryId,
                    categoryId: null,
                    fieldValues: null,
                });
                return;
            }

            const restrictedAssetFilter: AssetFilter = this.restrictAssetFilter(assetFilterFromParams);

            const pageFilterFromParams: PageFilter = { ...pickBy(cloneDeep(params), val => val != null) } as PageFilter;
            const restrictedPageFilter: PageFilter = this.restrictPageFilter(pageFilterFromParams);

            if (pageFilterFromParams.page == null || pageFilterFromParams.pageSize == null || pageFilterFromParams.sort == null) {
                this.updateRouteByAllFilters(this.initialPageFilter, restrictedAssetFilter);
                return;
            }

            if (!isEqual(this.removeEmptyFields(restrictedAssetFilter), this.removeEmptyFields(this.assetFilterChanged$.value))) {
                this.forceUpdateAssetFilter(restrictedAssetFilter);
                this.forceUpdatePageFilter({ ...restrictedPageFilter, page: 0 });
                this.updateRouteByPageFilter({ ...restrictedPageFilter, page: 0 });
                return;
            }
            if (!isEqual(this.removeEmptyFields(restrictedPageFilter), this.removeEmptyFields(this.pageFilterChanged$.value))) {
                this.forceUpdatePageFilter(restrictedPageFilter);
            }
        });
    }

    private observeCategoriesChanges() {
        this.categoryService.categoryFilters$
            .pipe(
                takeUntil(this.destroy$),
                switchMap(categoryFilters =>
                    combineLatest([this.primaryCategoryIdChanged$, this.categoryIdChanged$]).pipe(
                        takeUntil(this.destroy$),
                        map(([primaryCategoryId, categoryId]) => {
                            if (!categoryFilters.find(f => f.type === 'PRIMARY_CATEGORY' && f.id == primaryCategoryId)) {
                                primaryCategoryId = null;
                            }
                            if (!categoryFilters.find(f => f.type === 'CATEGORY' && f.id == categoryId)) {
                                categoryId = null;
                            }
                            return primaryCategoryId || categoryId;
                        }),
                        tap(categoryId => {
                            const categoryFilter: Filter = categoryFilters.find(
                                filter => (filter.type === 'PRIMARY_CATEGORY' || filter.type === 'CATEGORY') && filter.id == categoryId
                            );
                            if (categoryId == null) {
                                this.metadataService.metadataByCategory$.next([]);
                            }
                            this.selectedCategoryFilters$.next(categoryFilter ? [categoryFilter] : []);
                            if (categoryFilter?.type === 'CATEGORY') {
                                this.metadataService.getMetadataFieldsByCategoryId(categoryFilter.id).subscribe();
                            }
                        })
                    )
                )
            )
            .subscribe();
    }

    private observeMetadataChanges() {
        return combineLatest([this.metadataService.metadata$, this.dictionaryService.publishers$, this.metadataService.metadataByCategory$])
            .pipe(
                takeUntil(this.destroy$),
                map(([metadata, publishers, metadataByCategory]) => [
                    ...this.initSystemMetadataFilters(metadata),
                    this.initPublisherFilter(publishers),
                    ...this.initMetadataFilters(metadataByCategory),
                ]),
                switchMap(filters =>
                    combineLatest([this.groupIdsChanged$, this.dropdownIdsChanged$, this.fieldValuesChanged$]).pipe(
                        takeUntil(this.destroy$),
                        map(([groupIds, dropdownIds, fieldValues]) =>
                            this.applyMetadataFilters(filters, groupIds, dropdownIds, fieldValues)
                        )
                    )
                )
            )
            .subscribe();
    }

    private applyMetadataFilters(filters: Filter[], groupIds: Id[], dropdownIds: Id[], fieldValues: Id[]) {
        const selectedFilters: Filter[] = [];
        if (filters.length) {
            filters.forEach(filter => {
                filter.checked = 0;
                filter.children?.forEach(child => (child.value = false));
            });

            const applyIds = (type: 'PUBLISHER' | 'TAXONOMY' | 'METADATA_BY_CATEGORY', ids: Id[] = []) => {
                if (!ids?.length) {
                    return;
                }
                filters
                    .filter(filter => filter.type === type)
                    .forEach(group => {
                        const selectedItems = (group.children || []).filter(filter => ids.some(id => id == filter.id));
                        selectedFilters.push(...selectedItems);
                        group.checked = selectedItems.length;
                    });
            };

            applyIds('PUBLISHER', groupIds);
            applyIds('TAXONOMY', dropdownIds);
            applyIds('METADATA_BY_CATEGORY', fieldValues);

            selectedFilters.forEach(filter => (filter.value = true));
        }
        this.selectedFilters$.next(selectedFilters);
        this.filters$.next(filters);
    }

    get pageFilter(): PageFilter {
        return this.pageFilterChanged$.value;
    }

    get assetFilter(): AssetFilter {
        return this.assetFilterChanged$.value;
    }

    get isPageFilterInitial(): boolean {
        return isEqual(this.removeEmptyFields(this.pageFilter), this.initialPageFilter);
    }

    get isAssetFilterInitial(): boolean {
        return isEqual(this.removeEmptyFields(this.assetFilter), this.initialAssetFilter);
    }

    private restrictPageFilter(pageFilter: PageFilter): PageFilter {
        return {
            page: Number(pageFilter.page) || 0,
            pageSize: Number(pageFilter.pageSize) || 50,
            sort: pageFilter.sort,
        };
    }

    private restrictAssetFilter(assetFilter: AssetFilter): AssetFilter {
        return {
            searchTerm: assetFilter.searchTerm || '',
            status: this.hasFilterByStatus ? AssetStatus[assetFilter.status] || null : null,
            primaryCategoryId: this.hasFiltersBlock ? Number(assetFilter.primaryCategoryId) || assetFilter.primaryCategoryId || null : null,
            categoryId: this.hasFiltersBlock ? Number(assetFilter.categoryId) || assetFilter.categoryId || null : null,
            groupIds: this.hasFilterByGroup ? assetFilter.groupIds || null : null,
            dropdownIds: this.hasFiltersBlock ? assetFilter.dropdownIds || null : null,
            fieldValues: this.hasFiltersBlock ? assetFilter.fieldValues || null : null,
        };
    }

    private get initialPageFilter(): PageFilter {
        switch (this.pagePath) {
            case 'assets':
                return INITIAL_ASSETS_PAGE_FILTER;
            case 'manage-assets':
                return INITIAL_MANAGE_ASSETS_PAGE_FILTER;
            case 'landing-discover':
                return INITIAL_LANDING_DISCOVER_PAGE_FILTER;
            case 'asset-dependencies':
                return INITIAL_ASSET_DEPENDENCIES_PAGE_FILTER;
            case 'featured-assets-management':
                return INITIAL_FEATURED_ASSETS_MANAGEMENT_PAGE_FILTER;
        }
    }

    private get initialAssetFilter(): AssetFilter {
        return INITIAL_ASSET_FILTER;
    }

    get isAssetsPage(): boolean {
        return this.pagePath === 'assets';
    }

    get isManageAssetsPage(): boolean {
        return this.pagePath === 'manage-assets';
    }

    get isLandingPage(): boolean {
        return this.pagePath === 'landing-discover';
    }

    get isAssetDependenciesPage(): boolean {
        return this.pagePath === 'asset-dependencies';
    }

    get isFeaturedAssetsManagementPage(): boolean {
        return this.pagePath === 'featured-assets-management';
    }

    get getPagePath() {
        return this.pagePath;
    }

    private removeEmptyFields(o: any): any {
        return Object.keys(o)
            .filter(k => o[k] !== undefined)
            .reduce((a, k) => ({ ...a, [k]: o[k] }), {});
    }

    private initSystemMetadataFilters(metadata: MetadataFieldWithValuesDto[]): Filter[] {
        const mapFilter = (m: MetadataFieldWithValuesDto): Filter => ({
            id: m.id,
            name: m.label,
            children: (m.dropdownValues || []).map(item => ({
                id: item.id,
                name: item.label,
                checked: 0,
                type: 'TAXONOMY',
                children: [],
            })),
            checked: 0,
            type: 'TAXONOMY',
        });

        const findFilters = (items: MetadataFieldWithValuesDto[]) => {
            const result: Filter[] = [];
            items.forEach(item => {
                if (item.showInFilter && (item.dropdownValues || []).length) {
                    result.push(mapFilter(item));
                }
            });
            return result;
        };

        return findFilters(metadata);
    }

    private initPublisherFilter(publishers: DictionaryItem[]): Filter {
        return {
            id: 'publisher',
            name: 'Publisher',
            children: publishers.map(publisher => ({
                name: publisher.name,
                children: [],
                id: publisher.id,
                checked: 0,
                type: 'PUBLISHER',
            })),
            checked: 0,
            type: 'PUBLISHER',
        };
    }

    private initMetadataFilters(metadata: MetadataFieldWithValuesDto[]): Filter[] {
        return (metadata || []).map(item => {
            if (item.type === 'DROPDOWN') {
                return {
                    id: item.id,
                    name: item.label,
                    children: item.dropdownValues?.map(
                        (field): Filter => ({
                            id: field.id,
                            name: field.label,
                            children: [],
                            checked: 0,
                            type: 'METADATA_BY_CATEGORY',
                        })
                    ),
                    checked: 0,
                    type: 'METADATA_BY_CATEGORY',
                };
            } else if (item.type === 'STRING') {
                return {
                    id: item.id,
                    name: item.label,
                    children: item.values?.map(
                        (field): Filter => ({
                            id: field,
                            name: field,
                            children: [],
                            checked: 0,
                            type: 'METADATA_BY_CATEGORY',
                        })
                    ),
                    checked: 0,
                    type: 'METADATA_BY_CATEGORY',
                };
            }
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }
}

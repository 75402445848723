import { NgModule } from '@angular/core';

import { UiKitModule } from '@dagility-ui/kit';

import { FieldsGridComponent } from './component/fields-grid/fields-grid.component';
import { NewFieldPopupComponent } from './component/fields-grid/new-field-popup/new-field-popup.component';
import { CodemirrorEditorModule } from '../codemirror/codemirror-editor.module';
import { PropertiesCompletionModule } from '../../directives/properties-completion.directive';
import { AddSystemPropertyComponent } from './component/fields-grid/add-system-property/add-system-property.component';

@NgModule({
    declarations: [FieldsGridComponent, NewFieldPopupComponent, AddSystemPropertyComponent],
    imports: [UiKitModule, CodemirrorEditorModule, PropertiesCompletionModule],
    exports: [FieldsGridComponent]
})
export class FieldsGridModule {}

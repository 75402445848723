import { AfterViewInit, Directive, ElementRef, HostListener, Input, NgModule, OnDestroy } from '@angular/core';
import {
    CompletionResult,
    CompletionSourceItem,
    getPropertiesCompletionItems
} from './properties-completion';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'lib-input[propertiesCompletion], lib-textarea[propertiesCompletion]',
})
export class PropertiesCompletionDirective implements AfterViewInit, OnDestroy {
    @Input('propertiesCompletion') dictionary: CompletionSourceItem[];

    private hint: HTMLDivElement;

    private currentFocus = -1;

    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    private _inputEl: HTMLInputElement;

    get inputEl(): HTMLInputElement {
        return this._inputEl || this.elRef.nativeElement.querySelector<HTMLInputElement>('input, textarea');
    }

    constructor(private elRef: ElementRef<HTMLInputElement>) {
    }

    ngAfterViewInit() {
        this.inputEl.autocomplete = 'off';
    }

    @HostListener('input')
    onInput() {
        this.hideHint();
        if (!this.inputEl.value) {
            return;
        }
        this.startCompletion();
    }

    @HostListener('keydown', ['$event'])
    onSpaceClicked(event: any) {
        if (event.ctrlKey && event.code === 'Space') {
            this.startCompletion();
        }
        if (!this.hint) {
            return;
        }

        switch (event.code) {
            case 'Escape': {
                event.preventDefault();
                this.hideHint();
                break;
            }
            case 'ArrowDown': {
                event.preventDefault();
                this.moveToNextItem(this.currentFocus + 1);
                break;
            }
            case 'ArrowUp': {
                event.preventDefault();
                this.moveToNextItem(this.currentFocus - 1);
                break;
            }
            case 'Enter': {
                event.preventDefault();
                if (this.currentFocus > -1) {
                    if (this.hint && this.hint.children[this.currentFocus]) {
                        // simulate a click on the active item
                        (this.hint.children[this.currentFocus] as HTMLElement).click();
                    }
                }
                break;
            }
        }
    }

    @HostListener('document:click')
    hideHint() {
        if (this.hint) {
            this.hint.parentNode.removeChild(this.hint);
            this.hint = null;
        }
    }

    private highlightItem(item: HTMLElement) {
        const activeClass = 'hint-active';
        if (this.hint) {
            const children = this.hint.children;
            Array.from(children).forEach(child => child.classList.remove(activeClass));
        }

        item.classList.add(activeClass);
        item.style.cssText = 'background: #F1F9FE; color: #327DE2; position: relative;';
    }

    private startCompletion() {
        const { value, selectionStart } = this.inputEl;
        const completionResult = getPropertiesCompletionItems(value, selectionStart || 0, { start: '${', end: '}' }, this.dictionary);
        if (completionResult) {
            this.showHint(completionResult);
        }
    }

    private moveToNextItem(nextActiveIndex: number) {
        const { children } = this.hint;
        const activeItem = children[this.currentFocus] as HTMLElement;
        if (activeItem) {
            activeItem.removeAttribute('style');
        }
        this.currentFocus = nextActiveIndex;
        if (this.currentFocus >= children.length) {
            this.currentFocus = 0;
        } else if (this.currentFocus < 0) {
            this.currentFocus = children.length - 1;
        }
        this.highlightItem(children[this.currentFocus] as HTMLElement);
    }

    private showHint({ items, curWord }: CompletionResult) {
        if (this.hint) {
            this.hideHint();
        }
        if (!items.length) {
            return;
        }

        const input = this.inputEl;
        const selectionStart = input.selectionStart;
        const wrapper = document.createElement('div');
        const { top, left } = this.inputEl.getBoundingClientRect();
        const { x } = getCursorXY(input, selectionStart);
        wrapper.className = 'completion-wrapper';
        wrapper.style.cssText = `
                position: fixed;
                z-index: 1100;
                top: ${top + 20}px;
                left: ${left + x}px;
                background-color: #fff;
                padding: 5px;
                box-shadow: 2px 3px 5px rgba(0,0,0,.2);
                border-radius: 4px;
                border: 1px solid #DFE1E5;`;
        const val = curWord;
        this.currentFocus = 0;

        for (let i = 0; i < items.length; i++) {
            // create a div element for each matching element
            const item = document.createElement('div');
            // make the matching letters bold
            item.innerHTML = '<strong>' + items[i].text.substr(0, val.length) + '</strong>';
            item.innerHTML += items[i].text.substr(val.length);
            item.innerHTML += `<div class="completion-details">${items[i].description}</div>`;
            item.addEventListener('click', () => {
                this.hideHint();
                const value = input.value;
                input.value = [
                    value.slice(0, selectionStart),
                    items[i].text.slice(curWord.length),
                    value.slice(selectionStart),
                ].join('');
                input.dispatchEvent(newEvent('input'));
                // input.value.length - value.length = curWord.length
                input.selectionEnd = selectionStart + input.value.length - value.length;
            });
            if (i === this.currentFocus) {
                this.highlightItem(item);
            }
            wrapper.appendChild(item);
        }

        document.body.appendChild(wrapper);
        this.hint = wrapper;
    }

    ngOnDestroy() {
        this.hideHint();
    }
}

function newEvent(eventName: string, bubbles = false, cancelable = false) {
    const evt = document.createEvent('CustomEvent');  // MUST be 'CustomEvent'
    evt.initCustomEvent(eventName, bubbles, cancelable, null);
    return evt;
}

function getCursorXY(input: HTMLInputElement, selectionPoint: number) {
    const { offsetLeft: inputX, offsetTop: inputY } = input;
    // create a dummy element that will be a clone of our input
    const div = document.createElement('div');
    // get the computed style of the input and clone it onto the dummy element
    const copyStyle = getComputedStyle(input) as any;
    for (const prop of copyStyle) {
        div.style[prop] = copyStyle[prop];
    }
    // we need a character that will replace whitespace when filling our dummy element if it's a single line <input/>
    const swap = '.';
    const inputValue = input.tagName === 'INPUT' ? input.value.replace(/ /g, swap) : input.value;
    // set the text content of the dummy element div
    div.textContent = inputValue.substr(0, selectionPoint);
    div.style.width = 'auto';
    // create a marker element to obtain caret position
    const span = document.createElement('span');
    // give the span the textContent of remaining content so that the recreated dummy element is as close as possible
    span.textContent = inputValue.substr(selectionPoint) || '.';
    // append the span marker to the div
    div.appendChild(span);
    // append the dummy element to the body
    document.body.appendChild(div);
    // get the marker position, this is the caret position top and left relative to the input
    const { offsetLeft: spanX, offsetTop: spanY } = span;
    // lastly, remove that dummy element
    // NOTE:: can comment this out for debugging purposes if you want to see where that span is rendered
    document.body.removeChild(div);
    // return an object with the x and y of the caret. account for input positioning so that you don't need to wrap the input
    return {
        x: inputX + spanX,
        y: inputY + spanY,
    };
}

@NgModule({
    declarations: [PropertiesCompletionDirective],
    exports: [PropertiesCompletionDirective]
})
export class PropertiesCompletionModule {}

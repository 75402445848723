<div *ngIf="date" class="d-inline-block">
    <div *ngIf="pattern; else defaultDateTmpl">
        <fa-icon icon="simpleCalendar" class="mr-1"></fa-icon>
        {{ date | date:pattern }}
    </div>
    <ng-template #defaultDateTmpl>
        <div *ngIf="now - parseDate(date) <= month">
            <fa-icon icon="clock" class="mr-1"></fa-icon>
            {{ date | timeAgo }}
        </div>
        <div *ngIf="now - parseDate(date) > month">
            <fa-icon icon="simpleCalendar" class="mr-1"></fa-icon>
            {{ date | date:'d MMMM y' }}
        </div>
    </ng-template>
</div>

import { defaultEnvironment, getDefaultRuntimeEnv } from './env.common';

const envName = 'prod';

const service_url = 'https://dev-solutionhub.ustpace.com';
const kni_service_url = 'https://dev.dagility.com/keepni/api';

export const environment: StaticEnv = { ...defaultEnvironment, production: true };

export const runtimeEnv: Partial<RuntimeEnv> = {
    ...getDefaultRuntimeEnv(envName),
    serviceUrl: service_url,
    env: {
        product: 'SolutionHub',
        customer: 'UST',
        name: envName,
    },
    webConsoleApiUrl: null,
};

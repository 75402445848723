import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import {
    ColDef,
    ColumnApi,
    FilterManager,
    GridApi,
    IFilterComp,
    RowClassRules,
    RowNode,
} from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model';
import ResizeObserver from 'resize-observer-polyfill';
import { AgGridNoRowsOverlayComponent } from './ag-grid-no-rows-overlay/ag-grid-no-rows-overlay.component';

const CHECK_DELAY = 200;

@Component({
    selector: 'lib-ag-grid',
    templateUrl: './ag-grid.component.html',
    styleUrls: ['./ag-grid.component.scss'],
})
export class AgGridComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
    @Input() tableData: any = {};
    @Input() lazyLoad = false;
    @Input() gridHeight: string = '';
    @Input() headerHeight = 32;
    @Input() calculateMaxHeight = true;
    @Input() pinnedTopRowData: any = [];
    @Input() pinnedBottomRowData: any = [];
    @Input() rowBuffer: number = 0;
    @Input() pagination = false;
    @Input() paginationPageSize = 0;
    @Input() suppressPaginationPanel = false;
    @Input() showIssueCount = false;
    @Input() showResetButton = false;
    @Input() autoSizeColumns = false;
    @Input() rowClassRules: RowClassRules = {};
    @Input() frameworkComponents: Record<string, any> = {};
    @Input() checkOverflow = true;

    @Output() rowClicked = new EventEmitter();
    @Output() cellClicked = new EventEmitter();
    @Output() paginationChanged = new EventEmitter();
    @Output() gridReady = new EventEmitter();

    @ViewChild('gridWrapper') gridWrapper: ElementRef;

    private perfectScrollbarX: PerfectScrollbar;
    private resizeObserver: ResizeObserver;
    perfectScrollbarY: PerfectScrollbar;
    disableResetFilter = true;
    modules: any = [ClientSideRowModelModule, InfiniteRowModelModule];
    columnDefs: any = [];
    rowData: any = [];
    gridApi: GridApi;
    columnApi: ColumnApi;
    maxHeight: string | null = null;

    components: any;
    infiniteInitialRowCount: number = 100;
    maxConcurrentDatasourceRequests: number = 1;

    gridOptions: Record<string, any> = {
        defaultColDef: {
            sortable: true,
        },
        frameworkComponents: {
            agNoRowsOverlay: AgGridNoRowsOverlayComponent,
        },
        suppressMenuHide: true,
        suppressCellSelection: true,
        rowStyle: { 'font-size': '12px' },
        enableCellTextSelection: true,
        getRowStyle: (params: any) => {
            if (params.node.rowPinned) {
                return { 'background-color': '#F1F9FE' };
            }
        },
    };

    get calcGridHeight() {
        if (!this.gridHeight || !this.hasResetButton) {
            return this.gridHeight;
        }

        // 48px - reset button height
        return `calc(${this.gridHeight} - 48px)`;
    }

    get hasResetButton() {
        return this.lazyLoad || this.showResetButton || (this.showIssueCount && !!this.gridApi);
    }

    constructor(private elementRef: ElementRef<HTMLElement>) {}

    ngOnInit() {
        // if (this.lazyLoad) {
        //     this.components = {
        //         loadingRenderer: (params: any) => {
        //             return params.value;
        //         },
        //     };
        // }
        this.initGrid();
    }

    ngOnChanges() {
        this.initGrid();
        this.gridOptions.frameworkComponents = {
            ...this.gridOptions.frameworkComponents,
            ...(this.frameworkComponents || {})
        };
    }

    ngAfterViewInit() {
        if (this.calculateMaxHeight && this.gridWrapper) {
            setTimeout(() => {
                const offsetHeight = this.gridWrapper.nativeElement.offsetHeight;
                this.maxHeight = offsetHeight ? offsetHeight + 'px' : '100%';
            });
        }
    }

    initGrid() {
        this.columnDefs = this.tableData.columnDefs;
        this.rowData = this.tableData.rowData;
        // if (!this.lazyLoad) {
        // }
    }

    onGridSizeChanged(params: any) {
        setTimeout(() => {
            this.gridApi = params.api;
            this.sizeColumnsToFit();
            this.checkTableOverflow();
        });
        setTimeout(() => {
            this.isColumnFilterEmpty();
        }, CHECK_DELAY);
    }

    filterChanged() {
        if (this.showIssueCount) {
            this.isColumnFilterEmpty();
        }
        this.disableResetFilter = false;
    }

    onGridReady(params: any) {
        // if (this.gridApi && this.lazyLoad) {
        //     return;
        // }
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
        (window as any).grid = this.gridApi;
        this.gridReady.emit();
        // if (this.lazyLoad) {
            // this.tableData.rowData?.forEach((data: any, index: any) => {
            //     data.id = 'R' + (index + 1);
            // });
            // const dataSource: any = {
            //     rowCount: null,
            //     getRows: (rowParams: any) => {
            //         const dataAfterSortingAndFiltering = this.sortAndFilter(
            //             this.tableData.rowData,
            //             rowParams.sortModel,
            //             rowParams.filterModel
            //         );
            //         const rowsThisPage = dataAfterSortingAndFiltering?.slice(rowParams.startRow, rowParams.endRow);
            //         let lastRow = -1;
            //         if (dataAfterSortingAndFiltering.length <= rowParams.endRow) {
            //             lastRow = dataAfterSortingAndFiltering.length;
            //         }
            //         rowParams.successCallback(rowsThisPage, lastRow);
            //     },
            // };
            //
            // params.api.setDatasource(dataSource);
        // }
        const agBodyViewport: HTMLElement = this.elementRef.nativeElement.querySelector('.table-grid .ag-body-viewport');
        if (agBodyViewport && !this.perfectScrollbarY) {
            this.perfectScrollbarY = new PerfectScrollbar(agBodyViewport);
            this.perfectScrollbarY.update();
        }

        const agBodyHorizontalViewport: HTMLElement = this.elementRef.nativeElement.querySelector(
            '.table-grid .ag-body-horizontal-scroll-viewport'
        );
        if (agBodyHorizontalViewport && !this.perfectScrollbarX) {
            this.perfectScrollbarX = new PerfectScrollbar(agBodyHorizontalViewport);
            this.perfectScrollbarX.update();
            (agBodyHorizontalViewport as any)._getBoundingClientRect = agBodyHorizontalViewport.getBoundingClientRect;
            agBodyHorizontalViewport.getBoundingClientRect = () => {
                const original = (agBodyHorizontalViewport as any)._getBoundingClientRect();
                return {
                    ...original,
                    width: Math.floor(original.width),
                    height: Math.floor(original.height),
                };
            };
        }

        if (!this.resizeObserver) {
            this.resizeObserver = new ResizeObserver(() => {
                agBodyHorizontalViewport.scroll(1, 1);
            });

            this.resizeObserver.observe(agBodyHorizontalViewport);
        }

        setTimeout(() => {
            this.sizeColumnsToFit();
            this.checkTableOverflow();
        });

        setTimeout(() => {
            this.isColumnFilterEmpty();
        }, CHECK_DELAY);
    }

    onColumnResize() {
        this.perfectScrollbarX?.update();
        this.perfectScrollbarY?.update();
        this.checkTableOverflow();
    }

    sortAndFilter(allOfTheData: any, sortModel: any, filterModel: any) {
        return this.sortData(sortModel, this.filterData(filterModel, allOfTheData));
    }

    sortData(sortModel: any, data: any) {
        const sortPresent = sortModel && sortModel.length > 0;
        setTimeout(() => {
            this.isColumnFilterEmpty();
        }, CHECK_DELAY);
        if (!sortPresent) {
            return data;
        }
        const resultOfSort = data.slice();
        resultOfSort.sort(function(a: any, b: any) {
            for (let k = 0; k < sortModel.length; k++) {
                const sortColModel = sortModel[k];
                const valueA = a[sortColModel.colId];
                const valueB = b[sortColModel.colId];
                if (valueA === valueB) {
                    continue;
                }
                const sortDirection = sortColModel.sort === 'asc' ? 1 : -1;
                if (valueA > valueB) {
                    return sortDirection;
                } else {
                    return sortDirection * -1;
                }
            }
            return 0;
        });
        return resultOfSort;
    }

    filterData(filterModel: any, data: Array<Record<string, unknown>>) {
        const filterManager: FilterManager = this.gridApi['filterManager'];

        if (!filterManager.isAnyFilterPresent()) {
            return data;
        }

        const filters: Array<{ column: ColDef; filter: IFilterComp }> = this.gridApi
            .getColumnDefs()
            .map((column: ColDef) => ({ column, filter: this.gridApi.getFilterInstance(column.field) }));

        return data.filter(row =>
            filters.every(({ filter }) => {
                if (!filter || !filter.getModel()) {
                    return true;
                }

                const node = new RowNode(null);
                node.data = row;

                return filter.doesFilterPass({ node, data: row });
            })
        );
    }

    clearFilters() {
        this.gridApi.setFilterModel(null);

        setTimeout(() => {
            this.disableResetFilter = true;
        });
    }

    isColumnFilterEmpty() {
        this.disableResetFilter = !((this.gridApi && !(this.gridApi as any).destroyCalled && Object.keys(this.gridApi.getFilterModel() || {}).length) || {});
    }

    checkTableOverflow() {
        if (!this.checkOverflow) {
            return;
        }
        setTimeout(() => {
            this.elementRef.nativeElement.classList.toggle(
                'table-not-overflow',
                !this.pagination && !this.perfectScrollbarY?.scrollbarYActive && !this.perfectScrollbarX?.scrollbarXActive
            );
        });
    }

    private sizeColumnsToFit() {
        if (!this.gridApi) {
            return;
        }
        if (this.autoSizeColumns) {
            const fitToContent = () => {
                const availableWidth = this.gridWrapper.nativeElement.clientWidth;
                const displayedColumns = this.columnApi.getAllDisplayedColumns();
                const usedWidth = displayedColumns.reduce((sum, cur) => sum + cur.getActualWidth(), 0);
                const needToFit = usedWidth < availableWidth;
                if (needToFit) {
                    this.gridApi.sizeColumnsToFit();
                }
                return needToFit;
            };

            if (!fitToContent()) {
                const displayedColumns = this.columnApi.getAllDisplayedColumns();
                const allColumnIds: string[] = displayedColumns.map(column => column.getId());
                this.columnApi.autoSizeColumns(allColumnIds, false);

                fitToContent();
            }
        } else {
            this.gridApi.sizeColumnsToFit();
        }
    }

    ngOnDestroy() {
        this.gridApi = null;
    }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface SelectedItem {
    id: number;
    position: number;
}

@Injectable({
    providedIn: 'root',
})
export class ItemSelectionService {
    readonly selectedItems$: BehaviorSubject<SelectedItem[]> = new BehaviorSubject<SelectedItem[]>([]);

    add(id: number) {
        this.selectedItems$.next([
            ...this.selectedItems$.value,
            {
                id,
                position: this.selectedItems$.value.length + 1,
            },
        ]);
    }

    addAll(ids: number[]) {
        this.selectedItems$.next([
            ...this.selectedItems$.value,
            ...ids.map((id, index) => ({ id, position: this.selectedItems$.value.length + 1 + index })),
        ]);
    }

    setAll(ids: number[]) {
        this.selectedItems$.next(
            ids.map((id, index) => ({
                id,
                position: this.selectedItems$.value.length + 1 + index,
            }))
        );
    }

    addAllItems(items: SelectedItem[]) {
        this.selectedItems$.next([...this.selectedItems$.value, ...items]);
    }

    setAllItems(items: SelectedItem[]) {
        this.selectedItems$.next(items);
    }

    remove(id: number) {
        this.selectedItems$.next(
            this.selectedItems$.value
                .filter(selectedItem => selectedItem.id !== id)
                .map((selectedItem, index) => ({
                    id: selectedItem.id,
                    position: index + 1,
                }))
        );
    }

    removeAll() {
        this.selectedItems$.next([]);
    }

    addOrRemove(id: number) {
        if (this.selectedItems$.value.find(selectedItem => selectedItem.id === id)) {
            this.remove(id);
        } else {
            this.add(id);
        }
    }
}

import { AfterViewInit, Component, EventEmitter, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AssetActionResponse, AssetDto, AssetEvent } from '../../../asset.model';
import { StateMachineService } from '@app/shared/services/api/state-machine.service';
import { ModalService } from '@dagility-ui/kit';
import {
    RejectReason,
    RejectReasonModalComponent,
} from '@app/shared/components/assets/modals/reject-reason-modal/reject-reason-modal.component';
import { Router } from '@angular/router';
import { UpgradeVersionModalComponent } from '@app/shared/components/assets/modals/upgrade-version-modal/upgrade-version-modal.component';
import { keyIcon } from '@app/shared/icons/solutionhub-icons';
import { DownloadFilesModalComponent } from '@app/shared/components/assets/modals/download-files-modal/download-files-modal.component';
import { ItemSelectionService } from '@app/shared/services/utils/item-selection.service';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { AssetPermissionsService } from '@app/shared/components/assets/asset-permissions.service';
import { AssetsService } from '@app/shared/services/api/assets.service';
import { RequestAccessModalComponent } from '@app/shared/components/assets/modals/request-access-modal/request-access-modal.component';

@Component({
    selector: 'app-asset-list-item',
    templateUrl: './asset-list-item.component.html',
    styleUrls: ['./asset-list-item.component.scss'],
})
export class AssetListItemComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
    private readonly destroy$: Subject<any> = new Subject<any>();

    readonly events = AssetEvent;

    @Input() item: AssetDto;

    @Input() isManageAssets: boolean = false;

    @Input() hasManagementActions: boolean = false;

    @Input() hasActionButtons: boolean = true;

    @Input() showStatus = false;

    @Input() readOnly = false;

    @Input() isLoggedIn = false;

    @Input() canSelectAsset: boolean = false;

    @Input() isGridView = false;

    @Output() managementActionDone: EventEmitter<AssetActionResponse> = new EventEmitter<AssetActionResponse>();

    processing: boolean = false;

    position: number;

    pagePath: string;

    hideButtonText: boolean = false;
    private resizeObserver: ResizeObserver;
    private itemEl: HTMLElement;
    private cardEl: HTMLElement;
    private actionsEl: HTMLElement;
    gridTemplateColumnsStyleForButtons: string = '';

    private assetActionsWidth: number;

    readonly icons = { keyIcon };

    constructor(
        public stateMachineService: StateMachineService,
        public assetPermissions: AssetPermissionsService,
        private readonly assetsService: AssetsService,
        private readonly modalService: ModalService,
        private readonly router: Router,
        private readonly zone: NgZone,
        private readonly itemSelectionService: ItemSelectionService
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.isGridView && changes.isGridView.currentValue !== changes.isGridView.previousValue) {
            this.hideButtonText = false;
        }
    }

    ngOnInit(): void {
        this.pagePath = this.isManageAssets ? 'manage-assets' : 'assets';

        this.resizeObserver = new ResizeObserver(entries => {
            entries.forEach(() => {
                this.assetActionsWidth = this.actionsEl.className.includes('hide-button-text')
                    ? this.assetActionsWidth
                    : this.actionsEl.getBoundingClientRect().width;
                const itemWidth: number = this.itemEl.getBoundingClientRect().width - 49;
                const cardWidth: number = 500;
                const actionsWidth: number = this.assetActionsWidth;
                const hideButtonText: boolean =
                    (this.isGridView && itemWidth <= actionsWidth) || (!this.isGridView && itemWidth <= cardWidth + actionsWidth);

                const newGridTemplateColumnsStyleForButtons = this.getGridTemplateColumnsStyleForButtons(hideButtonText);

                if (
                    hideButtonText !== this.hideButtonText ||
                    newGridTemplateColumnsStyleForButtons !== this.gridTemplateColumnsStyleForButtons
                ) {
                    this.zone.run(() => {
                        this.hideButtonText = hideButtonText;
                        this.gridTemplateColumnsStyleForButtons = newGridTemplateColumnsStyleForButtons;
                    });
                }
            });
        });

        if (this.canSelectAsset && !this.item.private) {
            this.itemSelectionService.selectedItems$
                .pipe(takeUntil(this.destroy$))
                .subscribe(
                    selectedAssets =>
                        (this.position = selectedAssets.find(selectedAsset => selectedAsset.id === this.item.id)?.position || null)
                );
        }
    }

    ngAfterViewInit(): void {
        this.hideButtonText = false;
        this.itemEl = document.getElementById(`asset-item-${this.item.id}-${this.item.assetVersionId}`);
        this.cardEl = document.getElementById(`asset-card-${this.item.id}-${this.item.assetVersionId}`);
        this.actionsEl = document.getElementById(`asset-actions-${this.item.id}-${this.item.assetVersionId}`);
        this.assetActionsWidth = this.actionsEl?.getBoundingClientRect().width;
        if (this.itemEl && this.cardEl && this.actionsEl) {
            this.resizeObserver.observe(this.itemEl);
        }
    }

    ngOnDestroy(): void {
        this.resizeObserver.unobserve(this.itemEl);
        this.destroy$.next();
    }

    private getGridTemplateColumnsStyleForButtons(hideButtonText: boolean): string {
        if (!this.isGridView || !this.actionsEl) {
            return '';
        }
        let minButtonWidth: number = 50;
        if (!hideButtonText) {
            minButtonWidth = this.actionsEl.getElementsByClassName('upgrade-version-button')?.length ? 130 : 90;
        }
        return `repeat(${this.actionsEl.getElementsByClassName('action-button')?.length || 0}, minmax(${minButtonWidth}px, 1000px))`;
    }

    goToAsset() {
        this.router.navigate([`/${this.pagePath}/${this.item.id}/version/${this.item.assetVersionId}`]).then();
    }

    private executeManagementAction(observable: Observable<AssetActionResponse>) {
        this.processing = true;
        observable
            .pipe(
                tap((response: AssetActionResponse) => this.managementActionDone.emit(response)),
                switchMap((response: AssetActionResponse) => this.assetsService.getById(response.assetId, response.versionId))
            )
            .subscribe(
                (newAsset: AssetDto) => this.updateAsset(newAsset),
                () => (this.processing = false),
                () => (this.processing = false)
            );
    }

    handleApprove(asset: AssetDto, event: Event) {
        this.stopEventPropagation(event);

        if (!this.assetPermissions.canApprove(asset)) {
            return;
        }

        this.executeManagementAction(this.stateMachineService.handleAssetSlimEvent(asset, AssetEvent.ADMIN_APPROVE));
    }

    handleReject(asset: AssetDto, event: Event) {
        this.stopEventPropagation(event);

        if (!this.assetPermissions.canReject(asset)) {
            return;
        }

        const modalRef = this.modalService.open(RejectReasonModalComponent, {
            centered: true,
            backdrop: 'static',
            size: 'lg',
        });

        modalRef.result.then(
            (rejectReason: RejectReason) => {
                this.executeManagementAction(this.stateMachineService.handleAssetSlimEvent(asset, AssetEvent.ADMIN_REJECT, rejectReason));
            },
            () => undefined
        );
    }

    handleLaunch(asset: AssetDto, event: Event) {
        this.stopEventPropagation(event);

        if (!this.assetPermissions.canLaunch(asset)) {
            return;
        }

        this.executeManagementAction(this.stateMachineService.handleAssetSlimEvent(asset, AssetEvent.LAUNCH));
    }

    handleCancel(asset: AssetDto, event: Event) {
        this.stopEventPropagation(event);

        if (!this.assetPermissions.canLaunch(asset)) {
            return;
        }

        this.executeManagementAction(this.stateMachineService.handleAssetSlimEvent(asset, AssetEvent.CANCEL));
    }

    handleEdit(asset: AssetDto, event: Event) {
        this.stopEventPropagation(event);

        if (!this.assetPermissions.canEdit(asset) && !this.assetPermissions.canEditLaunched(asset)) {
            return;
        }

        this.router.navigate(['manage-assets', `${asset.id}`, `${asset.assetVersionId}`]).then();
    }

    handleRequestAccess(asset: AssetDto, event: Event) {
        this.stopEventPropagation(event);

        if (this.assetPermissions.canDownload(asset)) {
            return;
        }

        const modalRef = this.modalService.open(
            RequestAccessModalComponent,
            {
                centered: true,
                backdrop: 'static',
                size: 'lg',
            },
            { mode: 'REQUEST', asset }
        );

        modalRef.result.then(
            () => undefined,
            () => undefined
        );
    }

    handleGoToLink(asset: AssetDto, event: Event) {
        this.stopEventPropagation(event);

        if (!this.assetPermissions.canDownload(this.item) || !asset.repoUrl) {
            return;
        }

        window.open(asset.repoUrl.includes('http') ? asset.repoUrl : `https://${asset.repoUrl}`, '_blank');
    }

    handleDownload(asset: AssetDto, event: Event) {
        this.stopEventPropagation(event);

        if (!this.assetPermissions.canDownload(asset)) {
            return;
        }

        const modalRef = this.modalService.open(
            DownloadFilesModalComponent,
            {
                backdrop: 'static',
                centered: true,
                size: 'sm',
            },
            { asset }
        );

        modalRef.result.then(
            () => undefined,
            () => undefined
        );
    }

    handleUpgradeVersion(asset: AssetDto, event: Event) {
        this.stopEventPropagation(event);

        if (!this.assetPermissions.canUpgradeVersion(asset)) {
            return;
        }

        const modalRef = this.modalService.open(
            UpgradeVersionModalComponent,
            {
                centered: true,
                backdrop: 'static',
                size: 'lg',
            },
            {
                asset,
            }
        );

        modalRef.result.then(
            (response: AssetActionResponse) => {
                if (!response) {
                    return;
                }

                this.router.navigate(['manage-assets', asset.id, response.versionId]).then();
            },
            () => undefined
        );
    }

    selectAsset(event: Event) {
        this.stopEventPropagation(event);
        this.itemSelectionService.addOrRemove(this.item.id);
    }

    private updateAsset(newAsset: AssetDto) {
        this.item = newAsset;
        setTimeout(() =>
            this.zone.run(() => (this.gridTemplateColumnsStyleForButtons = this.getGridTemplateColumnsStyleForButtons(this.hideButtonText)))
        );
    }

    private stopEventPropagation(event: Event) {
        event.preventDefault();
        event.stopPropagation();
    }
}

<div class="price-tile align-items-center justify-content-center"
     *transloco="let transloco; read: 'assetEditor.product.pricingForm'"
>
    <ng-container *ngIf="false; else temporaryPriceTag">
        <div class="d-flex flex-column">
            <div class="mb-1 body1">{{ transloco('cardTitle') }}</div>
            <div class="price">${{ price }} <span class="body1">/ {{ transloco('month') }}</span></div>
        </div>
        <div class="d-flex">
            {{ transloco('cardDescription') }}
            <span class="link-normal link">{{ transloco('viewDetails') }}</span>
        </div>
    </ng-container>
</div>

<ng-template #temporaryPriceTag>
    <div class="d-flex font-22px">{{ 'assetEditor.product.pricingForm.priceForUSTEmployees' | transloco }}</div>
</ng-template>

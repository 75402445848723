import { HttpClient } from '@angular/common/http';
import { Page } from '@dagility-ui/kit';
import { Observable } from 'rxjs';
import {
    AssetActionResponse,
    AssetCreationDto,
    AssetDto,
    AssetStatus,
    AssetType,
    AssetVersionSlimDto,
    RecommendedAssetDto,
} from '../../components/assets/asset.model';
import { ReviewDto, ReviewSlimDto } from '@app/shared/models/review.model';
import { map } from 'rxjs/operators';
import { Inject } from '@angular/core';
import { ENV_TOKEN } from '@app/tokens';

export type Id = number | string;

export interface PageFilter {
    pageSize: number;
    page: number;
    sort: string;
}

export interface AssetFilter {
    searchTerm: string;
    status?: AssetStatus;
    primaryCategoryId?: Id;
    categoryId?: Id;
    groupIds?: Id[];
    dropdownIds?: Id[];
    fieldValues?: Id[];
}

export interface AssetVersionDependency {
    versionId: number;
    version: string;
    name: string;
}

export interface DashboardAssetVersion {
    id: number;
    version: string;
    publishDate: string;
    primaryCategory: string;
    secondaryCategory: string;
}

export interface DashboardAsset {
    id: number;
    name: string;
    type: AssetType;
    currentVersion: DashboardAssetVersion;
}

export interface AssetOwnerInfo {
    name: string;
    email: string;
    requestExist: boolean;
}

export class AssetsService {
    private readonly baseUrl = this.env.assetsApiURL;

    constructor(@Inject(ENV_TOKEN) private readonly env: Env, private readonly http: HttpClient, private status: 'managed' | 'launched') {}

    get(
        { page, pageSize, sort }: PageFilter,
        { status, searchTerm, primaryCategoryId, categoryId, groupIds, dropdownIds, fieldValues }: AssetFilter
    ): Observable<Page<AssetDto>> {
        return this.http.post<Page<AssetDto>>(
            `${this.baseUrl}/${this.status}`,
            {
                status,
                searchTerm,
                primaryCategoryId,
                categoryId,
                ...(groupIds?.length ? { groupIds } : {}),
                ...(dropdownIds?.length ? { dropdownIds } : {}),
                ...(fieldValues?.length ? { fieldValues } : {}),
            },
            {
                params: {
                    size: pageSize,
                    page,
                    sort,
                },
            }
        );
    }

    getManagedAssetsCount(filter: AssetFilter): Observable<number> {
        return this.http.post<number>(`${this.baseUrl}/managed/count`, filter);
    }

    getLaunchedAssetsCount({ searchTerm, categoryId, groupIds, dropdownIds, fieldValues }: AssetFilter): Observable<number> {
        return this.http.post<number>(`${this.baseUrl}/launched/count`, {
            searchTerm,
            categoryId,
            ...(groupIds?.length ? { groupIds } : {}),
            ...(dropdownIds?.length ? { dropdownIds } : {}),
            ...(fieldValues?.length ? { fieldValues } : {}),
        });
    }

    getById(id: number, versionId: number): Observable<AssetDto> {
        return this.http.get<AssetDto>(`${this.baseUrl}/${id}/versions/${versionId}`);
    }

    setAssetAccessLevel(id: number, isPrivate: boolean) {
        return this.http.post(`${this.baseUrl}/${id}/access`, {}, { params: { isPrivate } });
    }

    getFormById(id: number, assetVersionId: number): Observable<AssetCreationDto> {
        return this.http.get<AssetCreationDto>(`${this.baseUrl}/${id}/versions/${assetVersionId}/form`);
    }

    upgradeVersion(id: number, version: string): Observable<AssetActionResponse> {
        return this.http.post<AssetActionResponse>(`${this.baseUrl}/${id}/upgrade-version`, {}, { params: { upgradedVersion: version } });
    }

    getAssetVersions(id: number): Observable<AssetVersionSlimDto[]> {
        return this.http.get<AssetVersionSlimDto[]>(`${this.baseUrl}/${id}/versions`);
    }

    deleteAssetVersion(id: number, versionId: number) {
        return this.http.delete(`${this.baseUrl}/${id}/versions/${versionId}`);
    }

    getReviews(id: number, versionId: number, filter: PageFilter): Observable<Page<ReviewSlimDto>> {
        return this.http.get<Page<ReviewSlimDto>>(`${this.baseUrl}/${id}/versions/${versionId}/review`, {
            params: {
                size: filter.pageSize,
                page: filter.page,
                sort: filter.sort,
            },
        });
    }

    createReview(id: number, versionId: number, review: ReviewDto) {
        return this.http.post(`${this.baseUrl}/${id}/versions/${versionId}/review`, review);
    }

    availableDependencies(assetType: AssetType, assetId?: number): Observable<AssetVersionDependency[]> {
        const params = assetId
            ? {
                  assetId,
                  assetType,
              }
            : { assetType };
        return this.http.get<AssetVersionDependency[]>(`${this.baseUrl}/dependencies`, { params });
    }

    dependencies(id: number, versionId: number, filter: PageFilter): Observable<Page<AssetDto>> {
        return this.http.get<Page<AssetDto>>(`${this.baseUrl}/${id}/versions/${versionId}/dependency`, {
            params: {
                size: filter.pageSize,
                page: filter.page,
            },
        });
    }

    getAssetOwnerInfo(id: number): Observable<AssetOwnerInfo> {
        return this.http.get<AssetOwnerInfo>(`${this.baseUrl}/${id}/owner`);
    }

    getDashboardAssets({ page, pageSize, sort }: PageFilter, searchTerm: string, groupId: number): Observable<Page<DashboardAsset>> {
        return this.http.post<Page<DashboardAsset>>(
            `${this.baseUrl}/dashboard-asset`,
            {},
            {
                params: {
                    size: pageSize,
                    page,
                    sort,
                    searchTerm,
                    groupId: groupId,
                },
            }
        );
    }

    getDashboardAssetVersions(assetId: number, { page, pageSize, sort }: PageFilter): Observable<Page<DashboardAssetVersion>> {
        return this.http.post<Page<DashboardAssetVersion>>(
            `${this.baseUrl}/${assetId}/versions/dashboard-version`,
            {},
            {
                params: {
                    size: pageSize,
                    page,
                    sort,
                },
            }
        );
    }

    getRecommendedAssets(): Observable<RecommendedAssetDto[]> {
        return this.sortRecommendedAssets(this.http.get<RecommendedAssetDto[]>(`${this.baseUrl}/recommended`));
    }

    createOrUpdateRecommendedAssets(recommendedAssets: RecommendedAssetDto[]): Observable<RecommendedAssetDto[]> {
        return this.sortRecommendedAssets(this.http.post<RecommendedAssetDto[]>(`${this.baseUrl}/recommended`, recommendedAssets));
    }

    private sortRecommendedAssets(recommendedAssets: Observable<RecommendedAssetDto[]>): Observable<RecommendedAssetDto[]> {
        return recommendedAssets.pipe(
            map(assets => {
                return assets.sort((a, b) => a.position - b.position);
            })
        );
    }
}

<ng-content></ng-content>
<span class="position-relative context-help-container" *ngIf="contextData" (click)="$event.stopPropagation()">
    &nbsp;<fa-icon
        class="align-baseline cursor-pointer fa color-brand font-14px"
        icon="QuestionInCircle"
        aria-hidden="true"
        (click)="showContextHelp()"
    ></fa-icon>

    <div *ngIf="showHelp"
         class="context-help-wrap d-flex flex-column rounded position-absolute"
         (document:click)="onDocumentClick($event.target)"
         (window:keydown.Escape)="onEscapeKey()">
        <div class="d-flex justify-content-between border-bottom pt-10px pb-10px px-3">
            <span class="font-16px font-weight-normal">{{ contextData?.title }}</span>
            <button
            type="button"
            class="close ml-auto"
            aria-label="Close button"
            aria-describedby="modal-title"
            (click)="dismiss()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="font-12px pt-3 pr-3 pl-3 pb-0 context-help-text">
            <div [innerHTML]="parseEmptyContent(contextData?.content) | safeHtml: 'html'" (click)="handleContentClick($event)"></div>
        </div>
    </div>
</span>

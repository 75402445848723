import { Component, Input, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImagePathPipeModule } from '../../pipes/image-path.pipe';

@Component({
    selector: 'lib-user-pic',
    templateUrl: './user-pic.component.html',
    styleUrls: ['./user-pic.component.scss'],
    host: { class: 'lib-user-pic' }
})
export class UserPicComponent {
    @Input() enabled: boolean = true;
    @Input() circle: boolean = true;
    @Input() size: number = 24;
    @Input() user: any;

    get initials(): string {
        const user = this.user;
        if (!user) {
            return '';
        }
        if (user.name) {
            let matched = user.name.match(/\b\w/g);
            return matched ? matched.slice(0, 2).join('').toUpperCase() : user.name.substr(0, 1).toUpperCase();
        } else if (user.firstName) {
            return (user.firstName.substr(0, 1) +
                (user.lastName ? user.lastName.substr(0, 1) : '')).toUpperCase();
        } else {
            return '';
        }
    }

    get imagePath(): string {
        if (!this.user) {
            return null;
        }
        if (this.user.imagePath) {
            return this.user.imagePath;
        } else if (this.user.image) {
            return this.user.image;
        } else if (this.user.imageId) {
            return this.user.imageId;
        }
        return null;
    }
}

@NgModule({
    imports: [CommonModule, ImagePathPipeModule],
    declarations: [UserPicComponent],
    exports: [UserPicComponent]
})
export class UserPicModule {}

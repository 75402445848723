<div class="app-main-header" *transloco="let t; read: 'header'">
    <nav class="navbar navbar-expand-lg d-flex flex-row align-items-center w-100 h-100 p-0">
        <div
            class="d-flex flex-row align-items-center mr-auto overflow-x-auto flex-1"
            [class.flex-row-reverse]="collapsed"
            [style.min-width.px]="360"
            (libResizeObserver)="checkCollapseState()"
            #topLeftEl
        >
            <div class="app-header-logo" [class.mr-auto]="collapsed">
                <a class="navbar-brand line-height-40px p-0 m-0" routerLink="/">
                    <img [alt]="applicationName" [height]="20"
                         [src]="'assets/logos/SolutionHubLogo-' + (isDarkTheme ? 'Dark' : 'Light') + '.svg?q'"/>
                </a>
            </div>
            <app-header-navigation
                [class.mr-4]="collapsed"
                [navItems$]="headerService.menuItems$"
                [collapsed$]="collapsed$"
            ></app-header-navigation>
        </div>
        <div class="d-flex flex-row ml-auto">
            <a class="app-header-link" *ngIf="false">
                <fa-icon class="font-16px mr-5px color-brand" [icon]="icons.help"></fa-icon>
                {{ t('help') }}
            </a>
            <ng-container *ngIf="{ user: authService.user$ | async } as vm">
                <div *ngIf="vm.user === null" class="d-flex flex-row size-m align-items-center ml-4">
                    <a type="button" class="btn btn-primary font-14px text-nowrap" (click)="register()">
                        {{ t('register') }}
                    </a>
                    <span style="margin: 0 8px;">{{ t('or') }}</span>
                    <a type="button" class="btn btn-primary size-m font-14px text-nowrap" (click)="login()">
                        <fa-icon class="mr-8px font-16px" [icon]="icons.logIn"></fa-icon>
                        {{ t('signIn') }}
                    </a>
                </div>
                <div *ngIf="vm.user" class="d-flex flex-row align-items-center ml-4">
                    <lib-user-pic
                        class="mr-8px"
                        [user]="{
                            firstName: vm.user.firstName,
                            lastName: vm.user.lastName,
                            imagePath: vm.user | avatarUrl
                        }"
                        [size]="32"
                    ></lib-user-pic>
                    <app-user-role-badge *ngIf="authService.isAdmin()" [user]="vm.user"></app-user-role-badge>
                    <a type="button" class="btn btn-primary size-m font-14px ml-4 text-nowrap" (click)="logout()">
                        <fa-icon class="mr-5px font-16px" [icon]="icons.logOut"></fa-icon>
                        {{ t('signOut') }}
                    </a>
                </div>
            </ng-container>
        </div>
    </nav>
</div>

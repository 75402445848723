import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { SidenavItems } from '@dagility-ui/kit/modules/sidenav/types/sidenav-item';
import { AuthService } from '@app/auth';
import {
    isAdminRoleMostValuable,
    isGroupAdminRoleMostValuable,
    isProducerRoleMostValuable,
    ROLE_ADMIN,
    ROLE_PRODUCER,
    ROLE_USER_GROUP_ADMIN,
    UserDto,
} from '@app/core/models/user.model';
import {
    barChartIcon,
    bellIcon,
    groupsIcon,
    screenIcon,
    settingsIcon,
    suitcaseIcon,
    userIcon,
    usersIcon,
} from '@app/shared/icons/solutionhub-icons';
import { TranslationService } from '@app/core/services/translation.service';
import { AssetRequestService } from '@app/shared/services/api/asset-request.service';
import { map } from 'rxjs/operators';

const DEFAULT_SIDENAV_ITEMS: any = [
    {
        id: 'assets-tab',
        routerLink: '/assets',
        icon: [suitcaseIcon, [null, null]],
        translationKey: 'assets',
    },
    {
        id: 'profile-tab',
        routerLink: `profile`,
        icon: [userIcon, [null, null]],
        translationKey: 'profile',
    },
];

const MANAGE_ASSETS_SIDENAV_ITEM: any = {
    id: 'manage-assets-tab',
    routerLink: '/manage-assets',
    icon: [settingsIcon, [null, null]],
    translationKey: 'manageAssets',
};

const DASHBOARD_SIDENAV_ITEM: any = {
    id: 'dashboard-tab',
    routerLink: '/dashboard',
    icon: [barChartIcon, [null, null]],
    translationKey: 'dashboard',
};

const USER_MANAGEMENT_SIDENAV_ITEM: any = {
    id: 'user-management-tab',
    routerLink: '/users',
    icon: [usersIcon, [null, null]],
    translationKey: 'users',
};

const GROUP_MANAGEMENT_SIDENAV_ITEM: any = {
    id: 'group-management-tab',
    routerLink: '/groups',
    icon: [groupsIcon, [null, null]],
    translationKey: 'groups',
};

const LANDING_PAGE_MANAGEMENT_SIDENAV_ITEM: any = {
    id: 'landing-page-management-tab',
    routerLink: '/manage-landing-page',
    icon: [screenIcon, [null, null]],
    translationKey: 'manageLandingPage',
};

const MESSAGES_SIDENAV_ITEM: any = {
    id: 'messages-tab',
    routerLink: '/messages',
    icon: [bellIcon, [null, null]],
    data: {
        count: 0,
    },
    translationKey: 'messages',
};

@Injectable({
    providedIn: 'root',
})
export class SidenavService {
    readonly sidenavItems$: Observable<SidenavItems>;
    readonly notificationsCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    constructor(
        private readonly authService: AuthService,
        private readonly assetRequestService: AssetRequestService,
        private readonly translationService: TranslationService
    ) {
        this.sidenavItems$ = combineLatest([
            this.authService.onAfterLogin$,
            this.translationService.translateObject('sidenav'),
            this.notificationsCount$,
        ]).pipe(
            map(([user, translation, notificationCount]) => {
                const sidenavItems = this.createSideNavItems(user);
                sidenavItems.forEach(sidenavItem => {
                    sidenavItem.title = translation[sidenavItem.translationKey];
                    if (sidenavItem.id === 'messages-tab') {
                        sidenavItem.data = { count: notificationCount };
                    }
                });
                return sidenavItems;
            })
        );

        if (this.authService.hasAnyRole([ROLE_PRODUCER, ROLE_USER_GROUP_ADMIN, ROLE_ADMIN])) {
            this.assetRequestService
                .getActiveRequestsCount()
                .subscribe(notificationCount => this.notificationsCount$.next(notificationCount));
        }
    }

    private createSideNavItems(user: UserDto) {
        if (!user) {
            return [];
        }

        const sidenavItems = [...DEFAULT_SIDENAV_ITEMS];

        const isProducer = isProducerRoleMostValuable(user.roles);
        const isGroupAdmin = isGroupAdminRoleMostValuable(user.roles);
        const isAdmin = isAdminRoleMostValuable(user.roles);

        if (isProducer || isGroupAdmin || isAdmin) {
            sidenavItems.splice(1, 0, DASHBOARD_SIDENAV_ITEM, MANAGE_ASSETS_SIDENAV_ITEM);
            sidenavItems.splice(3, 0, MESSAGES_SIDENAV_ITEM);
        }

        if (isGroupAdmin) {
            sidenavItems.splice(3, 0, GROUP_MANAGEMENT_SIDENAV_ITEM);
        }

        if (isAdmin) {
            sidenavItems.splice(3, 0, USER_MANAGEMENT_SIDENAV_ITEM, GROUP_MANAGEMENT_SIDENAV_ITEM, LANDING_PAGE_MANAGEMENT_SIDENAV_ITEM);
        }

        return sidenavItems;
    }
}

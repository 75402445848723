import { EChartsChart } from '../echarts.chart';
import { Subject } from 'rxjs';

import { qualitativePalette, subTextColor, textColor } from '../charts-colors';

export class MultipleYAxisEChartsChart extends EChartsChart {
    colors = qualitativePalette;
    tooltipdata: any;
    options = {
        color: this.colors,
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
            },
        } as any,
        title: {
            text: '',
            subtext: '',
            textAlign: 'center',
            textVerticalAlign: 'top',
            left: '50%',
            textStyle: {
                color: textColor(),
                fontSize: 14,
                fontWeight: '500',
                fontFamily: 'sans-serif',
            },
            subtextStyle: {
                color: subTextColor(),
                fontSize: 12,
                fontFamily: 'sans-serif',
            },
        },
        grid: {
            right: '20%',
        },
        legend: {
            bottom: '7',
            icon: 'circle',
            textStyle: {
                fontSize: 11,
                color: textColor(),
            },
            selector: ['all', 'inverse'],
            selectorPosition: 'start',
            data: [] as any[],
        },
        xAxis: [
            {
                type: 'category',
                name: '',
                nameLocation: 'center',
                nameGap: 20,
                nameTextStyle: {
                    color: textColor(),
                },
                axisTick: {
                    alignWithLabel: true,
                },
                data: [] as any[],
                axisLabel: {
                    textStyle: {
                        color: textColor(),
                    },
                },
            },
        ],
        yAxis: [] as any[],
        series: [] as any[],
    };

    assignOptions(options: Record<string, any>) {
        this.options.series = options.series;
        this.options.xAxis[0].data = options.categories;
        this.options.xAxis[0].name = options.options.xaxis[0].name;
        this.tooltipdata = options.options.toolTipData;
        if (options.colors) {
            this.options.color = options.colors;
            this.colors = options.colors;
        }

        if (options.options) {
            this.options.legend.data = options.options.legend.data;
            this.options.yAxis = options.options.yaxis;
            this.options.yAxis.forEach((obj, index) => {
                obj.axisLabel = {
                    textStyle: {
                        color: textColor(),
                    },
                };
                obj.axisLine = {
                    lineStyle: {
                        color: this.colors[index],
                    },
                };
                obj.splitLine = {
                    show: false,
                };
                if (index === 1) {
                    obj.offset = 65;
                }

                obj.nameLocation = 'center';
                obj.nameGap = 35;
                obj.nameTextStyle = {
                    color: textColor(),
                };
            });

            if (options.options.toolTipData) {
                this.options.tooltip['formatter'] = this.tooltipConfiguration.bind(this);
            }

            if (options.tooltipFormatter) {
                (this.options as any).tooltipFormatter = options.tooltipFormatter;
                (this.options as any).anyWidget = true;
            }
        }
        super.assignOptions(this.options);
    }

    private tooltipConfiguration(params: any) {
        const item = params[0].axisValue;
        const tooltipData = this.tooltipdata[params[0].name];
        let output = '<div style="width:230px;">';
        if (item.length > 0 && item.match(/>/)) {
            const labelPart = item.substring(item.match(/>/).index + 1, item.length);
            const label = labelPart.substring(0, labelPart.match(/</).index);
            output += label + '<br/>';
        } else {
            output += item + '<br/>';
        }

        params.forEach((param: any) => {
            output +=
                '<label style="border-radius: 50%;width: 10px;height: 10px;vertical-align: middle;margin: 0;background:' +
                param.color +
                '"></label>&nbsp;&nbsp;' +
                param.seriesName +
                ': ';
            output += '<b>' + param.value + '</b><br/>';
        });

        let colorIndex = 3;
        tooltipData.forEach((element: any, index: number) => {
            colorIndex = colorIndex + index;
            output +=
                '<div style="white-space:normal;overflow:hidden;text-overflow:ellipsis;" > <label style="border-radius: 50%;width: 10px;height: 10px;vertical-align: middle;margin: 0;background:' +
                this.colors[colorIndex] +
                '"></label>&nbsp;&nbsp;' +
                element.name +
                ': ';
            output += '<b>' + element.value + '</b> </div> <br/>';
        });
        output += '</div>';
        return output;
    }

    handleEvents(emitter: Subject<{ type: string; payload: any }>, chartInstance: any) {
        if (!chartInstance) {
            return;
        }

        chartInstance.on('click', 'series', (event: any) => {
            emitter.next({
                type: 'barClicked',
                payload: {
                    barName: event.name,
                },
            });
        });
    }
}

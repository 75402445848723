<lib-stream-wrapper [dataStream$]="users$">
    <ng-template contentTemplate let-users="data">
        <lib-data-grid
            *ngIf="users?.length > 0; else noDataTmpl"
            class="custom-table"
            [translatedText]="paginationTranslatedText"
            [dataSource]="users"
            [columns]="gridColumns"
            [pagination]="pagination"
            [pageable]="pageable"
            [pageSizeContainer]="'body'"
            [sticky]="false"
            (pageChange)="onPageChange($event)"
            (sort)="sortClick($event)"
        >
            <ng-template headerTemplate>
                <tr>
                    <th *ngIf="hasCurrentUserInTable(users)"
                        id="me"
                        [style.width.px]="50"
                    ></th>
                    <ng-container *ngFor="let c of gridColumns; let i = index">
                        <th *ngIf="!(mode === 'group-management' && i === 8 && !canAddUser && !canRemoveUser)"
                            [id]="c.field"
                            [ngClass]="{ 'sort-field': c.sortingField }"
                            [style.width]="c.width"
                            (click)="sortClick(c.sortingField)"
                        >
                            <span class="column-header">
                                <div [class.cursor-pointer]="c.sortingField"
                                     [class.cursor-default]="!c.sortingField"
                                     [class.blue]="pageable && pageable.sorted(c.sortingField)"
                                >
                                    {{ c.title }}
                                </div>
                                <span
                                    *ngIf="c.sortingField"
                                    [class.sort-off-icon]="!pageable || !pageable.sorted(c.sortingField)"
                                    class="sorting-button"
                                    [innerHTML]="(!pageable || pageable.sorted(c.sortingField) === 'DESC' ? icons.sortingUp : icons.sortingDown) | safeHtml: 'html'"
                                >
                                </span>
                            </span>
                        </th>
                    </ng-container>
                </tr>
            </ng-template>
            <ng-template rowTemplate let-user="item">
                <ng-container *ngIf="{disabled: mode === 'select-users' && defaultGroup?.id != user.groupId} as styles">
                    <td [style.width.px]="50"
                        *ngIf="hasCurrentUserInTable(users)"
                        [class.disabled]="styles.disabled"
                    >
                        <div *ngIf="user.id == currentUser.id" class="text-nowrap">
                            <fa-icon [icon]="icons.userIcon" class="fa color-brand font-16px mr-8px"></fa-icon>
                            <span class="color-brand font-14px">{{ ('usersPage.me' | transloco) | uppercase }}</span>
                        </div>
                    </td>
                    <ng-container *ngIf="mode === 'select-users'">
                        <td [style.width]="gridColumns[0].width" [class.disabled]="styles.disabled">
                            <div class="text-truncate"
                                 [ngbTooltip]="isUserAdded(user)? targetGroup.name : user.groupName"
                                 container="body"
                                 triggers="manual"
                                 libTooltipWhenOverflow
                            >
                                {{ isUserAdded(user)? targetGroup.name : user.groupName }}
                            </div>
                        </td>
                    </ng-container>
                    <ng-container *ngIf="{ val: (mode === 'select-users' ? 1 : 0) } as columnIndexOffset">
                        <td [style.width]="gridColumns[0 + columnIndexOffset.val].width"
                            class="user-pic"
                            [class.disabled]="styles.disabled"
                        >
                            <lib-user-pic
                                [user]="{
                                    firstName: user.firstName,
                                    lastName: user.lastName,
                                    imagePath: user | avatarUrl: 'SMALL'
                                }"
                                [size]="24"
                            ></lib-user-pic>
                        </td>
                        <td [style.width]="gridColumns[1 + columnIndexOffset.val].width"
                            [class.disabled]="styles.disabled"
                        >
                            <div class="text-truncate"
                                 [ngbTooltip]="user.firstName"
                                 container="body"
                                 triggers="manual"
                                 libTooltipWhenOverflow
                            >
                                {{ user.firstName }}
                            </div>
                        </td>
                        <td [style.width]="gridColumns[2 + columnIndexOffset.val].width"
                            [class.disabled]="styles.disabled"
                        >
                            <div class="text-truncate"
                                 [ngbTooltip]="user.lastName"
                                 container="body"
                                 triggers="manual"
                                 libTooltipWhenOverflow
                            >
                                {{ user.lastName }}
                            </div>
                        </td>
                    </ng-container>
                    <ng-container *ngIf="mode !== 'select-users'">
                        <td [style.width]="gridColumns[3].width" [class.disabled]="styles.disabled">
                            <div class="text-truncate"
                                 [ngbTooltip]="user.login"
                                 container="body"
                                 triggers="manual"
                                 libTooltipWhenOverflow
                            >
                                {{ user.login }}
                            </div>
                        </td>
                        <td [style.width]="gridColumns[4].width" [class.disabled]="styles.disabled">
                            <div class="text-truncate"
                                 [ngbTooltip]="user.groupName"
                                 container="body"
                                 triggers="manual"
                                 libTooltipWhenOverflow
                            >
                                {{ user.groupName }}
                            </div>
                        </td>
                        <td [style.width]="gridColumns[5].width" [class.disabled]="styles.disabled">
                            <div class="text-truncate"
                                 [ngbTooltip]="user.email"
                                 container="body"
                                 triggers="manual"
                                 libTooltipWhenOverflow
                            >
                                {{ user.email }}
                            </div>
                        </td>
                        <td [style.width]="gridColumns[6].width" [class.disabled]="styles.disabled">
                            <app-date-badge [date]="user.createdAt"></app-date-badge>
                        </td>
                    </ng-container>
                    <ng-container [ngSwitch]="mode">
                        <ng-container *ngSwitchCase="'user-management'">
                            <td *ngFor="let column of gridColumns.slice(7, gridColumns.length - 1)"
                                [style.width]="column.width"
                                [class.disabled]="styles.disabled"
                            >
                                <checkbox
                                    [blueCheck]="true"
                                    [preventDefault]="true"
                                    [disabled]="user.id == currentUser.id"
                                    [value]="
                                    userRoleChanges[user.id]
                                        ? userRoleChanges[user.id].includes($any(column.field))
                                        : user.roles.includes($any(column.field))
                                "
                                    (change)="updateUserRole(user, $any(column.field))"
                                >
                                </checkbox>
                            </td>
                            <td [style.width]="gridColumns[gridColumns.length - 1].width"
                                [class.disabled]="styles.disabled"
                            >
                                <a [routerLink]="user.id"
                                   [ngbTooltip]="'usersPage.edit' | transloco"
                                   placement="top"
                                   container="body"
                                >
                                    <fa-icon icon="facEdit" class="font-14px"></fa-icon>
                                </a>
                            </td>
                        </ng-container>
                        <ng-container *ngSwitchCase="'group-management'">
                            <td [style.width]="gridColumns[6].width" [class.disabled]="styles.disabled">
                                <div class="text-truncate"
                                     [ngbTooltip]="rolesAsString(user.roles)"
                                     container="body"
                                     triggers="manual"
                                     libTooltipWhenOverflow
                                >
                                    {{ rolesAsString(user.roles) }}
                                </div>
                            </td>
                            <td [style.width]="gridColumns[7].width"
                                *ngIf="canAddUser || canRemoveUser"
                                [class.disabled]="styles.disabled"
                            >
                                <ng-container *ngIf="isAdmin || currentUser.id != user.id">
                                    <button class="btn btn-link"
                                            *ngIf="canAddUser"
                                            (click)="addUserEmit(user)"
                                    >
                                        <fa-icon icon="plus" class="fa font-16px mr-10px"></fa-icon>
                                        {{ 'groupsPage.addUser' | transloco }}
                                    </button>
                                    <button class="btn btn-link"
                                            *ngIf="canRemoveUser"
                                            (click)="removeUserEmit(user)"
                                    >
                                        <fa-icon [icon]="icons.minus" class="fa font-16px mr-10px"></fa-icon>
                                        {{ 'groupsPage.removeUser' | transloco }}
                                    </button>
                                </ng-container>
                            </td>
                        </ng-container>
                        <ng-container *ngSwitchCase="'select-users'">
                            <td [style.width]="gridColumns[4].width" [class.disabled]="styles.disabled">
                                <div class="text-truncate"
                                     [ngbTooltip]="rolesAsString(user.roles)"
                                     container="body"
                                     triggers="manual"
                                     libTooltipWhenOverflow
                                >
                                    {{ rolesAsString(user.roles) }}
                                </div>
                            </td>
                            <td [style.width]="gridColumns[5].width" [class.disabled]="styles.disabled">
                                <span *ngIf="isUserAdded(user)"
                                      class="custom-badge custom-badge-background text-white added-badge"
                                >
                                    <fa-icon style="margin-right: 4px; font-size: 8px" [icon]="icons.checkAllIcon"
                                    ></fa-icon>
                                    <span class="font-12px">{{ 'groupsPage.added' | transloco }}</span>
                                </span>
                            </td>
                            <td [style.width]="gridColumns[6].width"
                                *ngIf="canAddUser || canRemoveUser"
                                [class.disabled]="styles.disabled"
                            >
                                <ng-container
                                    *ngIf="(isAdmin || currentUser.id != user.id) && defaultGroup?.id == user.groupId"
                                >
                                    <button class="btn btn-link"
                                            *ngIf="canAddUser && !isUserAdded(user)"
                                            (click)="addUserEmit(user)"
                                    >
                                        <fa-icon icon="plus" class="fa font-16px mr-10px"></fa-icon>
                                        {{ 'groupsPage.addUser' | transloco }}
                                    </button>
                                    <button class="btn btn-link"
                                            *ngIf="canRemoveUser && isUserAdded(user)"
                                            (click)="removeUserEmit(user)"
                                    >
                                        <fa-icon [icon]="icons.minus" class="fa font-16px mr-10px"></fa-icon>
                                        {{ 'groupsPage.removeUser' | transloco }}
                                    </button>
                                </ng-container>
                            </td>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ng-template>
        </lib-data-grid>
    </ng-template>
</lib-stream-wrapper>

<ng-template #noDataTmpl>
    <app-no-data-available></app-no-data-available>
</ng-template>

import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { DictionaryItem, DictionaryService } from '@app/shared/services/api/dictionary.service';
import { TranslationKeysOfTextarea } from '@dagility-ui/kit';
import { TranslationService } from '@app/core/services/translation.service';

export interface RejectReason {
    resolutionId: number;
    comment: string;
}

@Component({
    selector: 'app-reject-reason-modal',
    templateUrl: './reject-reason-modal.component.html',
})
export class RejectReasonModalComponent implements OnInit {
    readonly resolutions$: Observable<DictionaryItem[]> = this.dictionaryService.getRejectReasons();

    form: FormGroup;

    get textareaTranslatedText(): Partial<Record<TranslationKeysOfTextarea, string>> {
        return this.translationService.textareaTranslatedText;
    }

    constructor(
        private readonly dictionaryService: DictionaryService,
        public activeModal: NgbActiveModal,
        private readonly fb: FormBuilder,
        private readonly translationService: TranslationService
    ) {}

    ngOnInit() {
        this.form = this.fb.group({
            resolutionId: [null, Validators.required],
            comment: [''],
        });
    }

    reject() {
        if (this.form.invalid) {
            return;
        }
        const formValue = this.form.getRawValue();
        const rejectReason: RejectReason = {
            resolutionId: formValue.resolutionId,
            comment: formValue.comment,
        };

        this.activeModal.close(rejectReason);
    }
}

import { Injectable } from '@angular/core';
import { NavItem } from '@app/core/components/header/components/header-navigation/header-navigation.component';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Category } from '@app/shared/models/category.model';
import { CategoryService } from '@app/shared/services/api/category.service';
import { DropdownItem } from '@app/core/components/header/components/header-navigation/header-navigation-dropdown/header-navigation-dropdown.component';
import { TranslationService } from '@app/core/services/translation.service';
import { MetadataService } from '@app/shared/services/api/metadata.service';
import { MetadataFieldWithValuesDto } from '@app/shared/models/metadata.model';
import { SystemDictionaryItem } from '@app/shared/services/api/dictionary.service';

@Injectable({
    providedIn: 'root',
})
export class HeaderService {
    staticMenuItems$: Observable<NavItem[]>;

    categoriesMenuItems$: Observable<NavItem[]>;

    taxonomiesMenuItems$: Observable<NavItem[]>;

    menuItems$: Observable<NavItem[]>;

    moduleList: NavItem[];

    readonly menuInitialized$ = new Subject();

    constructor(
        private readonly categoryService: CategoryService,
        private readonly metadataService: MetadataService,
        private readonly translationService: TranslationService
    ) {
        this.translationService.translateObject('staticNavItems').subscribe(staticNavItems => {
            this.staticMenuItems$ = of<NavItem[]>([
                // {
                //     title: staticNavItems.community.title,
                //     label: staticNavItems.community.title,
                //     link: '/community',
                //     position: 5,
                //     children: [
                //         {
                //             label: staticNavItems.community.technicalDocumentation,
                //             link: '/community/technical-documentation',
                //             parameters: { queryParams: {} },
                //             children: null,
                //         },
                //         {
                //             label: staticNavItems.community.useCases,
                //             link: '/community/use-cases',
                //             parameters: { queryParams: {} },
                //             children: null,
                //         },
                //         {
                //             label: staticNavItems.community.backlogs,
                //             link: '/community/backlogs',
                //             parameters: { queryParams: {} },
                //             children: null,
                //         },
                //         {
                //             label: staticNavItems.community.discussionBoards,
                //             link: '/community/discussion-boards',
                //             parameters: { queryParams: {} },
                //             children: null,
                //         },
                //     ],
                // },
                // {
                //     title: staticNavItems.about.title,
                //     label: staticNavItems.about.title,
                //     link: '/about',
                //     position: 6,
                //     children: [
                //         {
                //             label: staticNavItems.about.marketplace,
                //             link: '/about/marketplace',
                //             parameters: { queryParams: {} },
                //             children: null,
                //         },
                //         {
                //             label: staticNavItems.about.howToSell,
                //             link: '/about/how-to-sell',
                //             parameters: { queryParams: {} },
                //             children: null,
                //         },
                //         {
                //             label: staticNavItems.about.costManagement,
                //             link: '/about/cost-management',
                //             parameters: { queryParams: {} },
                //             children: null,
                //         },
                //     ],
                // },
            ]);

            this.categoriesMenuItems$ = this.categoryService.categories$.pipe(categories => this.getCategoriesNavItem(categories));
            this.taxonomiesMenuItems$ = this.metadataService.metadata$.pipe(taxonomies => this.getTaxonomiesNavItems(taxonomies));

            this.menuItems$ = combineLatest([this.staticMenuItems$, this.categoriesMenuItems$, this.taxonomiesMenuItems$]).pipe(
                map(([staticItems, categories, taxonomies]) => [...categories, ...taxonomies, ...staticItems]),
                tap(items => {
                    this.moduleList = items;
                    this.menuInitialized$.next();
                })
            );
        });
    }

    private getCategoriesNavItem(categories$: Observable<Category[]>, startIndex = 1): Observable<NavItem[]> {
        return categories$.pipe(
            map(categories =>
                categories.map((category, index) => ({
                    title: category.name,
                    label: category.name,
                    link: '/assets',
                    position: startIndex + index,
                    children: this.categoryMapper('/assets', category.children),
                }))
            )
        );
    }

    private categoryMapper(parentLink: string, categories: Category[]): DropdownItem[] {
        return Array.from(
            categories.map(category => {
                const hasChildren = category.children?.length > 0;
                return {
                    label: category.name,
                    link: parentLink,
                    parameters: !hasChildren
                        ? {
                              queryParams: {
                                  primaryCategoryId: null,
                                  categoryId: category.id,
                                  dropdownIds: [],
                                  groupIds: [],
                                  fieldValues: [],
                              },
                              queryParamsHandling: 'merge',
                          }
                        : null,
                    children: hasChildren ? this.categoryMapper(parentLink, category.children) : null,
                };
            })
        );
    }

    private getTaxonomiesNavItems(metadata$: Observable<MetadataFieldWithValuesDto[]>, startIndex = 3): Observable<NavItem[]> {
        return metadata$.pipe(
            map(metadata =>
                metadata
                    .filter(m => m.showInHeader)
                    .map((m, index) => ({
                        title: m.label,
                        label: m.label,
                        link: '/assets',
                        position: startIndex + index,
                        children: this.taxonomyMapper(m.dropdownValues),
                    }))
            )
        );
    }

    private taxonomyMapper(metadata: SystemDictionaryItem[], parentLink = '/assets'): DropdownItem[] {
        return Array.from(
            metadata.map(m => ({
                label: m.label,
                link: parentLink,
                parameters: {
                    queryParams: {
                        dropdownIds: m.id,
                        primaryCategoryId: null,
                        categoryId: null,
                        groupIds: [],
                        fieldValues: [],
                    },
                    queryParamsHandling: 'merge',
                },
                children: [],
            }))
        );
    }
}

<lib-card *transloco="let transloco; read: 'assets.listItem'"
          class="asset-card" [id]="'asset-item-' + item.id + '-' + item.assetVersionId"
          [style.min-width.px]="isGridView ? 480 : 650"
          (click)="goToAsset()"
>
    <div *ngIf="item.private" class="asset-access-badge access-badge-private">
        {{ 'assets.card.accessModifier.private' | transloco }}
    </div>
    <div class="d-flex justify-content-between" [class.asset-list-item]="isGridView">
        <app-asset-card class="d-flex text-truncate"
                        [class.asset-card-in-management]="isManageAssets"
                        [id]="'asset-card-' + item.id + '-' + item.assetVersionId"
                        [asset]="item"
                        [showStatus]="showStatus"
                        [isGridView]="isGridView"
        ></app-asset-card>
        <div class="align-items-center"
             [id]="'asset-actions-' + item.id + '-' + item.assetVersionId"
             [ngClass]="isGridView ? 'grid-view-buttons asset-list-item-buttons' : 'list-view-buttons d-flex flex-row'"
             [ngStyle]="gridTemplateColumnsStyleForButtons ? {'grid-template-columns': gridTemplateColumnsStyleForButtons} : {}"
             [class.mt-16px]="isLoggedIn && hasActionButtons && isGridView"
             [class.hide-button-text]="hideButtonText"
        >
            <ng-container *ngIf="isLoggedIn && hasActionButtons">
                <ng-container *ngIf="hasManagementActions">
                    <button
                        *ngIf="assetPermissions.canUpgradeVersion(item)"
                        class="btn btn-warning action-button upgrade-version-button"
                        [class.size-s]="isGridView"
                        [disabled]="processing"
                        (click)="handleUpgradeVersion(item, $event)"
                        [ngbTooltip]="hideButtonText ? transloco('upgradeVersion') : null"
                        container="body"
                    >
                        <fa-icon icon="cog" class="fa button-icon"></fa-icon>
                        <div class="button-text">{{ transloco('upgradeVersion') }}</div>
                    </button>

                    <button
                        *ngIf="assetPermissions.canEdit(item) || assetPermissions.canEditLaunched(item)"
                        class="btn btn-success action-button"
                        [class.size-s]="isGridView"
                        [disabled]="processing"
                        (click)="handleEdit(item, $event)"
                        [ngbTooltip]="hideButtonText ? transloco('editBtn') : null"
                        container="body"
                    >
                        <fa-icon icon="facEdit" class="fa button-icon"></fa-icon>
                        <div class="button-text">{{ transloco('editBtn') }}</div>
                        <fa-icon icon="facInfoCircle"
                                 class="fa button-icon mr-0 ml-7px"
                                 *ngIf="assetPermissions.canEditLaunched(item)"
                                 [ngbTooltip]="transloco('editBtnInfo')"
                                 container="body"
                                 placement="top"
                        ></fa-icon>
                    </button>

                    <button *ngIf="assetPermissions.canApprove(item)"
                            class="btn btn-success action-button"
                            [class.size-s]="isGridView"
                            [disabled]="processing"
                            (click)="handleApprove(item, $event)"
                            [ngbTooltip]="hideButtonText ? transloco('approveBtn') : null"
                            container="body"
                    >
                        <fa-icon icon="facBuildSuccess" class="fa button-icon"></fa-icon>
                        <div class="button-text">{{ transloco('approveBtn') }}</div>
                    </button>

                    <button *ngIf="assetPermissions.canReject(item)"
                            class="btn btn-danger action-button"
                            [class.size-s]="isGridView"
                            [disabled]="processing"
                            (click)="handleReject(item, $event)"
                            [ngbTooltip]="hideButtonText ? transloco('rejectBtn') : null"
                            container="body"
                    >
                        <fa-icon icon="facBuildFailure" class="fa button-icon"></fa-icon>
                        <div class="button-text">{{ transloco('rejectBtn') }}</div>
                    </button>

                    <ng-container *ngIf="assetPermissions.canLaunch(item)">
                        <button class="btn btn-success action-button"
                                [class.size-s]="isGridView"
                                [disabled]="processing"
                                (click)="handleLaunch(item, $event)"
                                [ngbTooltip]="hideButtonText ? transloco('launchBtn') : null"
                                container="body"
                        >
                            <fa-icon icon="resumePlay" class="fa button-icon"></fa-icon>
                            <div class="button-text">{{ transloco('launchBtn') }}</div>
                        </button>
                        <button class="btn btn-danger action-button"
                                [class.size-s]="isGridView"
                                [disabled]="processing"
                                (click)="handleCancel(item, $event)"
                                [ngbTooltip]="hideButtonText ? transloco('cancelBtn') : null"
                                container="body"
                        >
                            <fa-icon icon="facStop" class="fa button-icon"></fa-icon>
                            <div class="button-text">{{ transloco('cancelBtn') }}</div>
                        </button>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="!readOnly">
                    <ng-container
                        *ngIf="assetPermissions.canDownload(item); else requestAccessBtn"
                    >
                        <button *ngIf="item.downloadable?.length"
                                class="btn btn-warning asset-download-btn action-button"
                                [class.size-s]="isGridView"
                                (click)="handleDownload(item, $event)"
                                [ngbTooltip]="hideButtonText ? transloco('download') : null"
                                container="body"
                        >
                            <fa-icon icon="facDownload" class="fa button-icon"></fa-icon>
                            <div class="button-text">{{ transloco('download') }}</div>
                        </button>
                        <button *ngIf="item.repoUrl"
                                class="btn btn-primary asset-github-link action-button"
                                [class.size-s]="isGridView"
                                (click)="handleGoToLink(item, $event)"
                                [ngbTooltip]="hideButtonText ? transloco('link') : null"
                                container="body"
                        >
                            <fa-icon icon="facExternalLink" class="fa button-icon"></fa-icon>
                            <div class="button-text">{{ transloco('link') }}</div>
                        </button>
                    </ng-container>
                    <ng-template #requestAccessBtn>
                        <button class="btn btn-primary action-button"
                                [class.size-s]="isGridView"
                                (click)="handleRequestAccess(item, $event)"
                                [ngbTooltip]="hideButtonText ? transloco('requestAccess') : null"
                                container="body"
                        >
                            <fa-icon [icon]="icons.keyIcon" class="fa button-icon"></fa-icon>
                            <div class="button-text">{{ transloco('requestAccess') }}</div>
                        </button>
                    </ng-template>
                </ng-container>
            </ng-container>
        </div>
    </div>
    <div *ngIf="canSelectAsset && !item.private"
         [ngClass]="position ? 'asset-selector--selected' : 'asset-selector'"
         (click)="selectAsset($event)"
    >
        <span *ngIf="position; else plusIcon">{{ position }}</span>
        <ng-template #plusIcon>
            <fa-icon icon="plus" class="color-brand font-24px"></fa-icon>
        </ng-template>
    </div>
</lib-card>

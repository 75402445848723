<span *ngIf="label" class="label text-truncate" [class.disabled]="readonly">
    {{ label }}
    <span *ngIf="isRequired" class="mandatory">*</span>
    <ng-content></ng-content>
</span>
<div class="position-relative asset-version-input-content">
    <input
        #input
        #controlContainer
        class="main-asset-version-input"
        [value]="value"
        [class.disabled]="readonly"
        [class.invalid]="hasErrors"
        [class.warning]="warning"
        [class.clearable]="clearable"
        [class.focus]="focus"
        [class.hover]="hover"
        [readOnly]="true"
        type="text"
        [attr.data-test-id]="id"
    />
    <div class="asset-version-parts-wrapper"
         (mouseenter)="hover = true"
         (mouseleave)="hover = false"
         (mouseover)="hover = true"
         (mouseout)="hover = false"
    >
        <div class="asset-version-parts-container"
             [class.disabled]="readonly"
             [ngStyle]="clearable ? {'padding-right': '24px'} : {}"
        >
            <ng-container
                [ngTemplateOutlet]="versionPartTemplate"
                [ngTemplateOutletContext]="{ index: 0 }"
            ></ng-container>
            <div class="asset-version-part-div">.</div>
            <ng-container
                [ngTemplateOutlet]="versionPartTemplate"
                [ngTemplateOutletContext]="{ index: 1 }"
            ></ng-container>
            <div class="asset-version-part-div">.</div>
            <ng-container
                [ngTemplateOutlet]="versionPartTemplate"
                [ngTemplateOutletContext]="{ index: 2 }"
            ></ng-container>
            <div class="asset-version-part-div">—</div>
            <ng-container
                [ngTemplateOutlet]="versionPartTemplate"
                [ngTemplateOutletContext]="{ index: 3 }"
            ></ng-container>
        </div>
    </div>

    <button
        *ngIf="!readonly && clearable"
        [style.top.px]="0"
        [style.right.px]="12"
        class="clear-btn"
        (click)="handleClearBtn()"
    >
        <span aria-hidden="true" class="icon">&times;</span>
    </button>
</div>
<lib-app-warning-message *ngIf="warning" [message]="warningMessage">
</lib-app-warning-message>

<ng-template #versionPartTemplate let-index="index">
    <input class="asset-version-part-input"
           [class.disabled]="disabledParts[index]"
           [class.invalid]="hasErrors && !isVersionPartValid(versionParts[index])"
           [readOnly]="disabledParts[index]"
           [style.width.px]="versionParts[index]?.length > 0 ? versionParts[index].length * 8 : 8"
           [class.asset-version-part-input--filled]="versionParts[index]"
           [value]="versionParts[index]"
           (focus)="handleFocus(index)"
           (blur)="handleFocus()"
           (input)="handleInput($event, index)"
    />
</ng-template>


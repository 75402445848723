import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TourAnchorPopoverDirective, TourAnchorDirective } from './tour-anchor.directive';
import { TourStepComponent } from './tour-step-template.component';
import { TourMenuComponent } from './tour-menu/tour-menu.component';

@NgModule({
    declarations: [TourAnchorPopoverDirective, TourAnchorDirective, TourStepComponent, TourMenuComponent],
    exports: [TourAnchorPopoverDirective, TourAnchorDirective, TourStepComponent],
    imports: [CommonModule, NgbPopoverModule, FontAwesomeModule]
})
export class TourModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContextHelpComponent } from './context-help.component';
import { UiKitModule } from '@dagility-ui/kit';

@NgModule({
    declarations: [ContextHelpComponent],
    imports: [CommonModule, UiKitModule],
    exports: [ContextHelpComponent],
})
export class ContextHelpModule {}

import { EChartsChart } from '../echarts.chart';
import { Subject } from 'rxjs';

import { qualitativePalette, textColor } from '../charts-colors';

export class SimpleLineEChartsChart extends EChartsChart {
    options: Record<string, any> = {
        grid: {
            top: '20%',
            bottom: '20%',
        },
        xAxis: {
            type: 'category',
            data: [] as any[],
            silent: true,
            axisTick: {
                show: false,
            },
            axisLabel: {
                show: false,
            },
            axisLine: {
                show: false,
            },
            splitLine: {
                show: false,
            },
            nameTextStyle: {
                color: textColor(),
            },
        },
        yAxis: {
            type: 'value',
            min: 0,
            max: 10,
            splitNumber: 3,
            interval: 5,
            axisTick: {
                show: false,
            },
            axisLabel: {
                show: true,
                margin: 12,
                textStyle: {
                    color: textColor(),
                },
            },
            axisLine: {
                show: false,
            },
            nameTextStyle: {
                color: textColor(),
            },
        },
        tooltip: {
            show: false,
        },
        legend: {
            show: false,
        },
        series: [] as any[],
        color: qualitativePalette
    };

    typeAttributes = {
        type: 'line',
        showSymbol: true,
        symbolSize: 2,
        emphasis: {
            scale: false
        },
        lineStyle: {
            width: 1,
        },
    };

    moveToLeft = true;

    assignOptions(options: Record<string, any>, isCustom?: boolean) {
        if (options.moveToLeft !== undefined) {
            this.moveToLeft = options.moveToLeft;
        }

        super.assignOptions(options, isCustom);
    }

    handleEvents(emitter: Subject<{ type: string; payload: any }>, chartInstance: any) {
        if (!chartInstance) {
            return;
        }

        super.handleEvents(emitter, chartInstance);

        if (this.moveToLeft) {
            window.setTimeout(() => {
                chartInstance.setOption({
                    grid: {
                        left: '25%',
                    },
                });
            }, 400);
        }
    }
}

import {TrackEventGroup} from "../../model/track-event-group";
import {UserActivityTrackingService} from "../user-activity-tracking.service";
import {UserActivity} from "../../model/track-events";

export class EventGroups {

    static FOCUS_EVENTS: Array<string> = ['focus', 'blur'];
    static DOCUMENT_EVENTS: Array<string> = ['scroll', 'resize'];
    static MOUSE_MOVE_EVENTS: Array<string> = ['mouseover', 'mousemove', 'drag'];
    static MOUSE_EVENTS: Array<string> = ['click', 'dblclick', 'auxclick', 'wheel', 'drop']
        .concat(EventGroups.MOUSE_MOVE_EVENTS);
    static INPUT_EVENTS: Array<string> = ['keyup', 'change', 'click'];
    static ALL_EVENTS: Array<string> =
        EventGroups.FOCUS_EVENTS
            .concat(EventGroups.DOCUMENT_EVENTS)
            .concat(EventGroups.MOUSE_EVENTS)
            .concat(EventGroups.INPUT_EVENTS)
            .filter((value, index, self) => self.indexOf(value) === index) // unique
    ;

    static eventGroups: Array<TrackEventGroup> = [
        new TrackEventGroup(
            'active',
            'ACTIVE',
            EventGroups.ALL_EVENTS,
            60 * 1000,
            () => new UserActivity()
        ),
        new TrackEventGroup(
            'input',
            'INPUT',
            EventGroups.INPUT_EVENTS,
            0,
            (ev: Event) => UserActivityTrackingService.createActionUserEvent(ev)
        ),
        new TrackEventGroup(
            'mousemove',
            'mousemove',
            EventGroups.MOUSE_MOVE_EVENTS,
            10 * 1000,
            (ev: Event) => UserActivityTrackingService.createActionUserEvent(ev)
        )
    ];
}

import { HttpClient } from '@angular/common/http';
import { Translation, TRANSLOCO_CONFIG, TRANSLOCO_LOADER, translocoConfig, TranslocoLoader, TranslocoModule } from '@ngneat/transloco';
import { Injectable, NgModule } from '@angular/core';
import { TranslocoMessageFormatModule } from '@ngneat/transloco-messageformat';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { ENV_TOKEN } from '@app/tokens';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
    constructor(private http: HttpClient) {}

    getTranslation(lang: string) {
        return this.http.get<Translation>(`/assets/translations/${lang}.json`);
    }
}

@NgModule({
    exports: [TranslocoModule],
    providers: [
        {
            provide: TRANSLOCO_CONFIG,
            useFactory: (env: Env) =>
                translocoConfig({
                    availableLangs: [{ id: 'en', label: 'English' }],
                    reRenderOnLangChange: true,
                    prodMode: env.production,
                    fallbackLang: 'en',
                    defaultLang: 'en',
                    missingHandler: {
                        useFallbackTranslation: false,
                    },
                }),
            deps: [ENV_TOKEN],
        },
        { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
    ],
    imports: [
        TranslocoMessageFormatModule.forRoot(),
        TranslocoLocaleModule.forRoot({
            langToLocaleMapping: {
                en: 'en-US',
            },
        }),
    ],
})
export class TranslocoRootModule {}

import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { catchError, startWith } from 'rxjs/operators';
import { KeycloakService } from './keycloak.service';
import { ROLE_ADMIN, ROLE_CONSUMER, ROLE_PRODUCER, ROLE_USER_GROUP_ADMIN, UserDto, UserRole } from '@app/core/models/user.model';
import { UserService } from '../services/user/user.service';
import { IUserSession, IUserSessionResolver } from '@keep-ni';
import { v4 as uuidv4 } from 'uuid';
import { AUTHENTICATED_STORAGE_NAME } from '@app/core/auth/auth.constants';

@Injectable({
    providedIn: 'root',
})
export class AuthService implements IUserSessionResolver {
    public readonly onAfterLogin$ = new ReplaySubject<UserDto>(1);
    public readonly onBeforeLogout$ = new Subject<void>();

    public readonly user$ = this.onAfterLogin$.asObservable().pipe(startWith(undefined as UserDto));
    private user: UserDto;

    get authenticated(): boolean {
        return this.keycloakService.authenticated$.value;
    }

    constructor(private readonly userService: UserService, private readonly keycloakService: KeycloakService) {}

    init() {
        this.onBeforeLogout$.subscribe(() => this.authenticate(null));
        return this.userService
            .getCurrentUser()
            .pipe(catchError(() => of(null)))
            .toPromise()
            .then((user: UserDto | null) => this.authenticate(user));
    }

    authenticate(user: UserDto | null) {
        this.user = user;
        this.onAfterLogin$.next(user);
    }

    getUser(): UserDto {
        return this.user;
    }

    getTokenApi() {
        return this.keycloakService.getTokenApi();
    }

    getUpdateToken$() {
        return this.keycloakService.getUpdateToken$();
    }

    getUserSession(): Promise<IUserSession> | Observable<IUserSession> {
        return !!this.user
            ? of({
                  id: `${this.user.id}`,
                  roles: this.user.roles,
                  lastLoginTime: this.user.lastLoginAt,
              })
            : of({ id: uuidv4(), lastLoginTime: 0 });
    }

    public hasAnyRole(roles: UserRole[]): boolean {
        if (!roles) {
            return false;
        }
        return roles.some(role => this.hasRole(role));
    }

    public hasAllRoles(roles: UserRole[]): boolean {
        if (!roles) {
            return false;
        }
        return roles.every(role => this.hasRole(role));
    }

    public isAdmin(): boolean {
        return this.hasRole(ROLE_ADMIN);
    }

    public isGroupAdmin(): boolean {
        return this.hasRole(ROLE_USER_GROUP_ADMIN);
    }

    public isProducer(): boolean {
        return this.hasRole(ROLE_PRODUCER);
    }

    public isConsumer(): boolean {
        return this.hasRole(ROLE_CONSUMER);
    }

    public hasRole(role: UserRole): boolean {
        if (!this.user) {
            return false;
        }
        return this.user.roles?.includes(role) || role === ROLE_CONSUMER;
    }

    login(loginRequired: boolean = false) {
        this.keycloakService.login(loginRequired).then();
    }

    logout() {
        this.onBeforeLogout$.next();
        this.keycloakService.logout().then();
    }

    register() {
        this.keycloakService.register().then();
    }

    getAuthenticatedValueFromLS(): string {
        return localStorage.getItem(AUTHENTICATED_STORAGE_NAME);
    }
}

import {IHttpClient} from '../../api/keep-ni-config';

export class DefaultHttpClient implements IHttpClient {

    private readonly HEADER_NAME = 'Auth';

    private token: string = null;
    private headerName: string = null;

    post = async function(url: string, data: any, params: any, logEnabled?: boolean) {
        try {
            const request = new Request(url, {
                method: 'POST',
                // @ts-ignore
                crossDomain: true,
                xhrFields: {
                    withCredentials: true
                },
                headers: {
                    ...params.headers,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
            if (this.token != null) {
                request.headers.append(this.headerName, this.token);
            }
            return await fetch(request);
        } catch (error) {
            if (logEnabled) {
                console.error(error);
            }
        }
    }

    public setToken(token: string) : void {
        this.token = token;
        this.headerName = this.headerName == null ? this.HEADER_NAME : this.headerName;
    }

    public setHeaderName(header: string): void {
        this.headerName = header;
    }
}

import { AfterContentInit, Component, HostBinding, Input, ViewChild } from '@angular/core';
import CKEditor from 'ckeditor-build';
import { BaseFormControl, LabelPosition } from '@dagility-ui/kit';
import { ChangeEvent, CKEditorComponent } from '@ckeditor/ckeditor5-angular';

@Component({
    selector: 'app-ck-editor',
    templateUrl: './ck-editor.component.html',
    styleUrls: ['./ck-editor.component.scss'],
    host: {
        class: 'd-flex flex-column ckeditor',
    },
})
export class CkEditorComponent extends BaseFormControl<string, CKEditorComponent> implements AfterContentInit {
    @Input() id: string = 'ck-editor';

    @Input() labelPosition: LabelPosition = 'top';

    @Input() set setDisable(value: boolean) {
        this.setDisabledState(value);
    }

    @ViewChild(CKEditorComponent) ckeditor: CKEditorComponent;

    readonly editor = CKEditor;

    @HostBinding('class.label-left')
    get isLabelPositionLeft() {
        return this.labelPosition === 'left';
    }

    ngAfterContentInit(): void {
        this.control.markAsPristine();
        this.control.markAsUntouched();
        this.control.updateValueAndValidity();
    }

    handleInput({ editor }: ChangeEvent) {
        this.writeValue(editor.getData());
        this.onChange(editor.getData());
    }

    setDisabledState(isDisabled: boolean) {
        this.readonly = isDisabled;
    }
}

import { Directive, Inject, TemplateRef } from '@angular/core';

@Directive({
    selector: 'ng-template[secondaryHeaderTemplate]',
})
export class SecondaryHeaderTemplateDirective {
    constructor(
        private tmpl: TemplateRef<any>,
        @Inject('secondaryHeaderTemplate') private secondaryHeaderTemplate: { tmpl: TemplateRef<any> }
    ) {}

    async ngOnInit() {
        await Promise.resolve();
        this.secondaryHeaderTemplate.tmpl = this.tmpl;
    }
}

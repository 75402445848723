import { Component, Input, OnInit } from '@angular/core';
import { TranslationService } from '@app/core/services/translation.service';
import { TranslationKeysOfDropdown, TranslationKeysOfTextarea } from '@dagility-ui/kit';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AssetDto } from '@app/shared/components/assets/asset.model';
import { AssetAccessRequest, AssetRequestService } from '@app/shared/services/api/asset-request.service';
import { DictionaryItem, DictionaryService } from '@app/shared/services/api/dictionary.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '@app/auth';
import { UserDto } from '@app/core/models/user.model';
import { AssetsService } from '@app/shared/services/api/assets.service';

type RequestAccessModalMode = 'REQUEST' | 'REJECT' | 'REQUESTED';

@Component({
    selector: 'app-request-access-modal',
    templateUrl: './request-access-modal.component.html',
})
export class RequestAccessModalComponent implements OnInit {
    @Input() mode: RequestAccessModalMode;

    @Input() asset: AssetDto;

    @Input() assetAccessRequest: AssetAccessRequest;

    form: FormGroup;

    requestExist: boolean;

    processing: boolean = false;

    readonly rejectReasons$: Observable<DictionaryItem[]> = this.dictionaryService.getRequestRejectReasons();

    get textareaTranslatedText(): Partial<Record<TranslationKeysOfTextarea, string>> {
        return this.translationService.textareaTranslatedText;
    }

    get dropdownTranslatedText(): Partial<Record<TranslationKeysOfDropdown, string>> {
        return this.translationService.dropdownTranslatedText;
    }

    get currentUser(): UserDto {
        return this.authService.getUser();
    }

    constructor(
        private readonly assetRequestService: AssetRequestService,
        private readonly dictionaryService: DictionaryService,
        private readonly assetsService: AssetsService,
        private readonly translationService: TranslationService,
        private readonly authService: AuthService,
        private readonly fb: FormBuilder,
        private readonly router: Router,
        public activeModal: NgbActiveModal
    ) {}

    ngOnInit(): void {
        if (this.mode === 'REJECT') {
            this.assetAccessRequest = {
                ...this.assetAccessRequest,
                acceptorId: this.currentUser.id,
                acceptorName: this.currentUser.name,
                acceptorEmail: this.currentUser.email,
            };
        } else {
            this.assetAccessRequest = {
                ...this.assetAccessRequest,
                requestedAssetId: this.asset.id,
                requestedAssetName: this.asset.assetName,
                requestedAssetVersionId: this.asset.assetVersionId,
                requesterName: this.currentUser.name,
                requesterEmail: this.currentUser.email,
                createdAt: new Date(Date.now()).toISOString(),
            } as AssetAccessRequest;
            this.assetsService.getAssetOwnerInfo(this.asset.id).subscribe(ownerInfo => {
                this.assetAccessRequest = {
                    ...this.assetAccessRequest,
                    acceptorName: ownerInfo.name,
                    acceptorEmail: ownerInfo.email,
                } as AssetAccessRequest;

                this.requestExist = ownerInfo.requestExist;

                if (this.requestExist) {
                    this.mode = 'REQUESTED';
                }
            });
        }

        this.form = this.fb.group(
            this.mode === 'REJECT'
                ? {
                      rejectReason: [null, Validators.required],
                      rejectComment: [''],
                  }
                : { comment: [''] }
        );
    }

    execute() {
        switch (this.mode) {
            case 'REQUEST':
                if (this.requestExist === true) {
                    this.mode = 'REQUESTED';
                    return;
                }

                if (this.form.invalid || this.requestExist == null) {
                    return;
                }

                this.processing = true;
                this.assetRequestService
                    .createRequest({
                        ...this.assetAccessRequest,
                        ...this.form.getRawValue(),
                    })
                    .subscribe(accessRequest => {
                        this.assetAccessRequest = accessRequest;
                        this.mode = 'REQUESTED';
                        this.processing = false;
                    });
                break;
            case 'REJECT':
                if (!this.assetAccessRequest.id || this.form.invalid) {
                    return;
                }

                this.activeModal.close({
                    ...this.form.getRawValue(),
                    id: null,
                });
                break;
            case 'REQUESTED':
                if (!this.assetAccessRequest) {
                    return;
                }

                this.activeModal.close(this.assetAccessRequest);
                break;
        }
    }

    navigateToAsset(assetId: number, assetVersionId: number) {
        if (!assetId || !assetVersionId) {
            return;
        }

        this.activeModal.dismiss();
        this.router.navigate(['assets', assetId, 'version', assetVersionId]).then();
    }
}

import { EChartsChart } from '../echarts.chart';

export class GaugeEchartsChart extends EChartsChart {
    options: Record<string, any> = {
        tooltip: {
            formatter: '{a} <br/>{b} : {c}%'
        },
        series: [
            {
                type: 'gauge',
                radius: '100%',
                progress: {
                    show: true,
                    roundCap: true,
                    width: 20
                },
                startAngle: 180,
                endAngle: 0,
                min: 0,
                max: 1,
                splitNumber: 8,
                axisLine: {
                    lineStyle: {
                        width: 0,
                        color: [
                            [0.33, '#FB4E4E'],
                            [0.66, '#FF9800'],
                            [1, '#62CD20']
                        ]
                    }
                },
                axisTick: {
                    length: 2,
                    lineStyle: {
                        color: 'auto',
                        width: 2
                    }
                },
                splitLine: {
                    show: false
                },
                detail: {
                    valueAnimation: true,
                    fontSize: 30,
                    fontWeight: 300,
                    offsetCenter: [0, '0%']
                },
                data: [
                    {
                        value: 0.7,
                        name: ''
                    }
                ]
            }
        ]
    };
}

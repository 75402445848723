import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ENV_TOKEN } from '@app/tokens';
import { tap } from 'rxjs/operators';

export interface DictionaryItem {
    id: number;
    name: string;
}

export interface SystemDictionaryItem {
    id: number;
    label: string;
}

@Injectable({
    providedIn: 'root',
})
export class DictionaryService {
    private readonly apiUrl = this.env.apiURL;

    readonly publishers$: BehaviorSubject<DictionaryItem[]> = new BehaviorSubject<DictionaryItem[]>([]);

    constructor(@Inject(ENV_TOKEN) private readonly env: Env, private readonly http: HttpClient) {
        this.getPublishers().subscribe();
    }

    getPublishers(): Observable<DictionaryItem[]> {
        return this.http.get<DictionaryItem[]>(`${this.apiUrl}/groups`).pipe(tap(publishers => this.publishers$.next(publishers)));
    }

    getSearchKeywords(): Observable<DictionaryItem[]> {
        return this.http.get<DictionaryItem[]>(`${this.apiUrl}/search-keywords`);
    }

    addSearchKeyword(keyword: string): Observable<any> {
        return this.http.post(`${this.apiUrl}/search-keywords`, keyword);
    }

    getRejectReasons(): Observable<DictionaryItem[]> {
        return this.http.get<DictionaryItem[]>(`${this.apiUrl}/rejection-resolutions`);
    }

    getRequestRejectReasons(): Observable<DictionaryItem[]> {
        return this.http.get<DictionaryItem[]>(`${this.apiUrl}/reject-request-reason`);
    }
}

import { ChartLibrary } from '../chart-library';
import { AreaEChartsChart } from './charts/area-echarts.chart';
import { StackedBarEChartsChart } from './charts/stacked-bar-echarts.chart';
import { GroupedBarEChartsChart } from './charts/grouped-bar-echarts.chart';
import { LineEChartsChart } from './charts/line-echarts.chart';
import { PieEChartsChart } from './charts/pie-echarts.chart';
import { TimeSeriesEChartsChart } from './charts/time-series-echarts.chart';
import { DoughnutEChartsChart } from './charts/doughnut-echarts.chart';
import { BoxplotEChartsChart } from './charts/boxplot-echarts.chart';
import { MultipleYAxisEChartsChart } from './charts/mutiple-yaxis-echart.chart';
import { HorizontalStackedBarEChartsChart } from './charts/horizontal-stacked-bar-echarts.chart';
import { AxisLineEChartsChart } from './charts/axis-line-echarts.chart';
import { TreeMapEchartsChart } from './charts/treemap-echarts.chart';
import { SimpleLineEChartsChart } from './charts/simple-line-echarts.chart';
import { GanttEchartsChart } from './charts/gantt-echarts-chart';
import { RadialEchartsChart } from './charts/radial-echarts.chart';
import { SunburstEchartsChart } from './charts/sunburst-echarts.chart';
import { CustomEchartsChart } from './charts/custom-echarts.chart';
import { ScatterEChartsChart } from './charts/scatter-echarts.chart';
import { BasicLineEchartsChart } from './charts/basic-line-echarts';
import { GaugeEchartsChart } from './charts/gauge-echarts.chart';
import { MultipleSeriesTypeEchartChart } from './charts/multiple-series-type-echart.chart';

export const EchartsLibrary: ChartLibrary = {
    charts: {
        areachart: AreaEChartsChart,
        stackedbarchart: StackedBarEChartsChart,
        groupedbarchart: GroupedBarEChartsChart,
        linechart: LineEChartsChart,
        piechart: PieEChartsChart,
        timeserieschart: TimeSeriesEChartsChart,
        doughnutchart: DoughnutEChartsChart,
        boxplotchart: BoxplotEChartsChart,
        multipleyaxischart: MultipleYAxisEChartsChart,
        horizontalstackedbarchart: HorizontalStackedBarEChartsChart,
        axislinechart: AxisLineEChartsChart,
        ganttChart: GanttEchartsChart,
        customChart: CustomEchartsChart,
        radialChart: RadialEchartsChart,
        sunburstchart: SunburstEchartsChart,
        treemap: TreeMapEchartsChart,
        simplelinechart: SimpleLineEChartsChart,
        scatterchart: ScatterEChartsChart,
        basiclinechart: BasicLineEchartsChart,
        gaugechart: GaugeEchartsChart,
        multipleseriestypechart: MultipleSeriesTypeEchartChart
    },
};

import { Subject } from 'rxjs';
import { Injector } from '@angular/core';
import { ThousandSuffixesPipe } from '../../../../../pipes/thousand-format.pipe';

import { EChartsChart } from '../echarts.chart';
import { customTrendDataTooltip, drawTooltip } from '../custom-trend-data.tooltip';
import { qualitativePalette, textColor } from '../charts-colors';

export class TreeMapEchartsChart extends EChartsChart {
    trendData: any = [];

    options: Record<string, any> = {
        textStyle: {
            fontFamily: 'sans-serif',
            fontSize: 14,
            color: textColor(),
        },
        xAxis: {
            show: false,
            name: '',
        },
        yAxis: {
            show: false,
            name: '',
        },
        tooltip: {
            formatter: this.toolTipFormatter.bind(this),
        },
        series: {
            type: 'treemap',
            visibleMin: 300,
            borderRadius: 20,
            label: {
                show: true,
                formatter: (info: any) => {
                    const condition = info.color && !!info.data.dynamicColor && info.treeAncestors.length > 2;

                    return (
                        (condition ? (this.isLightColor(info.color.slice(1)) ? '{darkText|' : '{lightText|') : '') +
                        [
                            info.data.hasOwnProperty('error') ? '!! ' : '',
                            info.data.name,
                            info.data.hasOwnProperty('error')
                                ? ''
                                : info.data.hasOwnProperty('valueName')
                                ? '\n\n' + info.data.valueName + ': ' + new ThousandSuffixesPipe().transform(info.data.value, 2)
                                : '',
                            info.data.hasOwnProperty('rating') ? '\n\n' + info.data.rating : '',
                            info.data.hasOwnProperty('error') ? '\n\n' + info.data.error : '',
                        ].join('') +
                        (condition ? '}' : '')
                    );
                },
                position: 'insideTopLeft',
                offset: [5, 5],
                fontWeight: 'lighter',
                rich: {
                    darkText: {
                        color: '#000000',
                    },
                    lightText: {
                        color: '#ffffff',
                    },
                },
            },
            upperLabel: {
                show: true,
                height: 30,
                color: '#555',
            },
            itemStyle: {
                borderColor: '#fff',
            },
            roam: false,
            width: '100%',
            height: '93%',
            levels: [
                {
                    colorSaturation: [0.4, 0.9],
                    itemStyle: {
                        borderColor: '#e5e5e5',
                        borderWidth: 0,
                        gapWidth: 5,
                        borderColorSaturation: 0.4,
                    },
                    upperLabel: {
                        show: false,
                    },
                },
                {
                    itemStyle: {
                        borderColor: '#ffffff',
                        borderWidth: 5,
                        gapWidth: 4,
                    },
                    emphasis: {
                        itemStyle: {
                            borderColor: '#ddd',
                        },
                    },
                },
                {
                    colorSaturation: [0.35, 0.5],
                    itemStyle: {
                        gapWidth: 2,
                        borderColorSaturation: 0.6,
                    },
                },
            ],
            data: [],
        },
        color: qualitativePalette,
    };

    typeAttributes = {};

    assignOptions(options: Record<string, any>) {
        this.options.series.data = options.series.data;
        this.trendData = options.trendData || [];
        if (this.trendData.length) {
            this.options.tooltip['backgroundColor'] = '#FFFFFF';
            this.options.tooltip['textStyle'] = { color: '#000' };
            this.options.tooltip['enterable'] = true;
            this.options.tooltip['appendToBody'] = true;
            this.options.tooltip['confine'] = true;
            this.options.tooltip['extraCssText'] = 'min-height:100px;max-height:300px;';
        }

        if (options.breadcrumb) {
            this.options.series.breadcrumb = options.breadcrumb;

            if (!options.breadcrumb.show) {
                this.options.series.roam = true;
            }
        }

        if (options.upperLabel) {
            this.options.series.upperLabel = options.upperLabel;

            if (!options.upperLabel.show) {
                delete this.options.series.levels;
                this.options.series.itemStyle.borderWidth = 2;
            }
        }

        if (options.labelFormatter) {
            this.options.series.label.formatter = options.labelFormatter;
        }

        super.assignOptions(options);
    }

    trendToolTip(params: any, trendData: any) {
        return customTrendDataTooltip(params, trendData);
    }

    isLightColor(color: string) {
        const red = parseInt(color.slice(0, 2), 16);
        const green = parseInt(color.slice(2, 4), 16);
        const blue = parseInt(color.slice(4), 16);

        const hsp = Math.sqrt(0.299 * (red * red) + 0.587 * (green * green) + 0.114 * (blue * blue));
        return hsp > 127.5;
    }

    toolTipFormatter(info: any) {
        if (this.trendData.length && !info.data.hasOwnProperty('error')) {
            return this.trendToolTip(info, this.trendData);
        } else {
            if (!info.data.name) {
                return;
            }

            return [
                info.data.hasOwnProperty('error') ? '!! ' : '',
                info.data.name,
                info.data.hasOwnProperty('error')
                    ? ''
                    : info.data.hasOwnProperty('valueName')
                    ? '\n\n' + info.data.valueName + ': ' + new ThousandSuffixesPipe().transform(info.data.value, 2)
                    : '',
                info.data.hasOwnProperty('rating') ? '<br/>' + info.data.rating : '',
                info.data.hasOwnProperty('error') ? '<br/>' + info.data.error : '',
            ].join('');
        }
    }

    handleEvents(emitter: Subject<{ type: string; payload: any }>, chartInstance: any, injector: Injector) {
        if (!chartInstance) {
            return;
        }

        chartInstance.on('dblclick', 'series', (params: any) => {
            if (params.data && params.data.name) {
                emitter.next({
                    type: 'mapClicked',
                    payload: {
                        mapName: params.data.name,
                    },
                });
            }
        });

        if (this.trendData.length) {
            chartInstance.on('showTip', 'series', () => {
                drawTooltip(this.trendData, chartInstance, injector);
            });
        }
        super.handleEvents(emitter, chartInstance);
    }
}

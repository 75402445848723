import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-notification-inline-banner',
    templateUrl: './notification-inline-banner.component.html',
    styleUrls: ['./notification-inline-banner.component.scss'],
})
export class NotificationInlineBannerComponent {
    @Input() value: string = '';
}

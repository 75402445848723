import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-tags',
    templateUrl: './tags.component.html',
    styleUrls: ['./tags.component.scss'],
})
export class TagsComponent {
    @Input() tags: string[] = [];
    expanded: boolean = false;

    moreOrLessTags(event: Event) {
        this.stopEventPropagation(event);
        if (this.tags.length <= 6) {
            return;
        }
        this.expanded = !this.expanded;
    }

    private stopEventPropagation(event: Event) {
        event.preventDefault();
        event.stopPropagation();
    }
}

<div class="carousel" #carousel>
    <div class="carousel-items"
         #carouselItems
         [ngStyle]="{
            'height': itemHeight ? (itemHeight + 'px') : '100%',
            'grid-template-columns': 'repeat(' + itemsOnPageInternal + ', minmax(' + itemMinWidth + 'px, 10000px))',
            'grid-gap': spaceBetweenItems + 'px'
         }"
    >
        <ng-container *ngFor="let item of currentData; let i = index">
            <ng-container
                *ngTemplateOutlet="item.addItemTemplate ? getAsTemplate(item.addItemTemplate.template) : withoutAddingTemplate; context: { item: item, items: currentData, index: i }"
            ></ng-container>
            <ng-template #withoutAddingTemplate>
                <ng-container
                    *ngTemplateOutlet="itemTemplate ? itemTemplate.template : defaultTemplate; context: { item: item, items: currentData, index: i }"
                ></ng-container>
                <ng-template #defaultTemplate>
                    <div class="carousel-item" [style.background-image]="getUrl(item.backgroundImageUrl)">
                        <div
                            [ngClass]="item.isDarkMode? 'carousel-item-content--dark' : 'carousel-item-content--light'">
                            <div class="carousel-item-title">
                                <span *ngIf="item.title">{{ item.title }}</span>
                            </div>
                            <div class="carousel-item-body">
                                <span *ngIf="item.body">{{ item.body }}</span>
                            </div>
                            <div class="carousel-item-buttons">
                                <ng-container *ngIf="item.buttons?.length">
                                    <button *ngFor="let button of item.buttons"
                                            type="button"
                                            class="carousel-item-button btn btn-primary size-l"
                                            (click)="goToLink(button.link)"
                                    >
                                        {{ button.label }}
                                    </button>
                                </ng-container>

                            </div>
                        </div>
                    </div>
                </ng-template>
            </ng-template>
        </ng-container>
    </div>
    <ng-container *ngIf="data?.length && hasPrevNextControls && totalPages !== 1">
        <div class="carousel-controls-prev-next"
             [style.left.px]="spaceToPrevNextControls"
             (click)="toPage(getCorrectPrevPage(), true)"
        >
            <fa-icon icon="chevron-left" class="d-block font-24px"></fa-icon>
        </div>
        <div class="carousel-controls-prev-next"
             [style.right.px]="spaceToPrevNextControls"
             (click)="toPage(getCorrectNextPage(), true)"
        >
            <fa-icon icon="chevron-right" class="d-block font-24px"></fa-icon>
        </div>
    </ng-container>
</div>
<div *ngIf="data?.length && hasPageControls && totalPages !== 1"
     [ngClass]="pageControlsInside ? 'carousel-controls-dots--inside' : 'carousel-controls-dots'"
     [ngStyle]="pageControlsInside ? {'bottom': spaceToPageControls + 'px'} : {'margin-top': spaceToPageControls + 'px'}"
>
    <ng-container *ngFor="let page of array(totalPages).fill(0); let i = index; let last = last">
        <div class="carousel-controls-dots-item"
             *ngIf="currentPage !== i; else activeItem"
             (click)="toPage(i, true)"
        ></div>
        <ng-template #activeItem>
            <fa-icon [icon]="icons.carouselControlActiveItem"
                     class="d-block color-brand"
                     style="font-size: 48px;"
            ></fa-icon>
        </ng-template>
        <div class="carousel-controls-dots-div" *ngIf="!last"></div>
    </ng-container>
</div>

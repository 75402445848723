import {
    AfterContentInit,
    AfterViewInit,
    Component,
    ContentChild,
    ContentChildren,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { AddItemTemplateDirective, ItemTemplateDirective } from '@app/shared/components/carousel/carousel.directives';
import { interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CustomIcon } from '@dagility-ui/shared-components/icons';

const carouselControlActiveItem: CustomIcon = {
    prefix: 'fac',
    iconName: 'carouselControlActiveItem',
    icon: [
        48,
        48,
        [],
        '',
        'M24 1C11.2975 1 1 11.2975 1 24H0C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48V47C36.7025 47 47 36.7025 47 24C47 11.2975 36.7025 1 24 1Z M40 24C40 32.8366 32.8366 40 24 40C15.1634 40 8 32.8366 8 24C8 15.1634 15.1634 8 24 8C32.8366 8 40 15.1634 40 24Z',
    ],
    customStyles: {
        fill: 'currentColor',
        'fill-rule': 'evenodd',
        'clip-rule': 'evenodd',
    },
};

export interface CarouselBanner {
    backgroundImageUrl?: string;
    title: string;
    body: string;
    isDarkMode: boolean;
    buttons?: CarouselBannerButton[];
}

export interface CarouselBannerButton {
    label: string;
    link: string;
}

@Component({
    selector: 'app-carousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit, AfterContentInit {
    private readonly destroy$: Subject<any> = new Subject<any>();

    @Input() data: (CarouselBanner | any)[] = [];

    @Input() itemsOnPage: number = 1;
    itemsOnPageInternal: number = 1;

    @Input() itemMinWidth: number = 480;
    @Input() itemHeight: number;

    @Input() timeInterval: number = 10000;
    @Input() hasAutoSlides: boolean = true;

    @Input() hasPageControls: boolean = true;
    @Input() pageControlsInside: boolean = true;
    @Input() spaceToPageControls: number = 28;

    @Input() hasPrevNextControls: boolean = true;
    @Input() spaceToPrevNextControls: number = 52;

    @Input() spaceBetweenItems: number = 24;

    @Input() selfSizingControl: boolean = true;
    @Output() carouselOverflowing: EventEmitter<any> = new EventEmitter<any>();
    @Output() carouselIncomplete: EventEmitter<any> = new EventEmitter<any>();

    @Output() pageChanged: EventEmitter<number> = new EventEmitter<number>();

    totalPages: number = 0;
    currentPage: number = 0;
    currentData: (CarouselBanner | any)[] = [];

    @ContentChild(ItemTemplateDirective, { static: true }) itemTemplate: ItemTemplateDirective;

    @ContentChildren(AddItemTemplateDirective) addItemTemplates: QueryList<AddItemTemplateDirective>;

    @ViewChild('carousel') carouselRef: ElementRef<HTMLElement>;
    @ViewChild('carouselItems') carouselItemsRef: ElementRef<HTMLElement>;

    private resizeObserver: ResizeObserver;

    private readonly manualClick$: Subject<any> = new Subject<any>();
    private readonly timer = interval(this.timeInterval).pipe(takeUntil(this.destroy$), takeUntil(this.manualClick$));

    readonly array = Array;

    readonly icons = { carouselControlActiveItem };

    constructor(private readonly zone: NgZone) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.itemsOnPage && changes.itemsOnPage.previousValue !== changes.itemsOnPage.currentValue) {
            this.itemsOnPageInternal = this.itemsOnPage;
            this.resetPages();
        }
    }

    ngOnInit(): void {
        if (this.hasAutoSlides) {
            this.manualClick$.subscribe(() => this.startTimer());
            this.startTimer();
        }
        this.resizeObserver = new ResizeObserver(entries => {
            entries.forEach(() => {
                if (this.carouselItemsRef.nativeElement.clientWidth > this.carouselRef.nativeElement.clientWidth) {
                    this.processCarouselOverflowing();
                } else if (this.carouselItemsRef.nativeElement.clientWidth === this.carouselRef.nativeElement.clientWidth) {
                    this.processCarouselIncomplete();
                }
            });
        });
    }

    ngAfterContentInit(): void {
        if (this.addItemTemplates?.length) {
            const itemsToStart: any[] = [];
            const itemsToEnd: any[] = [];
            this.addItemTemplates.forEach(addItemTemplate => {
                if (!addItemTemplate.template) {
                    return;
                }
                if (addItemTemplate.placeInsert === 'start') {
                    itemsToStart.push({ addItemTemplate });
                } else if (addItemTemplate.placeInsert === 'end') {
                    itemsToEnd.push({ addItemTemplate });
                }
            });
            this.data = [...itemsToStart, ...this.data, ...itemsToEnd];
            this.resetPages();
        }
    }

    ngAfterViewInit(): void {
        this.resizeObserver.observe(this.carouselRef.nativeElement);
    }

    ngOnDestroy(): void {
        this.resizeObserver.unobserve(this.carouselRef.nativeElement);
        this.destroy$.next();
    }

    getAsTemplate(template: any): TemplateRef<any> {
        return template as TemplateRef<any>;
    }

    private resetPages() {
        this.totalPages = Math.ceil(this.data.length / this.itemsOnPageInternal);
        this.toPage(0);
    }

    toPage(page: number, manual: boolean = false) {
        if (this.totalPages < page + 1) {
            return;
        }

        this.currentPage = page;
        this.currentData = this.data.slice(this.itemsOnPageInternal * page, this.itemsOnPageInternal * (page + 1));

        this.pageChanged.next(this.currentPage);

        if (manual) {
            this.resetTimer();
        }
    }

    getCorrectNextPage(): number {
        return this.totalPages <= this.currentPage + 1 ? 0 : this.currentPage + 1;
    }

    getCorrectPrevPage(): number {
        return this.currentPage === 0 ? this.totalPages - 1 : this.currentPage - 1;
    }

    private startTimer() {
        this.timer.subscribe(() => this.toPage(this.getCorrectNextPage()));
    }

    private resetTimer() {
        this.manualClick$.next();
    }

    getUrl(content: string): string {
        return content ? `url("${content}")` : '';
    }

    goToLink(link: string) {
        if (!link) {
            return;
        }
        if (link.startsWith('#')) {
            document.getElementById(link.slice(1))?.scrollIntoView();
        } else {
            window.open(link, '_self');
        }
    }

    private processCarouselOverflowing() {
        if (!this.selfSizingControl) {
            this.carouselOverflowing.next();
            return;
        }

        if (this.itemsOnPageInternal !== 1) {
            const itemsOnPage =
                this.itemsOnPageInternal -
                Math.ceil(
                    (this.carouselItemsRef.nativeElement.clientWidth - this.carouselRef.nativeElement.clientWidth) /
                        (this.itemMinWidth + this.spaceBetweenItems)
                );

            this.zone.run(() => {
                this.itemsOnPageInternal = itemsOnPage >= 1 ? itemsOnPage : 1;
                this.resetPages();
            });
        }
    }

    private processCarouselIncomplete() {
        if (this.itemsOnPageInternal < this.itemsOnPage) {
            const itemsOnPage = Math.floor(this.carouselRef.nativeElement.clientWidth / (this.itemMinWidth + this.spaceBetweenItems));

            if (itemsOnPage !== this.itemsOnPageInternal) {
                if (!this.selfSizingControl) {
                    this.carouselIncomplete.next();
                    return;
                }

                this.zone.run(() => {
                    this.itemsOnPageInternal = itemsOnPage <= this.itemsOnPage ? itemsOnPage : this.itemsOnPage;
                    this.resetPages();
                });
            }
        }
    }
}

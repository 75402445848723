import { EChartsChart } from '../echarts.chart';
import { Subject } from 'rxjs';
import chroma from 'chroma-js';
import { qualitativePalette, subTextColor, textColor } from '../charts-colors';

const SAVE_ICON_PATH =
    'path://M8,5 C9.65685,5 11,6.34315 11,8 C11,9.65685 9.65685,11 8,11 C6.34315,11 5,9.65685 5,8 C5,6.34315 6.34315,5 8,5 Z M8,6 C6.89543,6 6,6.89543 6,8 C6,9.10457 6.89543,10 8,10 C9.10457,10 10,9.10457 10,8 C10,6.89543 9.10457,6 8,6 Z M14,10 L14,13 C14,13.5523 13.5523,14 13,14 L13,14 L10,14 L10,13 L13,13 L13,10 L14,10 Z M3,10 L3,13 L6,13 L6,14 L3,14 C2.44772,14 2,13.5523 2,13 L2,13 L2,10 L3,10 Z M6,2 L6,3 L3,3 L3,6 L2,6 L2,3 C2,2.44772 2.44772,2 3,2 L3,2 L6,2 Z M13,2 C13.5523,2 14,2.44772 14,3 L14,3 L14,6 L13,6 L13,3 L10,3 L10,2 Z';

export class TimeSeriesEChartsChart extends EChartsChart {
    private unitMeasure = '';

    get palette() {
        return qualitativePalette;
    }

    options: Record<string, any> = {
        textStyle: {
            fontFamily: 'sans-serif',
            fontSize: 12,
            color: textColor(),
        },
        grid: {
            top: '15%',
            bottom: '20%',
        },
        title: {
            text: '',
            subtext: '',
            textAlign: 'center',
            textVerticalAlign: 'top',
            left: '50%',
            textStyle: {
                color: textColor(),
                fontSize: 14,
                fontWeight: '500',
                fontFamily: 'sans-serif',
            },
            subtextStyle: {
                color: subTextColor(),
                fontSize: 12,
                fontFamily: 'sans-serif',
            },
        },
        xAxis: {
            type: 'time',
            boundaryGap: false,
            data: [] as any[],
            splitLine: {
                show: false,
            },
            axisLabel: {
                rotate: 45,
                fontSize: 10,
                margin: 11,
                formatter: this.dateFormatter.bind(this),
                textStyle: {
                    color: textColor(),
                },
            },
            axisLine: {
                show: false,
            },
            axisTick: {
                show: true,
                alignWithLabel: true,
            },
            nameLocation: 'center',
            nameGap: 50,
            nameTextStyle: {
                color: textColor(),
            },
        },
        yAxis: {
            type: 'value',
            boundaryGap: ['20%', '20%'],
            scale: true,
            min: '0',
            axisLine: {
                show: false,
            },
            axisTick: {
                show: false,
            },
            axisLabel: {
                textStyle: {
                    color: textColor(),
                },
            },
            nameLocation: 'center',
            nameGap: 55,
            nameTextStyle: {
                color: textColor(),
            },
        },
        series: [] as any[],
        color: this.palette,
        tooltip: {
            trigger: 'axis',
            renderMode: 'html',
            formatter: this.sprintBurnDownToolTip,
            axisPointer: {
                type: 'line',
                lineStyle: {
                    width: 0,
                },
                label: {
                    show: false,
                },
            },
        },
    };

    typeAttributes = {
        type: 'line',
        connectNulls: true,
        symbol: 'circle',
        symbolSize: 2,
        showAllSymbol: 'auto',
    };

    private xAxisLabels: Record<string, any> = {};

    handleEvents(emitter: Subject<{ type: string; payload: any }>, chartInstance: any) {
        if (!chartInstance) {
            return;
        }

        chartInstance.on('click', 'series', (event: any) => {
            emitter.next({
                type: 'barClicked',
                payload: {
                    barId: event.seriesId,
                    barName: event.seriesName,
                    data: event.data,
                },
            });
        });

        super.handleEvents(emitter, chartInstance);
    }

    assignOptions(options: Record<string, any>) {
        if (options.series && options.series.length > 1) {
            this.options.grid.bottom = '30%';
            this.options['legend'] = {
                bottom: '0',
                icon: 'circle',
                textStyle: {
                    fontSize: 11,
                },
            };
        }

        if (options.yAxis.nameGap) {
            this.options.yAxis.nameGap = options.yAxis.nameGap;
        }

        if (options.title && (options.title === 'Service Monitor Trends' || options.title.text === 'Service Monitor Trends')) {
            this.typeAttributes.symbolSize = 5;
            this.options.tooltip.formatter = this.monitoringServiceToolTip.bind(this);
            this.options.tooltip.trigger = 'item';
            this.options.grid.bottom = '30%';
            this.options['legend'] = {
                bottom: '0',
                icon: 'circle',
                textStyle: {
                    fontSize: 11,
                },
            };
            this.options['toolbox'] = {
                feature: {
                    saveAsImage: {
                        show: true,
                        title: 'Save',
                        icon: SAVE_ICON_PATH,
                    },
                },
            };

            if (options.yaxisLabel === 'Response time (ms)') {
                this.unitMeasure = 'ms';
            } else {
                this.unitMeasure = '%';
            }

            if (options.series && options.series.length) {
                const data = options.series[0].data;
                const start = data[0][0];
                const end = data[data.length - 1][0];
                const span = end - start;
                const splitNumber = span / (4 * 60 * 1000 * 60); /* 4 hours */
                this.options.xAxis['splitNumber'] = splitNumber > 120 ? 120 : splitNumber;
                this.options.xAxis.axisLabel.rotate = 0;
            }
        }

        if (options.hasOwnProperty('editWidgetMode') && options.editWidgetMode) {
            this.options.yAxis.nameGap = 25;
        }

        if (options.series && options.series.length && this.palette.length < options.series.length) {
            this.options.color = this.extendPaletteColors(options.series);
        }

        super.assignOptions(options);
    }

    extendPaletteColors(series: unknown[]) {
        const diff = series.length - this.palette.length;
        const colors = chroma
            .scale(['#fadb14', '#62cd20', '#40b2ff'])
            .colors(diff + 2)
            .slice(1, -1);

        return [...this.palette, ...colors];
    }

    sprintBurnDownToolTip(params: any) {
        const item = params[0];
        const dateVal = new Date(item.data[0]);
        let output = super.dateFormatter(item.data[0], 1) + ' ' + dateVal.toLocaleTimeString();
        output +=
            '&nbsp;&nbsp; <label style="border-radius: 50%;width: 10px;height: 10px;vertical-align: middle;margin: 0;background:' +
            item.color +
            '"></label>&nbsp;&nbsp;' +
            item.seriesName +
            ': ';
        output += '<b>' + item.data[1] + '</b><br/>';
        return output;
    }

    monitoringServiceToolTip(param: any) {
        return `${param.seriesName} ${super.dateFormatter(param.data[0], 1)} ${new Date(
            param.data[0]
        ).toLocaleTimeString()}: <b>${param.data[1].toFixed(2)} ${this.unitMeasure}</b><br/>`;
    }

    dateFormatter(value: any, index: number): string {
        const date = super.dateFormatter(value, index);
        const year = new Date(value).getFullYear();
        // if (this.options.title.text !== 'Service Monitor Trends') {
        //     return date;
        // }
        if (index === 0) {
            this.xAxisLabels = {};
        }

        if (this.xAxisLabels[`${year}-${date}`]) {
            return new Date(value).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        }

        this.xAxisLabels[`${year}-${date}`] = true;
        return date;
    }
}

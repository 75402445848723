import { qualitativePalette, textColor } from '../charts-colors';
import { EChartsChart } from '../echarts.chart';

export class ScatterEChartsChart extends EChartsChart {
    options: Record<string, any> = {
        grid: {
            top: '15%',
            bottom: '45%',
        },
        xAxis: {
            nameLocation: 'center',
            nameGap: 55,
            type: 'category',
            scale: true,
            axisLabel: {
                fontSize: 10,
                margin: 13,
                textStyle: {
                    color: textColor(),
                },
            },
            axisLine: {
                show: false,
            },
            axisTick: {
                show: false,
                alignWithLabel: true,
            },
            nameTextStyle: {
                color: textColor(),
            },
        },
        yAxis: {
            nameLocation: 'middle',
            nameGap: 45,
            type: 'value',
            scale: true,
            axisLine: {
                show: false,
            },
            axisTick: {
                show: false,
                alignWithLabel: true,
            },
            axisLabel: {
                fontSize: 10,
                margin: 13,
                textStyle: {
                    color: textColor(),
                },
            },
            nameTextStyle: {
                color: textColor(),
            },
        },
        textStyle: {
            fontFamily: 'sans-serif',
            fontSize: 11,
            color: textColor()
        },
        series: [] as any[],
        color: qualitativePalette,
        tooltip: {
            renderMode: 'html',
            appendToBody: true,
            showDelay: 0,
            textStyle: {
                fontSize: 11,
            },
        },
    };

    typeAttributes = {
        type: 'scatter',
        name: '',
        symbolSize: 10,
    };

    assignOptions(options: Record<string, any>) {
        super.assignOptions(options);
    }
}

export const defaultEnvironment = {
    production: false,
    helpSupportParams: {
        url: 'https://www.dagility.com',
        version: 3,
    },
};

export const getDefaultRuntimeEnv = (envName: string) => {
    return {
        keycloakParams: {
            enable: true,
            url: 'https://keycloak.dagility.com/auth',
            realm: 'Edgeops-Dev',
            clientId: 'Edgeops-ui',
            'ssl-required': 'external',
            'public-client': true,
        },
        env: {
            product: 'SolutionHub',
            customer: 'UST',
            name: envName,
        },
        webConsoleApiUrl: 'https://dev.dagility.com/keepni/api',
        titleName: 'UST PACE SolutionHub',
    };
};

export function buildApiUrls(serviceUrl: string, apiVersion: string): GeneratedEnv {
    const baseApiURL: string = `${serviceUrl}/api`;
    const apiURL: string = `${baseApiURL}/${apiVersion}`;
    return {
        baseApiURL,
        apiURL,
        assetsApiURL: `${apiURL}/assets`,
        assetRequestApiURL: `${apiURL}/assets/requests`,
        dashboardApiURL: `${apiURL}/dashboard`,
        reviewApiURL: `${apiURL}/review`,
        usersApiURL: `${apiURL}/users`,
        userGroupApiURL: `${apiURL}/user-groups`,
        userProfileApiURL: `${apiURL}/user-profile`,
        rolesApiURL: `${apiURL}/roles`,
        categoriesApiURL: `${apiURL}/categories`,
        metaDataApiURL: `${apiURL}/metadata`,
        fileApiURL: `${apiURL}/files`,
        stateMachineApiURL: `${apiURL}/state-machine`,
    };
}

import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: 'ng-template[itemTemplate]',
})
export class ItemTemplateDirective {
    constructor(public template: TemplateRef<any>) {}
}

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: 'ng-template[addItemTemplate]',
})
export class AddItemTemplateDirective {
    @Input() placeInsert: 'start' | 'end' = 'start';

    constructor(public template: TemplateRef<any>) {}
}

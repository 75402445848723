import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { validateFormAndDisplayErrors } from '@dagility-ui/kit';
import { AssetsService } from '@app/shared/services/api/assets.service';
import { AssetDto } from '@app/shared/components/assets/asset.model';
import { assetVersionValidator } from '@app/shared/components/controls/asset-version-input/asset-version-input.component';

@Component({
    selector: 'app-upgrade-version-modal',
    templateUrl: './upgrade-version-modal.component.html',
})
export class UpgradeVersionModalComponent implements OnInit {
    @Input() asset: AssetDto;

    loading = false;

    form: FormGroup;

    constructor(public activeModal: NgbActiveModal, private readonly fb: FormBuilder, private readonly assetsService: AssetsService) {}

    ngOnInit() {
        this.form = this.fb.group({
            assetVersion: [this.asset.assetVersion, [assetVersionValidator(this.asset.assetVersion)]],
        });

        setTimeout(validateFormAndDisplayErrors.bind(this, this.form));
    }

    upgrade() {
        if (this.form.invalid) {
            return;
        }
        this.loading = true;
        this.assetsService.upgradeVersion(this.asset.id, this.form.getRawValue().assetVersion).subscribe(
            response => this.activeModal.close(response),
            () => (this.loading = false),
            () => (this.loading = false)
        );
    }
}

import {
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ElementRef,
    HostBinding,
    Injector,
    NgZone,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { isInDom } from '../chart-libraries/echarts/echarts.chart';

declare let ResizeObserver: any;

@Component({
    selector: 'lib-chart-legend',
    templateUrl: './chart-legend.component.html',
    styleUrls: ['./chart-legend.component.scss'],
})
export class ChartLegendComponent implements OnInit, OnDestroy {
    chartInstance: any;

    items: LegendItem[];

    @ViewChild('container') container: ElementRef<HTMLDivElement>;

    resizeObserver: ResizeObserver;

    @HostBinding('hidden') get isHidden() {
        return !this.items.length;
    }

    constructor(private cdRef: ChangeDetectorRef, private zone: NgZone, private elementRef: ElementRef<HTMLElement>) {}

    ngOnInit(): void {
        this.items = getAllLegendItems(this.chartInstance);
        setTimeout(() => {
            this.cdRef.detectChanges();
            this.zone.runOutsideAngular(() => this.initScroll());
        });
    }

    dispatchAction(type: string, seriesName: string) {
        this.chartInstance.dispatchAction({
            type,
            seriesName,
        });
    }

    handleClick(item: LegendItem) {
        this.chartInstance.dispatchAction({
            type: 'legendToggleSelect',
            name: item.name,
        });
        item.selected = !item.selected;
        this.dispatchLegendChangeEvent();
        this.cdRef.detectChanges();
    }

    legendAllSelect() {
        this.chartInstance.dispatchAction({ type: 'legendAllSelect' });
        this.items.forEach(item => (item.selected = false));
        this.dispatchLegendChangeEvent();
        this.cdRef.detectChanges();
    }

    legendInverseSelect() {
        this.chartInstance.dispatchAction({ type: 'legendInverseSelect' });
        this.items.forEach(item => (item.selected = !item.selected));
        this.dispatchLegendChangeEvent();
        this.cdRef.detectChanges();
    }

    private initScroll() {
        if (!this.items.length) {
            return;
        }
        const container = this.container.nativeElement;
        const wrapper = container.querySelector('ul');
        const leftArrow = container.querySelector<HTMLElement>('.arrow--left');
        const rightArrow = container.querySelector<HTMLElement>('.arrow--right');

        const scrollTo = (left: number) => {
            wrapper.scrollBy({ left, behavior: 'smooth' });
        };
        leftArrow.onclick = () => scrollTo(-100);
        rightArrow.onclick = () => scrollTo(100);
        const updateMenu = () => {
            const width = wrapper.offsetWidth;
            const scrollWidth = wrapper.scrollWidth;
            if (scrollWidth <= width) {
                leftArrow.style.visibility = rightArrow.style.visibility = 'hidden';
            } else {
                leftArrow.style.visibility = wrapper.scrollLeft > 0 ? 'visible' : 'hidden';
                rightArrow.style.visibility = wrapper.scrollLeft < scrollWidth - width ? 'visible' : 'hidden';
            }
        };
        wrapper.onscroll = () => updateMenu();
        this.resizeObserver = new ResizeObserver(() => updateMenu());
        this.resizeObserver.observe(container);
    }

    dispatchLegendChangeEvent() {
        this.elementRef.nativeElement.dispatchEvent(
            new CustomEvent('legend-changed', {
                bubbles: true,
                detail: {
                    legends: this.items,
                },
            })
        );
    }

    ngOnDestroy() {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
            this.resizeObserver = null;
        }
    }
}

interface LegendItem {
    name: string;
    color: string;
    selected?: boolean;
}

function getRenderedRect(chartInstance: any) {
    const rect: any = {
        top: null,
        right: null,
        bottom: null,
        left: null,
    };

    const elements = chartInstance.getZr().storage.getDisplayList(true);
    let xAxisTextBox: any;
    elements.forEach((elem: any) => {
        const rectangle = elem.getBoundingRect().clone();
        if (isNaN(rectangle.x)) {
            return;
        }
        const component = chartInstance._api.getComponentByElement(elem);
        if (component?.type === 'dataZoom.slider' && component.getOrient() === 'horizontal') {
            return;
        }
        if (elem.transform) {
            rectangle.applyTransform(elem.transform);
        }
        const { x, y, width, height } = rectangle;

        if (!xAxisTextBox) {
            if (component && component.mainType === 'xAxis' && elem.type === 'tspan') {
                xAxisTextBox = { bottom: y + height };
            }
        }

        rect.top = Math.min(rect.top === null ? y : rect.top, y);
        rect.left = Math.min(rect.left === null ? x : rect.left, x);
        rect.right = Math.max(rect.right === null ? x + width : rect.right, x + width);
        rect.bottom = Math.max(rect.bottom === null ? y + height : rect.bottom, y + height);
    });

    return { rect, xAxisGapChange: xAxisTextBox ? rect.bottom - xAxisTextBox.bottom : 0 };
}

function toPx(value: string | number, fullSize: number) {
    if (typeof value === 'string') {
        return (parseInt(value, 10) * fullSize) / 100;
    }
    return value;
}
function updateMargins(chartInstance: any) {
    const canvas = chartInstance.getDom().querySelector('canvas');
    const option = chartInstance.getOption();
    const { rect, xAxisGapChange } = getRenderedRect(chartInstance);
    const hasData = getHasData(option);

    const MARGIN = 0;
    let bottomMargin = MARGIN;

    const box = canvas.getBoundingClientRect();

    const horizontalDataZoom = (option.dataZoom || []).find((item: any) => !!item.xAxisIndex);
    if (horizontalDataZoom) {
        bottomMargin +=
            (toPx(horizontalDataZoom.height, box.height) || 0) +
            10 +
            (horizontalDataZoom.bottom ? toPx(horizontalDataZoom.bottom, box.height) : 0);
    }
    const grid = option.grid[0];
    const oldGrid = { ...grid };
    grid.left = toPx(grid.left, box.width) || 0;
    grid.right = toPx(grid.right, box.width) || 0;
    grid.top = toPx(grid.top, box.height) || 0;
    grid.bottom = toPx(grid.bottom, box.height) || 0;
    const isXAxisNeedsToShift = xAxisGapChange > 0;

    Promise.resolve().then(() => {
        if (chartInstance.isDisposed()) {
            return;
        }
        const top = hasData ? MARGIN + (grid.top - rect.top) : oldGrid.top;
        const bottom = bottomMargin + (grid.bottom - (box.height - rect.bottom));

        chartInstance.setOption({
            resize: true,
            ...(isXAxisNeedsToShift
                ? {
                      xAxis: {
                          nameGap: option.xAxis[0].nameGap + xAxisGapChange + 15,
                      },
                  }
                : {}),
            grid: {
                left: !hasData && !option.yAxis[0]?.name ? oldGrid.left : MARGIN + (grid.left - rect.left),
                top: top + bottom > box.height ? grid.top : top,
                bottom: top + bottom > box.height ? grid.bottom : bottom,
                right: hasData ? MARGIN + (grid.right - (box.width - rect.right)) : oldGrid.right,
            },
        });
    });
}

function getHasData({ series }: any) {
    let res = false;
    let i = 0;

    while (res === false && i < series.length) {
        res = series[i].data.some((val: any) => val !== null && val !== undefined && !Number.isNaN(val));
        i++;
    }

    return res;
}

export function adjustMargins(chartInstance: any) {
    const originSetOption = chartInstance.setOption;
    let finishTimeout: any;

    chartInstance.setOption = function(option: any) {
        option.animationDuration = 0;
        option.animationDurationUpdate = 0;

        const hasLegend = option.legend && option.legend.show !== false;
        if (hasLegend) {
            option.legend.show = false;
            // @ts-ignore
            const injector = window['ngModuleInjector'] as Injector;
            if (!injector) {
                console.error('There is no ngModuleInjector found. Skipping legend');
                return;
            }
            const componentFactory = injector.get(ComponentFactoryResolver).resolveComponentFactory(ChartLegendComponent);
            const componentRef = componentFactory.create(injector);
            componentRef.instance.chartInstance = chartInstance;
            const hostEl = componentRef.location.nativeElement;
            const widgetWrapper = chartInstance.getDom().parentNode;
            const legendElement = widgetWrapper.querySelector('lib-chart-legend');

            if (legendElement) {
                legendElement.remove();
            }

            widgetWrapper.appendChild(hostEl);

            setTimeout(() => {
                if (chartInstance.isDisposed()) {
                    return;
                }
                componentRef.changeDetectorRef.detectChanges();
                chartInstance.resize();
            });

            const originDispose = chartInstance.dispose;
            chartInstance.dispose = function() {
                hostEl.parentNode?.removeChild(hostEl);
                componentRef.destroy();

                return originDispose.apply(this, arguments);
            };
        }

        if (option.title && !option.title.text && !option.title.subtext) {
            option.title.show = false;
        }

        if (!option.resize) {
            clearTimeout(finishTimeout);
            finishTimeout = window.setTimeout(() => {
                if (chartInstance.isDisposed()) {
                    return;
                }
                chartInstance.resize();
                chartInstance.one('finished', () => {
                    if (isInDom(chartInstance.getDom())) {
                        updateMargins(chartInstance);
                    }
                });
            }, 60);
        }

        return originSetOption.apply(this, arguments);
    };
}

export function getAllLegendItems(chartInstance: any): LegendItem[] {
    const chartModel = chartInstance.getModel();
    const legendModels = chartModel.findComponents({
        mainType: 'legend',
    });
    if (!legendModels || !legendModels.length) {
        return [];
    }

    const legendModel = legendModels[0];

    const items = legendModel.getData();

    const result: LegendItem[] = [];

    items.forEach((itemModel: any) => {
        const name = itemModel.get('name');
        const seriesModel = chartModel.getSeriesByName(name)[0];

        // Series legend
        if (seriesModel) {
            const data = seriesModel.getDataParams(0);
            let color = data.color;

            // If color is a callback function
            if (typeof color === 'function') {
                // Use the first data
                color = color(seriesModel.getDataParams(0));
            }

            result.push({
                name,
                color,
                selected: !legendModel.isSelected(name),
            });
        } else {
            // Data legend of pie, funnel
            chartModel.eachRawSeries((model: any) => {
                if (model.legendVisualProvider) {
                    const provider = model.legendVisualProvider;

                    if (!provider.containName(name)) {
                        return;
                    }
                    const idx = provider.indexOfName(name);
                    const style = provider.getItemVisual(idx, 'style');
                    const color = style.fill;

                    result.push({
                        name,
                        color,
                    });
                }
            });
        }
    });

    return result;
}

import {KeepNiApi} from '../api/keep-ni-api';
import {IEnvResolver, IHttpClient, IPagePathResolver, IUserSessionResolver, KeepNIConfig} from '../api/keep-ni-config';
import {DefaultManualPathResolver} from "../services/default/default-manual-path-resolver";
import {DefaultEnvResolver} from "../services/default/default-env-resolver";

export class KNI {
    private readonly _api: KeepNiApi;
    private readonly config: KeepNIConfig = new KeepNIConfig();

    get api(): KeepNiApi {
        return this._api;
    }

    constructor(
    ) {
        this._api = new KeepNiApi(this.config);
    }

    public httpClient(httpClient: IHttpClient): KNI {
        this.config.httpClient = httpClient;
        return this;
    }

    public setHttpToken(token: string): KNI {
        this.config.httpClient.setToken(token);
        return this;
    }

    public setHttpTokenHeader(header: string): KNI {
        this.config.httpClient.setHeaderName(header);
        return this;
    }

    public userSessionResolver(userSessionResolver: IUserSessionResolver): KNI {
        this.config.userSessionResolver = userSessionResolver;
        return this;
    }

    public withProductAndCustomer(product: string, customer: string, environment?: string, envId?: string) {
        this.config.envResolver = DefaultEnvResolver.withProductAndCustomer(product, customer, environment, envId);
        return this;
    }

    public envResolver(envResolver: IEnvResolver): KNI {
        this.config.envResolver = envResolver;
        return this;
    }

    public pagePathResolver(pagePathResolver: IPagePathResolver): KNI {
        this.config.pagePathResolver = pagePathResolver;
        return this;
    }

    public withManualPathResolver(): KNI {
        this.config.pagePathResolver = new DefaultManualPathResolver();
        return this;
    }

    start(on: boolean = true): KNI {
        this._api.start(on);
        return this;
    }

    interval(interval: number): KNI {
        this.config.interval = interval;
        return this;
    }

    maxEventBufferSize(maxEventBufferSize: number): KNI {
        this._api.maxEventBufferSize(maxEventBufferSize);
        return this;
    }

    withServiceUrl(serviceUrl: string): KNI {
        this.config.serviceUrl = this.config.serviceUrl || serviceUrl;
        return this;
    }
}

export function kni(serviceUrl?: string): KNI {
    // @ts-ignore
    if (!window['__kni__']) {
        // @ts-ignore
        window['__kni__'] = new KNI();
    }
    // @ts-ignore
    const k: KNI = window['__kni__'];
    k.withServiceUrl(serviceUrl);
    return k;
}

// @ts-ignore
window['_kni'] = kni;


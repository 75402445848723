import { Component, Input, OnChanges } from '@angular/core';

export interface CircleProgressOptionsInterface {
    svgClass?: string;
    backgroundColor?: string;
    backgroundOpacity?: number;
    backgroundStroke?: string;
    backgroundStrokeWidth?: number;
    backgroundPadding?: number;
    percent?: number;
    radius?: number;
    space?: number;
    toFixed?: number;
    maxPercent?: number;
    title?: string;
    titleColor?: string;
    titleFontSize?: string;
    titleFontWeight?: string;
    subtitle?: string;
    subtitleColor?: string;
    subtitleFontSize?: string;
    subtitleFontWeight?: string;

    centerIcon: string;
    showIcon: boolean;
    showTitle?: boolean;
    showSubtitle?: boolean;
    outerStrokeWidth?: number;
    outerStrokeColor?: string;
    outerStrokeLinecap?: string;
    innerStrokeColor?: string;
    innerStrokeWidth?: number;
    showBackground?: boolean;
    showInnerStroke?: boolean;
    clockwise?: boolean;
    responsive?: boolean;
}

export class CircleProgressOptions implements CircleProgressOptionsInterface {
    svgClass = '';
    backgroundColor = 'transparent';
    backgroundOpacity = 1;
    backgroundStroke = 'transparent';
    backgroundStrokeWidth = 0;
    backgroundPadding = 5;
    percent = 0;
    radius = 90;
    space = 4;
    toFixed = 0;
    maxPercent = 100;
    title: string = 'auto';
    titleColor = '#444444';
    titleFontSize = '20';
    titleFontWeight = 'normal';
    subtitle: string = 'Complete';
    subtitleColor = '#A9A9A9';
    subtitleFontSize = '10';
    subtitleFontWeight = 'normal';
    outerStrokeWidth = 8;
    outerStrokeColor = '#317CE2';
    outerStrokeLinecap = 'round';
    innerStrokeColor = '#E5E5E5';
    innerStrokeWidth = 4;
    showTitle = false;
    showSubtitle = false;
    centerIcon = '';
    showIcon = false;
    showBackground = true;
    showInnerStroke = true;
    clockwise = true;
}

@Component({
    selector: 'lib-circle-progress',
    templateUrl: './circle-progress.component.html',
    styleUrls: ['./circle-progress.component.scss'],
})
export class CircleProgressComponent implements OnChanges {
    constructor(defaultOptions: CircleProgressOptions) {
        Object.assign(this.options, defaultOptions);
        Object.assign(this.defaultOptions, defaultOptions);
    }
    @Input() svgClass: string;
    @Input() backgroundColor: string;
    @Input() backgroundOpacity: number;
    @Input() backgroundStroke: string;
    @Input() backgroundStrokeWidth: number;
    @Input() backgroundPadding: number;

    @Input() radius: number;
    @Input() space: number;
    @Input() percent: number;
    @Input() toFixed: number;
    @Input() maxPercent: number;

    @Input() centerIcon: string;
    @Input() centerIconHtml: boolean;
    @Input() showIcon: boolean;
    @Input() centerIconClass: string;

    @Input() title: string;
    @Input() titleYPadding: number;
    @Input() titleColor: string;
    @Input() titleFontSize: string;
    @Input() titleFontWeight: string;

    @Input() subtitle: string;
    @Input() subtitleColor: string;
    @Input() subtitleFontSize: string;
    @Input() subtitleFontWeight: string;

    @Input() outerStrokeWidth: number;
    @Input() outerStrokeColor: string;
    @Input() outerStrokeLinecap: string;

    @Input() innerStrokeColor: string;
    @Input() innerStrokeWidth: string | number;

    @Input() showTitle: boolean;
    @Input() showSubtitle: boolean;
    @Input() showBackground: boolean;
    @Input() showInnerStroke: boolean;
    @Input() clockwise: boolean;
    @Input() responsive: boolean;

    svg: any;

    options: CircleProgressOptions = new CircleProgressOptions();
    defaultOptions: CircleProgressOptions = new CircleProgressOptions();
    render = () => {
        this.applyOptions();
        this.draw(this.options.percent);
    };
    polarToCartesian = (centerX: number, centerY: number, radius: number, angleInDegrees: number) => {
        const angleInRadius = (angleInDegrees * Math.PI) / 180;
        const x = centerX + Math.sin(angleInRadius) * radius;
        const y = centerY - Math.cos(angleInRadius) * radius;
        return { x: x, y: y };
    };
    draw = (percent: number) => {
        // make percent reasonable
        percent = percent === undefined ? this.options.percent : Math.abs(percent);
        // circle percent shouldn't be greater than 100%.
        const circlePercent = percent > 100 ? 100 : percent;
        // determine box size
        let boxSize = this.options.radius * 2 + this.options.outerStrokeWidth * 2;
        if (this.options.showBackground) {
            boxSize += this.options.backgroundStrokeWidth * 2 + this.max(0, this.options.backgroundPadding * 2);
        }
        // the centre of the circle
        const centre = { x: boxSize / 2, y: boxSize / 2 };
        // the start point of the arc
        const startPoint = { x: centre.x, y: centre.y - this.options.radius };
        // get the end point of the arc
        const endPoint = this.polarToCartesian(
            centre.x,
            centre.y,
            this.options.radius,
            (360 * (this.options.clockwise ? circlePercent : 100 - circlePercent)) / 100
        ); // ####################
        // We'll get an end point with the same [x, y] as the start point when percent is 100%, so move x a little bit.
        if (circlePercent === 100) {
            endPoint.x = endPoint.x + (this.options.clockwise ? -0.01 : +0.01);
        }
        // largeArcFlag and sweepFlag
        let largeArcFlag, sweepFlag;
        if (circlePercent > 50) {
            [largeArcFlag, sweepFlag] = this.options.clockwise ? [1, 1] : [1, 0];
        } else {
            [largeArcFlag, sweepFlag] = this.options.clockwise ? [0, 1] : [0, 0];
        }

        const titlePercent = this.options.percent;
        const titleTextPercent = titlePercent > this.options.maxPercent ?
            `${this.options.maxPercent.toFixed(this.options.toFixed)}+` : `${titlePercent.toFixed(this.options.toFixed)}%`;
        // get title object
        const title = {
            x: centre.x,
            y: centre.y,
            textAnchor: 'middle',
            color: this.options.titleColor,
            fontSize: this.options.titleFontSize,
            fontWeight: this.options.titleFontWeight,
            texts: [] as string[],
            tspans: [] as any[]
        };

            if (this.options.title === 'auto') {
                title.texts.push(titleTextPercent);
            }

        // get subtitle object
        const subtitle = {
            x: centre.x,
            y: centre.y,
            textAnchor: 'middle',
            color: this.options.subtitleColor,
            fontSize: this.options.subtitleFontSize,
            fontWeight: this.options.subtitleFontWeight,
            texts: [] as string[],
            tspans: [] as any[]
        };
        subtitle.texts.push(this.options.subtitle.toString());
        // get total count of text lines to be shown
        let rowCount = 0, rowNum = 1;
        this.options.showTitle && (rowCount += title.texts.length);
        this.options.showSubtitle && (rowCount += subtitle.texts.length);
        // calc dy for each tspan for title
        if (this.options.showTitle) {
            for (const span of title.texts) {
                title.tspans.push({ span: span, dy: this.getRelativeY(rowNum, rowCount) });
                rowNum++;
            }
        }
        // calc dy for each tspan for subtitle
        if (this.options.showSubtitle) {
            for (const span of subtitle.texts) {
                subtitle.tspans.push({ span: span, dy: this.getRelativeY(rowNum, rowCount) });
                rowNum++;
            }
        }

        this.options.centerIcon = this.centerIcon;
        this.options.showIcon = this.showIcon;

        // Bring it all together
        this.svg = {
            viewBox: `0 0 ${boxSize} ${boxSize}`,
            // Set both width and height to '100%' if it's responsive
            width: boxSize,
            height: boxSize,
            backgroundCircle: {
                cx: centre.x,
                cy: centre.y,
                r: this.options.radius + this.options.outerStrokeWidth / 2 + this.options.backgroundPadding,
                fill: this.options.backgroundColor,
                fillOpacity: this.options.backgroundOpacity,
                stroke: this.options.backgroundStroke,
                strokeWidth: this.options.backgroundStrokeWidth,
            },
            path: {
                // A rx ry x-axis-rotation large-arc-flag sweep-flag x y (https://developer.mozilla.org/en/docs/Web/SVG/Tutorial/Paths#Arcs)
                d: `M ${startPoint.x} ${startPoint.y}
        A ${this.options.radius} ${this.options.radius} 0 ${largeArcFlag} ${sweepFlag} ${endPoint.x} ${endPoint.y}`,
                stroke: this.options.outerStrokeColor,
                strokeWidth: this.options.outerStrokeWidth,
                strokeLinecap: this.options.outerStrokeLinecap,
                fill: 'none',
            },
            circle: {
                cx: centre.x,
                cy: centre.y,
                r: this.options.radius - this.options.space - this.options.outerStrokeWidth / 2 - this.options.innerStrokeWidth / 2,
                fill: 'none',
                stroke: this.options.innerStrokeColor,
                strokeWidth: this.options.innerStrokeWidth,
            },
            title: title,
            subtitle: subtitle,
        };
    };

    private applyOptions = () => {
        // the options of <circle-progress> may change already
        for (const name of Object.keys(this.options)) {
            if (this.hasOwnProperty(name) && (this as Record<string, any>)[name] !== undefined) {
                (this.options as Record<string, any>)[name] = (this as Record<string, any>)[name];
                // } else if (this.templateOptions && this.templateOptions[name] !== undefined) {
                //     this.options[name] = this.templateOptions[name];
            }
        }
        // make sure key options valid
        this.options.radius = Math.abs(+this.options.radius);
        this.options.space = +this.options.space;
        this.options.percent = Math.abs(+this.options.percent);
        this.options.maxPercent = Math.abs(+this.options.maxPercent);
        this.options.outerStrokeWidth = Math.abs(+this.options.outerStrokeWidth);
        this.options.innerStrokeWidth = Math.abs(+this.options.innerStrokeWidth);
        this.options.backgroundPadding = +this.options.backgroundPadding;
    };

    private max = (a: any, b: any) => {
        return a > b ? a : b;
    };

    private getRelativeY = (rowNum: number, rowCount: number): string => {
        // why '-0.18em'? It's a magic number when property 'alignment-baseline' equals 'baseline'. :)
        const initialOffset = -0.18, offset = 1;
        return (initialOffset + offset * (rowNum - rowCount / 2)).toFixed(2) + 'em';
    };

    ngOnChanges() {
        this.render();
    }
}

import { EChartsChart } from '../echarts.chart';
import { Subject } from 'rxjs';

import { qualitativePalette, subTextColor, textColor } from '../charts-colors';

export class AxisLineEChartsChart extends EChartsChart {
    options = {
        textStyle: {
            fontFamily: 'sans-serif',
            fontSize: 12,
        },
        grid: {
            top: '15%',
            bottom: '45%',
        },
        title: {
            text: '',
            subtext: '',
            textAlign: 'center',
            textVerticalAlign: 'top',
            left: '50%',
            textStyle: {
                color: textColor(),
                fontSize: 14,
                fontWeight: '500',
                fontFamily: 'sans-serif',
            },
            subtextStyle: {
                color: subTextColor(),
                fontSize: 12,
                fontFamily: 'sans-serif',
            },
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: [] as any[],
            axisLabel: {
                fontSize: 10,
                rotate: 45,
                margin: 11,
                interval: 0,
                textStyle: {
                    color: textColor(),
                },
            },
            axisLine: {
                show: false,
            },
            axisTick: {
                show: false,
                alignWithLabel: true,
            },
            nameLocation: 'center',
            nameGap: 55,
            nameTextStyle: {
                color: textColor(),
            },
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                textStyle: {
                    color: textColor(),
                },
            },
            axisLine: {
                show: false,
            },
            axisTick: {
                show: false,
            },
            nameLocation: 'center',
            nameGap: 48,
            nameTextStyle: {
                color: textColor(),
            },
        },
        legend: {
            bottom: '0',
            icon: 'circle',
            width: '90%',
            textStyle: {
                fontSize: 11,
                color: textColor(),
            },
            selector: ['all', 'inverse'],
            selectorPosition: 'start',
        },
        series: [] as any[],
        color: qualitativePalette,
        tooltip: {
            trigger: 'axis',
            renderMode: 'html',
            axisPointer: {
                type: 'line',
                lineStyle: {
                    width: 0,
                },
            },
        },
        dataZoom: [
            {
                width: '80%',
                height: '8%',
                id: 'dataZoomX',
                type: 'slider',
                xAxisIndex: [0],
                filterMode: 'filter',
                showDetail: false,
                start: 30,
                bottom: 30,
            },
        ],
    };

    typeAttributes = {
        type: 'line',
        smooth: true,
        symbol: 'rect',
        symbolSize: 8,
        showAllSymbol: 'auto',
        animation: true,
    };

    assignOptions(options: Record<string, any>) {
        if (options.hasOwnProperty('editWidgetMode') && options.editWidgetMode) {
            this.options.yAxis.nameGap = 25;
        }
        super.assignOptions(options);
    }

    handleEvents(emitter: Subject<{ type: string; payload: any }>, chartInstance: any) {
        if (!chartInstance) {
            return;
        }

        chartInstance.on('click', 'series', (event: any) => {
            emitter.next({
                type: 'barClicked',
                payload: {
                    barId: event.seriesId,
                    barName: event.seriesName,
                },
            });
        });

        // drawLegend(chartInstance);
        super.handleEvents(emitter, chartInstance);
    }
}

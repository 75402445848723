<form *transloco="let t; read: 'common.downloadFilesModal'" (reset)="activeModal.dismiss('Cancel')">
    <div class="modal-header">
        <h1 class="mt-0">{{ t('title') }}</h1>
        <button type="reset" class="close" aria-label="Close button" aria-describedby="modal-title">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body p-4">
        <div class="line-block mb-16px">
            <checkbox [value]="allSelected" (click)="selectAll()">
                {{ t(allSelected ? 'unselectAll' : 'selectAll') }}
            </checkbox>
        </div>
        <div class="line-block" *ngFor="let file of files">
            <ng-container *ngIf="{ val: isDownloading(file.uuid) } as isDownloading">
                <div class="filename-block text-truncate" (click)="selectFile(file)"
                     [class.active]="file.hover || isDownloading.val"
                >
                    <checkbox [value]="file.selected"></checkbox>
                    <fa-icon icon="file" class="file-icon secondary-text-and-icons font-16px mr-10px"></fa-icon>
                    <span class="text-truncate font-14px"
                          [ngbTooltip]="file.originalFileName"
                          container="body"
                          triggers="manual"
                          libTooltipWhenOverflow
                    >
                        {{ file.originalFileName }}
                    </span>
                </div>
                <span *ngIf="file.size" class="secondary-text-and-icons font-14px text-nowrap ml-8px">
                    {{ file.size | fileSize }}
                </span>
                <div class="d-flex align-items-center pl-10px ml-auto">
                    <button class="btn btn-link px-8px"
                            *ngIf="!isDownloading.val"
                            (mouseover)="file.hover = true"
                            (mouseout)="file.hover = false"
                            (click)="download(file)"
                    >
                        <fa-icon [icon]="icons.download"></fa-icon>
                    </button>
                    <div class="d-flex align-items-center color-brand font-12px" *ngIf="isDownloading.val">
                        <ng-container *ngIf="getDownload(file) as download">
                            <div class="progress-bar">
                                <div class="progress" [style.width.%]="download.progress"></div>
                            </div>
                            <span class="ml-8px">{{ download.progress }}%</span>
                            <span class="ml-4">{{ t('downloading') }}</span>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

    <div class="modal-footer d-flex justify-content-end">
        <div *ngIf="selected !== 0" class="font-14px">
            <span class="secondary-text-and-icons">{{ t('selected') }}: </span>{{ selected }} {{ t('files') }}
        </div>
        <button type="reset" class="btn btn-secondary ml-16px">
            {{ t('cancelBtn') }}
        </button>
        <button type="button" class="btn btn-primary ml-24px"
                *ngIf="selected !== 0"
                (click)="downloadSelected()"
                [btnWithLoader]="downloadingSelected"
                [disabled]="downloadingSelected"
        >
            <fa-icon [icon]="icons.download" class="font-16px mr-10px"></fa-icon>
            {{ t('downloadSelectedBtn') }}
        </button>
    </div>
</form>

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { logInIcon } from "@app/shared/icons/solutionhub-icons";
import { AuthService } from "@app/auth";

@Component({
    selector: 'app-login-modal',
    templateUrl: './login-modal.component.html'
})
export class LoginModalComponent implements OnInit {
    @Input() assetId: number;
    @Input() assetVersionId: number;

    readonly icons = {
        logIn: logInIcon
    };

    constructor(
        public activeModal: NgbActiveModal,
        private authService: AuthService
    ) {}

    ngOnInit() {}

    login() {
        this.authService.login(true);
    }

    register() {
        this.authService.register();
    }
}

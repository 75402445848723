import { AfterViewInit, Component, Input, OnDestroy, ViewChildren } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import { Id } from '@app/shared/services/api/assets.service';
import { Category } from '@app/shared/models/category.model';
import { CategoryService } from '@app/shared/services/api/category.service';
import { AssetFiltersService, Filter, FilterType } from '@app/shared/components/assets/asset-filters.service';

@Component({
    selector: 'app-assets-page-filters',
    templateUrl: './assets-page-filters.component.html',
    styleUrls: ['./assets-page-filters.component.scss'],
})
export class AssetsPageFiltersComponent implements AfterViewInit, OnDestroy {
    private readonly destroy$ = new Subject();

    @Input() hasRouteObservation: boolean = true;

    @ViewChildren(NgbAccordion) accordions: NgbAccordion[];

    selectedCategoryFilter: Filter;

    readonly rootCategories$: BehaviorSubject<Category[]> = this.categoryService.categories$;

    readonly filters$: Observable<Filter[]> = this.assetFiltersService.filters$.pipe(
        takeUntil(this.destroy$),
        tap(filters => filters.forEach(f => this.setIsFilterExpanded(f.id, this.getIsFilterExpanded(f.id) || false)))
    );

    private isFilterExpandedMap: Map<number | string, boolean> = new Map<number | string, boolean>();

    constructor(private readonly categoryService: CategoryService, private readonly assetFiltersService: AssetFiltersService) {}

    ngAfterViewInit(): void {
        this.subscribeOnTakingFilters();
    }

    private subscribeOnTakingFilters() {
        combineLatest([this.assetFiltersService.selectedCategoryFilters$, this.assetFiltersService.selectedFilters$])
            .pipe(
                takeUntil(this.destroy$),
                tap(([categoryFilters, filters]) => this.handleFilters(categoryFilters, filters))
            )
            .subscribe();
    }

    private handleFilters(categoryFilters: Filter[], filters: Filter[]) {
        this.accordions.forEach(accordion => accordion.collapseAll());

        this.selectedCategoryFilter = categoryFilters.find(f => f);
        const allPrimaryCategories = this.rootCategories$.value.map(category => category.children).reduce((a, b) => a.concat(b), []);

        const primaryCategory = allPrimaryCategories.find(
            pc => pc.id === this.selectedCategoryFilter?.id || pc.children.map(c => c.id).includes(this.selectedCategoryFilter?.id)
        );

        if (primaryCategory) {
            this.accordions
                .find(accordion => accordion.panels.map(p => p.id).includes(`category-${primaryCategory.id}`))
                ?.toggle(`category-${primaryCategory.id}`);
        }

        this.assetFiltersService.filters$.value.forEach(filter => {
            const type: FilterType = filter.type;
            if (
                (filter.children || []).some(f =>
                    filters
                        .filter(sf => sf.type === type)
                        .map(sf => sf.id)
                        .includes(f.id)
                )
            ) {
                this.accordions
                    .find(accordion => accordion.panels.map(p => p.id).includes(`filter-${filter.id}-${type}`))
                    ?.toggle(`filter-${filter.id}-${type}`);
            }
        });
    }

    selectCategory(id: Id, isPrimary: boolean = false) {
        if (this.assetFiltersService.assetFilter.primaryCategoryId == id || this.assetFiltersService.assetFilter.categoryId == id) {
            if (this.hasRouteObservation) {
                this.assetFiltersService.updateRouteByAssetFilter({
                    primaryCategoryId: null,
                    categoryId: null,
                    fieldValues: null,
                });
            } else {
                this.assetFiltersService.forceUpdateAssetFilter({
                    primaryCategoryId: null,
                    categoryId: null,
                    fieldValues: null,
                });
            }
            return;
        }
        if (this.hasRouteObservation) {
            this.assetFiltersService.updateRouteByAssetFilter(
                isPrimary
                    ? {
                          primaryCategoryId: id,
                          categoryId: null,
                          fieldValues: null,
                      }
                    : { primaryCategoryId: null, categoryId: id, fieldValues: null }
            );
        } else {
            this.assetFiltersService.forceUpdateAssetFilter(
                isPrimary
                    ? {
                          primaryCategoryId: id,
                          categoryId: null,
                          fieldValues: null,
                      }
                    : { primaryCategoryId: null, categoryId: id, fieldValues: null }
            );
        }
    }

    setIsFilterExpanded(filterId: number | string, value: boolean): void {
        this.isFilterExpandedMap.set(filterId, value);
    }

    getIsFilterExpanded(filterId: number | string): boolean {
        return this.isFilterExpandedMap.get(filterId);
    }

    toFilters(filters: unknown): Filter[] {
        return filters as Filter[];
    }

    handleCheckbox(filter: Filter, value: boolean) {
        const selectedFilters: Filter[] = [...this.assetFiltersService.selectedFilters$.value];
        this.calculateFilter(value ? [...selectedFilters, filter] : selectedFilters.filter(item => item !== filter));
    }

    private calculateFilter(filters: Filter[]) {
        const groupIds = filters.filter(item => item.type === 'PUBLISHER').map(item => item.id);
        const dropdownIds = filters.filter(item => item.type === 'TAXONOMY').map(item => item.id);
        const fieldValues = filters.filter(item => item.type === 'METADATA_BY_CATEGORY').map(item => item.id);
        if (this.hasRouteObservation) {
            this.assetFiltersService.updateRouteByAssetFilter({
                groupIds,
                dropdownIds,
                fieldValues,
            });
        } else {
            this.assetFiltersService.forceUpdateAssetFilter({
                groupIds,
                dropdownIds,
                fieldValues,
            });
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
    }
}

import {TrackingTTFBEvent} from '../model/track-events';

import {TrackEventSenderService} from './track-event-sender.service';


export class TrackTTFBService {

    private performanceObserver: PerformanceObserver;

    private static currentPage(): string {
        return (location.hash ? location.hash.substring(1) : location.pathname);
    }

    constructor(
        private eventSender: TrackEventSenderService
    ) {
    }

    trackPageTTFB() {
        const nav = performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming;
        const ttfb = parseInt(String(nav.responseStart - nav.requestStart), 10);
        const page = TrackTTFBService.currentPage();
        const ttfbEvent: TrackingTTFBEvent = new TrackingTTFBEvent(ttfb, page, true);
        this.eventSender.sendEvent(ttfbEvent, page, true);
    }

    public subscribe() {
        const ut = this;
        this.performanceObserver = new PerformanceObserver((list: any, obj: any) => {
            ut.catchResponseEvents.apply(ut, [list, obj]);
        });
        this.performanceObserver.observe({entryTypes: ['resource']});
    }

    private catchResponseEvents(list: any, _obj: any) {
        let entries = list.getEntries() as PerformanceResourceTiming[];
        entries = entries.filter(value => value.initiatorType === 'xmlhttprequest');
        const eventData = entries
            .filter(e => !e.name.endsWith(TrackEventSenderService.EVENTS_ENDPOINT))
            .map(resEntry => {
                const ttfb = parseInt(String(resEntry.responseStart - resEntry.requestStart), 10);
                const ttfbResEvent: TrackingTTFBEvent = new TrackingTTFBEvent(ttfb, resEntry.name, false);
                return {event: ttfbResEvent, page: resEntry.name};
            });
        eventData.forEach((data) => {
            this.eventSender.sendEvent(data.event, null, true);
        });
    }
}

import { Component, HostBinding, Input } from '@angular/core';
import { AssetStatus } from '@app/shared/components/assets/asset.model';

@Component({
    selector: 'app-asset-status-badge',
    templateUrl: './asset-status-badge.component.html',
    styleUrls: ['./asset-status-badge.component.scss'],
})
export class AssetStatusBadgeComponent {
    @Input() status: AssetStatus = AssetStatus.DRAFT;

    @HostBinding('class')
    get class() {
        return `custom-badge custom-badge-background status-${this.status}`;
    }
}

import { Component, HostBinding, Input } from '@angular/core';
import { AssetStatus } from '../../asset.model';

@Component({
    selector: 'app-asset-status-label',
    templateUrl: './asset-status-label.component.html',
    styleUrls: ['./asset-status-label.component.scss'],
})
export class AssetStatusLabelComponent {
    @Input() status: AssetStatus = AssetStatus.DRAFT;

    @HostBinding('class') get class() {
        return `status-${this.status}`;
    }
}

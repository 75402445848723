import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { AssetActionResponse, AssetDto } from '../../asset.model';
import { FileService } from '@app/shared/services/api/file.service';
import { KeepNiService } from '@app/shared/services/keep-ni.service';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Component({
    selector: 'app-assets-list',
    templateUrl: './assets-list.component.html',
})
export class AssetsListComponent implements OnDestroy {
    private readonly destroy$: Subject<any> = new Subject<any>();
    @Input() assets: AssetDto[] = [];

    @Input() isManageAssets: boolean = false;

    @Input() hasManagementActions: boolean;

    @Input() hasActionButtons: boolean = true;

    @Input() canSelectAssets: boolean = false;

    @Input() showStatus = false;

    @Input() readOnly = false;

    @Input() isLoggedIn = false;

    @Input() isGridView = false;

    @Output() managementActionDone: EventEmitter<AssetActionResponse> = new EventEmitter<AssetActionResponse>();

    constructor(private readonly router: Router, private readonly fileService: FileService, private readonly kniService: KeepNiService) {
        this.kniService.setPage(this.router.url);
        this.fileService.assetDownloadEvent$
            .pipe(
                takeUntil(this.destroy$),
                filter(() => !this.isManageAssets)
            )
            .subscribe(e => this.kniService.sendEvent(e));
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }
}

import { EChartsChart } from '../echarts.chart';
import { Subject } from 'rxjs';
import { Injector } from '@angular/core';
import { cloneDeep } from 'lodash';

import { drawLegend } from '../pie.legend';
import { qualitativePalette, subTextColor, textColor } from '../charts-colors';

const EMPTY_DOUGHNUT_SERIES = {
    data: [
        {
            emphasis: {
                scale: false,
                itemStyle: {
                    color: '#E9E9E9',
                },
            },
            cursor: 'default',
            tooltip: {
                show: false,
            },
            label: {
                show: false,
            },
            value: 1,
            itemStyle: {
                color: '#E9E9E9',
            },
        },
    ],
};

export class DoughnutEChartsChart extends EChartsChart {
    options: Record<string, any> = {
        textStyle: {
            fontFamily: 'sans-serif',
            fontSize: 12,
            color: textColor(),
        },
        grid: {
            top: '5%',
            bottom: '40%',
        },
        title: {
            text: '',
            subtext: '',
            textAlign: 'center',
            textVerticalAlign: 'middle',
            left: '49%',
            top: '44%',
            textStyle: {
                color: subTextColor(),
                fontSize: 14,
                fontWeight: 'bold',
                fontFamily: 'sans-serif',
                verticalAlign: 'bottom',
            },
            subtextStyle: {
                color: textColor(),
                fontSize: 30,
                fontWeight: 'bold',
                fontFamily: 'sans-serif',
            },
        },
        xAxis: {
            show: false,
            name: '',
        },
        yAxis: {
            show: false,
            name: '',
        },
        legend: {
            show: true,
            bottom: '0',
            icon: 'circle',
            textStyle: {
                fontSize: 11,
                color: textColor(),
            },
            width: '85%',
            selector: ['all', 'inverse'],
            selectorPosition: 'start',
        },
        series: [] as any[],
        color: qualitativePalette,
        tooltip: {
            trigger: 'item',
            textStyle: {
                fontSize: 12,
            },
            formatter: this.toolTipFormatter,
        },
    };

    typeAttributes: any = {
        type: 'pie',
        stillShowZeroSum: false,
        radius: ['45%', '65%'],
        center: ['50%', '50%'],
    };

    onCustomize = false;
    editWidgetMode = false;
    isEmpty = false;

    get shouldRenderCustomLegend() {
        return !this.onCustomize && !this.editWidgetMode && this.options.hasOwnProperty('tableHeader1') && this.options.tableHeader1 !== '';
    }

    assignOptions(options: Record<string, any>) {
        this.onCustomize = options.onCustomize;
        this.editWidgetMode = options.editWidgetMode;

        if (options.series && options.series[0].data && options.series[0].data.length > 6 && this.options.legend.show) {
            this.options.legend['type'] = 'scroll';
            this.options.legend['pageFormatter'] = 'page {current} of {total}';
            this.options.legend['pageButtonItemGap'] = 30;
        }

        // table data available for the widget. So show table on the remaining width
        if (options.tableHeader1) {
            if (options.onCustomize || options.editWidgetMode) {
                this.options.title.left = '50%';
                this.options.title.top = '48%';
                this.options.grid.bottom = '50%';
            } else {
                this.typeAttributes.center = ['25%', '40%'];
                this.options.title.left = '25%';
                this.options.title.top = '38%';
            }
            this.options.title.textStyle['fontSize'] = 14;
            this.options.title.subtextStyle['fontSize'] = 24;
        } else {
            if (this.onCustomize) {
                this.options.title.textStyle['fontSize'] = 8;
                this.options.title.subtextStyle['fontSize'] = 9;
                this.options.legend.width = '70%';
                this.options.title.left = '46%';
            } else {
                this.options.title.textStyle['fontSize'] = 10;
                this.options.title.subtextStyle['fontSize'] = 14;
                this.options.legend.width = '62%';
            }

            if (options.customTextValue) {
                this.options.legend.show = options.legend.show;
                this.typeAttributes.radius = ['60%', '70%'];
                this.options.tooltip.position = ['10%', '50%'];
            }

            if (this.options.legend.show) {
                this.options.legend.width = '62%';
                this.options.legend.selectorPosition = 'auto';
                this.options.legend.textStyle.fontSize = 10;
                this.options.legend['selectorLabel'] = { fontSize: 10 };
            }
        }

        options.series.forEach((element: any) => {
            element.label = {
                show: false,
            };
            element.labelLine = {
                show: false,
            };
            element.data = element.data.map((elem: any, i: number) => {
                // dynamic color option for WC WIdgets -- will be removed later
                let widgetCunstructorData = elem.hasOwnProperty('value') && elem.value.hasOwnProperty('value');
                let customItemStyle;
                let customValue;
                if (widgetCunstructorData) {
                    let custElem = Object.assign({}, elem.value);
                    customValue = custElem.value;
                    customItemStyle = !options.colors.length ? custElem.color : undefined;
                    elem.value = customValue;
                }
                // -----------------
                return {
                    name: elem.hasOwnProperty('priorityName') ? elem['priorityName'] : elem['name'],
                    value: elem.hasOwnProperty('count') ? elem['count'] : elem['y'] !== undefined ? elem['y'] : elem.value,
                    itemStyle: {
                        color: customItemStyle ? customItemStyle : options.colors[i % options.colors.length],
                    },
                };
            });
            if (!options.anyWidget || options.showTotal) {
                this.options.title.text = options.piWorkChartLabel ? options.piWorkChartLabel : 'Total';
                this.options.title.subtext = element.data.reduce((prev: any, current: any) => prev + Number(current.value), 0);
            }
            if (options.showCount) {
                this.options.title.text = options.piWorkChartLabel ? options.piWorkChartLabel : 'Count';
                this.options.title.subtext = element.data.length;
                this.options.title.left = '49%';
                this.options.title.top = '47%';
            }
            if (options.anyWidget && (options.showTotal || options.showCount)) {
                this.options.title.textStyle['fontSize'] = 14;
                this.options.title.subtextStyle['fontSize'] = 24;
            }

            if (options.title.show === false && !(options.showTotal || options.showCount)) {
                this.options.title.show = false;
                return;
            }

            if (options.customTextValue) {
                this.options.title.text = options.title.subtext;
                this.options.title.subtext = element.data.reduce((prev: any, current: any) => prev + Number(current.value), 0);
                this.options.title.textStyle = options.title.subtextStyle;
                this.options.title.subtextStyle = options.title.textStyle;
                this.options.title.left = '48%';
                this.options.title.top = '47%';
            }
        });

        this.checkIsEmpty(options);

        if (this.isEmpty) {
            this.assignEmptyDoughnutSeries(options);

            if (options.legend) {
                options.legend.show = false;
            }
        }

        if (options.showZeroValue && this.isEmpty) {
            this.options.title.subtext = '0';
        }

        if (!options.showZeroValue) {
            this.options.title.show = !this.isEmpty;
        }

        super.assignOptions(options);
        if (this.shouldRenderCustomLegend) {
            this.options.legend.show = false;
        }
    }

    randomColor() {
        const r = Math.floor(Math.random() * 255);
        const g = Math.floor(Math.random() * 255);
        const b = Math.floor(Math.random() * 255);
        return `rgb(${r},${g},${b})`;
    }

    handleEvents(emitter: Subject<{ type: string; payload: any }>, chartInstance: any, injector: Injector) {
        if (!chartInstance) {
            return;
        }

        chartInstance.on('click', 'series.pie', (event: any) => {
            if (this.isEmpty) {
                return;
            }

            emitter.next({
                type: 'barClicked',
                payload: {
                    barName: event.name,
                },
            });
        });

        if (this.shouldRenderCustomLegend) {
            drawLegend(chartInstance, injector);
        }
        super.handleEvents(emitter, chartInstance);
    }

    toolTipFormatter(params: any) {
        let output = '';
        if (params.data.prop) {
            params.data.prop.forEach((elem: any) => {
                output = `${output}${elem}<br/>`;
            });
        } else {
            output =
                '<label style="border-radius: 50%;width: 10px;height: 10px;vertical-align: middle;margin: 0;background:' +
                params.color +
                '"></label>&nbsp;&nbsp;';
            output += params.name + ': ' + params.value + ' (' + params.percent + '%)<br/>';
        }
        return output;
    }

    private checkIsEmpty(options: Record<string, any>) {
        this.isEmpty = (options.series || []).every((series: Record<string, any>) =>
            (series.data || []).every(({ value }: { value: number | null }) => value == 0)
        );
    }

    private assignEmptyDoughnutSeries(options: Record<string, any>) {
        let [internalRadius] = this.typeAttributes.radius;
        internalRadius = parseInt(internalRadius, 10);
        options.series = [
            cloneDeep(EMPTY_DOUGHNUT_SERIES),
            Object.assign(cloneDeep(EMPTY_DOUGHNUT_SERIES), {
                seriesCustomOptions: {
                    radius: [`${internalRadius - 2}%`, `${internalRadius - 1}%`],
                },
            }),
        ];
    }
}

import { environment } from '@app/env';
import Keycloak from 'keycloak-js';
import { BehaviorSubject, from, Subject } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { ENV_TOKEN } from '@app/tokens';
import { AUTHENTICATED_STORAGE_NAME } from '@app/core/auth/auth.constants';

@Injectable({
    providedIn: 'root',
})
export class KeycloakService {
    private readonly instance = new Keycloak(this.env.keycloakParams);

    private timerHandler: number;
    private logEnabled = false;

    readonly onRefreshToken$ = new Subject<void>();
    readonly authenticated$ = new BehaviorSubject(false);

    constructor(@Inject(ENV_TOKEN) private readonly env: Env) {
        this.instance.onAuthRefreshSuccess = () => {
            this.log('The Keycloak token has been refreshed successfully');
        };
        this.instance.onAuthRefreshError = () => {
            console.error('Cannot refresh the Keycloak authorization token.');
        };
        this.instance.onReady = (result: boolean) => {
            this.log('The Keycloak service is ready with result =' + result);
        };
        this.instance.onTokenExpired = () => {
            this.log('The Keycloak token has expired.');
            this.getUpdateToken$().subscribe();
        };
        this.instance.onAuthLogout = () => {
            this.authNotify(false);
            this.clearRefreshTimeout();
        };
    }

    getUpdateToken$() {
        this.clearRefreshTimeout();
        return from(this.instance.updateToken(-1)).pipe(
            tap(
                () => {
                    this.onRefreshToken$.next();
                    this.authNotify(true);
                    this.log('The Keycloak token has been refreshed.');
                },
                () => this.authNotify(false)
            )
        );
    }

    /**
     * init and login
     */
    login(loginRequired: boolean = false): Promise<any> {
        this.clearRefreshTimeout();
        this.instance.clearToken();
        return this.instance
            .init({
                onLoad: loginRequired ? 'login-required' : 'check-sso',
                checkLoginIframe: !!environment.production,
                redirectUri: location.href,
            })
            .then(authSuccess => this.authNotify(authSuccess));
    }

    /**
     * logout
     */
    logout(): Promise<void> {
        this.clearRefreshTimeout();
        return new Promise<void>((resolve, reject) => {
            if (this.instance.authenticated) {
                this.instance
                    .logout({ redirectUri: '' })
                    .then(() => {
                        this.authNotify(false);
                        resolve();
                    })
                    .catch(() => {
                        reject('Failed to logout');
                    });
            } else {
                this.authNotify(false);
                resolve();
            }
        });
    }

    register(): Promise<any> {
        return this.instance.register({ redirectUri: location.href });
    }

    getTokenApi() {
        return this.instance.token || '';
    }

    private authNotify(authenticated: boolean) {
        localStorage.setItem(AUTHENTICATED_STORAGE_NAME, String(authenticated));
        this.authenticated$.next(authenticated);
    }

    private clearRefreshTimeout() {
        if (this.timerHandler) {
            this.log('The Keycloak token refresh timer is OFF.');
            window.clearTimeout(this.timerHandler);
            this.timerHandler = null;
        }
    }

    private log(msg: string) {
        if (this.logEnabled) {
            console.log(msg);
        }
    }
}

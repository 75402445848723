import { EChartsChart } from '../echarts.chart';
import { qualitativePalette, textColor } from '../charts-colors';

export class SunburstEchartsChart extends EChartsChart {
    options: Record<string, any> = {
        noHoverOnBar: true,
        textStyle: {
            fontFamily: 'sans-serif',
            fontSize: 12,
            color: textColor(),
        },
        xAxis: {
            show: false,
            name: '',
        },
        yAxis: {
            show: false,
            name: '',
        },
        series: {
            type: 'sunburst',
            highlightPolicy: 'ancestor',
            data: [],
            radius: [0, '90%'],
            label: {
                rotate: 'tangential',
                minAngle: 25,
                formatter: (args: any) => {
                    const label: string = args.data.name;
                    return label ? (label.length > 7 ? label.substr(0, 7) + '...' : label) : '';
                },
            },
        },
        tooltip: {
            trigger: 'item',
            textStyle: {
                fontSize: 12,
            },
        },
        color: qualitativePalette,
    };

    typeAttributes = {};

    assignOptions(options: Record<string, any>) {
        this.options.series.data = options.series.data;
    }
}

import { EChartsChart } from '../echarts.chart';
import { Subject } from 'rxjs';

import { qualitativePalette, subTextColor, textColor } from '../charts-colors';

let ctx: CanvasRenderingContext2D;
let font: string;
function measureText(text: string) {
    if (!ctx) {
        ctx = document.createElement('canvas').getContext('2d');
        ctx.font = font;
    }
    return ctx.measureText(text).width;
}

export class GroupedBarEChartsChart extends EChartsChart {
    options: Record<string, any> = {
        textStyle: {
            fontFamily: 'sans-serif',
            fontSize: 12,
        },
        grid: {
            top: '15%',
            bottom: '35%',
        },
        title: {
            text: '',
            subtext: '',
            textAlign: 'center',
            textVerticalAlign: 'top',
            left: '50%',
            textStyle: {
                color: textColor(),
                fontSize: 14,
                fontWeight: '500',
                fontFamily: 'sans-serif',
            },
            subtextStyle: {
                color: subTextColor(),
                fontSize: 12,
                fontFamily: 'sans-serif',
            },
        },
        xAxis: {
            type: 'category',
            boundaryGap: true,
            data: [] as any[],
            triggerEvent: true,
            axisLabel: {
                rotate: '45',
                fontSize: 10,
                margin: 11,
                interval: 0,
                formatter: (textLine: string) => {
                    if (!font || !this.chartInstance) {
                        return textLine;
                    }
                    const containerWidth = this.chartInstance
                        .getModel()
                        .getSeries()[0]
                        .coordinateSystem.getBaseAxis()
                        .getBandWidth();
                    let lineWidth = measureText(textLine);
                    if (lineWidth <= containerWidth) {
                        return textLine;
                    }
                    for (let j = 0; ; j++) {
                        if (lineWidth <= containerWidth || j >= 100) {
                            textLine += '...';
                            break;
                        }

                        textLine = textLine.slice(0, -1);
                        lineWidth = measureText(textLine);
                    }
                    return textLine;
                },
                textStyle: {
                    color: textColor(),
                },
            },
            axisLine: {
                onZero: true,
                show: false,
            },
            axisTick: {
                show: false,
                alignWithLabel: true,
            },
            nameLocation: 'center',
            nameGap: 60,
            nameTextStyle: {
                color: textColor(),
            },
        },
        yAxis: {
            inverse: false,
            axisLabel: {
                textStyle: {
                    color: textColor(),
                },
            },
            axisLine: {
                show: false,
            },
            axisTick: {
                show: false,
            },
            nameLocation: 'center',
            nameGap: 54,
            nameTextStyle: {
                color: textColor(),
            },
        },
        legend: {
            bottom: '-2',
            icon: 'circle',
            textStyle: {
                fontSize: 11,
                color: textColor(),
            },
        },
        color: qualitativePalette,
        series: [] as any[],
        tooltip: {
            trigger: 'axis',
            renderMode: 'html',
            triggerEvent: true,
            textStyle: {
                fontSize: 12,
            },
            axisPointer: {
                type: 'shadow',
                label: {
                    backgroundColor: '#6a7985',
                },
            },
        },
    };

    typeAttributes = {
        type: 'bar',
    };

    chartInstance: any;

    protected axisPointerClick = true;

    assignOptions(options: Record<string, any>) {
        if (options.series && options.series[0] && options.series[0].data[0].prop) {
            this.options.tooltip['formatter'] = this.propToolTip.bind(this);

            this.options.grid['bottom'] = '40%';
            if (!options.hasOwnProperty('editWidgetMode') || !options.editWidgetMode) {
                this.options['dataZoom'] = [
                    {
                        width: '80%',
                        height: '6%',
                        id: 'dataZoomX',
                        type: 'slider',
                        xAxisIndex: [0],
                        filterMode: 'filter',
                        showDetail: false,
                        start: 40,
                        bottom: 30,
                    },
                ];
            }
        }

        if (options.series && options.series[0] && options.series[0].hasOwnProperty('name')) {
            this.options.legend['selector'] = ['all', 'inverse'];
            this.options.legend['selectorPosition'] = 'start';
        }

        if (options.hasOwnProperty('editWidgetMode') && options.editWidgetMode) {
            this.options.yAxis.nameGap = 25;
        }

        super.assignOptions(options);
    }

    propToolTip(params: any) {
        let output = '';
        (params[0].data.prop || []).forEach((elem: any) => {
            output = `${output}${elem}<br/>`;
        });
        if (output === '') {
            const dataIndex = params[0].dataIndex;
            (this.options.series[0].data[dataIndex].prop || []).forEach((element: any) => {
                output = `${output}${element}<br/>`;
            });
        }
        return output;
    }

    handleEvents(emitter: Subject<{ type: string; payload: any }>, chartInstance: any) {
        if (!chartInstance) {
            return;
        }

        const options = chartInstance.getOption();
        if (options.series && options.series[0]) {
            this.chartInstance = chartInstance;
            font = chartInstance
                .getModel()
                .getSeries()[0]
                .getReferringComponents('xAxis')
                .models[0].getFont();
        }

        super.handleEvents(emitter, chartInstance);
    }
}

import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { NgModule, Type } from '@angular/core';
import { AUTH_PROVIDERS, SessionExpirationTracker } from '@app/auth';
import { RouterStateHistory } from './services/router-state-history.service';
import { HTTP_INTERCEPTOR_PROVIDERS } from './http-interceptors';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderModule } from '@app/core/components/header/header.module';
import { SharedModule } from '@app/shared/shared.module';

const modules: Type<any>[] = [HeaderModule];
const components: Type<any>[] = [ProgressBarComponent, FooterComponent];

@NgModule({
    imports: [
        HttpClientModule,
        HttpClientXsrfModule.withOptions({
            cookieName: 'XSRF-TOKEN',
            headerName: 'X-XSRF-TOKEN',
        }),
        SharedModule,
        ...modules,
    ],
    providers: [RouterStateHistory, ...HTTP_INTERCEPTOR_PROVIDERS, ...AUTH_PROVIDERS],
    declarations: [...components],
    exports: [...components, ...modules],
})
export class CoreModule {
    // Inject RouterStateHistory to make it eager...
    constructor(private readonly routerStateHistory: RouterStateHistory, private readonly sessionExpiration: SessionExpirationTracker) {}
}

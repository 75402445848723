import { EChartsChart } from '../echarts.chart';
import { Subject } from 'rxjs';

import { qualitativePalette, subTextColor, textColor } from '../charts-colors';
import { getAllLegendItems } from '../../../chart-legend/chart-legend.component';

export class MultipleSeriesTypeEchartChart extends EChartsChart {
    colors = qualitativePalette;
    tooltipdata: any;
    options = {
        color: this.colors,
        tooltip: {
            trigger: 'item',
            renderMode: 'html',
            textStyle: {
                fontSize: 12,
            },
            formatter: this.toolTipFormatter,
        },
        title: {
            text: '',
            subtext: '',
            textAlign: 'center',
            textVerticalAlign: 'top',
            left: '50%',
            textStyle: {
                color: textColor(),
                fontSize: 14,
                fontWeight: '500',
                fontFamily: 'sans-serif',
            },
            subtextStyle: {
                color: subTextColor(),
                fontSize: 12,
                fontFamily: 'sans-serif',
            },
        },
        grid: {
            right: '20%',
        },
        legend: {
            bottom: '7',
            icon: 'circle',
            textStyle: {
                fontSize: 11,
                color: textColor(),
            },
            selector: ['all', 'inverse'],
            selectorPosition: 'start',
            data: [] as any[],
        },
        xAxis: [
            {
                type: 'value',
                name: '',
                min: 'dataMin',
                nameLocation: 'center',
                nameGap: 20,
                nameTextStyle: {
                    color: textColor(),
                },
                axisTick: {
                    alignWithLabel: true,
                },
                data: [] as any[],
                axisLabel: {
                    textStyle: {
                        color: textColor(),
                    },
                    rotate: '45',
                    fontSize: 10,
                    margin: 11,
                } as any,
            } as any,
        ],
        yAxis: [] as any[],
        series: [] as any[],
    };

    assignOptions(options: Record<string, any>) {
        this.options.xAxis[0].data = options.categories;
        this.options.series = options.series;
        this.options.xAxis[0].name = options.options.xaxis[0].name;
        if (options.colors) {
            this.options.color = options.colors;
            this.colors = options.colors;
        }

        if (options.options) {
            this.options.legend.data = options.options.legend.data;
            this.options.yAxis = options.options.yaxis;
        }

        if (options.options.xaxis[0].formatter) {
            this.options.xAxis[0].axisLabel.formatter = options.options.xaxis[0].formatter;
        }

        if (options.tooltipFormatter) {
            (this.options as any).tooltipFormatter = options.tooltipFormatter;
            (this.options as any).anyWidget = true;
        }

        super.assignOptions(this.options);
    }

    toolTipFormatter(params: any) {
        let output = '';
        output += params.name;

        if (params.componentSubType === 'scatter') {
            if (!params.value || params.data.value === undefined) {
                return;
            }

            const paramData = params.data.value || params.data.value === 0 ? params.data.value : params.value;
            output +=
                '<span class="d-block"></span><label style="border-radius: 50%;width: 10px;height: 10px;vertical-align: middle;margin: 0;background:' +
                params.color +
                '"></label>&nbsp;&nbsp;' +
                params.seriesName +
                ': ';
            output += '<b>' + paramData + '</b><br/>';
        }

        return output;
    }

    handleEvents(emitter: Subject<{ type: string; payload: any }>, chartInstance: any) {
        if (!chartInstance) {
            return;
        }

        chartInstance.on('click', 'series', (event: any) => {
            emitter.next({
                type: 'barClicked',
                payload: {
                    barId: event.seriesId,
                    barName: event.seriesName,
                    name: event.name,
                    value: event.value,
                    dataIndex: event.dataIndex,
                    legend: getAllLegendItems(chartInstance),
                },
            });
        });

        super.handleEvents(emitter, chartInstance);
    }
}

import { Component, Input, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'lib-insight-loader',
    templateUrl: './insight-loader.component.html',
})
export class InsightLoaderComponent {
    @Input() dataLoad: any;
}

@NgModule({
    exports: [InsightLoaderComponent],
    declarations: [InsightLoaderComponent],
    imports: [CommonModule, NgbModule],
})
export class InsightLoaderModule {}

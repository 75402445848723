import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AgGridModule } from '@ag-grid-community/angular';

import { AgGridComponent } from './ag-grid.component';
import { AgGridService } from './ag-grid.service';
import { AgGridNoRowsOverlayComponent } from './ag-grid-no-rows-overlay/ag-grid-no-rows-overlay.component';

@NgModule({
    exports: [AgGridModule, AgGridComponent],
    declarations: [AgGridComponent, AgGridNoRowsOverlayComponent],
    providers: [AgGridService],
    imports: [CommonModule, NgbModule, AgGridModule]
})
export class AgGridTemplateModule {}

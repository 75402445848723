import {IPagePathResolver} from '../../api/keep-ni-config';

export class DefaultHashPathResolver implements IPagePathResolver {
    getPath(): string {
        return (location.hash ? location.hash.substring(1) : location.pathname);
    }

    setPath(page: string): void {
        //empty implementation by default
    }
}

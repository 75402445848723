import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Page } from '@dagility-ui/kit';
import { UserDto, UserRole, UsersFilter } from '@app/core/models/user.model';
import { UserBaseService } from './user-base.service';
import { ENV_TOKEN } from '@app/tokens';

export const DEFAULT_USERS_FILTER_VALUE: UsersFilter = {
    searchQuery: '',
    roles: [],
    page: 0,
    size: 10,
    sort: [],
};

@Injectable({
    providedIn: 'root',
})
export class UserService implements UserBaseService {
    private readonly apiUrl = this.env.baseApiURL;
    private readonly baseUrl = this.env.usersApiURL;

    constructor(@Inject(ENV_TOKEN) private readonly env: Env, private readonly http: HttpClient) {}

    getCurrentUser(): Observable<UserDto> {
        return this.http.get<UserDto>(`${this.apiUrl}/current-user`);
    }

    getUsers(filter: UsersFilter): Observable<Page<UserDto>> {
        return this.http.get<Page<UserDto>>(this.baseUrl, {
            params: {
                ...filter,
            },
        });
    }

    bulkRoleUpdate(userRoles: Array<{ userId: string; roles: UserRole[] }>) {
        return this.http.post(`${this.baseUrl}/bulk-role-update`, userRoles);
    }

    getUser(userId: number): Observable<UserDto> {
        return this.http.get<UserDto>(`${this.baseUrl}/${userId}`);
    }

    updateUser(user: UserDto): Observable<UserDto> {
        return this.http.put<UserDto>(this.baseUrl, user);
    }

    uploadAvatar(userId: number, file: File): Observable<string> {
        const formData = new FormData();
        formData.append('image', file);
        return this.http.post<string>(`${this.baseUrl}/${userId}/avatar`, formData);
    }

    deleteAvatar(userId: number): Observable<string> {
        return this.http.delete<string>(`${this.baseUrl}/${userId}/avatar`);
    }
}

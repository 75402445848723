<div class="navigation-dropdown">
    <div class="navigation-dropdown-parent">
        <perfect-scrollbar>
            <div *ngIf="title" class="navigation-dropdown-title">{{ title }}</div>
            <ng-container *ngFor="let item of dropdownItems; let i = index">
                <a *ngIf="(item.link || '').includes('http')"
                   id="{{ activeParentDropdown }}-{{ i }}"
                   class="navigation-dropdown-item"
                   [ngClass]="item.parameters ? 'cursor-pointer' : 'cursor-default'"
                   (click)="goToLink(item.link, item.parameters, 'HREF')"
                   (mouseover)="showChildren(i, item)"
                >
                    {{ item.label }}
                </a>
                <a *ngIf="!(item.link || '').includes('http')"
                   id="{{ activeParentDropdown }}-{{ i }}"
                   class="navigation-dropdown-item"
                   [ngClass]="item.parameters ? 'cursor-pointer' : 'cursor-default'"
                   (click)="goToLink(item.link, item.parameters, 'ROUTER')"
                   (mouseover)="showChildren(i, item)"
                >
                    {{ item.label }}
                </a>
            </ng-container>
        </perfect-scrollbar>
    </div>
    <div *ngIf="activeDropdownId && activeDropdown && activeDropdown.children?.length > 0"
         class="navigation-dropdown-child">
        <app-header-navigation-dropdown
            [title]="activeDropdown.label"
            [activeParentDropdown]="activeDropdownId"
            [dropdownItems]="activeDropdown.children"
            (wentToLink)="wentToLink.emit()"
        ></app-header-navigation-dropdown>
    </div>
</div>

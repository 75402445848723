<div class="stepper">
    <div *ngFor="let step of steps; let i = index; let last = last" class="step">
        <div class="circle-wrapper">
            <div class="circle" [class.active]="step.active" [class.passed]="step.passed" [class.invalid]="step.invalid">
                <span *ngIf="step.passed && !step.invalid" [innerHTML]="icons.facCheck | safeHtml: 'html'" class="checkmark-icon"></span>
                <fa-icon *ngIf="step.passed && step.invalid" icon="facBuildFailure" class="invalid-icon font-16px"></fa-icon>
                <span *ngIf="!step.passed && step.active" [innerHTML]="icons.facDots | safeHtml: 'html'" class="dots-icon"></span>
                <span *ngIf="!step.active && !step.passed">{{ i + 1 }}</span>
            </div>
            <div class="name">
                {{ step.name }}
                <span *ngIf="step.contextHelp" [context-help-id]="step.contextHelp"></span>
            </div>
            <div *ngIf="step.description" class="body1 secondary-text-and-icons">
                {{ step.description }}
            </div>
        </div>

        <div *ngIf="!last" class="separator" [class.active]="step.passed"></div>
    </div>
</div>
<div *ngIf="steps[activeStep].label" class="d-flex justify-content-center info-block">
    <div class="info-plate" [style.min-width.px]="labelSize">
        <fa-icon icon="facInfoCircle" [style.font-size.px]="14" class="color-brand mr-10px"></fa-icon>
        <div class="info-plate-text">
            {{ steps[activeStep].label }}
        </div>
    </div>
</div>

<label
    class="label body2 min-width-fit-content"
    *ngIf="label"
    [class.disabled]="readonly"
>
    {{ label }}
    <span *ngIf="isRequired" class="mandatory">*</span>
</label>

<div class="w-100 position-relative">
    <ckeditor
        #input
        #controlContainer
        class="ckeditor-input"
        [attr.data-test-id]="id"
        [class.invalid]="hasErrors"
        [class.readonly]="readonly"
        [tagName]="'textarea'"
        [editor]="editor"
        [(ngModel)]="value"
        [disabled]="readonly"
        (change)="handleInput($event)"
        (blur)="onTouched()"
    ></ckeditor>
</div>


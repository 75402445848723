import { Inject, Injectable, Injector } from '@angular/core';
import { createHttpClient } from './http-client.factory';
import { environment } from 'src/environments/environment';

const basicAuthCredential = 'edgeopsapi' + ':' + 'f?4WDzBMh5ydyN*^';
const bace64 = btoa(basicAuthCredential);

@Injectable()
export class BasicHttpClient {
    protected API_URL = environment.helpSupportParams.url;
    protected Version = environment.helpSupportParams.version;
    // basicAuthCredential = `${environment.helpSupportParams.username}:${environment.helpSupportParams.password}`;
    basicToken = `Basic ${bace64}`;
    protected http = createHttpClient(this.injector, [this.authInterceptor], req => {
        return req.clone({
            headers: req.headers.append('Authorization', this.basicToken),
        });
    });

    constructor(private injector: Injector, @Inject('AuthInterceptor') protected authInterceptor: any) {}
}

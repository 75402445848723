import { EChartsChart } from '../echarts.chart';
import { Subject } from 'rxjs';
import { isObject } from 'lodash';

import { qualitativePalette, subTextColor, textColor } from '../charts-colors';

export class HorizontalStackedBarEChartsChart extends EChartsChart {
    options: Record<string, any> = {
        textStyle: {
            fontFamily: 'sans-serif',
            fontSize: 12,
            color: textColor(),
        },
        grid: {
            left: '0%',
            top: '15%',
            bottom: '35%',
            containLabel: true,
        },
        title: {
            text: '',
            subtext: '',
            textAlign: 'center',
            textVerticalAlign: 'top',
            left: '50%',
            textStyle: {
                color: textColor(),
                fontSize: 14,
                fontWeight: '500',
                fontFamily: 'sans-serif',
            },
            subtextStyle: {
                color: subTextColor(),
                fontSize: 12,
                fontFamily: 'sans-serif',
            },
        },
        xAxis: {
            type: 'value',
            renderMode: 'html',
            data: [],
            triggerEvent: true,
            axisLabel: {
                rotate: '45',
                fontSize: 10,
                margin: 11,
                formatter: this.labelFormatter,
                textStyle: {
                    color: textColor(),
                },
            },
            axisLine: {
                onZero: true,
                show: false,
            },
            axisTick: {
                show: false,
                alignWithLabel: true,
            },
            nameLocation: 'center',
            nameGap: 45,
            nameTextStyle: {
                color: textColor(),
            },
        },
        yAxis: {
            type: 'category',
            renderMode: 'html',
            data: [],
            triggerEvent: true,
            axisLabel: {
                fontSize: 10,
                textStyle: {
                    color: textColor(),
                },
                width: 100,
                overflow: 'truncate',
            },
            axisLine: {
                show: false,
            },
            axisTick: {
                show: false,
            },
            nameLocation: 'end',
            nameGap: 5,
            nameTextStyle: {
                color: textColor(),
            },
        },
        legend: {
            bottom: '0',
            icon: 'circle',
            textStyle: {
                fontSize: 11,
                color: textColor(),
            },
            selector: ['all', 'inverse'],
            selectorPosition: 'start',
        },
        series: [],
        color: qualitativePalette,
        tooltip: {
            trigger: 'axis',
            renderMode: 'html',
            triggerEvent: true,
            textStyle: {
                fontSize: 12,
            },
            axisPointer: {
                type: 'line',
                lineStyle: {
                    width: 0,
                },
                label: {
                    show: false,
                },
            },
        },
    };

    typeAttributes = {
        type: 'bar',
        stack: 'one',
        barMinHeight: 1,
    };

    typeAttributesWithoutStack = {
        type: 'bar',
        barMinHeight: 1,
    };

    protected axisPointerClick = true;

    assignOptions(options: Record<string, any>) {
        if (options.series.length > 6) {
            this.options.legend['type'] = 'scroll';
            this.options.legend['pageFormatter'] = 'page {current} of {total}';
            this.options.legend['pageButtonItemGap'] = 30;
        }

        if (options.series && options.series[0]) {
            if (options.series[0].data[0]?.prop) {
                this.options.tooltip['formatter'] = this.propToolTip;
            } else {
                this.options.tooltip['formatter'] = this.formatToolTip;
            }

            if (options.series[0].data.length > 8) {
                this.options.grid['right'] = '20%';
                this.options['dataZoom'] = [
                    {
                        width: '2%',
                        id: 'dataZoomY',
                        type: 'slider',
                        yAxisIndex: [0],
                        filterMode: 'filter',
                        showDetail: false,
                        start: 50,
                    },
                ];
            }
        }

        // for Widget constructor dynamic color implimentation - will be removed later
        const chartColors = this.getDynamicChartColors(options.series);
        if (chartColors.length && (!options.colors || !options.colors.length)) {
            options.colors = chartColors;
        } else if (chartColors.length && options.colors && options.colors.length) {
            options.series = this.removeDynamicCOlors([...options.series]);
        }
        super.assignOptions(options);
    }

    removeDynamicCOlors(series: any) {
        return series.map((obj: any) => {
            const itemStyle = obj.data.filter((x: any) => x != null && x.itemStyle);
            if (obj.data.length && itemStyle.length && itemStyle[0].itemStyle && itemStyle[0].itemStyle.color) {
                obj.data = obj.data.map((x: any) => {
                    return x !== null ? { value: x.value } : null;
                });
            }
            return obj;
        });
    }

    getDynamicChartColors(series: any) {
        return series
            .map((obj: any) => {
                let reslt = '';
                const itemStyle = obj.data.filter((x: any) => x != null && x.itemStyle);
                if (obj.data.length && itemStyle.length && itemStyle[0].itemStyle && itemStyle[0].itemStyle.color) {
                    reslt = itemStyle[0].itemStyle.color;
                }
                return reslt;
            })
            .filter((color: string) => color);
    }

    propToolTip(params: any) {
        let output = '';
        (params[0].data.prop || params[0].data.value.prop || []).forEach((elem: any) => {
            output = `${output}${elem}<br/>`;
        });
        return output;
    }

    private formatToolTip(params: any) {
        const item = params[0].axisValue;
        let output = '';
        if (item.length > 0 && item.match(/>/)) {
            const labelPart = item.substring(item.match(/>/).index + 1, item.length);
            const label = labelPart.substring(0, labelPart.match(/</).index);
            output += label + '<br/>';
        } else {
            output += item + '<br/>';
        }

        params.forEach((param: any) => {
            if (!param || param.value === undefined) {
                return;
            }

            output +=
                '<label style="border-radius: 50%;width: 10px;height: 10px;vertical-align: middle;margin: 0;background:' +
                param.color +
                '"></label>&nbsp;&nbsp;' +
                param.seriesName +
                ': ';
            if (isObject(param.value)) {
                output += '<b>' + param.value.value + '</b><br/>';
            } else {
                output += '<b>' + param.value + '</b><br/>';
            }
        });

        return output;
    }

    handleEvents(emitter: Subject<{ type: string; payload: any }>, chartInstance: any) {
        if (!chartInstance) {
            return;
        }

        chartInstance.on('click', 'yAxis', (event: any) => {
            if (event.value.length > 0 && event.value.match(/href="/)) {
                const labelPart = event.value.substring(event.value.match(/href="/).index + 6, event.value.length);
                const url = labelPart.substring(0, labelPart.match(/"/).index);
                window.open(url, '_blank');
            }
        });

        super.handleEvents(emitter, chartInstance);
    }
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { AssetDto } from '@app/shared/components/assets/asset.model';
import { MarketplaceTemplateRating } from '@dagility-ui/kit';
import { TranslationKeysOfRating } from '@dagility-ui/kit/modules/forms/controls/rating/rating.component';
import { TranslationService } from '@app/core/services/translation.service';
import { switchMap, takeUntil } from 'rxjs/operators';

export function getAssetRatingStatistic(asset$: Observable<AssetDto>): Observable<MarketplaceTemplateRating> {
    const getRate = (asset: AssetDto, stars: number) => {
        if (!asset.reviewCountByMark) {
            return 0;
        }
        return asset.reviewCountByMark[stars] || 0;
    };
    return asset$.pipe(
        switchMap(asset =>
            of({
                rate1: getRate(asset, 1),
                rate2: getRate(asset, 2),
                rate3: getRate(asset, 3),
                rate4: getRate(asset, 4),
                rate5: getRate(asset, 5),
                downloads: asset.downloadCount,
                rating: asset.averageScore,
                myRating: asset.userRatingMark,
                ratesCount: asset.reviewCount,
            })
        )
    );
}

@Component({
    selector: 'app-asset-rating-statistic',
    templateUrl: './asset-rating-statistic.component.html',
    styleUrls: ['./asset-rating-statistic.component.scss'],
})
export class AssetRatingStatisticComponent implements OnInit, OnDestroy {
    private readonly destroy$: Subject<any> = new Subject<any>();

    @Input() asset$: BehaviorSubject<AssetDto> = new BehaviorSubject<AssetDto>(null);

    asset: AssetDto;
    ratingStatistics$: Observable<MarketplaceTemplateRating>;

    get ratingTranslatedText(): Partial<Record<TranslationKeysOfRating, string>> {
        return this.translationService.ratingTranslatedText;
    }

    constructor(private readonly translationService: TranslationService) {}

    ngOnInit(): void {
        this.ratingStatistics$ = getAssetRatingStatistic(this.asset$);
        this.asset$.pipe(takeUntil(this.destroy$)).subscribe(asset => (this.asset = asset));
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }
}

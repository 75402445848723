<form [formGroup]="form" (reset)="activeModal.dismiss('Cancel')" (submit)="reject()">
    <ng-container *transloco="let transloco; read: 'rejectReasonModal'">
        <div class="modal-header">
            <h1 class="mt-0">{{ transloco('title') }}</h1>
            <button type="reset" class="close" aria-label="Close button" aria-describedby="modal-title">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-body">
            <div class="d-flex flex-column" *ngIf="resolutions$ | async as resolutions">
                <lib-dropdown
                    class="mb-4 w-100"
                    labelPosition="top"
                    formControlName="resolutionId"
                    [label]="transloco('resolutionLabel')"
                    [items]="resolutions"
                    valueKey="id"
                    labelKey="name"
                    [clearable]="false"
                ></lib-dropdown>

                <lib-textarea
                    class="mb-4 w-100"
                    labelPosition="top"
                    formControlName="comment"
                    [label]="transloco('commentLabel')"
                    [placeholder]="transloco('commentPlaceholder')"
                    maxlength="256"
                    [translatedText]="textareaTranslatedText"
                    [resize]="false"
                    [customHeight]="true"
                ></lib-textarea>
            </div>
        </div>

        <div class="modal-footer">
            <button type="reset" class="btn btn-secondary mr-24px ml-auto">
                {{ transloco('cancelBtn') }}
            </button>
            <button type="submit" class="btn btn-primary" [disabled]="form.invalid">
                {{ transloco('rejectBtn') }}
            </button>
        </div>
    </ng-container>
</form>

import { ComponentFactoryResolver, ComponentRef, Injectable, Injector } from '@angular/core';

import { AgGridComponent } from './ag-grid.component';

@Injectable({
    providedIn: 'root',
})
export class AgGridService {
    constructor(private resolver: ComponentFactoryResolver, private injector: Injector) {}

    public createGridComp(inputValues: any): ComponentRef<AgGridComponent> {
        const factory = this.resolver.resolveComponentFactory(AgGridComponent);
        // create compnent without adding it directly to the DOM
        const comp = factory.create(this.injector);
        // add inputs first !! otherwise component/template crashes ..
        comp.instance.tableData = inputValues;
        return comp;
      }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'searchFilter',
})
export class SearchPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (!value) {
            return null;
        }
        if (!args) {
            return value;
        }

        args = args.toLowerCase();

        return value.filter((item: any) => {
            if (item.hasOwnProperty('name')) {
                return item.name.toLowerCase().includes(args);
            } else {
                return JSON.stringify(item)
                    .toLowerCase()
                    .includes(args);
            }
        });
    }
}

import {IPagePathResolver} from '../../api/keep-ni-config';

export class DefaultManualPathResolver implements IPagePathResolver {
    private _path: string;

    getPath(): string {
        return this._path;
    }

    setPath(page: string): void {
        this._path = page;
    }
}

<form [formGroup]="form" *transloco="let t; read: 'profilePage.userEditor'">
    <lib-stream-wrapper [dataStream$]="roles$">
        <ng-template contentTemplate let-roles="data">
            <div class="row mb-3">
                <div class="col-md-6">
                    <lib-input [label]="t('firstName')" [placeholder]="t('firstNamePlaceholder')"
                               formControlName="firstName" maxlength="100"
                    ></lib-input>
                </div>
                <div class="col-md-6">
                    <lib-input [label]="t('lastName')" [placeholder]="t('lastNamePlaceholder')"
                               formControlName="lastName" maxlength="100"
                    ></lib-input>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-md-6">
                    <lib-input [label]="t('email')" [placeholder]="t('emailPlaceholder')"
                               formControlName="email" maxlength="100"
                    ></lib-input>
                </div>
                <div class="col-md-6">
                    <lib-input [label]="t('login')" [placeholder]="t('loginPlaceholder')"
                               [setDisable]="true" formControlName="login" maxlength="100"
                    ></lib-input>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-md-6">
                    <lib-dropdown [label]="t('group')" [placeholder]="t('groupPlaceholder')"
                                  [setDisable]="true" [items]="groups" formControlName="group"
                    ></lib-dropdown>
                </div>
                <div class="col-md-6">
                    <label class="font-12px">{{ t('roles') }}</label>
                    <app-checkbox-group [isInline]="true" [blueCheck]="true" [items]="roles" formControlName="roles"
                    ></app-checkbox-group>
                </div>
            </div>
        </ng-template>
    </lib-stream-wrapper>
</form>

import { EChartsChart } from '../echarts.chart';
import { qualitativePalette, textColor } from '../charts-colors';

export class RadialEchartsChart extends EChartsChart {
    options = {
        noHoverOnBar: true,
        yAxis: { show: false },
        xAxis: { show: false },
        angleAxis: {
            type: 'value',
            max: 120,
            startAngle: 240,
            interval: 25,
            splitLine: {
                show: false,
            },
            axisLine: {
                show: false,
            },
            axisLabel: {
                margin: -120,
                showMaxLabel: true,
                color: textColor(),
            },
            splitArea: {
                show: false,
            },

            axisTick: {
                show: false,
            },
        },
        radiusAxis: {
            type: 'category',
            axisLine: {
                show: false,
            },
            axisTick: {
                show: false,
            },
            axisLabel: {
                show: false,
            },
        },
        polar: {},
        color: qualitativePalette,
    };

    assignOptions(options: Record<string, any>, isCustom?: boolean) {
        if (options.hideLegend) {
            (this.options.angleAxis.axisLabel as any).show = false;
        }

        super.assignOptions(options, isCustom);
    }
}

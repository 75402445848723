import { Component, TemplateRef, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { PerfectScrollbarComponent, PerfectScrollbarConfig } from 'ngx-perfect-scrollbar';

const BODY_HEIGHT = 555;
const BODY_WIDTH = 960;
const BODY_PADDING = 160;
const LOG_PADDING = 55 + 96;

@Component({
    selector: 'lib-log-modal',
    templateUrl: './log-modal.component.html',
    styleUrls: ['./log-modal.component.scss'],
})
export class LogModalComponent {
    @ViewChild(PerfectScrollbarComponent) scrollbar: PerfectScrollbarComponent;

    title = 'Log';

    logs$: Observable<any>;
    headerTemplateRef: TemplateRef<any>;
    titleTemplateRef: TemplateRef<any>;
    request$: Observable<any>;
    fileName: string;

    subHeaderData: {
        buildNumber: string;
        startingTime: string;
    };

    contentTemplateRef: TemplateRef<any>;
    isExpanded: boolean;

    perfectScrollbarConfig: PerfectScrollbarConfig = new PerfectScrollbarConfig({
        suppressScrollX: false,
        suppressScrollY: true,
    });

    get bodyWidth() {
        return this.isExpanded && document.body.clientWidth > 850 ? document.body.clientWidth - BODY_PADDING : BODY_WIDTH;
    }

    get bodyHeight() {
        return this.isExpanded ? document.body.clientHeight - BODY_PADDING - 100 : BODY_HEIGHT;
    }

    get logHeight() {
        return this.bodyHeight - LOG_PADDING;
    }

    constructor(public modal: NgbActiveModal) {}
}

<div class="d-flex flex-row align-items-center">
    <div *ngIf="collapsed" id="collapsed-nav-btn" (click)="openNav(); $event.stopPropagation()">
        <fa-icon icon="bars"></fa-icon>
    </div>
    <div
        class="{{ collapsed && !collapsedNavOpened ? 'd-none' : 'd-flex' }} {{
            collapsed && collapsedNavOpened ? 'flex-column header-navbar-dropdown nav--opened' : ''
        }}"
        [style.top]="collapsed && collapsedNavOpened ? '80%' : ''"
        id="collapsed-nav"
        #ref
    >
        <div *ngIf="collapsed" (window:click)="clickHandler($event, ref)"></div>
        <div *ngFor="let item of navItems$ | async; let i = index">
            <div
                id="nav-item-{{ i }}"
                class="app-nav-item"
                [class.active]="activeItem === 'nav-item-' + i"
                (click)="toggleItem(item, i, $event)"
            >
                <span>{{ item.label }}</span>
                <ng-container *ngIf="item.children?.length > 0">
                    <fa-icon
                        [class.d-none]="collapsed"
                        class="icon-non-collapsed"
                        [icon]="activeItem !== 'nav-item-' + i ? icons.chevronDown : icons.chevronUp"
                    ></fa-icon>
                    <fa-icon
                        [class.d-none]="!collapsed"
                        class="icon-collapsed"
                        [icon]="activeItem !== 'nav-item-' + i ? icons.chevronRight : icons.chevronLeft"
                    ></fa-icon>
                </ng-container>
            </div>
            <div
                *ngIf="item.children?.length > 0 && activeDropdown && activeDropdown === 'dropdown-' + i"
                id="dropdown-{{ i }}"
                class="header-navbar-dropdown"
                style="{{ collapsed ? 'top: 0; left: 100%;' : '' }}"
                #dropdownRef
                [style.--top.px]="topOffset"
                (window:click)="clickHandler($event, dropdownRef)"
            >
                <app-header-navigation-dropdown
                    [activeParentDropdown]="activeDropdown"
                    [dropdownItems]="dropdownItems"
                    [title]="item.title"
                    (wentToLink)="closeAll()"
                ></app-header-navigation-dropdown>
            </div>
        </div>
    </div>
</div>

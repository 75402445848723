import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { facCheck, facDots } from '../../icons';

export interface Step {
    name: string;
    description?: string;
    active?: boolean;
    passed?: boolean;
    contextHelp?: string;
    label?: string;
    invalid?: boolean;
}

@Component({
    selector: 'lib-stepper',
    templateUrl: './stepper.component.html',
    styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent implements OnInit, OnChanges {
    @Input() activeStep: number = 0;
    @Input() steps: Step[] = [];

    readonly icons = {
        facCheck,
        facDots,
    };

    get labelSize() {
        return this.steps.length * 100 + (this.steps.length - 1) * 150 + 50;
    }

    ngOnInit(): void {
        this.checkSteps();
    }

    ngOnChanges() {
        this.resetSteps();
        this.checkSteps();
    }

    private resetSteps() {
        this.steps.forEach(step => {
            step.active = false;
            step.passed = false;
        });
    }

    private checkSteps() {
        this.steps.forEach((step, i) => {
            if (i < this.activeStep) {
                step.passed = true;
            } else if (i === this.activeStep) {
                step.active = true;
            }
        });
    }
}

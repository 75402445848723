import { Injectable } from '@angular/core';
import { AssetDto, AssetPermission } from '@app/shared/components/assets/asset.model';

@Injectable({
    providedIn: 'root',
})
export class AssetPermissionsService {
    canDownload(asset: AssetDto): boolean {
        return this.hasPermission(asset, AssetPermission.CAN_DOWNLOAD);
    }

    canUpgradeVersion(asset: AssetDto): boolean {
        return this.hasPermission(asset, AssetPermission.CAN_UPGRADE);
    }

    canDelete(asset: AssetDto): boolean {
        return this.hasPermission(asset, AssetPermission.CAN_DELETE);
    }

    canApprove(asset: AssetDto): boolean {
        return this.hasPermission(asset, AssetPermission.CAN_APPROVE);
    }

    canReject(asset: AssetDto): boolean {
        return this.hasPermission(asset, AssetPermission.CAN_REJECT);
    }

    canLaunch(asset: AssetDto): boolean {
        return this.hasPermission(asset, AssetPermission.CAN_LAUNCH);
    }

    canEdit(asset: AssetDto): boolean {
        return this.hasPermission(asset, AssetPermission.CAN_EDIT);
    }

    canEditLaunched(asset: AssetDto): boolean {
        return this.hasPermission(asset, AssetPermission.CAN_EDIT_LAUNCHED);
    }

    private hasPermission(asset: AssetDto, permission: AssetPermission): boolean {
        return asset.permissions?.length ? asset.permissions.includes(permission) : false;
    }
}

import { NgModule } from '@angular/core';

import { UiKitModule } from '@dagility-ui/kit';

import { FieldsGridModule } from '../fields-grid/fields-grid.module';
import { PropertiesEditorComponent } from './properties-editor.component';
import { CodemirrorEditorModule } from '../codemirror/codemirror-editor.module';
import { PropertiesCompletionModule } from '../../directives/properties-completion.directive';

@NgModule({
    imports: [FieldsGridModule, CodemirrorEditorModule, UiKitModule, PropertiesCompletionModule],
    declarations: [PropertiesEditorComponent],
    exports: [PropertiesEditorComponent, FieldsGridModule],
})
export class PropertiesEditorModule {}

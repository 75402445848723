<div class="asset-rating"
     [class.gold-rating]="asset?.averageScore >= 4"
     [ngClass]="asset?.userRatingMark ? ('user-mark-' + asset?.userRatingMark) : ''"
>
    <div class="asset-rating-value">{{ asset?.averageScore | number:'1.1-1' }}</div>
    <lib-rating [value]="asset?.averageScore"
                [expanded]="true"
                [ratingStatistics]="ratingStatistics$"
                [translatedText]="ratingTranslatedText"
    ></lib-rating>
</div>

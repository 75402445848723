<div *transloco="let t; read: 'notFound'" class="not-found-wrapper">
    <div class="not-found-image-block">
        <img src="../assets/images/404.svg" [alt]="t('altText')"/>
    </div>
    <div class="not-found-text-block">
        <div class="title">{{ t('title') }}</div>
        <div class="message">{{ t('message') }}</div>
        <button class="btn btn-primary" routerLink="/assets">
            {{ t('buttonText') }}
        </button>
    </div>
</div>

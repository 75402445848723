import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

import {EnvironmentModel} from '@dagility-ui/kit';
import {ToolTemplate} from '../models/domain/tool.models';

@Injectable()
export class ToolTemplateService {
    private basePath = `${this.environment.dpApiURL}/tool-templates`;

    constructor(private http: HttpClient, @Inject('environment') private environment: EnvironmentModel) {}

    findAll(): Observable<ToolTemplate[]> {
        return this.http.get<ToolTemplate[]>(this.basePath);
    }

    getToolTemplateById(id: number) {
        return this.http.get(`${this.basePath}/${id}`);
    }

    updateToolTemplate(toolTemplate: any) {
        return this.http.post<any>(this.basePath, toolTemplate);
    }

    deleteToolTemplateById(id: number) {
        return this.http.delete(`${this.basePath}/${id}`);
    }
}

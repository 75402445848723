import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslationService } from '@app/core/services/translation.service';
import { AssetFiltersService } from '@app/shared/components/assets/asset-filters.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AssetFilter } from '@app/shared/services/api/assets.service';

export type LayoutType = 'list' | 'grid';

export const SORTING_FIELDS_IN_ASSETS = ['name', 'publishDate', 'rating'];
export const SORTING_FIELDS_IN_MANAGE_ASSETS = ['name', 'publishDate', 'rating'];
export const SORTING_FIELDS_DEPENDENT_BY_VERSION = ['publishDate', 'rating'];

@Component({
    selector: 'app-assets-filter',
    templateUrl: './assets-filter.component.html',
})
export class AssetsFilterComponent implements OnInit, OnDestroy {
    private readonly destroy$ = new Subject();

    @Input() withRouteObservation: boolean = true;

    @Input() layout: LayoutType = 'list';

    @Output() layoutChange = new EventEmitter<LayoutType>();

    get isManageAssetsPage(): boolean {
        return this.assetFiltersService.isManageAssetsPage;
    }

    get assetFilter(): AssetFilter {
        return this.assetFiltersService.assetFilter;
    }

    sortingItems: { label: string; value: string }[] = [];

    sortingButtonText: string;

    constructor(private readonly assetFiltersService: AssetFiltersService, private readonly translationService: TranslationService) {}

    ngOnInit(): void {
        this.translationService.translateObject('assets.filter').subscribe(filter => {
            this.sortingItems = [];
            (this.isManageAssetsPage ? SORTING_FIELDS_IN_MANAGE_ASSETS : SORTING_FIELDS_IN_ASSETS).forEach(field => {
                this.sortingItems.push({ label: filter[field + 'ASC'], value: field + ',asc' });
                this.sortingItems.push({ label: filter[field + 'DESC'], value: field + ',desc' });
            });
        });
        this.assetFiltersService.sortChanged$
            .pipe(takeUntil(this.destroy$))
            .subscribe(sort => (this.sortingButtonText = this.sortingItems.find(i => i.value === sort)?.label));
    }

    changeSorting({ value }: { value: string }) {
        if (this.withRouteObservation) {
            this.assetFiltersService.updateRouteByPageFilter({
                sort: value,
            });
        } else {
            this.assetFiltersService.forceUpdatePageFilter({
                sort: value,
            });
        }
    }

    search(searchTerm: string) {
        if (this.withRouteObservation) {
            this.assetFiltersService.updateRouteByAssetFilter({
                searchTerm,
            });
        } else {
            this.assetFiltersService.forceUpdateAssetFilter({
                searchTerm,
            });
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }
}

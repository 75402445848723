import { KeycloakService } from './keycloak.service';
import { AuthService } from './auth.service';
import { SessionExpirationTracker } from './session-expiration.tracker';
import { APP_INITIALIZER } from '@angular/core';

export const AUTH_PROVIDERS = [
    AuthService,
    SessionExpirationTracker,
    {
        provide: APP_INITIALIZER,
        useFactory: (keycloakService: KeycloakService, authService: AuthService) => () =>
            keycloakService.login().then(() => authService.init()),
        deps: [KeycloakService, AuthService],
        multi: true,
    },
];

export { AuthService, SessionExpirationTracker };

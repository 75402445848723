import { NgModule } from '@angular/core';
import { FaConfig, FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { CUSTOM_ICONS, HEADER_ICONS, PROCESSOR_ICONS, PROJECT_PIPELINE_ICONS } from './icon.library';
import { CustomIcon } from './types';

const modules = [FontAwesomeModule];

let initialized = false;

@NgModule({
    imports: [...modules],
    exports: [...modules],
})
export class IconsModule {
    constructor(iconLibrary: FaIconLibrary, faConfig: FaConfig) {
        if (!initialized) {
            CUSTOM_ICONS.concat(PROCESSOR_ICONS)
                .concat(PROJECT_PIPELINE_ICONS)
                .concat(HEADER_ICONS)
                .forEach((icon: CustomIcon) => (icon.prefix = 'far'));
            faConfig.defaultPrefix = 'far';
            iconLibrary.addIcons(...CUSTOM_ICONS, ...PROCESSOR_ICONS, ...PROJECT_PIPELINE_ICONS);
            initialized = true;
        }
    }
}

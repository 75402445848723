<lib-card *transloco="let t; read: 'assets.filter'"
          [style.min-width.px]="650"
          [staticCard]="!isManageAssetsPage"
          [withShadow]="!isManageAssetsPage"
>
    <div class="d-flex">
        <lib-search class="flex-1"
                    [placeholder]="t('search')"
                    [value]="assetFilter.searchTerm"
                    (searchText)="search($event)"
        ></lib-search>
        <lib-split-button
            class="mx-3"
            [buttonText]="t('sortingBy') + sortingButtonText"
            [items]="sortingItems"
            [isPrimary]="false"
            [container]="'body'"
            (itemClick)="changeSorting($event)"
        >
        </lib-split-button>
        <div class="button-toggle-group">
            <input
                id="list-view"
                type="radio"
                name="view"
                value="list"
                [ngModel]="layout"
                (ngModelChange)="layoutChange.emit($event)"
            />
            <label
                for="list-view"
                class="button-toggle"
                [ngbTooltip]="t('listViewTooltip')"
                placement="bottom"
                container="body"
                [openDelay]="500"
            >
                <fa-icon icon="grid2Horizontal" [class.color-brand]="layout === 'grid'"></fa-icon>
            </label>
            <input
                id="grid-view"
                type="radio"
                name="view"
                value="grid"
                [ngModel]="layout"
                (ngModelChange)="layoutChange.emit($event)"
            />
            <label
                for="grid-view"
                class="button-toggle"
                [ngbTooltip]="t('gridViewTooltip')"
                placement="bottom"
                container="body"
                [openDelay]="500"
            >
                <fa-icon icon="grid" [class.color-brand]="layout === 'list'"></fa-icon>
            </label>
        </div>
    </div>

    <ng-content></ng-content>
</lib-card>

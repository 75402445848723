import { AfterViewInit, Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { LayoutType, SORTING_FIELDS_DEPENDENT_BY_VERSION } from './assets-filter/assets-filter.component';
import { AssetFilter, AssetsService, PageFilter } from '../../../services/api/assets.service';
import { combineLatest, of, Subject } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { generateUUID, Pager, Pagination, TranslationKeysOfPagination } from '@dagility-ui/kit';
import { AssetActionResponse, AssetDto, AssetStatus } from '../asset.model';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslationService } from '@app/core/services/translation.service';
import { AuthService } from '@app/auth';
import { UserDto } from '@app/core/models/user.model';
import { AssetFiltersService, Filter } from '@app/shared/components/assets/asset-filters.service';
import { SplitComponent } from 'angular-split';

const ASSETS_LIST_TABS = ['my', 'group'];

@Component({
    selector: 'app-assets-list-wrapper',
    templateUrl: './assets-list-wrapper.component.html',
    styleUrls: ['./assets-list-wrapper.component.scss'],
    host: {
        id: 'asset-list-wrapper',
    },
})
export class AssetsListWrapperComponent implements OnInit, AfterViewInit, OnDestroy {
    private readonly destroy$ = new Subject();

    @Input() asset: AssetDto;

    @Input() isManageAssets: boolean = false;

    @Input() hasRouteObservation: boolean = true;

    @Input() hasFiltersBlock: boolean = true;

    @Input() hasGroupTabs: boolean = false;

    @Input() hasFilterByStatus: boolean = false;

    @Input() hasFilterByGroup: boolean = true;

    @Input() hasActionButtons: boolean = true;

    @Input() hasManagementActions: boolean = false;

    @Input() title: string;

    @Input() canAddAsset: boolean = false;

    @Input() canSelectAssets: boolean = false;

    @Input() showStatus: boolean = false;

    @Input() readOnly: boolean = false;

    @Input() layout: LayoutType = 'grid';

    @Output() loaded: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild(SplitComponent) readonly splitter: SplitComponent;
    readonly splitterId: string = `splitter--${generateUUID()}`;

    private resizeObserver: ResizeObserver;
    private splitterEl: Element;

    selectedFilters: Filter[] = [];

    get assetFilter(): AssetFilter {
        return this.assetFiltersService.assetFilter;
    }

    pagination: Pagination;

    filtersExpanded = false;

    readonly assetsListTabs = ASSETS_LIST_TABS;
    activeTab: string = ASSETS_LIST_TABS[0];

    readonly assetCountByTabAndStatusMap: Map<string, Map<AssetStatus, number>> = new Map<string, Map<AssetStatus, number>>(
        ASSETS_LIST_TABS.map(tab => [
            tab,
            new Map<AssetStatus, number>(Object.keys(AssetStatus).map(status => [status, -1] as [AssetStatus, number])),
        ])
    );

    loading: boolean = false;
    assets: AssetDto[];

    private readonly assets$ = combineLatest([
        this.assetFiltersService.pageFilterChanged$,
        this.assetFiltersService.assetFilterChanged$,
    ]).pipe(
        takeUntil(this.destroy$),
        tap(() => (this.loading = true)),
        switchMap(([pageFilter, assetFilter]) =>
            (this.asset ? this.getDependencies(this.asset, pageFilter) : this.getAssets(pageFilter, assetFilter)).pipe(
                tap(page => (this.pagination = Pagination.of(page))),
                map(page => page.content.map(asset => ({ ...asset, status: asset.status || AssetStatus.DRAFT }))),
                tap(
                    assets => {
                        this.assets = assets;
                        this.loaded.emit(assets?.length > 0);
                    },
                    () => {
                        this.loaded.emit(false);
                        this.loading = false;
                    },
                    () => (this.loading = false)
                )
            )
        )
    );

    get paginationTranslatedText(): Partial<Record<TranslationKeysOfPagination, string>> {
        return this.translationService.paginationTranslatedText;
    }

    get assetStatus(): typeof AssetStatus {
        return AssetStatus;
    }

    get currentUser(): UserDto {
        return this.authService.getUser();
    }

    get isLoggedIn(): boolean {
        return this.currentUser !== null;
    }

    constructor(
        private readonly assetsService: AssetsService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly translationService: TranslationService,
        private readonly authService: AuthService,
        private readonly assetFiltersService: AssetFiltersService,
        private readonly zone: NgZone
    ) {}

    ngOnInit(): void {
        this.assets$.subscribe();

        if (this.hasFiltersBlock) {
            this.initSplitterObserver();
            this.observeFilters();
        }
        if (this.hasFilterByStatus) {
            this.countAssets();
        }
        this.assetFiltersService.init(this.hasRouteObservation, this.hasFilterByStatus, this.hasFilterByGroup, this.hasFiltersBlock);
    }

    ngAfterViewInit(): void {
        if (this.hasFiltersBlock) {
            this.splitterEl = document.getElementById(this.splitterId);
            this.resizeObserver.observe(this.splitterEl);
        }
    }

    ngOnDestroy() {
        if (this.hasFiltersBlock) {
            this.resizeObserver.unobserve(this.splitterEl);
        }
        this.destroy$.next();
    }

    private initSplitterObserver() {
        this.resizeObserver = new ResizeObserver(entries => {
            entries.forEach(entry => {
                const minWidth = 925 + (this.splitter.direction === 'vertical' ? 17 : 0);
                const newDirection = entry.contentRect.width > minWidth ? 'horizontal' : 'vertical';
                this.zone.run(() => {
                    this.splitter.displayedAreas[1].component.elRef.nativeElement.style.width =
                        newDirection === 'horizontal' ? entry.contentRect.width - this.splitter.displayedAreas[0].size - 11 + 'px' : null;
                    if (newDirection !== this.splitter.direction) {
                        this.splitter.direction = newDirection;
                    }
                });
            });
        });
    }

    private observeFilters() {
        combineLatest([this.assetFiltersService.selectedCategoryFilters$, this.assetFiltersService.selectedFilters$])
            .pipe(
                takeUntil(this.destroy$),
                map(([categoryFilters, filters]) => [...categoryFilters, ...filters])
            )
            .subscribe(filters => (this.selectedFilters = filters));
    }

    private countAssets() {
        combineLatest([this.assetFiltersService.groupIdsChanged$, this.assetFiltersService.searchTermChanged$])
            .pipe(
                takeUntil(this.destroy$),
                switchMap(([groupIds, searchTerm]) =>
                    combineLatest(
                        ASSETS_LIST_TABS.map((tab, i) =>
                            this.assetsService
                                .getManagedAssetsCount({
                                    ...this.assetFilter,
                                    status: null,
                                    searchTerm,
                                    groupIds: i === 0 ? [] : [this.currentUser.groupId],
                                })
                                .pipe(map(assetCount => ({ tab, assetCount })))
                        )
                    ).pipe(
                        tap(assetCountsByTab =>
                            assetCountsByTab.forEach(assetCount => {
                                this.assetCountByTabAndStatusMap.get(assetCount.tab).set(null, assetCount.assetCount);
                            })
                        ),
                        switchMap(() => {
                            const tab: string = ASSETS_LIST_TABS[!groupIds?.length ? 0 : 1];
                            return this.assetCountByTabAndStatusMap.get(tab).get(null) !== 0
                                ? combineLatest(
                                      Array.from(this.assetCountByTabAndStatusMap.get(tab).keys())
                                          .filter(status => status !== null)
                                          .map(status =>
                                              this.assetsService
                                                  .getManagedAssetsCount({
                                                      ...this.assetFilter,
                                                      searchTerm,
                                                      status,
                                                  })
                                                  .pipe(map(assetCount => ({ status, assetCount })))
                                          )
                                  ).pipe(
                                      tap(assetCountsByStatus =>
                                          assetCountsByStatus.forEach(assetCount =>
                                              this.assetCountByTabAndStatusMap.get(tab).set(assetCount.status, assetCount.assetCount)
                                          )
                                      )
                                  )
                                : of();
                        })
                    )
                )
            )
            .subscribe();
    }

    handleManagementAction(actionResponse: AssetActionResponse) {
        if (!this.hasFilterByStatus || !this.hasGroupTabs) {
            return;
        }

        const asset: AssetDto = this.assets.find(a => a.id == actionResponse.assetId && a.assetVersionId == actionResponse.versionId);

        if (!asset) {
            return;
        }

        const assetCountMapOnTab = this.assetCountByTabAndStatusMap.get(
            ASSETS_LIST_TABS[!this.assetFiltersService.assetFilter.groupIds?.length ? 0 : 1]
        );
        assetCountMapOnTab.set(asset.status, assetCountMapOnTab.get(asset.status) - 1);
        assetCountMapOnTab.set(actionResponse.newStatus, assetCountMapOnTab.get(actionResponse.newStatus) + 1);

        asset.status = actionResponse.newStatus;

        if (this.assetFiltersService.assetFilter.status) {
            this.assets = this.assets.filter(a => a.id != actionResponse.assetId && a.assetVersionId != actionResponse.versionId);
        }
    }

    getStatusTextByStatus(statusText: string): string {
        return statusText.split('_').join(' ');
    }

    changeNav(tab: string) {
        this.activeTab = tab;

        if (this.hasFilterByGroup) {
            const newState: Partial<AssetFilter> = {
                groupIds: tab === ASSETS_LIST_TABS[0] ? [] : [this.currentUser.groupId],
                status: null,
            };
            if (this.hasRouteObservation) {
                this.assetFiltersService.updateRouteByAssetFilter(newState);
            } else {
                this.assetFiltersService.forceUpdateAssetFilter(newState);
            }
        }
    }

    private getAssets(pageFilter: PageFilter, assetFilter: AssetFilter) {
        if (this.hasFilterByGroup) {
            const newTab = ASSETS_LIST_TABS[assetFilter.groupIds?.length ? 1 : 0];
            if (this.activeTab !== newTab) {
                this.activeTab = newTab;
            }
        }
        const substitutionPageFilter: PageFilter = {
            ...pageFilter,
            sort: this.sortSubstitution(pageFilter.sort),
        };

        return this.assetsService.get(substitutionPageFilter, assetFilter);
    }

    private getDependencies(asset: AssetDto, pageFilter: PageFilter) {
        return this.assetsService.dependencies(asset.id, asset.assetVersionId, pageFilter);
    }

    private sortSubstitution(sortString: string) {
        const version: string = this.isManageAssets ? 'latestVersion' : 'currentVersion';
        SORTING_FIELDS_DEPENDENT_BY_VERSION.forEach(
            dependentField => (sortString = sortString.replace(dependentField, `${version}.${dependentField}`))
        );
        return sortString;
    }

    onPageChange({ currentPage, pageSize }: Pager) {
        const newState: Partial<PageFilter> = {
            page: currentPage - 1,
            pageSize,
        };
        if (this.hasRouteObservation) {
            this.assetFiltersService.updateRouteByPageFilter(newState);
        } else {
            this.assetFiltersService.forceUpdatePageFilter(newState);
        }
        document.querySelector('#asset-list-wrapper').scrollIntoView();
    }

    changeStatusFilter(status: AssetStatus) {
        if (this.hasRouteObservation) {
            this.assetFiltersService.updateRouteByAssetFilter({ status: status || null });
        } else {
            this.assetFiltersService.forceUpdateAssetFilter({ status: status || null });
        }
    }

    removeFilter(filter: Filter) {
        if (this.hasRouteObservation) {
            switch (filter.type) {
                case 'PRIMARY_CATEGORY':
                    this.assetFiltersService.updateRouteByAssetFilter({
                        primaryCategoryId: null,
                    });
                    break;
                case 'CATEGORY':
                    this.assetFiltersService.updateRouteByAssetFilter({
                        categoryId: null,
                    });
                    break;
                case 'PUBLISHER':
                    this.assetFiltersService.updateRouteByAssetFilter({
                        groupIds: this.assetFilter.groupIds.filter(id => id != filter.id),
                    });
                    break;
                case 'TAXONOMY':
                    this.assetFiltersService.updateRouteByAssetFilter({
                        dropdownIds: this.assetFilter.dropdownIds.filter(id => id != filter.id),
                    });
                    break;
                case 'METADATA_BY_CATEGORY':
                    this.assetFiltersService.updateRouteByAssetFilter({
                        fieldValues: this.assetFilter.fieldValues.filter(id => id != filter.id),
                    });
                    break;
            }
        } else {
            switch (filter.type) {
                case 'PRIMARY_CATEGORY':
                    this.assetFiltersService.forceUpdateAssetFilter({
                        primaryCategoryId: null,
                    });
                    break;
                case 'CATEGORY':
                    this.assetFiltersService.forceUpdateAssetFilter({
                        categoryId: null,
                    });
                    break;
                case 'PUBLISHER':
                    this.assetFiltersService.forceUpdateAssetFilter({
                        groupIds: this.assetFilter.groupIds.filter(id => id != filter.id),
                    });
                    break;
                case 'TAXONOMY':
                    this.assetFiltersService.forceUpdateAssetFilter({
                        dropdownIds: this.assetFilter.dropdownIds.filter(id => id != filter.id),
                    });
                    break;
                case 'METADATA_BY_CATEGORY':
                    this.assetFiltersService.forceUpdateAssetFilter({
                        fieldValues: this.assetFilter.fieldValues.filter(id => id != filter.id),
                    });
                    break;
            }
        }
    }

    removeAllFilters() {
        const newState: Partial<AssetFilter> = {
            primaryCategoryId: null,
            categoryId: null,

            groupIds: null,
            dropdownIds: null,
            fieldValues: null,
        };
        if (this.hasRouteObservation) {
            this.assetFiltersService.updateRouteByAssetFilter(newState);
        } else {
            this.assetFiltersService.forceUpdateAssetFilter(newState);
        }
        this.filtersExpanded = false;
    }
}

import { InjectionToken, Injector } from '@angular/core';

import { EchartsLibrary } from './echarts/echarts.library';
import { Subject } from 'rxjs';

export type ChartType =
    | 'areachart'
    | 'stackedbarchart'
    | 'doughnutchart'
    | 'groupedbarchart'
    | 'piechart'
    | 'linechart'
    | 'timeserieschart'
    | 'boxplotchart'
    | 'multipleyaxischart'
    | 'horizontalstackedbarchart'
    | 'axislinechart'
    | 'customChart'
    | 'ganttChart'
    | 'radialChart'
    | 'sunburstchart'
    | 'treemap'
    | 'simplelinechart'
    | 'scatterchart'
    | 'basiclinechart'
    | 'gaugechart'
    | 'multipleseriestypechart';

export interface Chart {
    options: any;
    assignOptions: (optionsToAssign: Record<string, any>) => void;
    handleEvents?: (emitter: Subject<{ type: string; payload: any }>, chartInstance?: any, injector?: Injector) => void;
    destroy?: () => void;
}

export interface ChartLibrary {
    charts: { [key in ChartType]: new () => Chart };
}

export const CHART_LIBRARY = new InjectionToken<ChartLibrary>('ChartLibrary', {
    providedIn: 'root',
    factory: () => EchartsLibrary,
});

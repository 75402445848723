import {
    AfterViewInit,
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    NgModule,
    NgZone,
    OnChanges,
    OnDestroy,
    Output
} from '@angular/core';
import {fromEvent, Observable, Subscription} from 'rxjs';
import {mergeMap, takeUntil, tap} from 'rxjs/operators';

@Directive({
    // eslint-disable-next-line
    selector: 'svg[zoom]',
    exportAs: 'zoom',
})
export class SvgZoomDirective implements AfterViewInit, OnChanges, OnDestroy {
    @Input() scaleSensitivity = 0.25;

    @Input() minZoom = 0.1;

    @Input() maxZoom = 30;

    @Input() zoomDisabled: boolean;

    @Input() zoomViewMode = false;

    @Input() customSizeMode = false;

    @Input() shiftX: number = 50;

    @Input() shiftY: number = 50;

    @Output() svgSize = new EventEmitter();

    @Input() svgModeChanged$: Observable<boolean>;

    @Input() customWidth: number;

    @Input() customHeight: number;

    @Input() usingCtrl = false;

    originalState = {
        zoom: 1,
        x: 50,
        y: 50,
    };

    lastMouseWheelEventTime: number = Date.now();

    viewPort: any;

    activeState: any;

    pendingUpdate = false;

    subscription = Subscription.EMPTY;

    get svg() {
        return this.svgElem.nativeElement;
    }

    constructor(private svgElem: ElementRef<SVGSVGElement>, private ngZone: NgZone) {}

    ngOnChanges() {
        this.originalState.x = this.shiftX;
        this.originalState.y = this.shiftY;
    }

    ngAfterViewInit() {
        this.viewPort = this.svg.querySelector('g');
        this.reset();
        if (this.svgModeChanged$) {
            this.svgModeChanged$.subscribe(defaultMode => {
                if (defaultMode) {
                    this.ngOnDestroy();
                    const { x, y, width, height } = this.svg.getBBox();
                    this.svg.setAttribute('viewBox', `${x - 20} ${y - 20} ${width + 50} ${height + 50}`);
                    return;
                } else {
                    this.ngOnDestroy();
                    const { x, y, width, height } = this.svg.getBBox();
                    this.svg.setAttribute('viewBox', `${x - 20} ${y - 20} ${width + 50} ${height + 50}`);
                    this.initZoom();
                    this.initDrag();
                    return;
                }
            });
        }

        this.ngZone.runOutsideAngular(() => {
            if (this.customSizeMode) {
                const { x, y, width, height } = this.svg.getBBox();
                if (height > this.customHeight) {
                    this.svg.setAttribute('viewBox', `${x - 20} ${y - 20} ${width + 50} ${height + 50}`);
                } else if (width > this.customWidth) {
                    this.svg.setAttribute('viewBox', `${x - 20} ${y - 20} ${width + 50} ${height + 50}`);
                } else {
                    const blockWidth = 120;
                    const blockHeight = 28;

                    const blockAndHalfLineWidth = 210;
                    const blockAndLineHeight = 73;

                    const coefficientX = Math.ceil(width / blockAndHalfLineWidth);
                    const coefficientY = Math.ceil(height / blockAndLineHeight) + 1;
                    const newX = this.customWidth / 2 - blockWidth * coefficientX;
                    const newY = this.customHeight / 2 - blockHeight * coefficientY;
                    this.svg.setAttribute('viewBox', `${-newX} ${-newY} ${this.customWidth} ${this.customHeight}`);
                }
                this.svgSize.next({ width: width, height: height });
                return;
            }
            if (this.zoomViewMode) {
                const { x, y, width, height } = this.svg.getBBox();
                this.svg.setAttribute('viewBox', `${x - 20} ${y - 20} ${width + 50} ${height + 50}`);
                return;
            }
            this.initZoom();
            this.initDrag();
        });
    }

    initZoom() {
        this.svg.addEventListener('wheel', this.onMouseWheel);
    }

    initDrag() {
        const mouseDown$ = fromEvent(this.svg, 'mousedown').pipe(tap(() => document.body.classList.add('cursor-move')));
        const mouseMove$ = fromEvent(document, 'mousemove');
        const mouseUp$ = fromEvent(document, 'mouseup').pipe(tap(() => document.body.classList.remove('cursor-move')));

        let firstEventCTM: any;
        let stateOrigin: any;
        const mouseDrag$ = mouseDown$.pipe(
            mergeMap((event: any) => {
                this.viewPort.style.WebkitTransition = '';
                this.viewPort.style.MozTransition = '';

                firstEventCTM = this.getCTM();
                stateOrigin = this.createSVGPoint(event.clientX, event.clientY).matrixTransform(firstEventCTM.inverse());

                return mouseMove$.pipe(takeUntil(mouseUp$));
            })
        );

        this.subscription = mouseDrag$.subscribe((event: any) => {
            const point = this.createSVGPoint(event.clientX, event.clientY).matrixTransform(firstEventCTM.inverse());
            const viewportCTM = firstEventCTM.translate(point.x - stateOrigin.x, point.y - stateOrigin.y);

            this.setCTM(viewportCTM);
        });
    }

    animateTo(targetBlock?: HTMLElement) {
        this.viewPort.style.WebkitTransition = 'transform 1.5s';
        this.viewPort.style.MozTransition = 'transform 1.5s';
        if (!targetBlock) {
            this.reset();
        } else {
            const targetRect = targetBlock.getBoundingClientRect() as DOMRect;
            const svgRect = this.svg.getBoundingClientRect() as DOMRect;
            const svgCenter = {
                x: svgRect.x + svgRect.width / 2,
                y: svgRect.y + svgRect.height / 2
            };
            if (targetRect.x > svgCenter.x) {
                this.moveTo(targetRect, true);
            } else if (targetRect.x < svgRect.x) {
                this.moveTo(targetRect);
            }
        }
    }

    moveTo(targetBox: { x: number; y: number; width: number; height: number }, center = false) {
        const firstEventCTM = this.getCTM();

        const getInversedPoint = (xNum: number, yNum: number) => this.createSVGPoint(xNum, yNum).matrixTransform(firstEventCTM.inverse());

        const boxTopLeftPoint = getInversedPoint(targetBox.x, targetBox.y);
        const boxCenterPoint = getInversedPoint(
            targetBox.x + (center ? targetBox.width / 2 : 0),
            targetBox.y + (center ? targetBox.height / 2 : 0)
        );

        const { x, y, width, height } = this.svg.getBoundingClientRect() as DOMRect;
        const svgPoint = getInversedPoint(x + this.originalState.x, y + this.originalState.y);
        const svgCenterPoint = getInversedPoint(x + width / 2, y + height / 2);

        const viewportCTM = firstEventCTM.translate(
            center ? (svgCenterPoint.x - boxCenterPoint.x) : (svgPoint.x - boxTopLeftPoint.x),
            svgPoint.y - boxTopLeftPoint.y
        );

        this.setCTM(viewportCTM);
    }

    onMouseWheel = (event: any) => {
        if (this.zoomDisabled || (this.usingCtrl && !event.ctrlKey)) {
            return;
        }

        if (this.usingCtrl && event.ctrlKey) {
            event.preventDefault();
        }

        let delta = event.deltaY || 1;
        const timeDelta = Date.now() - this.lastMouseWheelEventTime;
        const divider = 3 + Math.max(0, 30 - timeDelta);

        // Update cache
        this.lastMouseWheelEventTime = Date.now();

        // Make empirical adjustments for browsers that give deltaY in pixels (deltaMode=0)
        if ('deltaMode' in event && event.deltaMode === 0 && event.wheelDelta) {
            delta = event.deltaY === 0 ? 0 : Math.abs(event.wheelDelta) / event.deltaY;
        }

        delta = -0.3 < delta && delta < 0.3 ? delta : ((delta > 0 ? 1 : -1) * Math.log(Math.abs(delta) + 10)) / divider;

        const inversedCTM = this.svg.getScreenCTM().inverse();
        const relativeMousePoint = this.createSVGPoint(event.clientX, event.clientY).matrixTransform(inversedCTM);
        const zoom = Math.pow(1 + this.scaleSensitivity, -1 * delta);
        this.zoomAtPoint(zoom, relativeMousePoint);
    };

    createSVGPoint(x: any, y: any) {
        const point = this.svg.createSVGPoint();
        point.x = x;
        point.y = y;

        return point;
    }

    zoomAtPoint(zoomScale: any, point: any) {
        const originalState = this.originalState;
        const zoom = this.activeState.zoom;

        // Check boundaries
        if (zoom * zoomScale < this.minZoom * originalState.zoom) {
            zoomScale = (this.minZoom * originalState.zoom) / zoom;
        } else if (zoom * zoomScale > this.maxZoom * originalState.zoom) {
            zoomScale = (this.maxZoom * originalState.zoom) / zoom;
        }

        const oldCTM = this.getCTM();
        const relativePoint = point.matrixTransform(oldCTM.inverse());
        const modifier = this.svg
            .createSVGMatrix()
            .translate(relativePoint.x, relativePoint.y)
            .scale(zoomScale)
            .translate(-relativePoint.x, -relativePoint.y);
        const newCTM = oldCTM.multiply(modifier);

        if (newCTM.a !== oldCTM.a) {
            this.setCTM(newCTM);
        }
    }

    zoom(shift: number) {
        const scaleMap: Record<string, any> = {
            1: 1 + this.scaleSensitivity,
            '-1': 1 / (1 + this.scaleSensitivity),
        };
        const { width, height } = this.svg.getBBox();
        this.zoomAtPoint(scaleMap[shift], this.createSVGPoint(width / 2, height / 2));
    }

    reset(state?: any) {
        const activeState = this.activeState;
        const { x, y } = (this.activeState = { ...(state || this.originalState) });
        this.viewPort.setAttributeNS(null, 'transform', `translate(${x},${y})`);
        const svgRect = this.svg.getBoundingClientRect() as DOMRect;
        this.moveTo(svgRect);
        return activeState;
    }

    getCTM() {
        const matrix = this.svg.createSVGMatrix();
        matrix.a = this.activeState.zoom;
        matrix.b = 0;
        matrix.c = 0;
        matrix.d = this.activeState.zoom;
        matrix.e = this.activeState.x;
        matrix.f = this.activeState.y;

        return matrix;
    }

    doUpdateCTM = () => {
        const { a, b, c, d, e, f } = this.getCTM();
        this.viewPort.style.transform = `matrix(${a},${b},${c},${d},${e},${f})`;
        this.viewPort.style.webkitTransform = `matrix(${a},${b},${c},${d},${e},${f})`;

        this.pendingUpdate = false;
    };

    setCTM({ a, e, f }: any) {
        const isZoomUpdated = this.activeState.zoom !== a;
        const isPanDifferent = this.activeState.x !== e || this.activeState.y !== f;

        if (!isZoomUpdated && !isPanDifferent) {
            return;
        }

        this.activeState = {
            zoom: a,
            x: e,
            y: f,
        };

        if (!this.pendingUpdate) {
            this.pendingUpdate = true;
            requestAnimationFrame(this.doUpdateCTM);
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.svg.removeEventListener('wheel', this.onMouseWheel);
    }
}

@NgModule({
    declarations: [SvgZoomDirective],
    exports: [SvgZoomDirective]
})
export class SvgZoomDirectiveModule {}

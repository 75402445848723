import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Category } from '@app/shared/models/category.model';
import { TreelikeDropdownItem } from '@dagility-ui/kit';
import { AssetType } from '@app/shared/components/assets/asset.model';
import { settingsFuture, threeLayers } from '@app/shared/icons/solutionhub-icons';
import { cloneDeep } from 'lodash';
import { Id } from '@app/shared/services/api/assets.service';
import { ENV_TOKEN } from '@app/tokens';
import { Filter } from '@app/shared/components/assets/asset-filters.service';

@Injectable({
    providedIn: 'root',
})
export class CategoryService {
    readonly categories$: BehaviorSubject<Category[]> = new BehaviorSubject<Category[]>([]);

    readonly categoryFilters$: BehaviorSubject<Filter[]> = new BehaviorSubject<Filter[]>([]);

    readonly categoryTreelikeDropdownItems$: BehaviorSubject<TreelikeDropdownItem[]> = new BehaviorSubject<TreelikeDropdownItem[]>([]);

    private readonly baseUrl = this.env.categoriesApiURL;

    constructor(@Inject(ENV_TOKEN) private readonly env: Env, private readonly http: HttpClient) {
        this.getCategories().subscribe(categories => {
            this.categories$.next(categories);
            this.categoryFilters$.next(this.mapCategoriesToFilters(cloneDeep(categories)));
            this.categoryTreelikeDropdownItems$.next(this.mapCategoriesToTreelikeDropdownItems(cloneDeep(categories)));
        });
    }

    getCategoryByName(name: string) {
        return this.searchCategory(this.categories$.value, name);
    }

    getCategoryById(id: Id) {
        return this.searchCategory(this.categories$.value, id);
    }

    getCategories(groupId: number = null): Observable<Category[]> {
        return this.http.get<Category[]>(this.baseUrl, { params: groupId ? { groupId } : null });
    }

    private mapCategoriesToFilters(categories: Category[], parentCategory?: Category, name?: string): Filter[] {
        const mapCategoryToFilters = (category: Category): Filter[] => {
            if (category.children?.length) {
                return this.mapCategoriesToFilters(category.children, category, name ? `${name}: ${category.name}` : category.name);
            } else {
                const categoryFilter: Filter = {
                    id: category.id,
                    name: name ? `${name}: ${category.name}` : category.name,
                    type: 'CATEGORY',
                    checked: 0,
                    children: [],
                };
                return parentCategory
                    ? [
                          {
                              id: parentCategory.id,
                              name: name ? `${name}` : parentCategory.name,
                              type: 'PRIMARY_CATEGORY',
                              checked: 0,
                              children: [],
                          },
                          categoryFilter,
                      ]
                    : [categoryFilter];
            }
        };

        return categories
            .map(category => mapCategoryToFilters(category))
            .reduce((a, b) => a.concat(b), [])
            .filter((v, i, arr) => arr.map(a => a.id).indexOf(v.id) === i);
    }

    mapCategoriesToTreelikeDropdownItems(categories: Category[]): TreelikeDropdownItem[] {
        return categories.map(c => this.mapCategoryToTreelikeDropdownItem(c));
    }

    private mapCategoryToTreelikeDropdownItem(category: Category): TreelikeDropdownItem {
        const mapCategory = (): TreelikeDropdownItem => {
            return {
                label: category.name,
                value: category.id,
                count: category.assetAmount,
                children: category.children.map(c => this.mapCategoryToTreelikeDropdownItem(c)),
            };
        };

        if (!category.assetType) {
            return mapCategory();
        } else {
            let icon;
            switch (category.assetType) {
                case AssetType.PRODUCT:
                    icon = settingsFuture;
                    break;
                case AssetType.SOLUTION:
                    icon = threeLayers;
                    break;
                default:
                    icon = null;
                    break;
            }
            return { ...mapCategory(), icon };
        }
    }

    private searchCategory(categories: Category[], value: number | string): Category {
        const searchTree = (array: Category[]): Category => {
            let result = null;
            for (let i = 0; result == null && i < array.length; i++) {
                result = searchNode(array[i]);
            }
            return result;
        };

        const searchNode = (item: Category): Category => {
            if (item.id === value || item.name === value) {
                return item;
            } else if (item.children?.length) {
                return searchTree(item.children);
            }
            return null;
        };

        return searchTree(categories);
    }
}

<lib-toastr
    [toastrType]="toastPackage.toastType"
    [title]="title"
    [message]="message"
    [htmlBodyContent]="options.enableHtml"
    [closeButton]="options.closeButton"
    [onCloseFunction]="onClose"
>
    <ng-template footerTemplate let-message>
        <button
            *ngIf="parseJSON(message).extraData && hasAdminUrl && !parseJSON(message).hideReport"
            class="btn btn-link size-s float-right"
            [attr.aria-label]="message"
            (click)="report(parseJSON(message))"
        >
            Report this issue
        </button>
    </ng-template>
</lib-toastr>

import { EChartsChart } from '../echarts.chart';
import { Subject } from 'rxjs';

import { qualitativePalette, subTextColor, textColor } from '../charts-colors';

export class StackedBarEChartsChart extends EChartsChart {
    options: Record<string, any> = {
        textStyle: {
            fontFamily: 'sans-serif',
            fontSize: 12,
        },
        grid: {
            top: '15%',
            bottom: '40%',
            containLabel: true,
        },
        title: {
            text: '',
            subtext: '',
            textAlign: 'center',
            textVerticalAlign: 'top',
            left: '50%',
            textStyle: {
                color: textColor(),
                fontSize: 14,
                fontWeight: '500',
                fontFamily: 'sans-serif',
            },
            subtextStyle: {
                color: subTextColor(),
                fontSize: 12,
                fontFamily: 'sans-serif',
            },
        },
        xAxis: {
            type: 'category',
            renderMode: 'html',
            data: [],
            triggerEvent: true,
            axisLabel: {
                rotate: '45',
                fontSize: 10,
                margin: 11,
                formatter: this.labelFormatter,
                textStyle: {
                    color: textColor(),
                },
            },
            axisLine: {
                onZero: true,
                show: false,
            },
            axisTick: {
                show: false,
                alignWithLabel: true,
            },
            nameLocation: 'center',
            nameGap: 50,
            labelRotationToNameGapMap: {
                45: 80,
                0: 50
            },
            nameTextStyle: {
                color: textColor(),
            },
        },
        yAxis: {
            type: 'value',
            renderMode: 'html',
            data: [],
            triggerEvent: true,
            axisLabel: {
                textStyle: {
                    color: textColor(),
                },
            },
            axisLine: {
                show: false,
            },
            axisTick: {
                show: false,
            },
            nameLocation: 'center',
            nameGap: 44,
            nameTextStyle: {
                color: textColor(),
            },
        },
        legend: {
            bottom: '0',
            icon: 'circle',
            textStyle: {
                fontSize: 11,
                color: textColor(),
            },
            selector: ['all', 'inverse'],
            selectorPosition: 'start',
        },
        series: [],
        color: qualitativePalette,
        tooltip: {
            trigger: 'axis',
            renderMode: 'html',
            triggerEvent: true,
            textStyle: {
                fontSize: 12,
            },
            axisPointer: {
                type: 'line',
                lineStyle: {
                    width: 0,
                },
                label: {
                    show: false,
                },
            },
        },
    };

    typeAttributes = {
        stack: 'one',
    };

    typeAttributesWithoutStack = {};

    protected axisPointerClick = true;

    assignOptions(options: Record<string, any>) {
        // changed for Widget constructor and the below code is for legacy widgets to work
        if (!options.anyWidget) {
            this.typeAttributes = { ...this.typeAttributes, ...{ type: 'bar' }, ...{ areaStyle: {} } };
            this.typeAttributesWithoutStack = { ...this.typeAttributesWithoutStack, ...{ type: 'bar' }, ...{ areaStyle: {} } };
        }
        if (options.yAxis && options.custom) {
            this.options.yAxis = options.yAxis;
        }
        if (options.xAxis && options.custom) {
            this.options.xAxis = options.xAxis
        }
        if (options.series.length > 6) {
            this.options.legend['type'] = 'scroll';
            this.options.legend['pageFormatter'] = 'page {current} of {total}';
            this.options.legend['pageButtonItemGap'] = 30;
        }
        if (options.series && options.series[0] && options.series[0].data[0]?.prop) {
            this.options.tooltip['formatter'] = this.propToolTip.bind(this);
        } else {
            this.options.tooltip['formatter'] = this.toolTipFormatter;
        }

        // for Widget constructor dynamic color implimentation - will be removed later
        const chartColors = this.getDynamicChartColors(options.series);
        if (chartColors.length && (!options.colors || !options.colors.length)) {
            options.colors = chartColors;
        } else if (chartColors.length && options.colors && options.colors.length) {
            options.series = this.removeDynamicCOlors([...options.series]);
        }

        if (
            options.series &&
            options.series.length &&
            options.series[0].hasOwnProperty('colorBy') &&
            options.series[0].colorBy === 'data' &&
            options.colors.length
        ) {
            this.options.color = options.colors;
        }
        super.assignOptions(options);
    }

    removeDynamicCOlors(series: any) {
        return series.map((obj: any) => {
            const itemStyle = obj.data.filter((x: any) => x != null && x.itemStyle);
            if (obj.data.length && itemStyle.length && itemStyle[0].itemStyle && itemStyle[0].itemStyle.color) {
                obj.data = obj.data.map((x: any) => {
                    return x !== null ? { value: x.value } : null;
                });
            }
            return obj;
        });
    }

    getDynamicChartColors(series: any) {
        return series
            .map((obj: any) => {
                let reslt = '';
                const itemStyle = obj.data.filter((x: any) => x != null && x.itemStyle);
                if (obj.data.length && itemStyle.length && itemStyle[0].itemStyle && itemStyle[0].itemStyle.color) {
                    reslt = itemStyle[0].itemStyle.color;
                }
                return reslt;
            })
            .filter((color: string) => color);
    }

    propToolTip(params: any) {
        let output = '';
        (params[0].data.prop || params[0].data.value.prop || []).forEach((elem: any) => {
            output = `${output}${elem}<br/>`;
        });
        if (output === '') {
            const dataIndex = params[0].dataIndex;
            (this.options.series[0].data[dataIndex].prop || []).forEach((element: any) => {
                output = `${output}${element}<br/>`;
            });
        }
        return output;
    }

    toolTipFormatter(params: any) {
        let output = '';
        output += params[0].name;
        params.forEach((param: any) => {
            if (!param || param.value === undefined) {
                return;
            }

            const paramData = param.value.value || param.value.value === 0 ? param.value.value : param.value;
            output +=
                '<span class="d-block"></span><label style="border-radius: 50%;width: 10px;height: 10px;vertical-align: middle;margin: 0;background:' +
                param.color +
                '"></label>&nbsp;&nbsp;' +
                param.seriesName +
                ': ';
            output += '<b>' + paramData + '</b><br/>';
        });

        return output;
    }

    handleEvents(emitter: Subject<{ type: string; payload: any }>, chartInstance: any) {
        if (!chartInstance) {
            return;
        }

        chartInstance.on('click', 'yAxis', (event: any) => {
            if (event.value.length > 0 && event.value.match(/href="/)) {
                const labelPart = event.value.substring(event.value.match(/href="/).index + 6, event.value.length);
                const url = labelPart.substring(0, labelPart.match(/"/).index);
                window.open(url, '_blank');
            }
        });

        super.handleEvents(emitter, chartInstance);
    }
}
